import React, { useState, useRef, useContext, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-light-svg-icons";

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import WhitePopUpNoTitle from "../../../shared/modal/whitePopUp/WhitePopUpNoTitle";
import { useTranslation } from "react-i18next";

const ImageUploadBtn = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState({});
  const fileInputRef = useRef(null);

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const openModal = useCallback(
    (title, text) => {
      setModalIsOpen(true);
      setModalTextContent({ title: title, text: text });
    },
    [modalIsOpen, modalTextContent]
  );

  const uploadImage = async (file) => {
    const fileName = auth.userId;
    let renamedFile = new File([file], fileName);
    // Get Signed URL for AWS S3 upload
    const presignedUrl = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/users/getSignedUrl/${
        auth.userId
      }?filename=${encodeURIComponent(fileName)}`,
      "PUT"
    );

    if (presignedUrl) {
      // Upload image to AWS S3
      await sendRequest(presignedUrl.toString(), "PUT", renamedFile, {
        "Content-Type": "image/jpeg",
      });

      let url = presignedUrl.split("?")[0];

      // Save profile image
      props.saveChanges({ id: "image", value: url });
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];

      // FileReader to validate image header
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // Validate image header
        let arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        // Check if the header matches valid image formats
        if (
          ![
            "89504e47",
            "ffd8ffee",
            "ffd8ffe0",
            "ffd8ffe1",
            "ffd8ffe2",
            "ffd8ffe3",
            "ffd8ffe8",
            "ffd8ffdb",
          ].includes(header)
        ) {
          openModal("Profile", ["Please upload a valid image (PNG or JPEG)"]);
          return; // Stop further processing
        }

        // Validate file size
        const fsize = Math.round(file.size / 1024);
        if (fsize >= 4096) {
          openModal("Profile", [
            "Your file is too large, please select a file less than 4MB",
          ]);
          return;
        } else if (fsize < 21) {
          openModal("Profile", [
            "Your file is too small, please select a file larger than 20KB",
          ]);
          return;
        }

        // If all validations pass
        uploadImage(file);
      };

      // Read the first bytes of the file to trigger onloadend
      fileReader.readAsArrayBuffer(file.slice(0, 4));
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner asOverlay />}
      {modalIsOpen && (
        <WhitePopUpNoTitle
          popupContents={modalTextContent}
          ctaBtn={t("T015")}
          handleSubmit={modalClickHandler}
        />
      )}
      <div className="photo-edit">
        <input
          type="file"
          accept=".jpg, .png, .jpeg"
          onChange={handleImageChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <div onClick={() => fileInputRef.current.click()}>
          <FontAwesomeIcon icon={faCamera} className="camera" />
        </div>
      </div>
    </>
  );
};

export default ImageUploadBtn;
