import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faAward, faFacePensive } from "@fortawesome/pro-thin-svg-icons";

import "../styles/componentsStyles/FinishedChallenge.css";

import completeImg from "../../contentTypes/images/complete.png";
import Confetti from "../../contentTypes/components/Confetti";
import WhiteButton from "../../shared/components/styledComponents/whiteButton/WhiteButton";

const FinishedChallenge = ({ title, subtitle, onClose, isSuccess }) => {
  const { t } = useTranslation();
  const [confettiActive, setConfettiActive] = useState(true);

  useEffect(() => {
    setConfettiActive(true);
    setTimeout(() => {
      setConfettiActive(false);
    }, 10000);
  }, []);

  return (
    <div className="popup-overlay">
      {confettiActive && isSuccess && <Confetti isActive={confettiActive} />}
      <div className="dark-container finishedChallenge">
        <FontAwesomeIcon
          icon={isSuccess ? faAward : faFacePensive}
          className="icon award"
        />
        {isSuccess && (
          <img src={completeImg} alt="`Complete` in large colourful font" />
        )}
        <h1
          className={`finishedChallenge-header ${
            isSuccess
              ? "finishedChallenge-header-success"
              : "finishedChallenge-header-nosuccess"
          }`}
        >
          {title}
        </h1>
        <div className="finishedChallenge-subtitile">{subtitle}</div>
        <WhiteButton onClick={onClose}>
          {t("M012")} <FontAwesomeIcon icon={faArrowRight} className="icon" />
        </WhiteButton>
      </div>
    </div>
  );
};

export default FinishedChallenge;
