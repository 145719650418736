import "./PinkButton1.css";

const PinkButton1 = (props) => (
  <button
    className={`pinkButton1 ${props.className}`}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.children}
  </button>
);

export default PinkButton1;
