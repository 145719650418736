import React from "react";
import "./Modal.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

function ModalTrigger(props) {
  function openModal(event) {
    props.onOpenModal(props.modalText);
  }

  return (
    <span onClick={openModal} className="modal_trigger_container">
      {props.registration ? (
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="rdrop_down_wtf icon"
        />
      ) : (
        <FontAwesomeIcon icon={faInfoCircle} className="icon modal_trigger" />
      )}
    </span>
  );
}
export default ModalTrigger;
