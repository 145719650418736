import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faLockAlt } from "@fortawesome/pro-light-svg-icons";
import { faGridRound } from "@fortawesome/sharp-light-svg-icons";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { colours } from "../../../assets/colours";
import { useTranslation } from "react-i18next";

const SecurityLeftMenu = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {props.profile && (
        <>
          <div className="btn-cont">
            <ChevronBackBtn onClick={() => props.changeMenu(0, "back")} />
          </div>
          <div
            className={
              props.activeSubMenu?.id === 52
                ? "profile-slider-btn active"
                : "profile-slider-btn"
            }
            onClick={() => props.changeSubMenu(1)}
          >
            <div className="menu-item">
              <FontAwesomeIcon
                icon={faLockAlt}
                style={{ color: colours.yellow }}
                className="slider-icon"
              />
              <div className="slider-text">{t("S026")}</div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
          </div>
          <div
            className={
              props.activeSubMenu?.id === 53
                ? "profile-slider-btn active"
                : "profile-slider-btn"
            }
            onClick={() => props.changeSubMenu(2)}
          >
            {" "}
            <div className="menu-item">
              <FontAwesomeIcon
                icon={faGridRound}
                style={{ color: colours.orange }}
                className="slider-icon"
              />
              <div className="slider-text">{t("S028")}</div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
          </div>
        </>
      )}
    </>
  );
};

export default SecurityLeftMenu;
