import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  defaults,
} from "chart.js";

import ModalWrapper from "../../../../../shared/modal/ModalWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";

//corep radar

function RadarGraph(props) {
  ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler);
  defaults.font.family = "futura-pt-medium";
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: "",
        borderColor: "#e92364",
        backgroundColor: "rgba(233,35,100,0.2)",
        data: props.values,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Radar Chart",
    },
    scales: {
      r: {
        angleLines: { color: "#dbd8de" },
        gridLines: { color: "#dbd8de" },
        ticks: {
          display: true,
          maxTicksLimit: 8,
          beginAtZero: true,
          suggestedMin: 100,
          suggestedMax: 100,
        },
        min: 0,
        max: 100,
        display: true,
        pointLabels: {
          font: { size: 16 },
          color: "#332938",
        },
      },
    },
    responsive: true,
    tooltips: {
      enabled: true,
      displayColors: false,
      titleFontSize: 0,
    },
    plugins: {
      filler: {
        propagate: true,
      },
    },
  };

  return (
    <div
      className={`${
        props.halfSquare ? "square half_square" : "square wide wide_square"
      }`}
    >
      <div className="square_content">
        {props.modalText && (
          <ModalWrapper
            modalText={props.modalText}
            onOpenModal={props.onOpenModal}
          >
            <FontAwesomeIcon icon={faInfo} className="icon info_icon" />
          </ModalWrapper>
        )}
        {props.title ? <h3>{props.title}</h3> : ""}
        {props.subTitle ? (
          <span className="subtitle">{props.subTitle}</span>
        ) : (
          ""
        )}
        <Radar data={data} options={options} width={600} height={600} />
      </div>
    </div>
  );
}

export default RadarGraph;
