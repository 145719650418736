import React, { useState, useEffect } from "react";
import "../styles/componentStyles/CircleGraph.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

function CircleGraph(props) {
  return (
    <div className={"chart-container " + props.width}>
      <div className="title-container">
        <div className="title">{props.title}</div>
        <div className="wtf">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="wtf icon"
            onClick={() => props.openModal(props.title, props.modalText)}
          />
        </div>
      </div>
      <div className="chart-box">
        <div className="circle_chart">
          {props.icon && (
            <FontAwesomeIcon icon={props.icon} className="chart-icon" />
          )}
          {props.value && (
            <h3 className="fs-mask" style={{ color: props.colour }}>
              {props.value}
            </h3>
          )}
          {props.discriptor && <span>{props.discriptor}</span>}
        </div>
      </div>
    </div>
  );
}

export default CircleGraph;
