import React, { useEffect, useState, useContext } from "react";

import "./Support.css";

import { useTranslation } from "react-i18next";
import { AuthContext } from "../shared/context/auth-context";
import Header from "../shared/header/MainHeader";
import Footer from "../shared/navfooter/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faComments,
  faHouse,
  faLifeRing,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";

import { useHttpClient } from "../shared/hooks/http-hook";
import DarkButton from "../shared/components/styledComponents/darkButton/DarkButton";

const Support = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const [regionForSupport, setRegionForSupport] = useState(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const getRegion = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/support`
        );
        if (responseData?.region) {
          setRegionForSupport(responseData.region);
        }
      } catch (err) {}
    };
    getRegion();
  }, []);

  return (
    <div id="main_container" className="foryou health-area support platform">
      <div className="wrap-screen">
        {auth.isLoggedIn && (
          <React.Fragment>
            <div id="nav_container" className="no-submenu">
              <Header />
            </div>
            <div className="heading">
              {/* <Link to="/for-you">
                <FontAwesomeIcon icon={faHouse} className="icon home" />
              </Link>
              <FontAwesomeIcon icon={faChevronRight} className="icon" /> */}
              <h2>{t("H001")}</h2>
            </div>
            <div className="support-cont">
              <div className="support-frame">
                <div className="life-ring-crisis">
                  <FontAwesomeIcon icon={faLifeRing} />
                  <span className="text-wrapper-7">&nbsp;&nbsp;</span>
                  <span className="text-wrapper-7">{t("H002")}</span>
                </div>
                <div className="p">{t("H005")}</div>
                <a
                  href={
                    regionForSupport
                      ? `https://championhealth.co.uk/help-and-support/signposting/?_sft_regions=${regionForSupport}&_sft_health_area=crisis-support`
                      : "https://championhealth.co.uk/help-and-support/signposting/?_sft_health_area=crisis-support"
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <DarkButton className="support-button">
                    {t("H006")}
                  </DarkButton>
                </a>
              </div>
              <div className="support-frame">
                <div className="life-ring-crisis">
                  <FontAwesomeIcon icon={faComments} />
                  <span className="text-wrapper-7">&nbsp;&nbsp;</span>
                  <span className="text-wrapper-7">{t("H003")}</span>
                </div>
                <div className="p">{t("H007")}</div>
                <a
                  href={
                    regionForSupport
                      ? `https://championhealth.co.uk/help-and-support/signposting/?_sft_regions=${regionForSupport}`
                      : "https://championhealth.co.uk/help-and-support/signposting/"
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <DarkButton className="support-button">
                    {t("H008")}
                  </DarkButton>
                </a>
              </div>{" "}
              <div className="support-frame">
                <div className="life-ring-crisis">
                  <FontAwesomeIcon icon={faWrench} />
                  <span className="text-wrapper-7">&nbsp;&nbsp;</span>
                  <span className="text-wrapper-7">{t("H004")}</span>
                </div>
                <div className="p">{t("H009")}</div>
                <a
                  href={"https://support.championhealth.co.uk/"}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <DarkButton className="support-button">
                    {t("H010")}
                  </DarkButton>
                </a>
              </div>
            </div>
            <Footer />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
export default Support;
