import React from "react";
import { useTranslation } from "react-i18next";

function Completed(props) {
  const { t } = useTranslation();
  return (
    <div className={"question_container"} id={props.questionid}>
      <div style={{ display: "block", width: "100%" }}>
        <h2>{props.questionHeading}</h2>
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <p>{props.questionText}</p>
      </div>
      <a href="/individual-report/overview">{t("A115")}</a>
      {JSON.stringify(props.assessmentValues)}
    </div>
  );
}

export default Completed;
