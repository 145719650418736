import React from "react";
//import './Style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';


function CheckboxItem(props) {
   
  function setRadioHandler(event) {  

    const newItem = {
      add: event.target.checked,
      id: event.target.id,
      title: event.target.title
    };
    props.onAddRadioItem(newItem);
  }

  return (
      <div className="checkbox_item">
          <input type="radio" name={props.name} id={props.secondaryQ ? props.item.id + '_sq' : props.item.id} className="fdatacollect" onChange={setRadioHandler} value={props.item.id} title={props.item.title} checked={props.checked} />
          <label htmlFor={props.secondaryQ ? props.item.id + '_sq' : props.item.id}><FontAwesomeIcon icon={faCheckCircle} className="icon" />{props.item.title}</label>
      </div>
 );}

export default CheckboxItem;