// store.js
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../profile/userSlice"; // import your user slice
import goalsReducer from "../goals/goalsSlice"; // import your goals slice
import mediaSlidersReducer from "../forYou/mediaSlidersSlice";
import mediaReducer from "../contentTypes/mediaSlice";
import challengesReducer from "../challenges/challengesSlice";
import { challengesApiSlice } from "../challenges/challengesApiSlice";
import { userApiSlice } from "../profile/userApiSlice"; // import your user api slice
import { goalsApiSlice } from "../goals/goalsApiSlice"; // import your goals api slice
import { mediaSlidersApiSlice } from "../forYou/mediaSlidersApiSlice";
import { mediaApiSlice } from "../contentTypes/mediaApiSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    goals: goalsReducer,
    mediaSliders: mediaSlidersReducer,
    media: mediaReducer,
    challenges: challengesReducer,
    [userApiSlice.reducerPath]: userApiSlice.reducer,
    [goalsApiSlice.reducerPath]: goalsApiSlice.reducer,
    [mediaSlidersApiSlice.reducerPath]: mediaSlidersApiSlice.reducer,
    [mediaApiSlice.reducerPath]: mediaApiSlice.reducer,
    [challengesApiSlice.reducerPath]: challengesApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApiSlice.middleware)
      .concat(goalsApiSlice.middleware)
      .concat(mediaSlidersApiSlice.middleware)
      .concat(mediaApiSlice.middleware)
      .concat(challengesApiSlice.middleware),
});
