import React from "react";

import "./AccessDenied.css";

function accessDenied(props) {
  return (
    <div id="accessDenied_container">
      <div className="fandf_warningSign_access"></div>
      Sorry, you cannot access this page.
    </div>
  );
}
export default accessDenied;
