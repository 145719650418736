import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faPersonSkiing,
} from "@fortawesome/pro-light-svg-icons";

import ActivityTracker from "./ActivityTracker";

const MovementCover = (props) => {
  const { t } = useTranslation();

  const [areaPicker, setAreaPicker] = useState(true);
  const [openActivity, setOpenActivity] = useState(false);

  const handleActivity = () => {
    const main = document.getElementById("snm-main");
    main?.classList.remove("slide-in");
    setTimeout(() => {
      setAreaPicker(false);
      setOpenActivity(true);
    }, 500);
  };

  useEffect(() => {
    if (openActivity) {
      const activity = document.getElementById("snm-activity");
      setTimeout(() => {
        activity?.classList.add("slide-in");
      }, 300);
    }
  }, [openActivity]);

  useEffect(() => {
    if (areaPicker) {
      const main = document.getElementById("snm-main");
      setTimeout(() => {
        main?.classList.add("slide-in");
      }, 300);
    }
  }, [areaPicker]);

  const handleBackToMain = () => {
    if (openActivity) {
      const activity = document.getElementById("snm-activity");
      activity?.classList.remove("slide-in");
      setTimeout(() => {
        setOpenActivity(false);
        setAreaPicker(true);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      {areaPicker && (
        <div id="snm-main" className="tracker-container">
          <div className="ind-tracker mood">
            <div className="title">{t("T134")}</div>
            <div className="mood-area" onClick={handleActivity}>
              <div>
                <FontAwesomeIcon icon={faPersonSkiing} className="mood-icon" />{" "}
                {t("T346")}{" "}
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="buttons mood">
            <div className="close-btn" onClick={props.closeTrackers}>
              {t("T015")}
            </div>
          </div>
        </div>
      )}{" "}
      {openActivity && (
        <ActivityTracker
          handleBackToMain={handleBackToMain}
          closeTrackers={props.closeTrackers}
        />
      )}
    </React.Fragment>
  );
};
export default MovementCover;
