import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../shared/context/auth-context";
import { useFetchProgrammeQuery } from "../mediaApiSlice";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { setProgramme } from "../mediaSlice";
import ProgrammePlayer from "../components/ProgrammePlayer";

const ProgrammePlayerWrap = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const chid = window.location.pathname.split("/")[2];

  const mediaCHID = useSelector((state) => state.media.programme.chid);
  const progress = useSelector((state) => state.media.programme.progress);

  const fetchResult = useFetchProgrammeQuery({
    token: auth.token,
    chid: chid,
  });

  const { data: programme, isFetching } =
    mediaCHID !== chid ? fetchResult : { data: null, isFetching: false };

  useEffect(() => {
    if (!isFetching && programme) {
      if (mediaCHID !== chid || progress !== programme.progress) {
        dispatch(
          setProgramme({
            _id: programme._id,
            completed: programme.completed,
            minutes_watched: programme.minutes_watched,
            days: programme.days,
            progress: programme.progress,
            related_media: programme.related_media,
            chid: programme.chid,
            title: programme.title,
            description: programme.description,
            label: programme.label,
            tags: programme.tags,
            signposting: programme.signposting,
            length: programme.length,
            author: programme.author,
            background_image: programme.background_image,
            thumbnail_image: programme.thumbnail_image,
            favourite: programme.favourite,
            like: programme.like,
            dislike: programme.dislike,
            chapters: programme.chapters,
            resources: programme.resources,
          })
        );
      }
    }
  }, [dispatch, programme]);

  return (
    <div id="cover-page">
      {isFetching ? <LoadingSpinner asOverlay /> : <ProgrammePlayer />}
    </div>
  );
};
export default ProgrammePlayerWrap;
