import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import TopSixGraph from "../../components/graphs/topSixGraph/TopSixGraph";
import { graphLimit, listLimit, getDisabledData, DISABLED_GRAPHS } from "../../../../shared/util/companyReport";

function BehaviourChange({ data, coData, onOpenModal }) {
  const { t } = useTranslation();
  const { areas_to_change, barriers_to_change, barriers_to_change_new, goals } =
    data;
  const {
    counterGoalsOldVersion,
    counterOldVersion,
    counterNewVersion,
    showOldFocus,
    goalsCounter,
  } = coData;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <React.Fragment>
      <TopSixGraph
        title={t("BC004")}
        square
        preText={
          goalsCounter >= graphLimit
            ? `${t("BC012")} ${goalsCounter} ${t("BC013")}`
            : ""
        }
        data={
          goals.length < listLimit || goalsCounter < graphLimit
            ? getDisabledData(DISABLED_GRAPHS.GOALS)
            : goals
        }
        modalText={t("BC006")}
        onOpenModal={onOpenModal}
        disabled={goals.length < listLimit || goalsCounter < graphLimit}
      />
      {showOldFocus && areas_to_change.length >= listLimit && (
        <TopSixGraph
          title={t("BC015")}
          square
          preText={
            counterGoalsOldVersion >= graphLimit
              ? `${t("BC012")} ${counterGoalsOldVersion} ${t("BC013")}`
              : ""
          }
          data={areas_to_change}
          modalText={t("BC016")}
          onOpenModal={onOpenModal}
        />
      )}
      <TopSixGraph
        title={t("BC007")}
        square
        preText={
          counterNewVersion >= graphLimit &&
          barriers_to_change_new.length > listLimit
            ? `${t("BC012")} ${counterNewVersion} ${t("BC013")}`
            : ""
        }
        data={
          counterNewVersion >= graphLimit &&
          barriers_to_change_new.length >= listLimit
            ? barriers_to_change_new
            : getDisabledData(DISABLED_GRAPHS.BARRIERS)
        }
        modalText={t("BC009")}
        onOpenModal={onOpenModal}
        disabled={counterNewVersion < graphLimit || barriers_to_change_new.length < listLimit}
      />
      {counterOldVersion >= graphLimit &&
        barriers_to_change.length >= listLimit && (
          <TopSixGraph
            title={t("BC010")}
            square
            preText={
              counterOldVersion >= graphLimit
                ? `${t("BC012")} ${counterOldVersion} ${t("BC013")}`
                : ""
            }
            data={barriers_to_change}
            modalText={t("BC011")}
            onOpenModal={onOpenModal}
          />
        )}
    </React.Fragment>
  );
}
export default BehaviourChange;
