import React, { useEffect, useRef, useState } from "react";

import BoardListRow from "./BoardListRow";
import "../styles/componentsStyles/BoardList.css";

const BoardList = ({ users, challengeType, userPlace, leaders }) => {
  const [dataToMap, setDataToMap] = useState([]);
  const listRef = useRef(null);

  useEffect(() => {
    if (leaders.length > 0 && userPlace > 6) {
      const filteredLeaders = leaders.filter(
        (x) => !users.some((y) => y.place === x.place)
      );
      setDataToMap(filteredLeaders);
    }
  }, [userPlace, leaders]);

  useEffect(() => {
    if (listRef.current) {
      const userElement = listRef.current.querySelector(".boardList-row-user");
      if (userElement) {
        const offsetTop = userElement.offsetTop;
        listRef.current.scrollTo(0, offsetTop - 91);
      }
    }
  }, [userPlace]);

  return (
    <div className="boardList-wrapper">
      <div className="boardList-overlay-top" />
      <div className="boardList-overlay-bottom" />
      <div className="boardList" ref={listRef}>
        {userPlace > 6 && dataToMap.length > 0 && (
          <div>
            {dataToMap.map((leader, index) => {
              return (
                <BoardListRow
                  key={`leaders-${index}`}
                  user={leader}
                  userPlace={userPlace}
                  challengeType={challengeType}
                  leaderBoard
                />
              );
            })}
          </div>
        )}
        {userPlace > 9 &&
          dataToMap.length === 3 &&
          users.length > 0 &&
          users[0].place !== 4 && <div className="boardList-dots">...</div>}
        {users.map((user, index) => {
          return (
            <BoardListRow
              key={`users-${index}`}
              user={user}
              userPlace={userPlace}
              challengeType={challengeType}
            />
          );
        })}
        {users.length < 5 &&
          Array.from(Array(5 - users.length).keys()).map((_, index) => (
            <div className={"boardList-row"} key={`empty-${index}`}>
              <div className={"boardList-user boardList-user-empty"}>
                <div className="boardList-avatar"></div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BoardList;
