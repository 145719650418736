import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

import {
  faCircleChevronLeft,
  faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";

import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton";
import TeamChallenge from "../components/TeamChallenge";
import Journal from "../components/Journal";
import Influencers from "../components/Influencers";
import HorizontalSlider from "../components/HorizontalSlider";
import TrackerHeading from "../components/TrackerHeading";
import Warning from "../components/Warning";
import { useHttpClient } from "../../shared/hooks/http-hook";

const StressNMoodTracker = (props) => {
  const { t } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isSaved, setIsSaved] = useState(false);
  const [date, setDate] = useState(false);
  const [data, setData] = useState(null);
  const [journal, setJournal] = useState(null);
  const [responseRequired, setResponseRequired] = useState([]);
  const [currentTracker, setCurrentTracker] = useState(null);

  const warningRef = useRef(null);

  useEffect(() => {
    if (warningRef.current && responseRequired) {
      const modalElement = warningRef.current.closest(".trackers");

      if (modalElement) {
        modalElement.scrollTop = warningRef.current.offsetTop;
      } else {
        window.scrollTo({
          top: warningRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [warningRef, responseRequired]);

  const getData = async (date) => {
    if (date.length > 0) {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/trackers/stress/${date}`
        );
        if (response && response.tracker?._id) {
          let tracker = response.tracker;
          setCurrentTracker({
            id: tracker._id,
            trackerDate: tracker.tracker_date.toString().slice(0, 10),
          });
          let data = JSON.parse(tracker.data);
          setData(data);
          setJournal({
            enhancers: tracker.enhancers,
            inhibitors: tracker.inhibitors,
            journal: tracker.journal,
          });
          setIsSaved(true);
        } else {
          setCurrentTracker({
            id: "",
            trackerDate: date,
          });
          setData({
            stress_at_moment: "",
            bar_index: 16,
          });
          setJournal({
            enhancers: [],
            inhibitors: [],
            journal: "",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getData(date);
  }, [date]);

  const saveResponse = (location, stat) => {
    if (location === "journal") {
      setJournal((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
    } else {
      setData((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
    }
  };

  const sendData = async () => {
    let method = "POST";
    let url = `${process.env.REACT_APP_BACKEND_URL}/trackers/`;
    if (currentTracker.id !== "") {
      method = "PUT";
      url = `${process.env.REACT_APP_BACKEND_URL}/trackers/${currentTracker.id}`;
    }
    try {
      const response = await sendRequest(
        url,
        method,
        JSON.stringify({
          trackerDate: date,
          type: "stress",
          data: data,
          hidden: false,
          enhancers: journal.enhancers,
          inhibitors: journal.inhibitors,
          journal: journal.journal,
          platform: "web",
        })
      );
      if (response) {
        setIsSaved(true);
        props.closeTrackers();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    const emptyKeys = [];

    for (const [key, value] of Object.entries(data)) {
      if (value === "") {
        emptyKeys.push(key);
      }
    }
    if (emptyKeys.length > 0) {
      setResponseRequired(emptyKeys);
    } else {
      setResponseRequired([]);
      sendData();
    }
  };
  const [narrowButtons, setNarrowButtons] = useState(true);

  useEffect(() => {
    //if .trackers-inner is scrolled to bottom - set narrow buttons to false)
    const trackersInner = document.querySelector(".trackers-inner");
    const handleScroll = () => {
      if (
        trackersInner.scrollTop + trackersInner.clientHeight >=
        trackersInner.scrollHeight
      ) {
        setNarrowButtons(false);
      } else {
        setNarrowButtons(true);
      }
    };
    trackersInner.addEventListener("scroll", handleScroll);
    return () => {
      trackersInner.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id="snm-stress" className="tracker-container">
      {" "}
      <div className="trackers-inner">
        <div className="back-to-main">
          <div className="back-btn" onClick={props.handleBackToMain}>
            <FontAwesomeIcon icon={faCircleChevronLeft} />
          </div>
        </div>
        <TrackerHeading title="T185" setDate={setDate} isSaved={isSaved} />
        <div className="trackers-body">
          <div className="ind-tracker">
            {responseRequired.includes("stress_at_moment") && (
              <React.Fragment>
                <div className="buffer" ref={warningRef}></div>
                <Warning />
              </React.Fragment>
            )}
            <div className="title">
              {t("T186")}
              {responseRequired.includes("stress_at_moment") && (
                <FontAwesomeIcon className="red-arrow" icon={faArrowLeft} />
              )}
            </div>
            <HorizontalSlider
              questionid="stress_at_moment"
              data={data}
              saveResponse={saveResponse}
            />
          </div>
          <div className="ind-tracker">
            <div className="title ">
              {t("T187")}
              <p>{t("T019")}</p>
            </div>
            <Influencers
              stress
              questionid={["enhancers", "inhibitors"]}
              data={journal}
              saveResponse={saveResponse}
            />
          </div>
          <div className="ind-tracker">
            <Journal
              placeholder={t("T020")}
              questionid="journal"
              data={journal}
              saveResponse={saveResponse}
            />
          </div>
        </div>{" "}
      </div>
      <div className={"buttons " + (narrowButtons ? "narrow" : "")}>
        <div className="close-btn" onClick={props.closeTrackers}>
          {t("T015")}
        </div>
        <DarkButton className="submit" onClick={handleSubmit}>
          {t("T016")} <FontAwesomeIcon className="icon" icon={faArrowRight} />
        </DarkButton>
      </div>
    </div>
  );
};
export default StressNMoodTracker;
