import React, { useEffect, useCallback, useMemo } from "react";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../../hooks/http-hook";

import googleLogo from "./Images/google-logo.svg";

const SignInButton = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { isLoading, error, sendRequest } = useHttpClient();

  useEffect(() => {
    props.setCompLoading(isLoading);
  }, [isLoading, props]);

  useEffect(() => {
    if (error) {
      props.setErrorMessage(error);
    }
  }, [error, props]);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const validateAuthentication = useCallback(
    async (code, scope, authuser, prompt) => {
      //Remove GET methods to ensure it only occurs once
      searchParams.delete("code");
      searchParams.delete("scope");
      searchParams.delete("authuser");
      searchParams.delete("prompt");

      navigate({ search: searchParams.toString() }, { replace: true });

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/signInGoogle`,
        "POST",
        JSON.stringify({
          code: code,
          scope: scope,
          authuser: authuser,
          prompt: prompt,
          fandfUser: props.fandfUser,
          clientId: props.clientid,
          pathname: location.pathname,
          platform: "web",
        })
      );

      if (props.handleResponse) {
        props.handleResponse(response);
      }
    },
    [location.pathname, navigate, props, searchParams, sendRequest]
  );

  useEffect(() => {
    if (
      searchParams.has("code") &&
      searchParams.has("scope") &&
      searchParams.has("authuser") &&
      searchParams.has("prompt")
    ) {
      validateAuthentication(
        searchParams.get("code"),
        searchParams.get("scope"),
        searchParams.get("authuser"),
        searchParams.get("prompt")
      );
    }
  }, [location.search, searchParams, validateAuthentication]);

  /* Auth Code Flow */
  const handleLogin = useGoogleLogin({
    flow: "auth-code",
    ux_mode: "redirect",
  });

  return (
    <button className="grey_theme" onClick={handleLogin} id="google">
      <img src={googleLogo} className="icon signup" alt="Google Logo" />
      {t("R020")}
    </button>
  );
};

export default function GoogleAuthButton(props) {
  return (
    <GoogleOAuthProvider
      clientId={`${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}.apps.googleusercontent.com`}
    >
      <SignInButton {...props} />
    </GoogleOAuthProvider>
  );
}
