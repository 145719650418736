import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

import { useHttpClient } from "../../shared/hooks/http-hook";

import Logo from "../../pages/images/logo_white.svg";
import Layout from "../components/Layout";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import PasswordInput from "../components/PasswordInput";

function ResetPassword() {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [message, setMessage] = useState();
  const [password, setPassword] = useState("");
  const [validToken, setValidToken] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const validateResetToken = useCallback(
    async (token) => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/reset/${token}`
        );
        if (response.token === true) {
          setValidToken(token);
        } else {
          setMessage(
            "Invalid or expired token, please request a password reset."
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    [sendRequest]
  );

  useEffect(() => {
    if (window.location.pathname.startsWith("/reset/")) {
      let token = window.location.pathname.split("/")[2];
      validateResetToken(token);
    }
  }, [validateResetToken]);

  const resetPasswordHandler = async (event) => {
    event.preventDefault();
    setMessage(null);
    clearError();

    if (password !== confirmPassword) {
      setMessage("Passwords are not the same, please try again.");
      return;
    }

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/resetPassword/${validToken}`,
        "POST",
        JSON.stringify({
          password: password,
        })
      );

      if (response.success === true) {
        setPassword("");
        setConfirmPassword("");
        navigate("/sign-in");
      }

      if (response.message) {
        setMessage(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <div id="right_container">
        <div className="frame">
          <div className="logo">
            <img
              src={Logo}
              className="icon title_icon"
              alt="Champion Health Logo"
            />
          </div>
          <h1>{t("R022")}</h1>

          {!isLoading ? (
            <>
              <form>
                <PasswordInput
                  visible={true}
                  handlePassword={(value) => setPassword(value)}
                  confirmPassword={confirmPassword}
                />
                <PasswordInput
                  visible={false}
                  handleConfirmPassword={(value) => setConfirmPassword(value)}
                  password={password}
                />
              </form>
              {(message?.length > 0 || error?.length > 0) && (
                <p className="login_error">
                  {" "}
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="icon"
                  />
                  <span
                    dangerouslySetInnerHTML={{ __html: message || error }}
                  />
                </p>
              )}
              <button
                onClick={resetPasswordHandler}
                id="continue"
                disabled={validToken === "" || validToken === null}
              >
                {t("R042")}
              </button>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </div>

        <div className="lower_container bottom_padding">
          <p className="signin">
            {t("R012")} <a href="/sign-in">{t("R011")}</a>
          </p>
          <p className="signin">
            {t("R075")}{" "}
            <a
              href="https://support.championhealth.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              {t("R076")}
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
}
export default ResetPassword;
