import "./WhitePopUpStyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/sharp-regular-svg-icons";
import DarkButton from "../../components/styledComponents/darkButton/DarkButton";

const WhitePopUpNoTitle = (props) => {
  return (
    //  {props.popupContents && (
    <div className="popup-overlay">
      <div className="popup-container results">
        <div className="popup-title">{props.popupContents.title}</div>
        <div className="popup-body">
          <div className="popup-text-container">
            {props.popupContents.text.length > 0 &&
              props.popupContents.text.map((item, index) => (
                <div
                  key={index}
                  className={index === 1 ? "mid-heading" : ""}
                  dangerouslySetInnerHTML={{
                    __html: item.replace(/\n/g, "<br />"),
                  }}
                ></div>
              ))}
          </div>
        </div>
        <div className="popup-cta single">
          <DarkButton
            className="wb"
            onClick={() => {
              props.handleSubmit(false);
            }}
          >
            {props.ctaBtn}{" "}
            <FontAwesomeIcon icon={faTimes} className="cta-icon" />
          </DarkButton>
        </div>
      </div>
    </div>
    // )}
  );
};

export default WhitePopUpNoTitle;
