import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const challengesApiSlice = createApi({
  reducerPath: "challengesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
  }),
  endpoints: (builder) => ({
    fetchChallenge: builder.query({
      query: ({ token, challengeType }) => ({
        url: `/challenges/${challengeType}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
    }),
    updateChallengesConsent: builder.mutation({
      query: ({ token, challengeId }) => ({
        url: `/challenges/addConsent/${challengeId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
      }),
    }),
    removeChallengesConsent: builder.mutation({
      query: ({ token, challengeId }) => ({
        url: `/challenges/removeConsent/${challengeId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useFetchChallengeQuery,
  useUpdateChallengesConsentMutation,
  useRemoveChallengesConsentMutation,
} = challengesApiSlice;
