import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faTimes } from "@fortawesome/pro-light-svg-icons";
import CircleGraph from "../components/graphs/circleGraph/CircleGraph";
import TopThree from "../components/graphs/topThree/TopThree";

function UsageStatistics(props) {
  const { t } = useTranslation();
  window.onscroll = function () {
    if (document.getElementById("nav")) {
      // add class on scroll
      var scrolled =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;
      if (scrolled >= 50) {
        document.getElementById("nav").classList.add("scrolled");
      } else if (scrolled < 50) {
        document.getElementById("nav").classList.remove("scrolled");
      }
    }
  };
  const limit = 15;
  //get previous month data as default
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function setDateRange(event) {
    //set menu active class
    var items = document.getElementsByClassName("white");
    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove("active");
      if (items[i].id === event.target.id) {
        items[i].classList.add("active");
      }
    }
    setDays(event.target.id);
    props.onFilterChange({ days: event.target.id });

    if (event.target.id !== "alltime") {
      writeDateRange(writeDateRangeText(event.target.id));
    } else {
      writeDateRange("All time");
    }
  }

  function writeDateRangeText(days) {
    var date0 = new Date();
    var date1 = new Date();
    date0.setDate(date0.getDate());
    date1.setDate(date1.getDate() - days);
    var dateString =
      ("0" + date1.getDate()).slice(-2) +
      " " +
      months[date1.getMonth()] +
      " " +
      date1.getFullYear() +
      " - " +
      ("0" + date0.getDate()).slice(-2) +
      " " +
      months[date0.getMonth()] +
      " " +
      date0.getFullYear();
    return dateString;
  }

  const [dateRange, writeDateRange] = useState(writeDateRangeText(30));
  const [days, setDays] = useState(30);

  const [activeData, setActiveData] = useState(props.data);

  const [filterOpen, setFilterOpen] = useState(false);

  function toggleFilterOpen() {
    filterOpen ? setFilterOpen(false) : setFilterOpen(true);
  }

  const [activeFilter, setActiveFilter] = useState();
  const [activeFilterItem, setActiveFilterItem] = useState();

  function filterSelectHandler(event) {
    if (!event.target.id || event.target.id === activeFilter) {
      setActiveFilterItem();
      setActiveFilter();
      props.onFilterChange({ filters: "" });
    } else {
      setActiveFilterItem(
        <span id={event.target.id}>
          {event.target.innerText}{" "}
          <FontAwesomeIcon icon={faTimes} className="icon" />
        </span>
      );
      setActiveFilter(event.target.id);
      props.onFilterChange({ filters: event.target.id });
    }
    setFilterOpen(false);
  }

  //apply filter to data
  useEffect(() => {
    let data = props.data;
    setActiveData(data);
  }, [props.data]);

  return (
    <div className="report-section active analytics">
      <div className="results-content">
        <h2 className="main_title">{t("US001")}</h2>
        <div id="statistics-container">
          <div id="nav" className="data_filter">
            <div id="date-range-bar">
              <span id="displayed-date">
                {dateRange}{" "}
                <FontAwesomeIcon icon={faCalendarAlt} className="" />
              </span>
              <button id="30" className="white active" onClick={setDateRange}>
                {t("US002")}
              </button>
              <button id="90" className="white" onClick={setDateRange}>
                {t("US003")}
              </button>
              <button id="365" className="white" onClick={setDateRange}>
                {t("US004")}
              </button>
              <button id="alltime" className="white" onClick={setDateRange}>
                {t("US005")}
              </button>
            </div>
            <div id="data-filter">
              <div id="active-filter" onClick={filterSelectHandler}>
                {activeFilterItem}
              </div>
              <div
                id="data-filter-container"
                className={filterOpen ? "open" : "closed"}
              >
                <div id="filter-select" onClick={toggleFilterOpen}>
                  {t("US006")}
                </div>
                <div id="filter-options">
                  <div
                    className="filter-option"
                    id=""
                    onClick={filterSelectHandler}
                  >
                    {t("US007")}
                  </div>
                  {props.coData.allowed_filters.male >= limit &&
                    props.coData.allowed_filters.female >= limit && (
                      <div
                        className="filter-option"
                        id="male"
                        onClick={filterSelectHandler}
                      >
                        {t("US008")}
                      </div>
                    )}
                  {props.coData.allowed_filters.male >= limit &&
                    props.coData.allowed_filters.female >= limit && (
                      <div
                        className="filter-option"
                        id="female"
                        onClick={filterSelectHandler}
                      >
                        {t("US009")}
                      </div>
                    )}
                  {props.coData.departments.map((item, index) => {
                    if (
                      props.coData.allowed_filters.deptUserCount[index + 1] >=
                      limit
                    ) {
                      return (
                        <div
                          className="filter-option"
                          id={index + 1}
                          key={"dept" + index}
                          onClick={filterSelectHandler}
                        >
                          {item}
                        </div>
                      );
                    }
                  })}
                  {props.coData.allowed_filters.day_shift >= limit &&
                    props.coData.allowed_filters.night_shift >= limit && (
                      <div
                        className="filter-option"
                        id="night-shift"
                        onClick={filterSelectHandler}
                      >
                        {t("US010")}
                      </div>
                    )}
                  {props.coData.allowed_filters.day_shift >= limit &&
                    props.coData.allowed_filters.night_shift >= limit && (
                      <div
                        className="filter-option"
                        id="day-shift"
                        onClick={filterSelectHandler}
                      >
                        {t("US011")}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div id="results" className="results-content">
            <CircleGraph
              title={t("US012")}
              square
              colour="green"
              value={props.usage.usage_no}
              subText={t("US013")}
              large
              percent={props.usage.usage_pc}
              modalText={t("US014")}
              onOpenModal={props.onOpenModal}
            />
            <CircleGraph
              title={t("US015")}
              square
              value={
                props.usage.time_on_platform > 24
                  ? Math.round(props.usage.time_on_platform / 24)
                  : props.usage.time_on_platform
              }
              unit={props.usage.time_on_platform > 24 ? t("US016") : t("US017")}
              large
              subText={t("US018")}
              modalText={t("US019")}
              onOpenModal={props.onOpenModal}
            />
            <CircleGraph
              title={t("US020")}
              square
              value={
                props.usage.usage_per_user > 60
                  ? Math.round(props.usage.usage_per_user / 60)
                  : props.usage.usage_per_user
              }
              unit={props.usage.usage_per_user > 60 ? t("US021") : t("US022")}
              subText={t("US023")}
              large
              modalText={t("US024")}
              onOpenModal={props.onOpenModal}
            />
            <TopThree
              title={t("US025")}
              square
              preText={t("US026")}
              modalText={t("US027")}
              value={props.usage.health_areas}
              onOpenModal={props.onOpenModal}
            />
            <TopThree
              title={t("US028")}
              square
              preText={t("US029")}
              modalText={t("US030")}
              value={props.usage.content_codes}
              onOpenModal={props.onOpenModal}
            />
            <CircleGraph
              title={t("US031")}
              square
              large
              value={props.usage.time_of_day}
              subText={t("US032")}
              modalText={t("US033")}
              onOpenModal={props.onOpenModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default UsageStatistics;
