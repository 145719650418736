import React from "react";
import { useTranslation } from "react-i18next";

import DotRadio from "../questions/dotRadio/DotRadio";
import GadHeaderRow from "../questions/dotRadio/GadHeaderRow";

function MentalHealth3(props) {
  const { t } = useTranslation();
  const headerText = [
    { key: 0, value: t("MH009") },
    { key: 1, value: t("MH010") },
    { key: 2, value: t("MH011") },
    { key: 3, value: t("MH012") },
  ];

  return (
    <div className={"question_container"} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <div className={"question_block_wide gad"}>
        <h3>{props.questionTitle}</h3>
        <div className="answer_block">
          <GadHeaderRow headerText={headerText} />
          <DotRadio
            questionid="nervous"
            range="4"
            width=""
            questionTitle={t("MH013")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="control_worrying"
            range="4"
            width=""
            questionTitle={t("MH014")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="worrying_too_much"
            range="4"
            width=""
            questionTitle={t("MH015")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="trouble_relaxing"
            range="4"
            width=""
            questionTitle={t("MH016")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="restless"
            range="4"
            width=""
            questionTitle={t("MH017")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="annoyed"
            range="4"
            width=""
            questionTitle={t("MH018")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="afraid"
            range="4"
            width=""
            questionTitle={t("MH019")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
        </div>
      </div>
    </div>
  );
}

export default MentalHealth3;
