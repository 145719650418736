import React, { useState, useEffect } from "react";
import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function IconSelect(props) {
  const [radioOption, setRadioOption] = useState(false);

  function setIconSelectHandler(event) {
    const element = event.currentTarget.querySelector("input");
    if (!element) return;
    const value = element?.value;
    const name = element?.name;
    if (!value || !name) return;
    if (value === radioOption) {
      //double click deselect
      setRadioOption("");
      updateIconsActive("");
      props.onSetValue({ id: name, value: "" });
    } else {
      updateIconsActive(value);
      saveValue(value);
    }
  }

  function updateIconsActive(value) {
    for (var i = 0; i <= parseFloat(props.range); i++) {
      var k = i;
      if (i < value || (i === 0 && value === 0)) {
        document.getElementById(props.questionid + k).classList.add("selected");
      } else {
        document
          .getElementById(props.questionid + k)
          .classList.remove("selected");
      }
    }
  }

  function saveValue(value) {
    setRadioOption(value);
    props.onSetValue({ id: props.questionid, value: value });
  }

  useEffect(() => {
    if (props.assessmentValues[props.questionid] && !radioOption) {
      setRadioOption(props.assessmentValues[props.questionid]);
      updateIconsActive(props.assessmentValues[props.questionid]);
    }
  }, [props.assessmentValues[props.questionid]]);

  var rows = [];
  for (var i = 0; i <= props.range; i++) {
    var k = i;
    var suffix = "";
    if (k === parseFloat(props.range)) {
      suffix = "+";
    }
    rows.push({ index: i, value: k, suffix: suffix });
  }

  return (
    <div id={props.questionid} className={"req question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.questionTitleSuffix ? (
        <span className="titleSuffix">{props.questionTitleSuffix}</span>
      ) : null}
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      <div className="answer_block fs-exclude">
        <div
          className={"dot_radio iconselect " + props.questionid}
          id={props.questionid}
        >
          {rows.map((option) => {
            let checked;
            {
              parseFloat(radioOption) === parseFloat(option.value)
                ? (checked = "checked")
                : (checked = "");
            }
            return (
              <div
                className="input_wrap"
                key={option.index}
                onClick={setIconSelectHandler}
              >
                <input
                  type="radio"
                  id={props.questionid + option.value}
                  name={props.questionid}
                  value={option.value}
                  readOnly
                  onClick={(e) => e.stopPropagation()}
                  checked={checked}
                />
                <label htmlFor={props.questionid + option.value}></label>
                {option.value}
                {option.suffix}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default IconSelect;
