import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Checkbox from "../questions/checkbox/Checkbox";
import Radio from "../questions/radio/Radio";
import OneToFiveSlider from "../questions/painSlider/OneToFiveSlider";
import CheckboxRadio from "../questions/checkboxRadio/CheckboxRadio";

function FinancialWellbeing(props) {
  const { t } = useTranslation();
  const finPosOptions = [
    { id: "very_comfortable", title: t("FW001") },
    { id: "relatively_comfortable", title: t("FW002") },
    {
      id: "cannot_afford_luxuries",
      title: t("FW003"),
    },
    {
      id: "can_afford_essentials",
      title: t("FW004"),
    },
    {
      id: "cannot_afford_essentials",
      title: t("FW005"),
    },
  ];

  const confidenceTextValues = [
    t("FW006"),
    t("FW007"),
    t("FW008"),
    t("FW009"),
    t("FW010"),
  ];

  const sometimesOptions = [
    { id: "yes", title: t("FW011") },
    { id: "sometimes", title: t("FW012") },
    { id: "no", title: t("FW013") },
  ];

  const moneyWorriesOptions = [
    {
      id: "none",
      title: t("FW014"),
    },
    { id: "mental_health", title: t("FW015") },
    { id: "physical_health", title: t("FW016") },
    {
      id: "relationships",
      title: t("FW017"),
    },
    {
      id: "ability_to_sleep",
      title: t("FW018"),
    },
    {
      id: "work_performance",
      title: t("FW019"),
    },
    {
      id: "job_satisfaction",
      title: t("FW020"),
    },
    {
      id: "alcohol_consumption",
      title: t("FW021"),
    },
    {
      id: "other",
      title: t("FW022"),
    },
  ];

  const yesnoOptions = [
    { id: "yes", title: t("FW023") },
    { id: "no", title: t("FW024") },
  ];

  useEffect(() => {
    if (props.assessmentValues.money_worries === "no") {
      props.onSetValue({ id: "money_worries_impact", value: null });
      props.onSetValue({ id: "able_discuss_money", value: null });
    }
  }, [props.assessmentValues.money_worries]);

  return (
    <div className={"question_container"} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>
      <CheckboxRadio
        questionid="financial_position"
        width="_wide"
        options={finPosOptions}
        questionTitle={t("FW025")}
        placeholder={t("FW026")}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <div className="question_block_container stacked">
        <h3 className="introduction">{t("FW027")}</h3>
        <OneToFiveSlider
          questionid="confident_managing_finance"
          questionTitle={t("FW028")}
          width=""
          textValues={confidenceTextValues}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
        />
        <OneToFiveSlider
          questionid="relies_on_borrowing"
          questionTitle={t("FW029")}
          width=""
          textValues={confidenceTextValues}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
        />
        <OneToFiveSlider
          questionid="in_control_finances"
          questionTitle={t("FW030")}
          width=""
          textValues={confidenceTextValues}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
        />
        <OneToFiveSlider
          questionid="cannot_meet_financial_goals"
          questionTitle={t("FW031")}
          width=""
          textValues={confidenceTextValues}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
        />
      </div>
      <Radio
        questionid="money_worries"
        width=""
        questionTitle={t("FW032")}
        options={sometimesOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      {props.assessmentValues.money_worries &&
        props.assessmentValues.money_worries !== "no" && (
          <>
            <Checkbox
              questionid="money_worries_impact"
              width=""
              itemLimit="1"
              questionTitle={t("FW033")}
              questionTitleSuffix={t("FW034")}
              options={moneyWorriesOptions}
              placeholder={t("FW035")}
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              secondaryQ
              fadeIn="yes"
            />
            <Radio
              questionid="able_discuss_money"
              width=""
              questionTitle={t("FW036")}
              options={sometimesOptions}
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              secondaryQ
              fadeIn="yes"
            />
          </>
        )}
      <Radio
        questionid="sought_financial_advice"
        width=""
        questionTitle={t("FW037")}
        questionTitleSuffix={t("FW038")}
        options={yesnoOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
    </div>
  );
}

export default FinancialWellbeing;
