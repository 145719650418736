export const activity_list = [
  { id: "aerobic_fitness_class", label: "T249", value: "181" },
  { id: "aerobic_low_impact", label: "T250", value: "125" },
  { id: "badminton", label: "T251", value: "131" },
  { id: "ballet", label: "T252", value: "120" },
  { id: "baseball", label: "T253", value: "111" },
  { id: "basketball", label: "T254", value: "145" },
  { id: "bowling", label: "T255", value: "71" },
  { id: "canoeing", label: "T256", value: "91" },
  { id: "cheerleading", label: "T257", value: "100" },
  { id: "circuit_training", label: "T258", value: "199" },
  { id: "cycling_moderate", label: "T259", value: "170" },
  { id: "cycling_vigorous", label: "T260", value: "200" },
  { id: "dancing", label: "T261", value: "109" },
  { id: "elliptical_trainer", label: "T262", value: "203" },
  { id: "fencing", label: "T263", value: "182" },
  { id: "football_competitive", label: "T264", value: "200" },
  { id: "football_recreational", label: "T265", value: "145" },
  { id: "frisbee	", label: "T266", value: "91" },
  { id: "gardening, heavy", label: "T267", value: "174" },
  { id: "gardening, light", label: "T268", value: "116" },
  { id: "golf", label: "T269", value: "109" },
  { id: "gymnastics", label: "T270", value: "121" },
  { id: "handball", label: "T271", value: "348" },
  { id: "hiit_workout", label: "T272", value: "239" },
  { id: "hiking", label: "T273", value: "172" },
  { id: "hockey_field_and_ice", label: "T274", value: "240" },
  { id: "horse_riding", label: "T275", value: "116" },
  { id: "housework_light", label: "T276", value: "72" },
  { id: "ice_skating", label: "T277", value: "122" },
  { id: "jogging", label: "T278", value: "181" },
  { id: "Jump rope", label: "T279", value: "250" },
  { id: "kayaking", label: "T280", value: "152" },
  { id: "kickball", label: "T281", value: "212" },
  { id: "lacrosse", label: "T282", value: "242" },
  { id: "martial_arts", label: "T283", value: "221" },
  { id: "padel", label: "T284", value: "160" },
  { id: "pilates", label: "T285", value: "91" },
  { id: "rock_climbing", label: "T286", value: "244" },
  { id: "rollerblading", label: "T287", value: "156" },
  { id: "rowing_machine", label: "T288", value: "212" },
  { id: "rugby", label: "T289", value: "303" },
  { id: "running_10_mile", label: "T290", value: "222" },
  { id: "running_12_mile", label: "T291", value: "178" },
  { id: "running_8_mile", label: "T292", value: "278" },
  { id: "sailing", label: "T293", value: "91" },
  { id: "seated_workout_gentle", label: "T294", value: "50" },
  { id: "seated_workout_moderate", label: "T295", value: "100" },
  { id: "seated_workout_vigorous", label: "T296", value: "200" },
  { id: "skateboarding", label: "T297", value: "102" },
  { id: "skiing", label: "T298", value: "109" },
  { id: "snowboarding", label: "T299", value: "182" },
  { id: "softball", label: "T300", value: "152" },
  { id: "spinning_bike", label: "T301", value: "200" },
  { id: "squash", label: "T302", value: "348" },
  { id: "stair_climbing_machine", label: "T303", value: "200" },
  { id: "strength_training", label: "T304", value: "106" },
  { id: "stretching", label: "T305", value: "15" },
  { id: "surfing", label: "T306", value: "91" },
  { id: "swimming_freestyle", label: "T307", value: "181" },
  { id: "swimming, leisure", label: "T308", value: "174" },
  { id: "table_tennis", label: "T309", value: "120" },
  { id: "tai_chi", label: "T310", value: "40" },
  { id: "tennis_doubles", label: "T311", value: "160" },
  { id: "tennis_singles", label: "T312", value: "200" },
  { id: "Trampoline", label: "T313", value: "90" },
  { id: "Volleyball", label: "T314", value: "91" },
  { id: "walking_average", label: "T315", value: "84" },
  { id: "walking_brisk", label: "T316", value: "122" },
  { id: "walking_stroll", label: "T317", value: "61" },
  { id: "water_aerobics", label: "T318", value: "116" },
  { id: "water_polo", label: "T319", value: "303" },
  { id: "weight_lifting", label: "T320", value: "67" },
  // { id: "wheelchair_basketball", label: "T321", value: "164" },
  // { id: "wheelchair_dance", label: "T322", value: "100" },
  // { id: "wheelchair_fencing", label: "T323", value: "100" },
  // { id: "wheelchair_football", label: "T324", value: "100" },
  // { id: "wheelchair_hockey", label: "T325", value: "100" },
  // { id: "wheelchair_rugby", label: "T326", value: "200" },
  // { id: "wheelchair_sailing", label: "T327", value: "100" },
  // { id: "wheelchair_sports_other", label: "T328", value: "100" },
  // { id: "Wheelchair_tennis", label: "T329", value: "100" },
  // { id: "wheeling_fast", label: "T330", value: "137" },
  // { id: "wheeling_leisurely", label: "T331", value: "70" },
  { id: "yoga, gentle", label: "T332", value: "45" },
  { id: "yoga, moderate", label: "T333", value: "72" },
  { id: "zumba", label: "T334", value: "148" },
];

export const mood1 = [
  { id: "sad", label: "T211" },
  { id: "anxious", label: "T212" },
  { id: "angry", label: "T213" },
  { id: "stressed", label: "T214" },
  { id: "frustrated", label: "T215" },
  { id: "guilty", label: "T216" },
  { id: "confused", label: "T217" },
  { id: "tired", label: "T218" },
  { id: "disappointed", label: "T219" },
  { id: "lonely", label: "T220" },
  { id: "disgusted", label: "T221" },
  { id: "embarrassed", label: "T222" },
  { id: "annoyed", label: "T223" },
  { id: "shocked", label: "T224" },
  { id: "distracted", label: "T225" },
  { id: "bored", label: "T226" },
  { id: "restless", label: "T227" },
  { id: "fed_up", label: "T228" },
  { id: "unsure", label: "T229" },
  { id: "indifferent", label: "T230" },
  { id: "tense", label: "T231" },
  { id: "okay", label: "T232" },
];
export const mood2 = [
  { id: "tired", label: "T218" },
  { id: "frustrated", label: "T215" },
  { id: "sad", label: "T211" },
  { id: "anxious", label: "T212" },
  { id: "annoyed", label: "T223" },
  { id: "stressed", label: "T214" },
  { id: "embarrassed", label: "T222" },
  { id: "distracted", label: "T225" },
  { id: "confused", label: "T217" },
  { id: "angry", label: "T213" },
  { id: "lonely", label: "T220" },
  { id: "disappointed", label: "T219" },
  { id: "restless", label: "T227" },
  { id: "fed_up", label: "T228" },
  { id: "tense", label: "T231" },
  { id: "disgusted", label: "T221" },
  { id: "guilty", label: "T216" },
  { id: "bored", label: "T226" },
  { id: "indifferent", label: "T230" },
  { id: "shocked", label: "T224" },
  { id: "okay", label: "T232" },
  { id: "unsure", label: "T229" },
];
export const mood3 = [
  { id: "indifferent", label: "T230" },
  { id: "okay", label: "T232" },
  { id: "content", label: "T234" },
  { id: "distracted", label: "T225" },
  { id: "unsure", label: "T229" },
  { id: "bored", label: "T226" },
  { id: "tired", label: "T218" },
  { id: "surprised", label: "T235" },
  { id: "tense", label: "T231" },
  { id: "calm", label: "T236" },
  { id: "fed_up", label: "T228" },
  { id: "confused", label: "T217" },
  { id: "happy", label: "T233" },
  { id: "sad", label: "T211" },
  { id: "anxious", label: "T212" },
  { id: "lonely", label: "T220" },
  { id: "restless", label: "T227" },
  { id: "annoyed", label: "T223" },
  { id: "embarrassed", label: "T222" },
  { id: "thoughtful", label: "T237" },
  { id: "stressed", label: "T214" },
  { id: "frustrated", label: "T215" },
];
export const mood4 = [
  { id: "content", label: "T234" },
  { id: "grateful", label: "T238" },
  { id: "happy", label: "T233" },
  { id: "determined", label: "T239" },
  { id: "proud", label: "T240" },
  { id: "calm", label: "T236" },
  { id: "relieved", label: "T241" },
  { id: "thoughtful", label: "T237" },
  { id: "joyful", label: "T242" },
  { id: "creative", label: "T243" },
  { id: "surprised", label: "T244" },
  { id: "inspired", label: "T245" },
  { id: "amazed", label: "T246" },
  { id: "energetic", label: "T247" },
  { id: "excited", label: "T248" },
  { id: "okay", label: "T232" },
  { id: "indifferent", label: "T230" },
  { id: "unsure", label: "T229" },
  { id: "distracted", label: "T225" },
  { id: "tired", label: "T218" },
];
export const mood5 = [
  { id: "amazed", label: "T246" },
  { id: "energetic", label: "T247" },
  { id: "excited", label: "T248" },
  { id: "happy", label: "T233" },
  { id: "content", label: "T234" },
  { id: "grateful", label: "T238" },
  { id: "proud", label: "T240" },
  { id: "joyful", label: "T242" },
  { id: "determined", label: "T239" },
  { id: "creative", label: "T243" },
  { id: "inspired", label: "T245" },
  { id: "calm", label: "T236" },
  { id: "relieved", label: "T241" },
  { id: "thoughtful", label: "T237" },
  { id: "surprised", label: "T244" },
  { id: "okay", label: "T232" },
  { id: "indifferent", label: "T230" },
  { id: "unsure", label: "T229" },
  { id: "distracted", label: "T225" },
  { id: "tired", label: "T218" },
];

export const emotions = [
  { label: "T211", id: "sad" },
  { label: "T212", id: "anxious" },
  { label: "T213", id: "angry" },
  { label: "T214", id: "stressed" },
  { label: "T215", id: "frustrated" },
  { label: "T216", id: "guilty" },
  { label: "T217", id: "confused" },
  { label: "T218", id: "tired" },
  { label: "T219", id: "disappointed" },
  { label: "T220", id: "lonely" },
  { label: "T221", id: "disgusted" },
  { label: "T222", id: "embarrassed" },
  { label: "T223", id: "annoyed" },
  { label: "T224", id: "shocked" },
  { label: "T225", id: "distracted" },
  { label: "T226", id: "bored" },
  { label: "T227", id: "restless" },
  { label: "T228", id: "fed_up" },
  { label: "T229", id: "unsure" },
  { label: "T230", id: "indifferent" },
  { label: "T231", id: "tense" },
  { label: "T232", id: "okay" },
  { label: "T233", id: "happy" },
  { label: "T234", id: "content" },
  { label: "T235", id: "surprised" },
  { label: "T236", id: "calm" },
  { label: "T237", id: "thoughtful" },
  { label: "T238", id: "grateful" },
  { label: "T239", id: "determined" },
  { label: "T240", id: "proud" },
  { label: "T241", id: "relieved" },
  { label: "T242", id: "joyful" },
  { label: "T243", id: "creative" },
  { label: "T244", id: "surprised" },
  { label: "T245", id: "inspired" },
  { label: "T246", id: "amazed" },
  { label: "T247", id: "energetic" },
  { label: "T248", id: "excited" },
];

export const nutrition_list = [
  { label: "T359", id: "apple" },
  { label: "T360", id: "apricot" },
  { label: "T361", id: "artichoke" },
  { label: "T362", id: "asparagus" },
  { label: "T363", id: "aubergine" },
  { label: "T364", id: "avocado" },
  { label: "T365", id: "baked_beans" },
  { label: "T366", id: "banana" },
  { label: "T367", id: "bean_sprouts" },
  { label: "T368", id: "beetroot" },
  { label: "T369", id: "black_beans" },
  { label: "T370", id: "blackberries" },
  { label: "T371", id: "blackcurrents" },
  { label: "T372", id: "black_eyed_peas" },
  { label: "T373", id: "blueberries" },
  { label: "T374", id: "bok_choy" },
  { label: "T375", id: "broad_beans" },
  { label: "T376", id: "broccoli" },
  { label: "T377", id: "brussels_sprouts" },
  { label: "T378", id: "butter_beans" },
  { label: "T379", id: "butternut_squash" },
  { label: "T380", id: "cabbage" },
  { label: "T381", id: "cannelloni_beans" },
  { label: "T382", id: "carrots" },
  { label: "T383", id: "cauliflower" },
  { label: "T384", id: "celeriac" },
  { label: "T385", id: "celery" },
  { label: "T386", id: "cherries" },
  { label: "T387", id: "chickpeas" },
  { label: "T388", id: "clementine" },
  { label: "T389", id: "coconut" },
  { label: "T390", id: "collard_greens" },
  { label: "T391", id: "courgette" },
  { label: "T392", id: "cranberries" },
  { label: "T393", id: "cucumber" },
  { label: "T394", id: "currants" },
  { label: "T395", id: "sates" },
  { label: "T396", id: "dragon_fruit" },
  { label: "T397", id: "dried_fruit" },
  { label: "T398", id: "edamame_beans" },
  { label: "T399", id: "elderberry" },
  { label: "T400", id: "fava_beans" },
  { label: "T401", id: "figs" },
  { label: "T402", id: "fresh_fruit_juice" },
  { label: "T403", id: "goji_berry" },
  { label: "T404", id: "gooseberries" },
  { label: "T405", id: "grapefruit" },
  { label: "T406", id: "grapes" },
  { label: "T407", id: "green_beans" },
  { label: "T408", id: "guava" },
  { label: "T409", id: "haricot_beans" },
  { label: "T410", id: "jackfruit" },
  { label: "T411", id: "juniper_berry" },
  { label: "T412", id: "kale" },
  { label: "T413", id: "kidney_beans" },
  { label: "T414", id: "kiwi" },
  { label: "T415", id: "kohlrabi" },
  { label: "T416", id: "kumquat" },
  { label: "T417", id: "leeks" },
  { label: "T418", id: "lemons" },
  { label: "T419", id: "lentils" },
  { label: "T420", id: "lettuce" },
  { label: "T421", id: "limes" },
  { label: "T422", id: "lychee" },
  { label: "T423", id: "mandarine" },
  { label: "T424", id: "mange_tout" },
  { label: "T425", id: "mango" },
  { label: "T426", id: "melon" },
  { label: "T427", id: "mushrooms" },
  { label: "T428", id: "nectarines" },
  { label: "T429", id: "okra" },
  { label: "T430", id: "onion" },
  { label: "T431", id: "orange" },
  { label: "T432", id: "papaya" },
  { label: "T433", id: "parsnips" },
  { label: "T434", id: "passion_fruit" },
  { label: "T435", id: "peach" },
  { label: "T436", id: "pear" },
  { label: "T437", id: "peas" },
  { label: "T438", id: "peppers" },
  { label: "T439", id: "persimmon" },
  { label: "T440", id: "pineapple" },
  { label: "T441", id: "plums" },
  { label: "T442", id: "pomegranate" },
  { label: "T443", id: "pumpkin" },
  { label: "T444", id: "radish" },
  { label: "T445", id: "raisins" },
  { label: "T446", id: "raspberries" },
  { label: "T447", id: "redcurrants" },
  { label: "T448", id: "rhubarb" },
  { label: "T449", id: "runner_beans" },
  { label: "T450", id: "satsumas" },
  { label: "T451", id: "shallots" },
  { label: "T452", id: "spinach" },
  { label: "T453", id: "spring Onion" },
  { label: "T454", id: "squash" },
  { label: "T455", id: "strawberries" },
  { label: "T456", id: "sultanas" },
  { label: "T457", id: "swede" },
  { label: "T458", id: "sweet_potato" },
  { label: "T459", id: "sweetcorn" },
  { label: "T460", id: "tangerines" },
  { label: "T461", id: "tomatoes" },
  { label: "T462", id: "turnips" },
  { label: "T463", id: "watermelon" },
];

export const smoking = [
  { id: "socialising", label: "T112" },
  { id: "work_breaks", label: "T113" },
  { id: "habit_routine", label: "T114" },
  { id: "feeling_stressed_anxious", label: "T115" },
  { id: "after_meal", label: "T116" },
  { id: "drinking_alcohol", label: "T117" },
  { id: "boredom", label: "T118" },
  { id: "manage_cravings", label: "T119" },
  { id: "routine_before_bed", label: "T120" },
  { id: "driving", label: "T121" },
];

export const enhancers_sleep = [
  { id: "relaxing_bedtime_routine", label: "T555" },
  { id: "listening_to_sleep_story", label: "T556" },
  { id: "regular_sleep_schedule", label: "T027" },
  { id: "listening_to_calming_sounds", label: "T557" },
  { id: "30_mins_of_daylight", label: "T028" },
  { id: "cool_dark_quiet_bedroom", label: "T558" },
  { id: "limited_blue_light_exposure", label: "T559" },
  { id: "meditation_practice ", label: "T560" },
  { id: "no_alcohol_before_bed", label: "T561" },
  { id: "no_smoking_before_bed", label: "T562" },
  { id: "limited_caffeine_intake", label: "T563" },
  { id: "regular_exercise", label: "T564" },
];

export const inhibitors_sleep = [
  { id: "staying_up_late", label: "T565" },
  { id: "large_meal_near_bedtime", label: "T566" },
  { id: "exercise_near_bedtime", label: "T567" },
  { id: "stress_or_anxiety", label: "T568" },
  { id: "blue_light_exposure", label: "T034" },
  { id: "working_shifts", label: "T569" },
  { id: "drinking_alcohol", label: "T037" },
  { id: "caffeine_near_bedtime", label: "T570" },
  { id: "smoking_before_bed", label: "T571" },
  { id: "scrolling_on_social_media", label: "T035" },
  { id: "hot_uncomfortable_room", label: "T572" },
  { id: "physical_pain_or_illness", label: "T573" },
  { id: "medication_side_effects", label: "T574" },
];

export const blockers_exercise = [
  { id: "lack_of_time", label: "T525" },
  { id: "no_motivation", label: "T526" },
  { id: "work_schedule", label: "T527" },
  { id: "feeling_unwell", label: "T528" },
  { id: "injury_or_pain", label: "T529" },
  { id: "physical_health_condition", label: "T530" },
  { id: "heavy_painful_period", label: "T531" },
  { id: "forgot_clothes_trainers", label: "T532" },
  { id: "low_confidence", label: "T533" },
  { id: "low_mood", label: "T534" },
  { id: "tiredness", label: "T535" },
  { id: "financial_cost", label: "T536" },
];

export const motivators_exercise = [
  { id: "working_towards_goal", label: "T515" },
  { id: "exercising_with_friend", label: "T516" },
  { id: "joining_fitness_group", label: "T517" },
  { id: "enjoyable_activity", label: "T518" },
  { id: "app_notifications", label: "T124" },
  { id: "tracking_progress", label: "T519" },
  { id: "pre_paid_membership", label: "T520" },
  { id: "competitions_challenges", label: "T521" },
  { id: "feeling_energised", label: "T522" },
  { id: "good_music_playlist", label: "T523" },
  { id: "personal_trainer", label: "T524" },
];

export const motivatorsHyd = [
  { id: "carry_water_bottle", label: "T168" },
  { id: "add_fruit_to_water", label: "T169" },
  { id: "app_notifications", label: "T170" },
  { id: "exercising", label: "T171" },
  { id: "strong_urine", label: "T172" },
  { id: "regular_routine", label: "T173" },
  { id: "team_challenge", label: "T174" },
];

export const blockersHyd = [
  { id: "busy_schedule", label: "T175" },
  { id: "forgetfulness", label: "T176" },
  { id: "irregular_breaks", label: "T177" },
  { id: "no_available_water", label: "T178" },
  { id: "drinking_caffeine", label: "T179" },
  { id: "drinking_sugary_drinks", label: "T180" },
  { id: "no_toilet_facilities", label: "T181" },
  { id: "drinking_alcohol", label: "T182" },
];

export const reasonAlc = [
  { id: "socialising", label: "T141" },
  { id: "improve_my_confidence", label: "T142" },
  { id: "relieve_stress_anxiety", label: "T143" },
  { id: "relax_and_unwind", label: "T144" },
  { id: "habit_routine", label: "T145" },
  { id: "enjoy_the_taste", label: "T146" },
  { id: "helps_me_sleep", label: "T147" },
  { id: "drinking_with_meal", label: "T148" },
  { id: "boredom", label: "T149" },
  { id: "social_pressure", label: "T560W" },
];

export const reasonStress = [
  { id: "work", label: "T193" },
  { id: "relationships", label: "T194" },
  { id: "family", label: "T195" },
  { id: "parenting", label: "T196" },
  { id: "money", label: "T197" },
  { id: "health", label: "T198" },
  { id: "personal", label: "T199" },
  { id: "other", label: "T200" },
];
export const inhibitorsProd = [
  { id: "tiredness", label: "T478" },
  { id: "too_much_caffeine", label: "T479" },
  { id: "multitasking", label: "T480" },
  { id: "frequent_meetings", label: "T481" },
  { id: "checking_social_media", label: "T482" },
  { id: "aiming_for_perfection", label: "T483" },
  { id: "lunch_at_desk", label: "T484" },
  { id: "limited_or_no_breaks", label: "T485" },
  { id: "unclear_priorities", label: "T486" },
  { id: "frequent_distractions", label: "T487" },
  { id: "big_lunchtime_meal", label: "T488" },
  { id: "feeling_stressed", label: "T489" },
  { id: "physical_pain_or_illness", label: "T490" },
  { id: "hay_fever", label: "T491" },
];

export const enhancersProd = [
  { id: "take_regular_breaks", label: "T465" },
  { id: "lunch_away_from_desk", label: "T466" },
  { id: "clutter_free_environment", label: "T467" },
  { id: "lunchtime_walk", label: "T468" },
  { id: "caffeine_limited", label: "T469" },
  { id: "schedule_tasks", label: "T470" },
  { id: "write_to_do_list", label: "T471" },
  { id: "focus_on_one_task", label: "T472" },
  { id: "tackle_hardest_task_first", label: "T473" },
  { id: "turn_on_do_not_disturb'", label: "T474" },
  { id: "remove_distractions", label: "T475" },
  { id: "pomodoro_technique", label: "T476" },
  { id: "set_small_goals", label: "T477" },
];
export const boosters_energy = [
  { id: "staying_hydrated", label: "T492" },
  { id: "good_quality_sleep", label: "T493" },
  { id: "exercising", label: "T171" },
  { id: "lunchtime_walk", label: "T494" },
  { id: "regular_mealtimes", label: "T495" },
  { id: "taking_breaks", label: "T496" },
  { id: "eating_nutritious_food", label: "T497" },
  { id: "time_outdoors", label: "T498" },
  { id: "low_stress_levels", label: "T499" },
  { id: "prioritising_self_care", label: "T500" },
  { id: "meditation_mindfulness", label: "T501" },
  { id: "power_nap", label: "T502" },
];

export const zappers_energy = [
  { id: "poor_quality_sleep", label: "T503" },
  { id: "dehydration", label: "T504" },
  { id: "too_much_caffeine", label: "T505" },
  { id: "processed_food", label: "T506" },
  { id: "sugary_drinks", label: "T507" },
  { id: "skipping_meals", label: "T508" },
  { id: "physical_pain_illness", label: "T509" },
  { id: "medication_side_effects", label: "T510" },
  { id: "feeling_stressed'", label: "T511" },
  { id: "drinking_alcohol", label: "T037" },
  { id: "sitting_for_long_periods", label: "T513" },
  { id: "big_lunchtime_meal", label: "T514" },
];
export const notSmoking = [
  { id: "nicotine_replacement", label: "T122" },
  { id: "medication", label: "T123" },
  { id: "app_notifications", label: "T124" },
  { id: "remembering_reasons", label: "T125" },
  { id: "keeping_the_goal_in_mind", label: "T126" },
  { id: "support_from_others", label: "T127" },
  { id: "avoided_triggers", label: "T128" },
  { id: "meditation_or_mindfulness", label: "T129" },
  { id: "journalling", label: "T130" },
  { id: "keeping_busy", label: "T131" },
];

export const inh_and_enh = [
  { id: "socialising", label: "T112" },
  { id: "work_breaks", label: "T113" },
  { id: "habit_routine", label: "T114" },
  { id: "feeling_stressed_anxious", label: "T115" },
  { id: "after_meal", label: "T116" },
  { id: "drinking_alcohol", label: "T117" },
  { id: "boredom", label: "T118" },
  { id: "manage_cravings", label: "T119" },
  { id: "routine_before_bed", label: "T120" },
  { id: "driving", label: "T121" },
  { id: "daily_exercise", label: "T024" },
  { id: "meditation_practice", label: "T025" },
  { id: "wind_down_routine", label: "T026" },
  { id: "regular_sleep_schedule", label: "T027" },
  { id: "30_mins_of_daylight", label: "T028" },
  { id: "listening_to_sleep_stories", label: "T029" },
  { id: "not_eating_near_bedtime", label: "T030" },
  { id: "no_caffeine_after_1pm", label: "T031" },
  { id: "exercising_close_to_sleep", label: "T033" },
  { id: "blue_light_exposure", label: "T034" },
  { id: "scrolling_on_social_media", label: "T035" },
  { id: "eating_meal_close_to_bed", label: "T036" },
  { id: "drinking_alcohol", label: "T037" },
  { id: "smoking_near_bedtime", label: "T038" },
  { id: "caffeine_after_1pm", label: "T039" },
  { id: "shift_patterns", label: "T040" },
  { id: "feeling_unwell", label: "T041" },
  { id: "experiencing_pain", label: "T042" },
  { id: "lack_of_time", label: "T525" },
  { id: "no_motivation", label: "T526" },
  { id: "work_schedule", label: "T527" },
  { id: "feeling_unwell", label: "T528" },
  { id: "injury_or_pain", label: "T529" },
  { id: "physical_health_condition", label: "T530" },
  { id: "heavy_painful_period", label: "T531" },
  { id: "forgot_clothes_trainers", label: "T532" },
  { id: "low_confidence", label: "T533" },
  { id: "low_mood", label: "T534" },
  { id: "tiredness", label: "T535" },
  { id: "financial_cost", label: "T536" },
  { id: "working_towards_goal", label: "T515" },
  { id: "exercising_with_friend", label: "T516" },
  { id: "joining_fitness_group", label: "T517" },
  { id: "enjoyable_activity", label: "T518" },
  { id: "app_notifications", label: "T124" },
  { id: "tracking_progress", label: "T519" },
  { id: "pre_paid_membership", label: "T520" },
  { id: "competitions_challenges", label: "T521" },
  { id: "feeling_energised", label: "T522" },
  { id: "good_music_playlist", label: "T523" },
  { id: "personal_trainer", label: "T524" },
  { id: "carry_water_bottle", label: "T168" },
  { id: "add_fruit_to_water", label: "T169" },
  { id: "app_notifications", label: "T170" },
  { id: "exercising", label: "T171" },
  { id: "strong_urine", label: "T172" },
  { id: "regular_routine", label: "T173" },
  { id: "team_challenge", label: "T174" },
  { id: "busy_schedule", label: "T175" },
  { id: "forgetfulness", label: "T176" },
  { id: "irregular_breaks", label: "T177" },
  { id: "no_available_water", label: "T178" },
  { id: "drinking_caffeine", label: "T179" },
  { id: "drinking_sugary_drinks", label: "T180" },
  { id: "no_toilet_facilities", label: "T181" },
  { id: "drinking_alcohol", label: "T182" },
  { id: "socialising", label: "T141" },
  { id: "improve_my_confidence", label: "T142" },
  { id: "relieve_stress_anxiety", label: "T143" },
  { id: "relax_and_unwind", label: "T144" },
  { id: "habit_routine", label: "T145" },
  { id: "enjoy_the_taste", label: "T146" },
  { id: "helps_me_sleep", label: "T147" },
  { id: "drinking_with_meal", label: "T148" },
  { id: "boredom", label: "T149" },
  { id: "social_pressure", label: "T560W" },
  { id: "work", label: "T193" },
  { id: "relationships", label: "T194" },
  { id: "family", label: "T195" },
  { id: "parenting", label: "T196" },
  { id: "money", label: "T197" },
  { id: "health", label: "T198" },
  { id: "personal", label: "T199" },
  { id: "other", label: "T200" },
  { id: "tiredness", label: "T478" },
  { id: "too_much_caffeine", label: "T479" },
  { id: "multitasking", label: "T480" },
  { id: "frequent_meetings", label: "T481" },
  { id: "checking_social_media", label: "T482" },
  { id: "aiming_for_perfection", label: "T483" },
  { id: "lunch_at_desk", label: "T484" },
  { id: "limited_or_no_breaks", label: "T485" },
  { id: "unclear_priorities", label: "T486" },
  { id: "frequent_distractions", label: "T487" },
  { id: "big_lunchtime_meal", label: "T488" },
  { id: "feeling_stressed", label: "T489" },
  { id: "physical_pain_or_illness", label: "T490" },
  { id: "hay_fever", label: "T491" },
  { id: "take_regular_breaks", label: "T465" },
  { id: "lunch_away_from_desk", label: "T466" },
  { id: "clutter_free_environment", label: "T467" },
  { id: "lunchtime_walk", label: "T468" },
  { id: "caffeine_limited", label: "T469" },
  { id: "schedule_tasks", label: "T470" },
  { id: "write_to_do_list", label: "T471" },
  { id: "focus_on_one_task", label: "T472" },
  { id: "tackle_hardest_task_first", label: "T473" },
  { id: "turn_on_do_not_disturb'", label: "T474" },
  { id: "remove_distractions", label: "T475" },
  { id: "pomodoro_technique", label: "T476" },
  { id: "set_small_goals", label: "T477" },
  { id: "staying_hydrated", label: "T492" },
  { id: "good_quality_sleep", label: "T493" },
  { id: "exercising", label: "T171" },
  { id: "lunchtime_walk", label: "T494" },
  { id: "regular_mealtimes", label: "T495" },
  { id: "taking_breaks", label: "T496" },
  { id: "eating_nutritious_food", label: "T497" },
  { id: "time_outdoors", label: "T498" },
  { id: "low_stress_levels", label: "T499" },
  { id: "prioritising_self_care", label: "T500" },
  { id: "meditation_mindfulness", label: "T501" },
  { id: "power_nap", label: "T502" },
  { id: "poor_quality_sleep", label: "T503" },
  { id: "dehydration", label: "T504" },
  { id: "too_much_caffeine", label: "T505" },
  { id: "processed_food", label: "T506" },
  { id: "sugary_drinks", label: "T507" },
  { id: "skipping_meals", label: "T508" },
  { id: "physical_pain_illness", label: "T509" },
  { id: "medication_side_effects", label: "T510" },
  { id: "feeling_stressed'", label: "T511" },
  { id: "drinking_alcohol", label: "T037" },
  { id: "sitting_for_long_periods", label: "T513" },
  { id: "big_lunchtime_meal", label: "T514" },
  { id: "nicotine_replacement", label: "T122" },
  { id: "medication", label: "T123" },
  { id: "app_notifications", label: "T124" },
  { id: "remembering_reasons", label: "T125" },
  { id: "keeping_the_goal_in_mind", label: "T126" },
  { id: "support_from_others", label: "T127" },
  { id: "avoided_triggers", label: "T128" },
  { id: "meditation_or_mindfulness", label: "T129" },
  { id: "journalling", label: "T130" },
  { id: "keeping_busy", label: "T131" },
  { label: "T211", id: "sad" },
  { label: "T212", id: "anxious" },
  { label: "T213", id: "angry" },
  { label: "T214", id: "stressed" },
  { label: "T215", id: "frustrated" },
  { label: "T216", id: "guilty" },
  { label: "T217", id: "confused" },
  { label: "T218", id: "tired" },
  { label: "T219", id: "disappointed" },
  { label: "T220", id: "lonely" },
  { label: "T221", id: "disgusted" },
  { label: "T222", id: "embarrassed" },
  { label: "T223", id: "annoyed" },
  { label: "T224", id: "shocked" },
  { label: "T225", id: "distracted" },
  { label: "T226", id: "bored" },
  { label: "T227", id: "restless" },
  { label: "T228", id: "fed_up" },
  { label: "T229", id: "unsure" },
  { label: "T230", id: "indifferent" },
  { label: "T231", id: "tense" },
  { label: "T232", id: "okay" },
  { label: "T233", id: "happy" },
  { label: "T234", id: "content" },
  { label: "T235", id: "surprised" },
  { label: "T236", id: "calm" },
  { label: "T237", id: "thoughtful" },
  { label: "T238", id: "grateful" },
  { label: "T239", id: "determined" },
  { label: "T240", id: "proud" },
  { label: "T241", id: "relieved" },
  { label: "T242", id: "joyful" },
  { label: "T243", id: "creative" },
  { label: "T244", id: "surprised" },
  { label: "T245", id: "inspired" },
  { label: "T246", id: "amazed" },
  { label: "T247", id: "energetic" },
  { label: "T248", id: "excited" },
  { id: "relaxing_bedtime_routine", label: "T555" },
  { id: "listening_to_sleep_story", label: "T556" },
  { id: "regular_sleep_schedule", label: "T027" },
  { id: "listening_to_calming_sounds", label: "T557" },
  { id: "30_mins_of_daylight", label: "T028" },
  { id: "cool_dark_quiet_bedroom", label: "T558" },
  { id: "limited_blue_light_exposure", label: "T559" },
  { id: "meditation_practice ", label: "T560" },
  { id: "no_alcohol_before_bed", label: "T561" },
  { id: "no_smoking_before_bed", label: "T562" },
  { id: "limited_caffeine_intake", label: "T563" },
  { id: "regular_exercise", label: "T564" },
  { id: "staying_up_late", label: "T565" },
  { id: "large_meal_near_bedtime", label: "T566" },
  { id: "exercise_near_bedtime", label: "T567" },
  { id: "stress_or_anxiety", label: "T568" },
  { id: "blue_light_exposure", label: "T034" },
  { id: "working_shifts", label: "T569" },
  { id: "drinking_alcohol", label: "T037" },
  { id: "caffeine_near_bedtime", label: "T570" },
  { id: "smoking_before_bed", label: "T571" },
  { id: "scrolling_on_social_media", label: "T035" },
  { id: "hot_uncomfortable_room", label: "T572" },
  { id: "physical_pain_or_illness", label: "T573" },
  { id: "medication_side_effects", label: "T574" },
];
