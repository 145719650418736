import React, { useEffect, useState } from "react";

import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";

import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

const PainSlider = (props) => {
  const [textValue, setTextValue] = useState("");
  const [styles, setStyles] = useState(false);
  const [percent, setPercent] = useState("");

  const convertTextValues = (value) => {
    switch (value) {
      case "1":
        return props.textValues[0];
      case "2":
        return props.textValues[1];
      case "3":
        return props.textValues[2];
      case "4":
        return props.textValues[3];
      default:
        return props.textValues[4];
    }
  };

  const convertToNumber = (value) => {
    switch (value) {
      case props.textValues[0]:
        return "1";
      case props.textValues[1]:
        return "2";
      case props.textValues[2]:
        return "3";
      case props.textValues[3]:
        return "4";
      default:
        return "5";
    }
  };

  const convertPercentValues = (value) => {
    switch (value) {
      case "1":
        return 10;
      case "2":
        return 30;
      case "3":
        return 50;
      case "4":
        return 70;
      default:
        return 90;
    }
  };

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      if (props.assessmentValues[props.questionid].textValue) {
        setTextValue(props.assessmentValues[props.questionid].textValue);
      } else {
        let text = convertTextValues(
          props.assessmentValues[props.questionid].toString()
        );
        setTextValue(text);
      }
      if (props.assessmentValues[props.questionid].percentValue) {
        setStyles({
          left: props.assessmentValues[props.questionid].percentValue + "%",
        });
        setPercent(props.assessmentValues[props.questionid].percentValue);
      } else {
        let percentages = convertPercentValues(
          props.assessmentValues[props.questionid].toString()
        );
        setStyles({ left: percentages + "%" });
        setPercent(percentages);
      }
    } else {
      setTextValue("3");
      setStyles({ left: "50%" });
      setPercent(50);
      props.onSetValue({ id: props.questionid, value: props.textValues[2] });
    }
  }, []);

  const onSlidePain = (percent) => {
    let pos = (Number(percent[0]) / 2).toFixed(0);
    setPipClass(pos);
    let painLevel = "";
    if (pos <= 10) {
      painLevel = props.textValues[0];
    } else if (pos <= 20) {
      painLevel = props.textValues[1];
    } else if (pos <= 30) {
      painLevel = props.textValues[2];
    } else if (pos <= 40) {
      painLevel = props.textValues[3];
    } else {
      painLevel = props.textValues[4];
    }
    setTextValue(painLevel);
    setStyles({ left: Number(percent[0]).toFixed(2) + "%" });
    let response = convertToNumber(painLevel);
    props.onSetValue({ id: props.questionid, value: response });
  };

  const setPipClass = (pos) => {
    if (pos > 50) {
      pos = 50;
    }

    let prePos = parseFloat(pos) - 1;
    let postPos = parseFloat(pos) + 1;

    let prePos2 = parseFloat(pos) - 2;
    let postPos2 = parseFloat(pos) + 2;

    let x = document
      .getElementById(props.questionid + "_slider")
      .querySelectorAll(".noUi-marker");

    [].forEach.call(x, function (el) {
      el.classList.remove("active");
      el.classList.remove("semiactive");
      el.classList.remove("nearactive");
    });

    if (pos <= x.length) {
      x[pos].classList.add("active");
    }
    if (prePos > -1) {
      x[prePos].classList.add("semiactive");
    }
    if (postPos <= 50) {
      x[postPos].classList.add("semiactive");
    }
    if (prePos2 > -1) {
      x[prePos2].classList.add("nearactive");
    }
    if (postPos2 <= 50) {
      x[postPos2].classList.add("nearactive");
    }
  };

  const setPain = () => {
    //alert('go');
  };

  return (
    <div id={props.questionid} className={"req question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      <div className="answer_block fs-exclude">
        <div className="painSlider" id={props.questionid}>
          <div className="leftLabel">{props.textValues[0]}</div>
          <div className="rightLabel">{props.textValues[4]}</div>
          <Nouislider
            id={props.questionid + "_slider"}
            pips={{ mode: "range", density: 2 }}
            clickablePips
            range={{ min: 0, max: 100 }}
            start={percent}
            connect="lower"
            onUpdate={onSlidePain}
            onSet={setPain}
          />
          {styles && textValue && (
            <div
              className="valueHandle"
              id={props.questionid + "_value_handle"}
              style={styles}
            >
              {textValue}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PainSlider;
