import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";

import "./DropDownCalendar.css";
import { useEffect } from "react";
import { prototype } from "chart.js";
import { IfPending } from "react-async";

function DropDownCalendar(props) {
  const [toggle, setToggle] = useState("closed");
  const [dropDownTitle, setDropDownTitle] = useState();

  useEffect(() => {
    if (props.assessmentDate) {
      if (props.profile) {
        setDropDownTitle(props.assessmentDate.replace(/&amp;/g, "&"));
      } else {
        setDropDownTitle(props.assessmentDate);
      }
    }
  }, [props.assessmentDate]);

  const dropDownClickHandler = () => {
    if (toggle === "closed") {
      setToggle("open");
      setDownArrow(false);
    } else {
      setToggle("closed");
      setDownArrow(true);
    }
  };

  const [downArrow, setDownArrow] = useState(true);

  const handleClick = (event) => {
    if (!props.profile) {
      setDropDownTitle(event.target.innerHTML);
      props.setAssessmentDate(event.target.innerHTML);
    } else {
      setDropDownTitle(event.target.innerText);
      props.setAssessmentDate(event.target.innerHTML);
    }
    if (props.deleteAccount) {
      props.addFollowUp(props.dates[event.target.id].explanation);
    }
  };

  return (
    <div
      className={
        props.profile ? "profile_drop_down_wrapper" : "cal_drop_down_wrapper"
      }
    >
      <div
        className={"drop_down " + props.show + " " + toggle}
        onClick={dropDownClickHandler}
      >
        <div
          className={
            props.profile
              ? "profile drop_down_title_wrapper"
              : "drop_down_title_wrapper"
          }
        >
          <span className={props.profile ? "profile_title" : "drop_down_title"}>
            {" "}
            {!props.profile && (
              <FontAwesomeIcon
                icon={faCalendar}
                className={
                  props.profile ? "icon_profile" : "icon fa-calendar-alt"
                }
              />
            )}
            {dropDownTitle}
          </span>
          {downArrow ? (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={
                props.profile
                  ? "profile icon fa-chevron-down"
                  : "icon fa-chevron-down calendar"
              }
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronUp}
              className={
                props.profile
                  ? "profile icon fa-chevron-up"
                  : "icon fa-chevron-up calendar"
              }
            />
          )}
        </div>
        <div className={props.profile ? "profile_suggestions" : ""}>
          {props.dates &&
            props.dates.map((date, i) => (
              <div
                key={i}
                id={i}
                className={
                  props.profile
                    ? "profile_drop_down_content"
                    : "drop_down_content dates"
                }
                onClick={handleClick}
              >
                {props.profile &&
                  !props.deleteAccount &&
                  date.replace(/&amp;/gi, "&")}
                {props.profile && props.deleteAccount && date.reason}
                {!props.profile &&
                  !props.deleteAccount &&
                  date.split("-").reverse().join(".")}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DropDownCalendar;
