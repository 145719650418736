import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles/ActivityList.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/pro-light-svg-icons";
import { faClock } from "@fortawesome/pro-thin-svg-icons";
import { faChevronDown } from "@fortawesome/sharp-regular-svg-icons";
import { activity_list } from "../../shared/components/data";

const ActivityList = (props) => {
  const { t } = useTranslation();
  const [lines, setLines] = useState([
    {
      id: 0,
      selectedItem: `${t("T071")}`,
      duration: 0,
      db_id: null,
    },
  ]);
  const [activeId, setActiveId] = useState(null);
  const inputRefs = useRef([]);

  useEffect(() => {
    // Prevent scrolling on number input
    const handleWheel = (event) => {
      event.preventDefault();
    };

    inputRefs.current.forEach((input) => {
      if (input) {
        input.addEventListener("wheel", handleWheel);
      }
    });

    return () => {
      inputRefs.current.forEach((input) => {
        if (input) {
          input.removeEventListener("wheel", handleWheel);
        }
      });
    };
  }, [lines]);

  useEffect(() => {
    if (props.resetLines) {
      setLines([
        {
          id: 0,
          selectedItem: `${t("T071")}`,
          duration: 0,
          db_id: null,
        },
      ]);
      props.setResetLines(false);
    }
  }, [props.resetLines]);

  useEffect(() => {
    if (props.data?.[props.questionid]?.[0]?.duration) {
      let arr = [...props.data[props.questionid]];
      let modifiedArr = arr.map((el, index) => {
        let item = activity_list.find((item) => item.id === el.id);
        return {
          id: index,
          selectedItem: t(item.label),
          duration: el.duration,
          db_id: el.id,
        };
      });
      setLines(modifiedArr);
    }
  }, [props.data?.[props.questionid]?.[0]?.duration]);

  useEffect(() => {
    let dbArray = [];
    lines.forEach((line) => {
      if (line.db_id && line.duration > 0) {
        dbArray.push({ id: line.db_id, duration: line.duration });
      }
    });

    props.saveResponse("data", {
      id: props.questionid,
      value: dbArray,
    });
  }, [lines]);

  const handleDeleteLine = (id) => {
    setLines(lines.filter((line) => line.id !== id));
    props.setExistingTracker(false);
  };

  const handleAddLine = () => {
    const newLineId = lines.length > 0 ? lines[lines.length - 1].id + 1 : 0;
    setLines([
      ...lines,
      {
        id: newLineId,
        selectedItem: `${t("T071")}`,
        duration: 0,
        db_id: null,
      },
    ]);
    props.setExistingTracker(false);
  };

  const handleDropDown = (id) => {
    setActiveId((prevActiveId) => (prevActiveId === id ? null : id));
  };

  const handleItemSelect = (event, lineId, item) => {
    props.setExistingTracker(false);
    const selectedItem = event.target.innerHTML;
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.id === lineId) {
          return {
            ...line,
            selectedItem,
            db_id: item.id,
          };
        }
        return line;
      })
    );
  };

  const handleDurationChange = (event, lineId) => {
    const duration = event.target.value > 300 ? 300 : event.target.value;

    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.id === lineId) {
          return { ...line, duration };
        }
        return line;
      })
    );
  };

  return (
    <div>
      {lines.map((line, index) => (
        <div key={line.id} className="line fs-mask">
          <div className="part-line">
            <div
              className="custom-select"
              style={{ zIndex: activeId === line.id ? 1 : 0 }}
            >
              <div className="selected" onClick={() => handleDropDown(line.id)}>
                {line.selectedItem}
                <FontAwesomeIcon id={line.id} icon={faChevronDown} />
              </div>
              {activeId === line.id && (
                <div
                  className="list-cont"
                  onMouseLeave={() => {
                    setActiveId(null);
                  }}
                  onTouchEnd={() => {
                    setActiveId(null);
                  }}
                >
                  <ul className="list">
                    {activity_list &&
                      activity_list.map((item, index) => {
                        return (
                          <li
                            key={index}
                            id={item.id}
                            onClick={(event) =>
                              handleItemSelect(event, line.id, item)
                            }
                            onTouchStart={(event) =>
                              handleItemSelect(event.touches[0], line.id, item)
                            }
                          >
                            {t(item.label)}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="part-line">
            <FontAwesomeIcon icon={faClock} className="icon clock" />
            <input
              ref={(el) => (inputRefs.current[index] = el)}
              type="number"
              min="0"
              max="300"
              style={{ width: "200px" }}
              value={line.duration}
              onChange={(event) => handleDurationChange(event, line.id)}
            />
            <p>{t("T070")}</p>
          </div>
          <div className="part-line">
            <FontAwesomeIcon
              icon={faTimes}
              className="icon times"
              onClick={() => handleDeleteLine(line.id)}
            />
          </div>
        </div>
      ))}
      <div className="add-line">
        <div className="icon-cont">
          <FontAwesomeIcon
            icon={faPlus}
            className="icon plus"
            onClick={handleAddLine}
          />
        </div>
        <div>{t("T069")}</div>
      </div>
    </div>
  );
};

export default ActivityList;
