import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";
import ProgressBars from "./ProgressBars";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { AuthContext } from "../../shared/context/auth-context";

function Footer(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const [lastPage, setLastPage] = useState(false);
  const [scrollControlIcon, setScrollControlIcon] = useState(faChevronDown);

  function navigateNextPage(event) {
    setScrollControlIcon(faChevronDown);
    props.onNextPage();
    setScrollControlIcon("down");
  }
  function navigatePrevPage(event) {
    setScrollControlIcon(faChevronDown);
    props.onPrevPage();
    setScrollControlIcon("down");
  }

  function scrollToBottom() {
    let containerHeight =
      document.getElementsByClassName("question_container")[0].clientHeight -
      200;
    if (scrollControlIcon === "down") {
      window.scroll({
        top: containerHeight,
        behavior: "smooth",
      });
      setScrollControlIcon("up");
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
      setScrollControlIcon("down");
    }
  }

  useEffect(() => {
    if (props.iProgress) {
      if (
        props.iProgress === 13 &&
        auth?.access === "2" &&
        props.goals?.length > 0
      ) {
        setLastPage(true);
      } else if (
        props.goals?.length > 0 &&
        props.iProgress === 15 &&
        auth.access !== "10"
      ) {
        setLastPage(true);
      } else if (props.iProgress === 16) {
        setLastPage(true);
      } else {
        setLastPage(false);
      }
    }
  }, [props.iProgress]);

  return (
    <div className="main_footer">
      <div id="scroll_fade_bottom">
        <div id="down_icon" onClick={scrollToBottom}>
          <FontAwesomeIcon
            icon={scrollControlIcon === "up" ? faChevronUp : faChevronDown}
            className="icon"
          />
        </div>
      </div>
      <div id="progress_footer_container">
        <div id="progress_footer">
          {props.onPrevPage && (
            <div id="back" onClick={navigatePrevPage}>
              <FontAwesomeIcon icon={faChevronLeft} className="icon" />
            </div>
          )}
          <ProgressBars
            sectionNo={props.sectionNo}
            sectionMax={props.sectionMax}
          />
          {props && props.onNextPage && (
            <button
              id="next"
              className={
                lastPage
                  ? "last_page"
                  : props.iProgress
                  ? "i" + props.iProgress
                  : "no_progress"
              }
              onClick={navigateNextPage}
            >
              {lastPage ? (
                <span id="generate_report">
                  {t("A116")}
                  <br />
                  {t("A117")}
                </span>
              ) : (
                <FontAwesomeIcon icon={faChevronRight} className="icon" />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Footer;
