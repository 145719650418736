import { useState, useCallback, useContext } from "react";
import { useHttpClient } from "./http-hook";
import { AuthContext } from "../context/auth-context";

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const useMedia = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  //get one media
  const getMediaContentById = async (chid) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/media/${chid}`
      );
      if (response) {
        openMediaModal(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //   useEffect(() => {
  //     if(!media){
  //     runHandler();
  //     }
  //   }, []);

  //handle media modal
  const [mediaModalIsOpen, setMediaModalIsOpen] = useState(false);
  //const[mediaModalTextContent, setMediaModalTextContent] = useState('')
  const [mediaContent, setMediaContent] = useState();

  //remember scroll top position
  const [scrolled, setScrolled] = useState(0);
  const [scrollSaver, setScrollSaver] = useState();

  const mediaModalClickHandler = useCallback(() => {
    setMediaModalIsOpen(false);
    setActiveId("");
    setScrolled(scrollSaver);
  }, [mediaModalIsOpen]);

  const openMediaModal = useCallback(
    (props) => {
      setActiveId(props.chid);
      setMediaContent(props);
      setTimeout(function () {
        setMediaModalIsOpen(true);
        // document.body.classList.add("popup_open");
      }, 200);
      //check scroll top and set scrolled

      let scrollTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;
      setScrollSaver(scrollTop);
    },
    [mediaModalIsOpen]
  );

  const [activeId, setActiveId] = useState();

  //set state for favourites and progress
  const [favourites, setFavourites] = useState();
  const [progressList, setProgressList] = useState();
  const [favouritesSlider, setFavouritesSlider] = useState();
  const [likes, setLikes] = useState();
  const [dislikes, setDislikes] = useState();

  const setFavouritesAndProgressHandler = (sliders) => {
    let favouritesArr = [];
    let progressArr = [];
    // let likes = [];
    // let dislikes = [];
    sliders.map((slider) => {
      if (slider.type === "slider") {
        slider.media.map((item) => {
          if (item.favourite) {
            let exists = favouritesArr.some((element) => element === item._id);
            if (!exists) {
              favouritesArr.push(item._id);
            }
          }
          if (item.progress && item.progress !== 0) {
            let exists = progressArr.some(
              (element) => element._id === item._id
            );
            if (!exists) {
              progressArr.push({
                mediaid: item._id,
                progress: item.progress,
              });
            }
          }
        });
      }
      if (slider.title === "Favourites") {
        setFavouritesSlider(() => slider);
      }
    });
    setFavourites(favouritesArr);
    setProgressList(progressArr);
  };

  const updateFavouritesSlider = (mediaContent) => {
    let amendedSlider;
    if (favouritesSlider) {
      amendedSlider = favouritesSlider;
    } else {
      amendedSlider = {
        media: [],
        target: "",
        title: "Favourites",
        type: "slider",
      };
    }
    let mediaArr = amendedSlider.media;
    let exists = mediaArr.some((element) => element._id === mediaContent._id);
    if (!exists) {
      mediaArr.push(mediaContent);
    } else {
      mediaArr = mediaArr.filter((media) => media._id !== mediaContent._id);
    }
    amendedSlider.media = mediaArr;
    setFavouritesSlider(amendedSlider);
  };

  const addFavouriteHandler = async (mediaId) => {
    try {
      const responseData2 = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/media/favourites`,
        "POST",
        JSON.stringify({
          id: mediaId,
        })
      );
      if (responseData2.favourites) {
        setFavourites(responseData2.favourites);
      }
    } catch (err) {}
  };

  const addLikeDislike = async (action, mediaId) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/media/preference`,
        "POST",
        JSON.stringify({
          action: action,
          mediaId: mediaId,
        })
      );
      if (responseData) {
        setLikes(responseData.likes);
        setDislikes(responseData.dislikes);
      }
    } catch (err) {}
  };

  const setLikesDislikesHandler = (likes, dislikes) => {
    setLikes(likes);
    setDislikes(dislikes);
  };

  //   const getFavouritesHandler = async () => {
  //     if(userId){
  //       try {
  //           const responseData3 = await sendRequest(
  //             `${process.env.REACT_APP_BACKEND_URL}/media/favourites`
  //           );
  //           if(responseData3.favourites){
  // //              setFavourites(responseData3.favourites);
  //           }
  //       } catch (err) {}
  //     }
  //   }

  // useEffect(() => {
  //   if(!favourites && userId){
  //     getFavouritesHandler();
  //   }
  //   if(favourites){
  //     setFavourites(favourites);
  //   }
  // }, [userId, favourites]);

  //progress
  const addProgressHandler = async (mediaId, progress) => {
    let completed;
    progress >= 99 ? (completed = true) : (completed = false);
    try {
      const responseData4 = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/media/progress`,
        "POST",
        JSON.stringify({
          mediaId: mediaId,
          progress: progress,
          completed: completed,
        })
      );
      if (responseData4.progressList) {
        setProgressList(JSON.parse(responseData4.progressList));
        //alert(responseData4.progressList);
      }
    } catch (err) {}
  };

  //     const getProgressHandler = async () => {
  //       if(userId){
  //         try {
  //             const responseData5 = await sendRequest(
  //               `${process.env.REACT_APP_BACKEND_URL}/media/progress`
  //             );
  //             if(responseData5.progressList){
  // //              setProgressList(JSON.parse(responseData5.progressList));
  //             }
  //         } catch (err) {}
  //       }
  //     }

  // useEffect(() => {
  //   if(!progressList && userId){
  //     getProgressHandler();
  //   }
  //   if(progressList){
  //     setProgressList(progressList);
  //   }
  // }, [userId, progressList]);

  return {
    mediaModalIsOpen,
    mediaModalClickHandler,
    mediaContent,
    activeId,
    openMediaModal,
    setFavouritesAndProgressHandler,
    favourites,
    addFavouriteHandler,
    progressList,
    addProgressHandler,
    favouritesSlider,
    updateFavouritesSlider,
    likes,
    dislikes,
    addLikeDislike,
    scrolled,
    getMediaContentById,
    setLikesDislikesHandler,
  };
};
