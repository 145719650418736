import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

const HourSelect = (props) => {
  const { t } = useTranslation();
  const [hourValue, setHourValue] = useState(0);
  const [minuteValue, setMinuteValue] = useState(0);
  const [hourSuffix, setHourSuffix] = useState(props.hourSuffix);
  const minuteSuffix = props.minuteSuffix;
  const [skipActive, setSkipActive] = useState("");

  const setIncreaseClockHours = () => {
    let newHourValue = Number(hourValue) + 1;
    if (newHourValue < 24) {
      setHourValue(newHourValue);
      saveValue(`${newHourValue}:${minuteValue}`);
    }

    if (newHourValue === 1) {
      setHourSuffix(t("A121"));
    } else {
      setHourSuffix(t("A122"));
    }
  };

  const setDecreaseClockHours = () => {
    let newHourValue = Number(hourValue);
    if (Number(hourValue) > 0) {
      newHourValue = Number(hourValue) - 1;
    }
    setHourValue(newHourValue);
    saveValue(`${newHourValue}:${minuteValue}`);

    if (newHourValue === 1) {
      setHourSuffix(t("A121"));
    } else {
      setHourSuffix(t("A122"));
    }
  };

  const setIncreaseClockMinutes = () => {
    var newMinuteValue = Number(minuteValue);
    if (Number(minuteValue) < 45) {
      newMinuteValue = Number(minuteValue) + 15;
    } else {
      if (props.questionid === "hours_sitting") {
        newMinuteValue = "0";
      } else {
        newMinuteValue = "00";
      }
    }
    setMinuteValue(newMinuteValue);
    saveValue(hourValue + ":" + newMinuteValue);
  };

  const setDecreaseClockMinutes = () => {
    var newMinuteValue = Number(minuteValue);
    if (Number(minuteValue) > 0) {
      newMinuteValue = Number(minuteValue) - 15;
      if (newMinuteValue <= 0) {
        newMinuteValue = "00";
      }
    } else {
      newMinuteValue = 45;
    }
    setMinuteValue(newMinuteValue);
    saveValue(`${hourValue}:${newMinuteValue}`);
  };

  const toggleSkipActive = () => {
    if (skipActive === "active") {
      setSkipActive("");
      props.onSetValue({ id: props.questionid, value: "" });
    } else {
      setSkipActive("active");
      props.onSetValue({ id: props.questionid, value: "wheelchair" });
    }
  };

  const clockChangeHandler = (event) => {
    if (event.target.id === "hours") {
      setHourValue(event.target.value);
      saveValue(event.target.value + ":" + minuteValue);
    } else if (event.target.id === "minutes") {
      setMinuteValue(event.target.value);
      saveValue(hourValue + ":" + event.target.value);
    }
  };

  const handleFocusOut = (event) => {
    if (event.target.id === "hours") {
      let hours = Number(event.target.value);
      if (hours > 23) {
        hours = 23;
      } else if (hours < 0) {
        hours = 0;
      } else if (isNaN(hours)) {
        hours = 0;
      }
      if (hours === 1) {
        setHourSuffix(t("A121"));
      } else {
        setHourSuffix(t("A122"));
      }
      setHourValue(hours);
      saveValue(hours + ":" + minuteValue);
    } else if (event.target.id === "minutes") {
      let mins = Number(event.target.value);
      if (mins > 45) {
        mins = 45;
      } else if (mins < 0) {
        mins = 0;
      } else if (isNaN(mins)) {
        mins = 0;
      }
      setMinuteValue(mins);
      saveValue(hourValue + ":" + mins);
    }
  };

  const saveValue = (time) => {
    props.onSetValue({ id: props.questionid, value: time });
  };

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      var time = props.assessmentValues[props.questionid];
      if (time === "wheelchair") {
        setSkipActive("active");
      } else {
        var index = time.indexOf(":");
        var hour = time.substr(0, index);
        var minute = time.substr(index + 1);
        if (!Number(hour)) {
          hour = "00";
        }

        if (!Number(minute)) {
          minute = "00";
        }
        setHourValue(hour);
        setMinuteValue(minute);
      }
    } else {
      if (props.questionid === "hours_sitting") {
        saveValue("0:0");
      } else {
        saveValue("00:00");
      }
    }
  }, []);

  return (
    <div id={props.questionid} className={"question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.questionTitleSuffix ? (
        <span className="titleSuffix fs-exclude">
          {props.questionTitleSuffix}
        </span>
      ) : null}
      {props.skipText ? (
        <span
          className={"suffixLabel fs-exclude " + skipActive}
          onClick={toggleSkipActive}
        >
          {props.skipText}
        </span>
      ) : null}
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      <div className="answer_block">
        {skipActive !== "active" && (
          <div className="number_field hour_select">
            <div className="number_container left fs-exclude">
              <input
                type="number"
                className="hours number"
                id="hours"
                value={hourValue}
                onChange={clockChangeHandler}
                onBlur={handleFocusOut}
                max="23"
                min="00"
              />
              <span className="suffix">{hourSuffix}</span>
            </div>
            <div className="number_controls">
              <div className="up_btn" onClick={setIncreaseClockHours}>
                <FontAwesomeIcon icon={faChevronUp} className="icon" />
              </div>
              <div className="down_btn" onClick={setDecreaseClockHours}>
                <FontAwesomeIcon icon={faChevronDown} className="icon" />
              </div>
            </div>
            <div className="number_container right fs-exclude">
              <input
                type="number"
                className="minutes number"
                id="minutes"
                value={minuteValue}
                onChange={clockChangeHandler}
                onBlur={handleFocusOut}
                step="15"
                max="45"
                min="00"
              />
              <span className="suffix">{minuteSuffix}</span>
            </div>
            <div className="number_controls">
              <div className="up_btn" onClick={setIncreaseClockMinutes}>
                <FontAwesomeIcon icon={faChevronUp} className="icon" />
              </div>
              <div className="down_btn" onClick={setDecreaseClockMinutes}>
                <FontAwesomeIcon icon={faChevronDown} className="icon" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HourSelect;
