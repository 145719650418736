import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import InfoTile from "./InfoTile";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

const InfoSlider = (props) => {
  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={"auto"} // Adjust this value to show parts of the next and previous slides
      navigation
      pagination={{ clickable: true }}
      style={{
        width: "626px",
        margin: "0",
        height: "420px",
      }}
      centeredSlides={true}
      mousewheel={false}
    >
      {props.textArr.map((slide, index) => (
        <SwiperSlide key={index} style={{ width: "336px", height: "420px" }}>
          <InfoTile tileContents={slide} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default InfoSlider;
