import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TextArea from "../../../individualReport/pages/components/TextArea";
import NavSlider from "../../../shared/components/slider/NavSlider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

function Page(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="report-section active leadership_page">
        <h2 className="main_title">{t("PP025")}</h2>
        {/* <NavSlider ids={['services']} titles={{services: 'Services'}} />        */}

        <div id="materials" className="results-content">
          <TextArea containerClass="clear wide partners"></TextArea>
          <TextArea containerClass="clear">
            <span className="centred">
              <h2>{t("PP001")}</h2>
              <p>{t("PP002")}</p>
            </span>
          </TextArea>
          <div id="partners_list_container">
            <div id="official_partner">
              <FontAwesomeIcon icon={faStar} className="" />
              {t("PP003")}
            </div>
            <ul className="partners_list" id="services">
              <li>
                <span className="provider_cat">{t("PP004")}</span>
                <span className="provider">
                  <a href="https://www.hellobenefex.com" target="_blank">
                    <FontAwesomeIcon icon={faExternalLink} className="" />
                    {t("PP005")}
                    <FontAwesomeIcon icon={faStar} className="" />
                  </a>
                </span>
              </li>
              <li>
                <span className="provider_cat">{t("PP006")}</span>
                <span className="provider">{t("PP007")}</span>
              </li>
              <li>
                <span className="provider_cat">{t("PP008")}</span>
                <span className="provider">
                  <a href="https://bippit.com" target="_blank">
                    <FontAwesomeIcon icon={faExternalLink} className="" />
                    {t("PP009")}
                    <FontAwesomeIcon icon={faStar} className="" />
                  </a>
                </span>
              </li>
              <li>
                <span className="provider_cat">{t("PP010")}</span>
                <span className="provider">{t("PP011")}</span>
              </li>
              <li>
                <span className="provider_cat">{t("PP012")}</span>
                <span className="provider">
                  <a href="https://www.menohealth.co.uk" target="_blank">
                    <FontAwesomeIcon icon={faExternalLink} className="" />
                    {t("PP013")}
                  </a>
                </span>
              </li>
              <li>
                <span className="provider_cat">{t("PP014")}</span>
                <span className="provider">{t("PP015")}</span>
              </li>
              <li>
                <span className="provider_cat">{t("PP016")}</span>
                <span className="provider">
                  <a
                    href="https://www.ripplesuicideprevention.com"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faExternalLink} className="" />
                    {t("PP017")}
                  </a>
                </span>
              </li>
              <li>
                <span className="provider_cat">{t("PP018")}</span>
                <span className="provider">
                  <a href="https://www.babylonhealth.com/en-gb" target="_blank">
                    <FontAwesomeIcon icon={faExternalLink} className="" />
                    {t("PP019")}
                  </a>
                </span>
              </li>
              <li>
                <span className="provider_cat">{t("PP020")}</span>
                <span className="provider">
                  <a href="https://www.physitrack.co.uk" target="_blank">
                    <FontAwesomeIcon icon={faExternalLink} className="" />
                    {t("PP021")}
                    <FontAwesomeIcon icon={faStar} className="" />
                  </a>
                </span>
              </li>
              <li>
                <span className="provider_cat"></span>
                <span className="provider">
                  <a
                    href="https://champion-health-plus.webflow.io"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faExternalLink} className="" />
                    {t("PP022")}
                    <FontAwesomeIcon icon={faStar} className="" />
                  </a>
                </span>
              </li>
              <li>
                <span className="provider_cat">{t("PP023")}</span>
                <span className="provider">{t("PP024")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Page;
