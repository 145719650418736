export const handleSeeAll = (seeMore, setSeeMore) => {
  const chapters = document.getElementsByClassName("chapter");
  for (const chapter of chapters) {
    if (seeMore) {
      document.getElementById(chapter.id).classList.remove("expanded");
      setSeeMore(false);
    } else {
      document.getElementById(chapter.id).classList.add("expanded");
      setSeeMore(true);
    }
  }
};

export const handleSeeMore = (id, item) => {
  const clickedId = id;
  const chapters = document.getElementsByClassName("chapter");
  for (const chapter of chapters) {
    if (clickedId === chapter.id && chapter.classList?.[1] === "expanded") {
      document.getElementById(chapter.id).classList.remove("expanded");
    } else if (
      clickedId === chapter.id &&
      chapter.classList?.[1] !== "expanded"
    ) {
      document.getElementById(clickedId).classList.add("expanded");
    } else {
      document.getElementById(chapter.id).classList.remove("expanded");
    }
  }
};
