import { faCircleXmark } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Clock from "../../assessment/questions/clock/Clock";
import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";

const ReminderPopUp = (props) => {
  const { t } = useTranslation();
  const daysOfWeek = [
    t("M057W"),
    t("M058W"),
    t("M059W"),
    t("M060W"),
    t("M061W"),
    t("M062W"),
    t("M063W"),
  ];
  const [reminderDays, setReminderDays] = useState([]);
  const [on, setOn] = useState(false);

  const handleTime = () => {};
  const handleDays = (e) => {
    let daysArr = reminderDays;
    if (daysArr.includes(e.target.id)) {
      daysArr = daysArr.filter((item) => item !== e.target.id);
    } else {
      daysArr.push(e.target.id);
    }
    setReminderDays([...daysArr]);
  };

  const handleToggle = () => {
    setOn((prevState) => !prevState);
  };

  const handleClearAll = () => {
    setReminderDays([]);
    setOn(false);
  };

  const handleSubmit = () => {
    props.setRemindOpen(false);
  };

  return (
    <div className="rate-overlay share reminder">
      <div className="share-container">
        <div className="share-heading">
          <div>{t("M013W")}</div>
          <FontAwesomeIcon
            onClick={() => {
              props.setRemindOpen(false);
            }}
            icon={faCircleXmark}
            className="icon "
          />
        </div>
        <div className="reminder_body">
          <div className="title">{t("M014")}</div>
          <Clock
            questionid="reminder_clock"
            width=""
            startHour="22"
            startMinute="30"
            onSetValue={handleTime}
          />
        </div>
        <div className="reminder_body">
          <div className="title">{t("M015")}</div>
          <div className="week">
            {daysOfWeek.map((day, index) =>
              reminderDays.includes(index.toString()) ? (
                <div
                  className="day selected"
                  id={index}
                  key={index}
                  onClick={handleDays}
                >
                  {day}
                </div>
              ) : (
                <div
                  className="day"
                  id={index}
                  key={index}
                  onClick={handleDays}
                >
                  {day}
                </div>
              )
            )}
          </div>
        </div>
        <div className="reminder_body repeat">
          <div className="title repeat">
            {t("M016")}
            <div
              className={`switch ${on ? t("T097") : t("T098")}`}
              onClick={handleToggle}
            >
              <div className="slider"></div>
              <div className="label">{on ? t("M016") : t("M019")}</div>
            </div>
          </div>
        </div>
        <div className="reminder_body buttons">
          <div className="buttons">
            <div className="clear" onClick={handleClearAll}>
              {t("M017")}
            </div>
            <DarkButton className="submit wb" onClick={handleSubmit}>
              {t("M018")}
              <FontAwesomeIcon className="icon" icon={faArrowRight} />
            </DarkButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReminderPopUp;
