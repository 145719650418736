import React, { useState, useEffect } from "react";
import "./ScrollingNumber.css";

const ScrollingNumber = ({ number, duration, direction }) => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    let intervalId;

    const updateNumber = () => {
      setCurrentNumber((prevNumber) => {
        const nextNumber = (prevNumber + 1) % 10;
        if (nextNumber === number) {
          clearInterval(intervalId);
        }
        return nextNumber;
      });
    };

    intervalId = setInterval(updateNumber, duration / 50);

    return () => {
      clearInterval(intervalId);
    };
  }, [number, duration]);

  return (
    <div className="scrolling-container">
      <div className="scrolling-number">
        <span key={currentNumber} className={`current ${direction}`}>
          {currentNumber % 10}
        </span>
      </div>
    </div>
  );
};

export default ScrollingNumber;
