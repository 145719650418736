import React, { useState, useContext } from "react";

import "./css/NpsStyle.css";

import { AuthContext } from "../../shared/context/auth-context";

function Nps(props) {
  const auth = useContext(AuthContext);
  const [firstScreen, setFirstScreen] = useState(true);
  const [score, setScore] = useState();
  const [slideDown, setSlideDown] = useState();

  const handleScoreClick = (e) => {
    setScore(e.currentTarget.innerText);
  };

  const handleNpsClick = () => {
    setFirstScreen(false);
    auth.npsStatusHandler(score);
    props.saveNps(score);
  };

  const handleFeedback = () => {
    setSlideDown("slide-down");
    props.triggerNps();
  };

  const handleMoreTime = () => {
    auth.npsStatusHandler("skip");
    setSlideDown("slide-down");
    props.triggerNps("skip");
  };

  const handleNotNow = () => {
    setSlideDown("slide-down");
    props.triggerNps("notNow");
  };

  return (
    <div className={"feedback-container " + slideDown}>
      {firstScreen ? (
        <React.Fragment>
          <div className="feedback-left-box">
            <h5>Rate your experience</h5>
            <h1>How likely are you to recommend Champion?</h1>
          </div>
          <div className="feedback-right-box">
            <div className="nps-container">
              {[...Array(11)].map((x, i) => (
                <React.Fragment>
                  {i == score ? (
                    <div
                      className="nps-number selected"
                      key={i}
                      onClick={handleScoreClick}
                    >
                      {i}
                    </div>
                  ) : (
                    <div
                      className="nps-number"
                      key={i}
                      onClick={handleScoreClick}
                    >
                      {i}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="button-container">
              <button className="nps-button" onClick={handleNpsClick}>
                Let us know
              </button>
              <div onClick={handleMoreTime}>I need more time</div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="nps-submitted">
          <h1>Thank you for letting us know!</h1>
          <p>Do you have two minutes to tell us a bit more?</p>
          <div className="button-container">
            <a
              className="nps-button"
              href={
                "https://physitrack.typeform.com/to/wUa3JVHL#company=" +
                auth.clientName.replace(/ /, "_")
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleFeedback}
            >
              Sure!{" "}
            </a>

            <div onClick={handleNotNow}>Not right now</div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Nps;
