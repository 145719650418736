import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { colours } from "../../../assets/colours";

import "./GreenCheckOverlay.css";

const GreenCheckOverlay = (props) => {
  const { t } = useTranslation();
  return (
    <div className="popup-overlay">
      {props.masterclass ? (
        <div className="complete-cont">
          {" "}
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{
              paddingRight: "10px",
            }}
          />{" "}
          {t("GC001")}
        </div>
      ) : (
        <FontAwesomeIcon
          icon={faCircleCheck}
          color={colours.green}
          style={{
            height: "150px",
            width: "150px",
            position: "fixed",
            left: "calc(50% - 75px)",
            top: "calc(50% - 150px)",
          }}
        />
      )}
    </div>
  );
};

export default GreenCheckOverlay;
