import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-thin-svg-icons";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

function SignupScreen3(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState();
  const [scrolled, setScrolled] = useState(false);
  // const [accepted, setAccepted] = useState(false);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  document.addEventListener("keydown", (event) => {
    if (event.code === "ArrowDown") {
      scroll(20);
    } else if (event.code === "ArrowUp") {
      scroll(-20);
    }
  });

  const scroll = (height) => {
    let el = document.getElementById("scrollable");
    el.scrollBy({
      top: height,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    let trigger = document.getElementById("button_trigger");
    const box = trigger.getBoundingClientRect();
    if (window.innerHeight / 2 > box.top) {
      setScrolled(true);
    }
  };

  const handleContinue = () => {
    if (scrolled) {
      let dataObj = {
        progress: "screen3",
        accepted: "accepted",
      };
      props.handleContinue(dataObj);
    }
  };

  return (
    <React.Fragment>
      <h1>{t("R034")}</h1>
      {!auth.isLoggedIn && (
        <div className="tandc">
          <div
            id="scrollable"
            className="tandc_container"
            onScroll={handleScroll}
          >
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035v") }} />
              <span> </span>
              <a
                href="https://championhealth.co.uk/eula/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("R035aa")}
              </a>
              .
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035a") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035b") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035c") }} />
            </p>
            <p id="button_trigger">
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035d") }} />
              <span> </span>{" "}
              <a
                href="https://championhealth.co.uk/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("R035u")}
              </a>{" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035ab") }} />{" "}
              <a
                href="https://support.championhealth.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("R035aa")}
              </a>
              .
            </p>
            <p>
              <b>{t("R035e")}</b>
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035f") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035g") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035h") }} />
            </p>
            <p>
              <b>{t("R035i")}</b>
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035j") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035k") }} />
              <a
                href="https://support.championhealth.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("R035ac")}
              </a>
              .
            </p>
            <p>
              <b>{t("R035l")}</b>
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035m") }} />
            </p>
            <p style={{ paddingLeft: "10px" }}>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035n") }} />
            </p>
            <p style={{ paddingLeft: "10px" }}>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035n1") }} />
            </p>
            <p style={{ paddingLeft: "10px" }}>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035o") }} />
            </p>
            <p style={{ paddingLeft: "10px" }}>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035o1") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035ad") }} />{" "}
              <a
                href="https://championhealth.co.uk/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("R035u")}
              </a>{" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035ae") }} />
            </p>
            <p>
              <b>{t("R035r")}</b>
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035s") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035t") }} />
            </p>
            <p>
              <b>{t("R035q")}</b>
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035w") }} />
            </p>
            <p>
              <b>{t("R035x")}</b>
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035y") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035z") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035af") }} />
            </p>
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035ag") }} />
            </p>{" "}
            <p>
              {" "}
              <span dangerouslySetInnerHTML={{ __html: t("R035ah") }} />
            </p>
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="dd icon fa-chevron-down"
            onClick={() => scroll(20)}
          />
        </div>
      )}
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <button
          onClick={handleContinue}
          id="continue"
          className={scrolled ? "accepted" : "declined"}
        >
          {t("R053")}
        </button>
      )}

      {errorMessage && <p>{errorMessage}</p>}
    </React.Fragment>
  );
}
export default SignupScreen3;
