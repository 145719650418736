import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TopSixGraph from "../../components/graphs/topSixGraph/TopSixGraph";
import BarChart from "../../components/graphs/barChart/BarChart";
import {
  DISABLED_GRAPHS,
  getDisabledData,
  listLimit,
} from "../../../../shared/util/companyReport";

function Stress({ data, coData, onOpenModal }) {
  const { t } = useTranslation();
  const { showWorkStress } = coData;
  const {
    stress_low,
    stress_low_moderate,
    stress_moderate,
    stress_moderate_high,
    stress_high,
    stress_level_moderate_to_high_no,
    stress_level_moderate_to_high,
    work_stress_none,
    work_stress_perfect,
    work_stress_too_much,
    work_stress_cause,
    productivity_high_stress_no,
    productivity_high_stress,
    outside_work_stressful,
  } = data;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const stress_levels = [
    { date: t("STR001"), value: stress_low },
    { date: t("STR002"), value: stress_low_moderate },
    { date: t("STR003"), value: stress_moderate },
    { date: t("STR004"), value: stress_moderate_high },
    { date: t("STR005"), value: stress_high },
  ];

  const stress_work = [
    { date: t("STR006"), value: work_stress_none },
    {
      date: t("STR007"),
      value: work_stress_perfect,
    },
    { date: t("STR008"), value: work_stress_too_much },
  ];

  return (
    <React.Fragment>
      <BarChart
        title={t("STR013")}
        data={stress_levels}
        unit="%"
        width="wide"
        modalText={t("STR014")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("STR015")}
        square
        colour="colour1"
        value={stress_level_moderate_to_high_no}
        large
        percent={stress_level_moderate_to_high}
        modalText={t("STR016")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("STR017")}
        data={stress_work}
        unit="%"
        modalText={t("STR018")}
        onOpenModal={onOpenModal}
      />
      <TopSixGraph
        title={t("STR019")}
        subTitle={t("STR040")}
        square
        preText={t("STR020")}
        modalText={t("STR021")}
        onOpenModal={onOpenModal}
        data={
          showWorkStress && work_stress_cause.length >= listLimit
            ? work_stress_cause
            : getDisabledData(DISABLED_GRAPHS.WORK_STRESS_CAUSE)
        }
        disabled={!showWorkStress || work_stress_cause.length < listLimit}
      />
      <CircleGraph
        title={t("STR022")}
        square
        colour="colour1"
        value={productivity_high_stress_no}
        large
        percent={productivity_high_stress}
        modalText={t("STR023")}
        onOpenModal={onOpenModal}
      />
      <TopSixGraph
        title={t("STR028")}
        square
        modalText={t("STR029")}
        onOpenModal={onOpenModal}
        data={
          outside_work_stressful.length < listLimit
            ? getDisabledData(DISABLED_GRAPHS.OUTSIDE_WORK)
            : outside_work_stressful
        }
        disabled={outside_work_stressful.length < listLimit}
      />
    </React.Fragment>
  );
}
export default Stress;
