// goalsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const goalsSlice = createSlice({
  name: "goals",
  initialState: {
    possibleGoals: [
      {
        title: "G013",
        id: "sleep",
        url: "sleep-&-rest",
        improves: "G056",
        maintains: "G057",
        worsens: "G058",
        descriptor: "D035",
      },
      {
        title: "G014",
        id: "activity",
        url: "movement",
        improves: "G059",
        maintains: "G060",
        worsens: "G061",
        descriptor: "D076",
      },
      {
        title: "G015",
        id: "stress",
        url: "mental-wellbeing",
        improves: "G062",
        maintains: "G063",
        worsens: "G064",
        descriptor: "DW053",
      },
      {
        title: "G016",
        id: "mood",
        url: "mental-wellbeing",
        improves: "G065",
        maintains: "G066",
        worsens: "G067",
        descriptor: "DW053",
      },
      {
        title: "G017",
        id: "nutrition",
        url: "nutrition",
        improves: "G068",
        maintains: "G069",
        worsens: "G070",
        descriptor: "G033",
      },
      {
        title: "G018",
        id: "hydration",
        url: "hydration-&-alcohol",
        improves: "G071",
        maintains: "G072",
        worsens: "G073",
        descriptor: "D139",
      },
      {
        title: "G019",
        id: "alcohol",
        url: "hydration-&-alcohol",
        improves: "G074",
        maintains: "G075",
        worsens: "G076",
        descriptor: "DW054",
      },
      {
        title: "G020",
        id: "productivity",
        url: "work-&-productivity",
        improves: "G077",
        maintains: "G078",
        worsens: "G079",
        descriptor: "D035",
      },
      {
        title: "G021",
        id: "energy",
        url: "energy",
        improves: "G080",
        maintains: "G081",
        worsens: "G082",
        descriptor: "D035",
      },
      {
        title: "G022",
        id: "smoking",
        url: "physical-wellbeing",
        improves: "G083",
        maintains: "G084",
        worsens: "G085",
        descriptor: "G033",
      },
    ],
    goals: [],
  },
  reducers: {
    setGoals: (state, action) => {
      if (action.payload?.goals) {
        let goalsArr = action.payload.goals.map((goal, index) => {
          const goalFromState = state.possibleGoals.find(
            (item) => item.id === goal.id
          );
          return {
            ...goal,
            title: goalFromState.title,
            url: goalFromState.url,
            improves: goalFromState.improves,
            maintains: goalFromState.maintains,
            worsens: goalFromState.worsens,
            descriptor: goalFromState.descriptor,
          };
        });
        state.goals = goalsArr;
      }
    },
  },
});

// your exported actions and reducer...
export const { setGoals } = goalsSlice.actions;
export default goalsSlice.reducer;
