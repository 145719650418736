import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";
import { DISABLED_GRAPHS, getDisabledData } from "../../../../shared/util/companyReport";

function Activity({ data, onOpenModal, coData }) {
  const { t } = useTranslation();
  const {
    activity_0_75,
    activity_75_150,
    activity_150,
    activity_150_no,
    resistance_no,
    resistance,
    stretching_no,
    stretching,
    sitting_hours,
    sitting_longer_no,
    sitting_longer,
    break_sitting_30,
    break_sitting_1,
    break_sitting_2,
    break_sitting_3,
    productivity_inactivity_no,
    productivity_inactivity,
  } = data;
  const { showSittingBreaks } = coData;
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const activity = [
    { date: t("AC001"), value: activity_0_75 },
    { date: t("AC002"), value: activity_75_150 },
    { date: t("AC003"), value: activity_150 },
  ];

  const sitting = [
    { date: t("AC004"), value: break_sitting_30 },
    { date: t("AC005"), value: break_sitting_1 },
    { date: t("AC006"), value: break_sitting_2 },
    { date: t("AC007"), value: break_sitting_3 },
  ];

  return (
    <React.Fragment>
      <BarChart
        title={t("AC008")}
        subTitle={t("AC009")}
        data={activity}
        width="wide"
        unit="%"
        modalText={t("AC010")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AC026")}
        square
        colour="colour1"
        value={activity_150_no}
        large
        percent={activity_150}
        modalText={t("AC027")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AC011")}
        square
        colour="colour1"
        subText={t("AC012")}
        value={resistance_no}
        large
        percent={resistance}
        modalText={t("AC013")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AC014")}
        square
        colour="colour2"
        subText={t("AC015")}
        value={stretching_no}
        large
        percent={stretching}
        modalText={t("AC016")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AC017")}
        subTitle={t("AC018")}
        square
        colour="colour3"
        value={sitting_hours}
        unit=""
        large
        modalText={t("AC019")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AC020")}
        square
        colour="colour1"
        value={sitting_longer_no}
        large
        percent={sitting_longer}
        modalText={t("AC021")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("AC022")}
        data={showSittingBreaks ? sitting : getDisabledData(DISABLED_GRAPHS.SITTING)}
        unit="%"
        modalText={t("AC023")}
        onOpenModal={onOpenModal}
        disabled={!showSittingBreaks}
      />
      <CircleGraph
        title={t("AC024")}
        square
        colour="colour1"
        value={productivity_inactivity_no}
        large
        percent={productivity_inactivity}
        modalText={t("AC025")}
        onOpenModal={onOpenModal}
      />
    </React.Fragment>
  );
}
export default Activity;
