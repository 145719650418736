import React, { useState, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Backdrop from "../menu/Backdrop";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

function Modal(props) {
  const { t } = useTranslation();
  const pageContents = {
    sub_title: t("MH003"),
    title: t("MH004"),
    text1: t("MH005"),
    text2: t("MH006"),
    buttonText: t("MH007"),
  };

  function modalClickHandler() {
    props.modalClickHandler();
  }

  return (
    <Backdrop
      open={`${props.modalIsOpen ? "open" : "closed"}`}
      onBackdropClose={modalClickHandler}
    >
      <div
        id="modal-container-onboarding"
        className={` ${props.modalIsOpen ? "open" : "closed"}`}
      >
        <div id="mentalwellbeing_image" className="left_side_onboarding"></div>
        <div className="right_side_onboarding">
          <h3>{pageContents.sub_title}</h3>
          <h1>{pageContents.title}</h1>
          <p>{pageContents.text1}</p>
          <p>{pageContents.text2}</p>
          <button id="mentalwellbeing-btn" onClick={modalClickHandler}>
            {pageContents.buttonText}{" "}
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
          </button>
        </div>
      </div>
    </Backdrop>
  );
}
export default Modal;
