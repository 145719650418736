import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ChangePasscodeRightMenu from "./children/ChangePasscodeRightMenu";
import AccountDetailsLeftMenu from "./children/AccountDetailsLeftMenu";
import NotificationsLeftMenu from "./children/NotificationsLeftMenu";
import SecurityLeftMenu from "./children/SecurityLeftMenu";
import AccountDetailsRightMenu from "./children/AccountDetailsRightMenu";
import ChangePasswordRightMenu from "./children/ChangePasswordRightMenu";

import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useTranslation } from "react-i18next";
import RemoveAccountRightMenu from "./children/RemoveAccountRightMenu";
import RemindersRightMenu from "./children/RemindersRightMenu";
import TrackersRightMenu from "./children/TrackersRightMenu";
import LanguagesRightMenu from "./children/LanguagesRightMenu";

const menuOptions = [
  { id: 41, title: "account" },
  { id: 42, title: "security" },
  { id: 43, title: "notifications" },
];

const subMenuOptions = [
  { id: 51, title: "profile" },
  { id: 52, title: "password" },
  { id: 53, title: "passcode" },
  { id: 54, title: "delete_account" },
  { id: 55, title: "reminders" },
  { id: 56, title: "trackers" },
  { id: 57, title: "languages" },
];

const Settings = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const pageName = window.location.pathname.split("/")[2];
  const subpageName = window.location.pathname.split("/")[3];
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [profile, setProfile] = useState();
  const [showRightMenu, setShowRightMenu] = useState();
  const [showLeftMenu, setShowLeftMenu] = useState();

  useEffect(() => {
    if (props.hide) {
      setShowRightMenu(false);
      setShowLeftMenu(true);
    } else {
      setShowRightMenu(true);
      setShowLeftMenu(true);
    }
  }, [props.hide]);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const profile = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile`
        );
        if (profile) {
          setProfile(profile);
        }
      } catch (err) {}
    };
    if (!profile && auth.userId) {
      getProfile();
    }
  }, [auth.userId, profile]);

  useEffect(() => {
    if (!pageName) return;
    if (subpageName === "notifications") {
      setActiveMenu(menuOptions[2]);
      setActiveSubMenu(subMenuOptions[4]);
    } else if (pageName === "settings" && !activeMenu) {
      setActiveMenu(menuOptions[0]);
      setActiveSubMenu(subMenuOptions[0]);
    }
  }, [activeMenu, pageName, subpageName]);

  const saveProfile = async (newprofile) => {
    if (newprofile && auth.token) {
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile`,
          "POST",
          JSON.stringify({
            profile: newprofile,
          })
        );
      } catch (err) {
        console.error(err);
      }
      if (responseData) {
        auth.profileHandler(newprofile);
      }
    }
  };

  const saveChanges = (props) => {
    let newprofile = { ...profile };
    newprofile[props.id] = props.value;
    setProfile(newprofile);
    saveProfile(newprofile);
  };

  const changeMenu = (index, back) => {
    if (index === 2) {
      navigate("/profile/settings/notifications");
      setActiveSubMenu(subMenuOptions[4]);
    } else {
      setActiveMenu(menuOptions[index]);
    }
    if (props.hide && (back || index !== 0)) {
      setShowLeftMenu(true);
      setShowRightMenu(false);
    } else if (props.hide && !back && index === 0) {
      setShowLeftMenu(false);
      setShowRightMenu(true);
    }
    if (index === 1 && !props.hide) {
      setActiveSubMenu(subMenuOptions[1]);
    } else if (index === 1 && props.hide) {
      setActiveSubMenu();
    }

    if (index === 0) {
      setActiveSubMenu(subMenuOptions[0]);
    }
    // else if (index === 1 && props.hide) {
    //   setActiveSubMenu();
    // }
  };

  const changeSubMenu = (index) => {
    setActiveSubMenu(subMenuOptions[index]);
    if (props.hide) {
      setShowLeftMenu(false);
      setShowRightMenu(true);
    }
  };

  return (
    <>
      {profile && (
        <div className="profile-container">
          {showLeftMenu && (
            <div className={`menu-left ${props.hide ? "simplify" : ""}`}>
              {activeMenu?.id === 41 && (
                <AccountDetailsLeftMenu
                  hide={props.hide}
                  profile={profile}
                  saveChanges={saveChanges}
                  changeMenu={changeMenu}
                  activeSubMenu={activeSubMenu}
                  changeSubMenu={changeSubMenu}
                />
              )}
              {activeMenu?.id === 42 && (
                <SecurityLeftMenu
                  hide={props.hide}
                  profile={profile}
                  activeSubMenu={activeSubMenu}
                  changeMenu={changeMenu}
                  changeSubMenu={changeSubMenu}
                />
              )}
              {activeMenu?.id === 43 && (
                <NotificationsLeftMenu
                  hide={props.hide}
                  profile={profile}
                  activeSubMenu={activeSubMenu}
                  changeMenu={changeMenu}
                  changeSubMenu={changeSubMenu}
                />
              )}
            </div>
          )}
          {showRightMenu && (
            <div className={`menu-right ${props.hide ? "simplify" : ""}`}>
              {activeMenu?.id === 41 && activeSubMenu?.id === 51 && (
                <AccountDetailsRightMenu
                  hide={props.hide}
                  changeMenu={changeMenu}
                  profile={profile}
                  saveChanges={saveChanges}
                />
              )}
              {activeMenu?.id === 42 && activeSubMenu?.id === 52 && (
                <ChangePasswordRightMenu
                  hide={props.hide}
                  profile={profile}
                  changeMenu={changeMenu}
                  saveChanges={saveChanges}
                />
              )}
              {activeMenu?.id === 42 && activeSubMenu?.id === 53 && (
                <ChangePasscodeRightMenu
                  hide={props.hide}
                  profile={profile}
                  changeMenu={changeMenu}
                  saveChanges={saveChanges}
                />
              )}
              {activeMenu?.id === 41 && activeSubMenu?.id === 54 && (
                <RemoveAccountRightMenu
                  hide={props.hide}
                  changeMenu={changeMenu}
                />
              )}
              {activeMenu?.id === 43 && activeSubMenu?.id === 55 && (
                <RemindersRightMenu hide={props.hide} changeMenu={changeMenu} />
              )}
              {activeMenu?.id === 43 && activeSubMenu?.id === 56 && (
                <TrackersRightMenu hide={props.hide} changeMenu={changeMenu} />
              )}
              {activeMenu?.id === 41 && activeSubMenu?.id === 57 && (
                <LanguagesRightMenu
                  hide={props.hide}
                  changeMenu={changeMenu}
                  profile={profile}
                  saveChanges={saveChanges}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Settings;
