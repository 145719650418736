import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarChart,
  faBolt,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

import { colours } from "../../../assets/colours";
import { useTranslation } from "react-i18next";

const StatsLeftMenu = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={
          props.activeSubMenu.id === 61
            ? "profile-slider-btn active"
            : "profile-slider-btn"
        }
        onClick={() => props.changeSubMenu(0)}
      >
        <div className="menu-item">
          <FontAwesomeIcon
            icon={faBolt}
            style={{ color: colours.blue }}
            className="slider-icon"
          />
          <div className="slider-text">{t("S043")}</div>
        </div>
        <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
      </div>
      <div
        className={
          props.activeSubMenu.id === 62
            ? "profile-slider-btn active"
            : "profile-slider-btn"
        }
        onClick={() => props.changeSubMenu(1)}
      >
        {" "}
        <div className="menu-item">
          <FontAwesomeIcon
            icon={faBarChart}
            style={{ color: colours.yellow }}
            className="slider-icon"
          />
          <div className="slider-text">{t("S047")}</div>
        </div>
        <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
      </div>
    </>
  );
};

export default StatsLeftMenu;
