import React, { useState, useEffect } from "react";
import "../styles/componentStyles/LargeCircle.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

import { useTranslation } from "react-i18next";

const outerWidth = 290;
const PI = Math.PI;
const TAU = 2 * PI;
const centre = { x: outerWidth / 2, y: outerWidth / 2 };
const radius = outerWidth / 2;

function angleForValue(value, startValue, endValue, startAngle, endAngle) {
  const progress = (value - startValue) / (endValue - startValue);
  return (endAngle - startAngle) * progress + startAngle;
}

/** Converts polar coordinates to cartesian, given the parameters of the circle */
function polarToCartesian(centerX, centerY, radius, angleInRadians) {
  return {
    x: centerX + radius * Math.cos(angleInRadians + Math.PI / 2),
    y: centerY + radius * Math.sin(angleInRadians + Math.PI / 2),
  };
}

/** Given the parameters of the arc, returns an SVG path description matching the parameters */
function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);
  var largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";
  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
  return d;
}

function WaterIntakeGraph(
  props,
  {
    startValue = 0,
    endValue = 183.3,
    startAngle = 0.25 * PI,
    endAngle = 1.75 * PI,
    thumbSize = 40,
    stroke = 10,
    fgColor = "#41c0d3",
    bgColor = "#362b3c",
    margin = 20,
  }
) {
  useEffect(() => {
    let percent = Number(props.percent.slice(0, -1));
    if (percent > 183.3) percent = 183.3;
    setValue(percent);
  }, [props.percent]);

  const { t } = useTranslation();
  const [value, setValue] = useState(Number(props.percent.slice(0, -1)));

  return (
    <div className={"chart-container " + props.width + " water"}>
      <div className="title-container ">
        <div className="title">{props.title}</div>
        <div
          className="wtf"
          onClick={() => props.openModal(props.title, props.modalText)}
        >
          <FontAwesomeIcon icon={faQuestionCircle} className="wtf icon" />
        </div>
      </div>
      <div className="charts-box water fs-mask">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              width: "290px",
              height: "290px",
              alignSelf: "centre",
            }}
          >
            <svg
              id="hs-slider"
              height="290px"
              width="290px"
              viewBox={`0 0 ${outerWidth} ${outerWidth}`}
              style={{ position: "absolute" }}
            >
              {new Array(19).fill(0).map((_, i) => {
                const delta = (TAU * 0.75) / 19;
                const theta = delta * i - PI / 2 - 0.71 * PI;
                const p1 = {
                  x: centre.x + (radius - 15) * Math.cos(theta),
                  y: centre.y + (radius - 15) * Math.sin(theta),
                };
                const p2 = {
                  x: centre.x + (radius - 5) * Math.cos(theta),
                  y: centre.y + (radius - 5) * Math.sin(theta),
                };
                return (
                  <React.Fragment key={i}>
                    <line
                      stroke="#ddcedb"
                      strokeWidth={1}
                      strokeLinecap="round"
                      x1={p1.x}
                      y1={p1.y}
                      x2={p2.x}
                      y2={p2.y}
                    />
                  </React.Fragment>
                );
              })}
              <path
                d={describeArc(
                  centre.x,
                  centre.y,
                  radius - stroke / 2 - margin,
                  startAngle,
                  endAngle
                )}
                stroke={bgColor}
                strokeWidth={stroke}
                fill="transparent"
                strokeLinecap="round"
              />
              <path
                d={describeArc(
                  centre.x,
                  centre.y,
                  radius - stroke / 2 - margin,
                  startAngle,
                  angleForValue(
                    value,
                    startValue,
                    endValue,
                    startAngle,
                    endAngle
                  )
                )}
                stroke={fgColor}
                strokeWidth={stroke}
                fill="transparent"
                strokeLinecap="round"
              />
              <filter
                id="shadow"
                x="-10"
                y="-10"
                width={thumbSize * 2 + 20}
                height={thumbSize * 2 + 20}
              >
                <feDropShadow
                  dx="-2"
                  dy="3"
                  stdDeviation="0"
                  floodColor="#d4ccd9"
                  floodOpacity="1"
                />
              </filter>

              <g>
                <text
                  x={centre.x}
                  y={centre.y - 35}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    fontFamily: "futura-pt-medium",
                    fontSize: "15px",
                    textTransform: "uppercase",
                    fill: "#ddcedb",
                  }}
                >
                  {props.litres}L
                </text>
                <text
                  x={centre.x}
                  y={centre.y - 5}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    fontFamily: "futura-pt-demi",
                    fontSize: "30px",
                    fill: "#fff",
                  }}
                >
                  {props.percent}
                </text>{" "}
                <text
                  x={centre.x}
                  y={centre.y + 20}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    fontFamily: "futura-pt-demi",
                    fontSize: "15px",
                    fill: "#ddcedb",
                  }}
                >
                  {t("D164")}
                </text>{" "}
              </g>
            </svg>
          </div>
        </div>
        <div className="glasses-cont">
          <div className="glass-no">{props.glasses}</div>
          <div className="glass-descr">{t("D163")}</div>
        </div>
      </div>
    </div>
  );
}

export default WaterIntakeGraph;
