import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";
import TopThree from "../../components/graphs/topThree/TopThree";
import {
  DISABLED_GRAPHS,
  getDisabledData,
  listLimit,
} from "../../../../shared/util/companyReport";

function Sleep({ data, coData, onOpenModal }) {
  const { t } = useTranslation();
  const {
    sleep_hours_6,
    sleep_hours_6_9,
    sleep_hours_9,
    sleep_quality_poor,
    sleep_quality_average,
    sleep_quality_good,
    sleep_quality_excellent,
    sleep_pattern_irregular,
    sleep_pattern_somewhat,
    sleep_pattern_regular,
    poor_sleep_productivity,
    average_sleep_productivity,
    good_sleep_productivity,
    excellent_sleep_productivity,
    sleep_quality_caffeine_4,
    sleep_quality_caffeine_4_8,
    sleep_quality_caffeine_8,
    productivity_tiredness_no,
    productivity_tiredness,
    sleep_most_frequent_problems,
  } = data;
  const { showSleepPattern, showSleepCoffeine } = coData;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const sleep_hours = [
    { date: t("SLP001"), value: sleep_hours_6 },
    { date: t("SLP002"), value: sleep_hours_6_9 },
    { date: t("SLP003"), value: sleep_hours_9 },
  ];

  const sleep_quality = [
    { date: t("SLP004"), value: sleep_quality_poor },
    {
      date: t("SLP005"),
      value: sleep_quality_average,
    },
    { date: t("SLP006"), value: sleep_quality_good },
    {
      date: t("SLP007"),
      value: sleep_quality_excellent,
    },
  ];

  const sleep_pattern = [
    {
      date: t("SLP008"),
      value: sleep_pattern_irregular,
    },
    {
      date: t("SLP009"),
      value: sleep_pattern_somewhat,
    },
    {
      date: t("SLP010"),
      value: sleep_pattern_regular,
    },
  ];

  const sleep_productivity = [
    {
      date: t("SLP011"),
      value: poor_sleep_productivity,
    },
    {
      date: t("SLP012"),
      value: average_sleep_productivity,
    },
    {
      date: t("SLP013"),
      value: good_sleep_productivity,
    },
    {
      date: t("SLP014"),
      value: excellent_sleep_productivity,
    },
  ];

  const caffeine_hours = [
    {
      date: t("HR001"),
      value: sleep_quality_caffeine_4,
    },
    {
      date: t("HR002"),
      value: sleep_quality_caffeine_4_8,
    },
    {
      date: t("HR003"),
      value: sleep_quality_caffeine_8,
    },
  ];

  return (
    <React.Fragment>
      <BarChart
        title={t("SLP015")}
        data={sleep_hours}
        unit="%"
        modalText={t("SLP016")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("SLP017")}
        subTitle={t("SLP018")}
        data={sleep_quality}
        width="wide"
        unit="%"
        modalText={t("SLP019")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("SLP020")}
        data={
          showSleepPattern
            ? sleep_pattern
            : getDisabledData(DISABLED_GRAPHS.SLEEP_PATTERN)
        }
        unit="%"
        modalText={t("SLP021")}
        onOpenModal={onOpenModal}
        disabled={!showSleepPattern}
      />
      <TopThree
        title={t("SLP027")}
        square
        modalText={t("SLP028")}
        onOpenModal={onOpenModal}
        value={
          sleep_most_frequent_problems.length < listLimit
            ? getDisabledData(DISABLED_GRAPHS.SLEEP_PROBLEMS)
            : sleep_most_frequent_problems
        }
        disabled={sleep_most_frequent_problems.length < listLimit}
      />
      <BarChart
        title={t("HR010")}
        data={
          showSleepCoffeine
            ? caffeine_hours
            : getDisabledData(DISABLED_GRAPHS.CAFFEINE_HOURS)
        }
        unit=""
        yaxis={t("HR011")}
        modalText={t("HR012")}
        onOpenModal={onOpenModal}
        disabled={!showSleepCoffeine}
      />
      <CircleGraph
        title={t("SLP025")}
        square
        colour="colour1"
        value={productivity_tiredness_no}
        large
        percent={productivity_tiredness}
        modalText={t("SLP026")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("SLP022")}
        data={sleep_productivity}
        unit=""
        yaxis={t("SLP023")}
        max={10}
        width="wide"
        modalText={t("SLP024")}
        onOpenModal={onOpenModal}
      />
    </React.Fragment>
  );
}
export default Sleep;
