import React from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentsStyles/TrackerChallengeBox.css";

const TrackerChallengeBox = () => {
  const { t } = useTranslation();
  return (
    <div className="tracker-challenge">
      <div className="tracker-challenge-heading">{t("CH001")} </div>
      <div className="tracker-challenge-body">{t("CH002")}</div>
    </div>
  );
};

export default TrackerChallengeBox;
