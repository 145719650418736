import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";
import TopThree from "../../components/graphs/topThree/TopThree";
import {
  DISABLED_GRAPHS,
  getDisabledData,
  listLimit,
} from "../../../../shared/util/companyReport";

function PhysicalHealth({ data, coData, onOpenModal }) {
  const { t } = useTranslation();
  const {
    lthc_yes_no,
    lthc_yes,
    lthc,
    seeking_treatment_yes,
    seeking_treatment_no,
    physical_productivity,
    physical_productivity_hayfever,
    physical_productivity_headaches,
    physical_productivity_female_health,
    eye_strain_no,
    eye_strain,
    colds_flu_no,
    colds_flu,
    menopause_no,
    menopause,
    absence,
    bmi_healthy_no,
    bmi_healthy,
    smoked_per_day,
    smoke_yes,
    smoke_no,
  } = data;
  const {
    showLthcList,
    showSeekingTreetment,
    showMenopausalStatus,
    showSmokingPerDay,
  } = coData;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const dataToDisplay = [];

  lthc.forEach((item) => {
    dataToDisplay.push(item.title);
  });
  const lthcListDisabled = ["anaemia", "fibromyalgia", "asthma"];

  const treatment = [
    {
      date: t("PHL001"),
      value: seeking_treatment_yes,
    },
    { date: t("PHL002"), value: seeking_treatment_no },
  ];

  const physical_health = [
    {
      date: t("PHL003"),
      value: physical_productivity,
    },
    {
      date: t("PHL004"),
      value: physical_productivity_hayfever,
    },
    {
      date: t("PHL005"),
      value: physical_productivity_headaches,
    },
    {
      date: t("PHL006"),
      value: physical_productivity_female_health,
    },
  ];

  const smoking_status = [
    { date: t("AS004"), value: smoke_yes },
    { date: t("AS005"), value: smoke_no },
  ];

  return (
    <React.Fragment>
      <CircleGraph
        title={t("PHL007")}
        subTitle=""
        square
        colour="colour1"
        value={lthc_yes_no}
        large
        percent={lthc_yes}
        modalText={t("PHL008")}
        onOpenModal={onOpenModal}
      />
      <TopThree
        title={t("PHL027")}
        square
        value={
          !showLthcList || dataToDisplay.length < listLimit
            ? lthcListDisabled
            : dataToDisplay
        }
        disabled={!showLthcList || dataToDisplay.length < listLimit}
      />
      <BarChart
        title={t("PHL009")}
        subtitle={t("PHL010")}
        data={
          showSeekingTreetment
            ? treatment
            : getDisabledData(DISABLED_GRAPHS.TREATMENT)
        }
        unit="%"
        modalText={t("PHL011")}
        onOpenModal={onOpenModal}
        disabled={!showSeekingTreetment}
      />
      <CircleGraph
        title={t("PHL012")}
        square
        colour="colour2"
        value={eye_strain_no}
        large
        percent={eye_strain}
        modalText={t("PHL013")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("PHL014")}
        subtitle={t("PHL015")}
        square
        colour="colour3"
        value={colds_flu_no}
        large
        percent={colds_flu}
        modalText={t("PHL016")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("PHL017")}
        square
        colour="colour1"
        value={showMenopausalStatus ? menopause_no : 0}
        large
        percent={showMenopausalStatus ? menopause : 0}
        modalText={t("PHL018")}
        onOpenModal={onOpenModal}
        disabled={!showMenopausalStatus}
      />
      <CircleGraph
        title={t("PHL019")}
        subTitle={t("PHL020")}
        square
        colour="colour2"
        value={absence}
        subText={t("PHL021")}
        large
        modalText={t("PHL022")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("PHL023")}
        data={physical_health}
        width="wide"
        unit="%"
        modalText={t("PHL024")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("PHL025")}
        square
        colour="colour3"
        value={bmi_healthy_no}
        large
        percent={bmi_healthy}
        modalText={t("PHL026")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("AS021")}
        data={smoking_status}
        unit="%"
        startZero
        modalText={t("AS022")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AS023")}
        square
        colour="colour1"
        value={showSmokingPerDay ? smoked_per_day : 0}
        subText={t("AS024")}
        large
        modalText={t("AS025")}
        onOpenModal={onOpenModal}
        disabled={!showSmokingPerDay}
      />
    </React.Fragment>
  );
}
export default PhysicalHealth;
