import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import "../styles/componentStyles/Influencers.css";

import {
  mood1,
  mood2,
  mood3,
  mood4,
  mood5,
  smoking,
  enhancers_sleep,
  inhibitors_sleep,
  blockers_exercise,
  motivators_exercise,
  motivatorsHyd,
  blockersHyd,
  reasonAlc,
  reasonStress,
  inhibitorsProd,
  enhancersProd,
  boosters_energy,
  zappers_energy,
  notSmoking,
} from "../../shared/components/data";

const Influencers = (props) => {
  const { t } = useTranslation();
  const [redList, setRedList] = useState({ title: "", list: [] });
  const [greenList, setGreenList] = useState({ title: "", list: [] });
  const [enhancerArr, setEnhancerArr] = useState([]);
  const [inhibitorArr, setInhibitorArr] = useState([]);

  useEffect(() => {
    if (props.sleep) {
      setRedList({ title: t("T032"), list: [...inhibitors_sleep] });
      setGreenList({ title: t("T023"), list: [...enhancers_sleep] });
    } else if (props.activity) {
      setRedList({ title: t("T075"), list: [...blockers_exercise] });
      setGreenList({ title: t("T073"), list: [...motivators_exercise] });
    } else if (props.hydration) {
      setRedList({ title: t("T075"), list: [...blockersHyd] });
      setGreenList({ title: t("T073"), list: [...motivatorsHyd] });
    } else if (props.alcohol) {
      setRedList({ title: t(""), list: [...reasonAlc] });
      setGreenList({ title: t(""), list: [] });
    } else if (props.stress) {
      setRedList({ title: t(""), list: [...reasonStress] });
      setGreenList({ title: t(""), list: [] });
    } else if (props.mood) {
      if (props.moodValue === 0 || props.moodList <= 20) {
        setGreenList({ title: t(""), list: [...mood1] });
      } else if (props.moodValue <= 40) {
        setGreenList({ title: t(""), list: [...mood2] });
      } else if (props.moodValue <= 60) {
        setGreenList({ title: t(""), list: [...mood3] });
      } else if (props.moodValue <= 80) {
        setGreenList({ title: t(""), list: [...mood4] });
      } else {
        setGreenList({ title: t(""), list: [...mood5] });
      }

      setRedList({ title: t(""), list: [] });
    } else if (props.productivity) {
      setRedList({ title: t("T032"), list: [...inhibitorsProd] });
      setGreenList({ title: t("T023"), list: [...enhancersProd] });
    } else if (props.energy) {
      setRedList({ title: t("T032"), list: [...zappers_energy] });
      setGreenList({ title: t("T023"), list: [...boosters_energy] });
    } else if (props.notsmoking) {
      setRedList({ title: "", list: [] });
      setGreenList({ title: "", list: [...notSmoking] });
    } else if (props.smoking) {
      setRedList({ title: "", list: [...smoking] });
      setGreenList({ title: "", list: [] });
    }
    if (props.data?.[props.questionid[0]])
      setEnhancerArr(() =>
        props.data[props.questionid[0]].map((el) => {
          return { id: el };
        })
      );

    if (props.data?.[props.questionid[1]])
      setInhibitorArr(() =>
        props.data[props.questionid[1]].map((el) => {
          return { id: el };
        })
      );
  }, [props]);

  useEffect(() => {
    if (props.moodValue && enhancerArr.length > 0) {
      let updatedArr = enhancerArr.filter((item1) =>
        greenList.list.some((item2) => item2.id === item1.id)
      );
      setEnhancerArr(updatedArr);
      let dbValues = [];
      updatedArr.forEach((item) => dbValues.push(item.id));
      props.saveResponse("journal", {
        id: props.questionid[0],
        value: dbValues,
      });
    }
  }, [props.moodValue]);

  const handleClick = (item, questionid) => {
    let newArr = [];
    if (questionid === "enhancers") {
      if (enhancerArr.length > 0 && enhancerArr.some((e) => e.id === item.id)) {
        const index = enhancerArr.findIndex((e) => e.id === item.id);
        newArr = enhancerArr;
        newArr.splice(index, 1);
        setEnhancerArr(newArr);
        let dbValues = [];
        if (newArr.length > 0) newArr.forEach((item) => dbValues.push(item.id));
        props.saveResponse("journal", {
          id: props.questionid[0],
          value: dbValues,
        });
      } else {
        if (enhancerArr.length < 3 || props.mood) {
          newArr = enhancerArr;
          newArr.push(item);
          setEnhancerArr(newArr);
          let dbValues = [];
          if (newArr.length > 0)
            newArr.forEach((item) => dbValues.push(item.id));
          props.saveResponse("journal", {
            id: props.questionid[0],
            value: dbValues,
          });
        }
      }
    } else {
      if (
        inhibitorArr.length > 0 &&
        inhibitorArr.some((e) => e.id === item.id)
      ) {
        const index = inhibitorArr.findIndex((e) => e.id === item.id);
        newArr = inhibitorArr;
        newArr.splice(index, 1);
        setInhibitorArr(newArr);
        let dbValues = [];
        if (newArr.length > 0) newArr.forEach((item) => dbValues.push(item.id));
        props.saveResponse("journal", {
          id: props.questionid[1],
          value: dbValues,
        });
      } else {
        if (inhibitorArr.length < 3) {
          newArr = inhibitorArr;
          newArr.push(item);
          setInhibitorArr(newArr);
          let dbValues = [];
          if (newArr.length > 0)
            newArr.forEach((item) => dbValues.push(item.id));
          props.saveResponse("journal", {
            id: props.questionid[1],
            value: dbValues,
          });
        }
      }
    }
  };
  return (
    <div className="influencers-cont">
      {greenList.list?.length > 0 && props.questionid && (
        <div className={props.twoColumns ? "list pos two-col" : "list pos"}>
          {!props.mood && (
            <div className="sub title">
              {greenList.title?.length > 0 ? greenList.title : " "}
              <p style={{ textTransform: "lowercase" }}>({t("T074")} 3)</p>{" "}
            </div>
          )}

          <ul className="fs-mask">
            {greenList.list.map((enh, index) => {
              return (
                <li
                  className={
                    enhancerArr.length > 0 &&
                    enhancerArr.find((e) => e.id === enh.id)
                      ? "active"
                      : ""
                  }
                  key={index + "enh"}
                  id={enh.id}
                  onClick={() => {
                    handleClick(enh, props.questionid[0]);
                  }}
                >
                  <p>{t(enh.label)}</p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {redList.list?.length > 0 && props.questionid && (
        <div className="list neg">
          <div className="sub title">
            {redList.title?.length > 0 ? redList.title : " "}
            <p style={{ textTransform: "lowercase" }}>({t("T074")} 3)</p>{" "}
          </div>

          <ul className="fs-mask">
            {redList.list.map((inh, index) => {
              return (
                <li
                  className={
                    inhibitorArr.length > 0 &&
                    inhibitorArr.find((e) => e.id === inh.id)
                      ? props.alcohol
                        ? "alc active"
                        : "active"
                      : props.alcohol
                      ? "alc "
                      : ""
                  }
                  key={index + "inh"}
                  id={inh.id}
                  onClick={() => handleClick(inh, props.questionid[1])}
                >
                  <p>{t(inh.label)}</p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Influencers;
