import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Radio from "../questions/radio/Radio";
import NumberSelectQuestion from "../questions/numberSelectQuestion/NumberSelectQuestion";
import Clock from "../questions/clock/Clock";
import OneToTenSlider from "../questions/painSlider/OneToTenSlider";

function Nutrition1(props) {
  const { t } = useTranslation();
  const dietOptions = [
    { id: "meat", title: t("NT001") },
    { id: "pescatarian", title: t("NT002") },
    { id: "vegetarian", title: t("NT003") },
    { id: "vegan", title: t("NT004") },
  ];

  const textValue = [t("NT005"), t("NT006")];

  let whoToContact;
  if (props.regionContent) {
    whoToContact = [
      { key: 0, value: props.regionContent.eating_disorder_box_1 },
      { key: 1, value: props.regionContent.eating_disorder_box_2 },
      { key: 2, value: props.regionContent.eating_disorder_box_3 },
    ];
  }
  let whoToContactOptions = [];
  whoToContact.map((contact) => whoToContactOptions.push(contact));

  useEffect(() => {
    if (
      props.assessmentValues.current_mental_health_conditions &&
      props.assessmentValues.mental_health_diagnosis === "yes" &&
      props.regionContent
    ) {
      if (
        props.assessmentValues.current_mental_health_conditions.find(
          (item) => item["id"] === "eating"
        ) ||
        props.assessmentValues.past_mental_health_conditions.find(
          (item) => item["id"] === "eating_sq"
        )
      ) {
        let html;
        if (
          props.assessmentValues.current_mental_health_conditions &&
          props.assessmentValues.current_mental_health_conditions.find(
            (item) => item["id"] === "eating"
          )
        ) {
          let modalWithBoxesText = {
            main: props.regionContent.eating_disorder_current_intro,
            secondary: whoToContactOptions,
          };
          html = modalWithBoxesText;
        } else {
          let modalWithBoxesText = {
            main: props.regionContent.eating_disorder_previous_intro,
            secondary: whoToContactOptions,
          };
          html = modalWithBoxesText;
        }
        if (html) {
          setTimeout(() => {
            props.onOpenModal(html);
          }, 700);
        }
      }
    }
  }, [
    props.assessmentValues.mental_health_conditions,
    props.assessmentValues.mental_health_diagnosis,
  ]);

  useEffect(() => {
    if (props.assessmentValues.diet !== "meat") {
      props.onSetValue({ id: "red_meat", value: null });
    }
  }, [props.assessmentValues.diet]);

  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <Radio
        questionid="diet"
        width="_wide"
        questionTitle={t("NT007")}
        options={dietOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("NT008")}
        onOpenModal={props.onOpenModal}
      />

      {props.assessmentValues.diet === "meat" && (
        <NumberSelectQuestion
          questionid="red_meat"
          questionTitle={t("NT009")}
          minlimit="0"
          maxlimit="10"
          trailingText={t("NT010")}
          width=""
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          fadeIn="yes"
          modalText={t("NT011")}
          onOpenModal={props.onOpenModal}
          skippable
        />
      )}

      <NumberSelectQuestion
        questionid="vegetables"
        questionTitle={t("NT012")}
        minlimit="0"
        maxlimit="10"
        trailingText={t("NT013")}
        width=""
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("NT014")}
        onOpenModal={props.onOpenModal}
        skippable
      />

      <NumberSelectQuestion
        questionid="takeaways"
        questionTitle={t("NT015")}
        minlimit="0"
        maxlimit="20"
        trailingText=""
        width=""
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        skippable
      />
      <hr />
      <h3>{t("NT016")}</h3>
      <hr />
      <Clock
        questionid="first_meal"
        width=""
        questionTitle={t("NT017")}
        startHour="7"
        startMinute="00"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <Clock
        questionid="last_meal"
        width=""
        questionTitle={t("NT018")}
        startHour="18"
        startMinute="30"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />

      <OneToTenSlider
        questionid="nutrition_quality"
        questionTitle={t("NT019")}
        width="_wide"
        textValues={textValue}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("NT020")}
        onOpenModal={props.onOpenModal}
      />
    </div>
  );
}

export default Nutrition1;
