import "./WhitePopUpStyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import TurquoiseButton from "../../components/styledComponents/turquoiseButton/TurquoiseButton";
import NoBackgroundButton from "../../components/styledComponents/noBackgroundButton/NoBackgroundButton";

const WhitePopUp = (props) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-title">{props.popUpContents.title}</div>
        <div className="popup-body">
          <div className="popup-heading">{props.popUpContents.heading}</div>
          <div className="popup-text-container">
            {props.popUpContents.paragraphs.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </div>
        <div className="popup-cta">
          <NoBackgroundButton
            onClick={() => {
              props.handleSubmit(false);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="icon left" />
            {props.popUpContents.secondaryBtn}
          </NoBackgroundButton>
          <TurquoiseButton
            onClick={() => {
              props.handleSubmit(true);
            }}
          >
            {props.popUpContents.primaryBtn}{" "}
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
          </TurquoiseButton>
        </div>
      </div>
    </div>
  );
};

export default WhitePopUp;
