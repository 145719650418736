import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  token: null,
  fname: null,
  tokenExpirationDate: null,
  access: null,
  usageId: null,
  login: () => {},
  logout: () => {},
  tags: null,
  image: null,
  clientName: "",
  npsStatus: null,
  authType: null,
  language: null,
  isHub: false,
  tagHandler: () => {},
  npsStatusHandler: () => {},
  profileHandler: () => {},
  languageHandler: () => {},
});
