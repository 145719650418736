import React from "react";
import { useTranslation } from "react-i18next";

import DotRadio from "../questions/dotRadio/DotRadio";
import GadHeaderRow from "../questions/dotRadio/GadHeaderRow";

function Energy2(props) {
  const { t } = useTranslation();
  const headerText = [
    { key: 0, value: t("EN029") },
    { key: 1, value: t("EN030") },
    { key: 2, value: t("EN031") },
    { key: 3, value: t("EN032") },
    { key: 4, value: t("EN033") },
  ];
  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <div className={"question_block_wide gad"}>
        <h3>{props.questionTitle}</h3>
        <div className="answer_block">
          <GadHeaderRow headerText={headerText} />
          <DotRadio
            questionid="respected_work"
            range="5"
            width=""
            questionTitle={t("EN034")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="supported_work"
            range="5"
            width=""
            questionTitle={t("EN035")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="trusted_work"
            range="5"
            width=""
            questionTitle={t("EN036")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="listened_work"
            range="5"
            width=""
            questionTitle={t("EN037")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="part_of_team_work"
            range="5"
            width=""
            questionTitle={t("EN038")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
        </div>
      </div>
    </div>
  );
}

export default Energy2;
