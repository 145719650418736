import React from "react";
import "./Style.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Menu(props) {
  const { t } = useTranslation();
  const pageid = useParams().pageid;

  const pageList = [
    { title: t("MN001"), active: "", url: "live-analytics" },
    // {title: 'Usage Statistics', active: '', url: 'usage-statistics'},
    { title: t("MN002"), active: "", url: "engagement-resources" },
    {
      title: t("MN003"),
      active: "",
      url: "campaigns-communications",
    },
    {
      title: t("MN004"),
      active: "",
      url: "health-wellbeing-strategy",
    },
    { title: t("MN005"), active: "", url: "partners-providers" },
    { title: t("MN006"), active: "", url: "leadership-lab" },
  ];

  if (props.coData && props.coData.erv === "yes") {
    pageList.push({
      title: t("MN007"),
      active: "",
      url: "erv-import",
    });
  }

  function scrollToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <div id="side_nav">
      <ul className="vertical-nav">
        {pageList.map((page) => {
          return (
            <li
              key={page.title}
              className={pageid === page.url ? "active" : ""}
            >
              <Link
                to={"/company-report/" + page.url}
                exact
                //onClick={() => scrollToTop}
              >
                <span>{page.title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default Menu;
