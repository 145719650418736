import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRotateLeft,
  faArrowRotateRight,
  faClosedCaptioning,
  faExpand,
  faCompress,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/pro-thin-svg-icons";
import {
  faCirclePlay,
  faCirclePause,
} from "@fortawesome/sharp-solid-svg-icons";

const PlayerButtons = (props) => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const [previousVolume, setPreviousVolume] = useState(1);

  const handleSkip = (seconds) => {
    const media = props.mediaRef.current;
    media.currentTime += seconds;
    props.setCurrentTime(media.currentTime);
  };

  const togglePlay = () => {
    let carryOn = true;
    if (props.mediaContent?.waiver === true) {
      carryOn = props.checkForConsent();
    }
    if (carryOn) {
      props.setIsPlaying(!props.isPlaying);
      const media = props.mediaRef.current;
      if (props.isPlaying) {
        media.pause();
        props.runCounter(false);
      } else {
        media.play();
        props.runCounter(true);
      }
    }
  };

  const toggleMute = () => {
    const media = props.mediaRef.current;
    if (!props.isMuted) {
      setPreviousVolume(media.volume);
      media.volume = 0;
    } else {
      media.volume = previousVolume;
    }
    props.setIsMuted(!props.isMuted);
  };

  const handleToggleFullScreen = () => {
    const container = props.containerRef.current;
    if (props.isFullScreen) {
      document.exitFullscreen();
      if (props.mediaHeight) {
        let video = document.getElementsByTagName("video");
        video[0].style.height = `${props.mediaHeight}px`;
      }
    } else {
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.mozRequestFullScreen) {
        /* Firefox */
        container.mozRequestFullScreen();
      } else if (container.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        container.webkitRequestFullscreen();
      } else if (container.msRequestFullscreen) {
        /* IE/Edge */
        container.msRequestFullscreen();
      }
      let video = document.getElementsByTagName("video");
      video[0].style.height = "100%";
    }
    props.setIsFullScreen(!props.isFullScreen);
  };

  const formatTime = (timeInSeconds) => {
    const result = new Date(timeInSeconds * 1000)
      .toISOString()
      .substring(11, 19);
    return {
      hours: result.substring(0, 2),
      minutes: result.substring(3, 5),
      seconds: result.substring(6, 8),
    };
  };

  const {
    hours: currentHours,
    minutes: currentMinutes,
    seconds: currentSeconds,
  } = formatTime(props.currentTime);
  const {
    hours: totalHours,
    minutes: totalMinutes,
    seconds: totalSeconds,
  } = formatTime(props.duration);

  return (
    <div className="player-buttons">
      <div className="top-row">
        <div className="captions">
          <button
            className={`cc ${props.class}`}
            onClick={props.toggleCaptions}
          >
            <FontAwesomeIcon
              icon={faClosedCaptioning}
              className={props.showCaptions ? "pink" : ""}
            />
          </button>
        </div>
        <div className="play">
          <button
            className={`skip-backward ${props.class}`}
            onClick={() => handleSkip(-10)}
          >
            <FontAwesomeIcon icon={faArrowRotateLeft} />
          </button>
          <button className={`play-pause  ${props.class}`} onClick={togglePlay}>
            <FontAwesomeIcon
              icon={props.isPlaying ? faCirclePause : faCirclePlay}
            />
          </button>
          <button
            className={`skip-forward ${props.class}`}
            onClick={() => handleSkip(10)}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} className="icon" />
          </button>
        </div>

        <div className="volume">
          {!isIOS && (
            <div className={`volume-container ${props.mediaContent.type}`}>
              <div className="volume-bar">
                <input
                  type="range"
                  min={0}
                  max={1}
                  step={0.1}
                  value={props.isMuted ? 0 : props.volume}
                  onChange={(e) => {
                    const media = props.mediaRef.current;
                    media.volume = e.target.value;
                    props.setVolume(media.volume);
                    if (props.isMuted) {
                      props.setIsMuted(false);
                    }
                  }}
                />
              </div>
              <button className={`mute ${props.class}`} onClick={toggleMute}>
                <FontAwesomeIcon
                  icon={props.isMuted ? faVolumeMute : faVolumeUp}
                  className={props.isMuted ? "mute-icon" : ""}
                />
              </button>
            </div>
          )}
          {props.mediaContent.type !== "audio" && (
            <button
              className="fullscreen-player"
              onClick={handleToggleFullScreen}
            >
              <FontAwesomeIcon
                icon={props.isFullScreen ? faCompress : faExpand}
              />
            </button>
          )}
        </div>
      </div>
      <div className="progress">
        {Number(totalHours) > 0 ? (
          <span className="time-current">
            {currentHours}:{currentMinutes}:{currentSeconds}
          </span>
        ) : (
          <span className="time-current">
            {currentMinutes}:{currentSeconds}
          </span>
        )}
        <progress
          value={props.currentTime}
          max={props.duration ? props.duration : undefined}
          onClick={(e) => {
            const media = props.mediaRef.current;
            media.currentTime =
              (e.nativeEvent.offsetX / e.target.offsetWidth) * media.duration;
            props.setCurrentTime(media.currentTime);
          }}
        />
        {Number(totalHours) > 0 ? (
          <span className="time-total">
            {totalHours}:{totalMinutes}:{totalSeconds}
          </span>
        ) : (
          <span className="time-total">
            {totalMinutes}:{totalSeconds}
          </span>
        )}
      </div>
    </div>
  );
};

export default PlayerButtons;
