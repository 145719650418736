import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../styles/componentsStyles/CircleChallengeGraph.css";

const CircleChallengeGraph = ({
  title,
  subtitle,
  value,
  colour,
  width,
  icon,
  unit,
  isActive = true,
}) => {
  return (
    <div className={"chart-container circle-challenge tile " + width}>
      <div className="title-container">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <div className="chart-box">
        <div className="circle_chart">
          <FontAwesomeIcon
            style={{ color: colour }}
            icon={icon}
            className=" icon tile"
          />
          <h3
            className={`value ${!isActive ? "ended" : ""} fs-mask`}
            style={{ color: colour }}
          >
            {value}
          </h3>
          <h3
            className={`unit ${!isActive ? "ended" : ""} fs-mask`}
            style={{ color: colour }}
          >
            {unit}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default CircleChallengeGraph;
