import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMinus,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";

import { faCircleChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faTrophy } from "@fortawesome/sharp-regular-svg-icons";

import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton.js";
// import TeamChallenge from "../../components/TeamChallenge";
import Journal from "../components/Journal.js";
import NutritionList from "../components/NutritionList.js";
import CircularSlider from "../components/CircularSlider.js";
import TrackerHeading from "../components/TrackerHeading.js";

import { useHttpClient } from "../../shared/hooks/http-hook.js";
import Warning from "../components/Warning.js";

const NutritionTracker = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();
  const warningRef = useRef(null);
  const [isSaved, setIsSaved] = useState(false);
  const [date, setDate] = useState(false);
  const [data, setData] = useState(null);
  const [journal, setJournal] = useState(null);
  const [responseRequired, setResponseRequired] = useState([]);
  const [currentTracker, setCurrentTracker] = useState(null);
  const [takeaways, setTakeaways] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [resetLines, setResetLines] = useState(false);

  const handleDecrease = () => {
    if (takeaways > 0) {
      setTakeaways((prevState) => prevState - 1);
    }
  };

  const handleIncrease = () => {
    if (takeaways < 30) {
      setTakeaways((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    setData((prevState) => {
      return {
        ...prevState,
        takeaways: takeaways,
      };
    });
  }, [takeaways]);

  useEffect(() => {
    if (warningRef.current && responseRequired) {
      const modalElement = warningRef.current.closest(".trackers");

      if (modalElement) {
        modalElement.scrollTop = warningRef.current.offsetTop;
      } else {
        window.scrollTo({
          top: warningRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [warningRef, responseRequired]);

  const getData = async (date) => {
    if (date.length > 0) {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/trackers/nutrition/${date}`
        );

        if (response && response.tracker?._id) {
          let tracker = response.tracker;
          setCurrentTracker({
            id: tracker._id,
            trackerDate: tracker.tracker_date.toString().slice(0, 10),
          });
          let data = JSON.parse(tracker.data);
          setData(data);
          setStartTime(data.first_meal);
          setEndTime(data.last_meal);
          setTakeaways(data.takeaways);
          setJournal({
            enhancers: tracker.enhancers,
            inhibitors: tracker.inhibitors,
            journal: tracker.journal,
          });
          setIsSaved(true);
        } else {
          setCurrentTracker({
            id: "",
            trackerDate: date,
          });
          setData({
            first_meal: "08:00",
            last_meal: "18:00",
            vegetables: "",
            takeaways: "",
          });
          setStartTime("08:00");
          setEndTime("18:00");
          setTakeaways(0);
          setJournal(() => ({
            enhancers: [],
            inhibitors: [],
            journal: "",
          }));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    setResetLines(true);
    getData(date);
  }, [date]);

  const saveResponse = (location, stat) => {
    if (location === "journal") {
      setJournal((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
    } else {
      setData((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
      if (stat.id === "first_meal") {
        setStartTime(stat.value);
      }
      if (stat.id === "last_meal") {
        setEndTime(stat.value);
      }
    }
  };

  const sendData = async () => {
    let method = "POST";
    let url = `${process.env.REACT_APP_BACKEND_URL}/trackers/`;
    if (currentTracker.id !== "") {
      method = "PUT";
      url = `${process.env.REACT_APP_BACKEND_URL}/trackers/${currentTracker.id}`;
    }

    try {
      const response = await sendRequest(
        url,
        method,
        JSON.stringify({
          trackerDate: date,
          type: "nutrition",
          data: data,
          hidden: false,
          enhancers: [],
          inhibitors: [],
          journal: journal.journal,
          platform: "web",
        })
      );
      if (response) {
        setIsSaved(true);

        props.closeTrackers();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    const emptyKeys = [];

    if (emptyKeys.length > 0) {
      setResponseRequired(emptyKeys);
    } else {
      setResponseRequired([]);
      sendData();
    }
  };
  const [narrowButtons, setNarrowButtons] = useState(true);

  useEffect(() => {
    //if .trackers-inner is scrolled to bottom - set narrow buttons to false)
    const trackersInner = document.querySelector(".trackers-inner");
    const handleScroll = () => {
      if (
        trackersInner.scrollTop + trackersInner.clientHeight >=
        trackersInner.scrollHeight
      ) {
        setNarrowButtons(false);
      } else {
        setNarrowButtons(true);
      }
    };
    trackersInner.addEventListener("scroll", handleScroll);
    return () => {
      trackersInner.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id="snm-nutrition" className="tracker-container">
      {" "}
      <div className="trackers-inner">
        <div className="back-to-main">
          <div className="back-btn" onClick={props.handleBackToMain}>
            <FontAwesomeIcon icon={faCircleChevronLeft} />
          </div>
        </div>
        <TrackerHeading title="T051" setDate={setDate} isSaved={isSaved} />
        <div className="trackers-body">
          <div className="ind-tracker">
            {/* <TeamChallenge /> */}
            <div className="title">{t("T052")}</div>

            {data?.first_meal && data?.last_meal && (
              <CircularSlider
                meals
                questionid={["first_meal", "last_meal"]}
                startTime={startTime}
                endTime={endTime}
                data={data}
                saveResponse={saveResponse}
              />
            )}
          </div>

          <div className="ind-tracker">
            <div className="title">
              {t("T054")}
              {/* <div className="tc-badge">
              <FontAwesomeIcon icon={faTrophy} /> <p>{t("T017")}</p>
            </div> */}
            </div>
            <div className="buffer"></div>
            <NutritionList
              questionid="vegetables"
              data={data}
              saveResponse={saveResponse}
              resetLines={resetLines}
              setResetLines={setResetLines}
            />
          </div>
          <div className="ind-tracker">
            <div className="title ">
              {t("T057")}
              <div className="plus-minus-cont fs-exclude">
                <FontAwesomeIcon
                  icon={faMinus}
                  className="icon"
                  onClick={handleDecrease}
                />
                <div className="value">{takeaways}</div>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="icon"
                  onClick={handleIncrease}
                />
              </div>
            </div>
            <div className="buffer"></div>
          </div>
          <div className="ind-tracker">
            <Journal
              placeholder={t("T020")}
              questionid="journal"
              data={journal}
              saveResponse={saveResponse}
            />
          </div>
        </div>{" "}
      </div>
      <div className={"buttons " + (narrowButtons ? "narrow" : "")}>
        <div className="close-btn" onClick={props.closeTrackers}>
          {t("T015")}
        </div>
        <DarkButton className="submit" onClick={handleSubmit}>
          {t("T016")} <FontAwesomeIcon className="icon" icon={faArrowRight} />
        </DarkButton>
      </div>
    </div>
  );
};
export default NutritionTracker;
