import React from "react";
import "./Modal.css";

function ModalWrapper(props) {
  let content = props.modalText;
  function openModal(event) {
    if (typeof content === "string") {
      content = <div dangerouslySetInnerHTML={{ __html: content }}></div>;
    }
    props.onOpenModal(content);
  }

  return (
    <React.Fragment>
      {props.modalText && (
        <span onClick={openModal} className="modal_wrapper">
          {props.children}
        </span>
      )}
      {!props.modalText && props.children}
    </React.Fragment>
  );
}
export default ModalWrapper;
