import { useState, useEffect } from "react";
import "../styles/componentStyles/One2TenTracker.css";

import { useTranslation } from "react-i18next";

const One2TenTracker = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (props.data?.[props.questionid] === "") {
      setSelected(null);
    } else if (props.data?.[props.questionid]) {
      setSelected(props.data[props.questionid]);
    }
  }, [props.data]);

  const handleClick = (event) => {
    let value;
    if (selected === event.target.innerHTML) {
      value = null;
    } else {
      value = event.target.innerHTML;
    }
    setSelected(value);
    props.saveResponse("data", {
      id: props.questionid,
      value: value,
    });
  };

  return (
    <div className="one-2-ten-cont">
      <div className="scale">
        <p>1 = {props.productivity ? t("T044") : t("T004")}</p>
        <p>10 = {props.productivity ? t("T045") : t("T005")}</p>
      </div>
      <div className="picker fs-exclude">
        {Array.from({ length: 10 }, (_, i) => (
          <p
            key={i}
            className={selected === (i + 1).toString() ? "active" : ""}
            onClick={handleClick}
          >
            {i + 1}
          </p>
        ))}
      </div>
    </div>
  );
};

export default One2TenTracker;
