import React from "react";
import ReactDOM from "react-dom";

import './Backdrop.css';


function Backdrop(props) {


    function backdropClose(){
        props.onBackdropClose();
    }
    
      const content =  (

        <React.Fragment>
        <div id="backdrop_holder" className={props.open} onClick={backdropClose}></div>{props.children}
        </React.Fragment>

      )
       return ReactDOM.createPortal(content, document.getElementById("sidebar"));
   

}
export default Backdrop;