import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import ModalWrapper from "../../../../../shared/modal/ModalWrapper";
import { faHashtag, faPercentage } from "@fortawesome/pro-regular-svg-icons";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";

function CircleGraph(props) {
  const { t } = useTranslation();
  const [strokeDashArray, setStrokeDashArray] = useState("0, 100");

  useEffect(() => {
    setStrokeDashArray(props.percent + ", 100");
  }, [strokeDashArray, props.percent]);

  let colour = props.colour ? props.colour : "#c7becd";

  const [showPercent, setShowPercent] = useState(
    props.percent > -1 && !props.numberFirst ? true : false
  );

  function showValue() {
    if (props.disabled) return;
    setShowPercent(false);
  }
  function showPercentValue() {
    if (props.disabled) return;
    setShowPercent(true);
  }

  return (
    <div
      className={`${props.square ? "square" : "narrow"} ${
        props.disabled ? "disabled" : ""
      }`}
    >
      <div className={`square_content ${props.disabled ? "disabled" : ""}`}>
        {props.target && (
          <FontAwesomeIcon icon={faChevronRight} className="icon" />
        )}
        {props.modalText && !props.disabled && (
          <ModalWrapper
            modalText={props.modalText}
            onOpenModal={props.onOpenModal}
          >
            <FontAwesomeIcon icon={faInfo} className="icon info_icon" />
          </ModalWrapper>
        )}
        {props.disabled && (
          <FontAwesomeIcon icon={faInfo} className="icon info_icon" />
        )}
        {props.title ? <h3>{props.title}</h3> : ""}
        {props.subTitle ? <span>{props.subTitle}</span> : ""}
        {props.disabled && (
          <div className="graph_disabled_text">{t("BC014")}</div>
        )}
        <div className={"chart_circle chart_circle_" + colour}>
          <div className="inner_circle">
            {props.preText && <span>{props.preText}</span>}
            <span className="h3_holder">
              <h3
                className={
                  `${showPercent ? "active " : ""}` +
                  `${props.large ? "large" : ""}`
                }
              >
                {props.percent}%
              </h3>
              <h3
                className={
                  `${!showPercent ? "active " : ""}` +
                  `${props.large ? "large" : ""}`
                }
              >
                {props.value}
                {props.unit ? <span className="units">{props.unit}</span> : ""}
              </h3>
            </span>
            <span className="below_circle">
              {props.subText && !props.disabled && <span>{props.subText}</span>}
            </span>
          </div>
          {props.percent > -1 && props.value > -1 && (
            <div className="switch_icon_container">
              <FontAwesomeIcon
                icon={faHashtag}
                className={`${
                  !showPercent ? "active icon switch_icon" : "icon switch_icon"
                }`}
                onClick={showValue}
              />
              <FontAwesomeIcon
                icon={faPercentage}
                className={`${
                  showPercent ? "active icon switch_icon" : "icon switch_icon"
                }`}
                onClick={showPercentValue}
              />
            </div>
          )}
          <svg viewBox="0 0 36 36" className="circular-chart">
            <path
              className="circle"
              strokeDasharray={strokeDashArray}
              d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default CircleGraph;
