import { t } from "i18next";

export const graphLimit = 15;
export const listLimit = 2;

export const DISABLED_GRAPHS = {
  GOALS: "goals",
  PAIN_SEVERITY: "pain_severity",
  BARRIERS: "barriers",
  TREATMENT: "treatment",
  AREA_OF_PAIN: "areas_of_pain",
  SITTING: "sitting",
  SLEEP_PROBLEMS: "sleep_problems",
  SLEEP_PATTERN: "sleep_pattern",
  CAFFEINE_HOURS: "caffeine_hours",
  WORK_STRESS_CAUSE: "work_stress_cause",
  OUTSIDE_WORK: "outside_work",
  MENTAL_CONDITIONS: "mental_conditions",
  FINANCIAL_IMPACT: "financial_impact",
  MONEY_WORRIES: "money_worries",
  MONEY_COMFORT: "money_comfort",
  MANAGING_CONFIDENCE: "managing_confidence",
  DISCUSS_MONEY_WORRIES: "discuss_money_worries",
  NUTRITION: "nutrition",
  ENERGY_DISABLED: "energy_disabled",
  NEGATIVE_PRODUCTIVITY: "negative_productivity",
};

export const getDisabledData = (graph) => {
  switch (graph) {
    case DISABLED_GRAPHS.GOALS:
      return [
        { title: t("BC017"), value: 59 },
        { title: t("BC018"), value: 36 },
        { title: t("BC019"), value: 19 },
        { title: t("BC020"), value: 18 },
        { title: t("BC021"), value: 16 },
        { title: t("BC022"), value: 11 },
      ];
    case DISABLED_GRAPHS.BARRIERS:
      return [
        { title: t("BC023"), value: 59 },
        { title: t("BC024"), value: 36 },
        { title: t("BC025"), value: 19 },
        { title: t("BC026"), value: 18 },
        { title: t("BC027"), value: 16 },
        { title: t("BC028"), value: 11 },
      ];
    case DISABLED_GRAPHS.PAIN_SEVERITY:
      return [
        { date: t("MSKH025"), value: 10 },
        { date: t("MSKH003"), value: 20 },
        { date: t("MSKH004"), value: 40 },
        { date: t("MSKH026"), value: 20 },
        { date: t("MSKH005"), value: 10 },
      ];
    case DISABLED_GRAPHS.TREATMENT:
      return [
        { date: t("MSKH006"), value: 32 },
        { date: t("MSKH007"), value: 53 },
      ];
    case DISABLED_GRAPHS.AREA_OF_PAIN:
      return [
        { title: t("MSKH022"), value: 53 },
        { title: t("MSKH023"), value: 52 },
        { title: t("MSKH024"), value: 31 },
      ];
    case DISABLED_GRAPHS.SITTING:
      return [
        { date: t("AC004"), value: 10 },
        { date: t("AC005"), value: 20 },
        { date: t("AC006"), value: 30 },
        { date: t("AC007"), value: 20 },
      ];
    case DISABLED_GRAPHS.SLEEP_PROBLEMS:
      return [t("SLP029"), t("SLP030"), t("SLP031")];
    case DISABLED_GRAPHS.SLEEP_PATTERN:
      return [
        {
          date: t("SLP008"),
          value: 10,
        },
        {
          date: t("SLP009"),
          value: 20,
        },
        {
          date: t("SLP010"),
          value: 30,
        },
      ];
    case DISABLED_GRAPHS.CAFFEINE_HOURS:
      return [
        {
          date: t("HR001"),
          value: 20,
        },
        {
          date: t("HR002"),
          value: 30,
        },
        {
          date: t("HR003"),
          value: 20,
        },
      ];
    case DISABLED_GRAPHS.WORK_STRESS_CAUSE:
      return [
        { title: t("STR050"), value: 30 },
        { title: t("STR051"), value: 25 },
        { title: t("STR052"), value: 20 },
        { title: t("STR053"), value: 15 },
        { title: t("STR054"), value: 10 },
        { title: t("STR055"), value: 6 },
      ];
    case DISABLED_GRAPHS.OUTSIDE_WORK:
      return [
        { title: t("STR056"), value: 30 },
        { title: t("STR057"), value: 20 },
        { title: t("STR058"), value: 10 },
        { title: t("STR059"), value: 8 },
        { title: t("STR060"), value: 4 },
        { title: t("STR061"), value: 2 },
      ];
    case DISABLED_GRAPHS.MENTAL_CONDITIONS:
      return [t("MW061"), t("MW062"), t("MW063")];
    case DISABLED_GRAPHS.FINANCIAL_IMPACT:
      return [t("F032"), t("F033"), t("F034")];
    case DISABLED_GRAPHS.MONEY_WORRIES:
      return [
        { date: t("F001"), value: 10 },
        {
          date: t("F002"),
          value: 20,
        },
        { date: t("F003"), value: 30 },
      ];
    case DISABLED_GRAPHS.MONEY_COMFORT:
      return [
        {
          date: t("F010"),
          value: 10,
        },
        {
          date: t("F011"),
          value: 10,
        },
        {
          date: t("F012"),
          value: 20,
        },
        {
          date: t("F013"),
          value: 20,
        },
        {
          date: t("F014"),
          value: 15,
        },
      ];
    case DISABLED_GRAPHS.MANAGING_CONFIDENCE:
      return [
        {
          date: t("F019"),
          value: 10,
        },
        {
          date: t("F018"),
          value: 20,
        },
        {
          date: t("F017"),
          value: 10,
        },
        {
          date: t("F016"),
          value: 40,
        },
        {
          date: t("F015"),
          value: 10,
        },
      ];
    case DISABLED_GRAPHS.DISCUSS_MONEY_WORRIES:
      return [
        {
          date: t("F001"),
          value: 10,
        },
        {
          date: t("F002"),
          value: 20,
        },
        {
          date: t("F003"),
          value: 10,
        },
      ];
    case DISABLED_GRAPHS.NUTRITION:
      return [
        { title: t("NTR013"), value: 34 },
        { title: t("NTR014"), value: 23 },
        { title: t("NTR015"), value: 20 },
      ];
    case DISABLED_GRAPHS.ENERGY_DISABLED:
      return [
        {
          date: t("STR036"),
          value: 10,
        },
        {
          date: t("STR035"),
          value: 20,
        },
        {
          date: t("STR034"),
          value: 30,
        },
        {
          date: t("STR033"),
          value: 20,
        },
        {
          date: t("STR032"),
          value: 10,
        },
      ];
    case DISABLED_GRAPHS.NEGATIVE_PRODUCTIVITY:
      return [
        { title: t("EG019"), value: 40 },
        { title: t("EG020"), value: 30 },
        { title: t("EG021"), value: 20 },
        { title: t("EG022"), value: 10 },
        { title: t("EG023"), value: 5 },
        { title: t("EG024"), value: 3 },
      ];
    default:
      return [];
  }
};
