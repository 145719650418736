import { useEffect, useState } from "react";

import StatsLeftMenu from "./children/StatsLeftMenu";
import StatsRightMenu from "./children/StatsRightMenu";

const Stats = (props) => {
  const subMenuOptions = [
    { id: 61, title: "streaks" },
    { id: 62, title: "stats" },
  ];
  const [activeSubMenu, setActiveSubMenu] = useState(subMenuOptions[0]);

  const [showLeftMenu, setShowLeftMenu] = useState(true);

  useEffect(() => {
    if (props.hide) {
      setShowLeftMenu(false);
    } else {
      setShowLeftMenu(true);
    }
  }, [props.hide]);

  const changeSubMenu = (props) => {
    setActiveSubMenu(subMenuOptions[props]);
  };
  return (
    <>
      <div className="profile-container">
        {showLeftMenu && (
          <div className="menu-left">
            <StatsLeftMenu
              changeSubMenu={changeSubMenu}
              activeSubMenu={activeSubMenu}
            />
          </div>
        )}
        <div className={`menu-right ${props.hide ? "simplify" : ""}`}>
          <StatsRightMenu activeSubMenu={activeSubMenu} hide={props.hide} />
        </div>
      </div>
    </>
  );
};

export default Stats;
