import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import EnergySlider from "../questions/energySlider/EnergySlider";
import Clock from "../questions/clock/Clock";
import OneToTenSlider from "../questions/painSlider/OneToTenSlider";
import Checkbox from "../questions/checkbox/Checkbox";

function Energy1(props) {
  const { t } = useTranslation();
  const productivityTextValues = [t("EN045"), t("EN046")];

  const productivityOptions = [
    { id: "none", title: t("EN001") },
    { id: "tiredness", title: t("EN002") },
    { id: "high_stress", title: t("EN003") },
    { id: "poor_nutrition", title: t("EN004") },
    { id: "dehydration", title: t("EN005") },
    { id: "mental_health", title: t("EN006") },
    { id: "headaches", title: t("EN007") },
    { id: "inactivity", title: t("EN008") },
  ];

  if (
    props.assessmentValues.current_joint_muscle_pain === "yes" &&
    props.assessmentValues.areas_of_pain
  ) {
    for (let i = 0; i < props.assessmentValues.areas_of_pain.length; i++) {
      productivityOptions.push({
        id: props.assessmentValues.areas_of_pain[i].id,
        title: props.assessmentValues.areas_of_pain[i].title + t("EN009"),
      });
    }
  }

  if (props.assessmentValues.tailor_recommendations === "female_specific") {
    productivityOptions.push({
      id: "female_health",
      title: t("EN010"),
    });
  }

  if (props.assessmentValues.drink_alcohol !== "no") {
    productivityOptions.push({
      id: "alcohol",
      title: t("EN011"),
    });
  }

  if (props.assessmentValues.smoker === "yes") {
    productivityOptions.push({
      id: "smoking",
      title: t("EN012"),
    });
  }

  if (props.assessmentValues.long_term_conditions) {
    for (
      let i = 0;
      i < props.assessmentValues.long_term_conditions.length;
      i++
    ) {
      if (props.assessmentValues.long_term_conditions[i].id !== "none") {
        productivityOptions.push({
          id: props.assessmentValues.long_term_conditions[i].id,
          title: props.assessmentValues.long_term_conditions[i].title,
        });
      }
    }
  }
  /* other last */
  if (productivityOptions.filter((e) => e.id === "other").length === 0) {
    productivityOptions.push({ id: "other", title: t("EN013") });
  }

  const impactTextValues = [t("EN014"), t("EN015")];

  useEffect(() => {
    if (
      (props.assessmentValues.negative_productivity &&
        props.assessmentValues.negative_productivity.length > 0 &&
        props.assessmentValues.negative_productivity[0]?.id === "none") ||
      !props.assessmentValues.negative_productivity
    ) {
      props.onSetValue({ id: "productivity_last_7", value: null });
    }
  }, [props.assessmentValues.negative_productivity]);

  return (
    <div className={"question_container"} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <EnergySlider
        questionid="energy_level"
        questionTitle={t("EN016")}
        leftlabel={t("EN017")}
        rightlabel={t("EN018")}
        width="_wide"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <h3>{t("EN019")}</h3>
      <hr />
      <Clock
        questionid="most_energetic"
        width=""
        questionTitle={t("EN020")}
        startHour="9"
        startMinute="00"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <Clock
        questionid="least_energetic"
        width=""
        questionTitle={t("EN021")}
        startHour="17"
        startMinute="00"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <OneToTenSlider
        questionid="productivity"
        questionTitle={t("EN022")}
        width="_wide"
        textValues={productivityTextValues}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("EN023")}
        onOpenModal={props.onOpenModal}
      />
      <Checkbox
        questionid="negative_productivity"
        width="_wide"
        questionTitle={t("EN024")}
        questionTitleSuffix={t("EN025")}
        options={productivityOptions}
        placeholder={t("EN026")}
        itemLimit="2"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      {props.assessmentValues.negative_productivity &&
      props.assessmentValues.negative_productivity.length > 0 &&
      props.assessmentValues.negative_productivity[0]?.id !== "none" ? (
        <OneToTenSlider
          questionid="productivity_last_7"
          questionTitle={t("EN027")}
          width="_wide"
          textValues={impactTextValues}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          modalText={t("EN028")}
          onOpenModal={props.onOpenModal}
        />
      ) : null}
    </div>
  );
}

export default Energy1;
