import React, { Component, useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

const VideoPlayer = (props) => {
  const videoPlayer = useRef(null);
  const videoSrc = props.url; //.replace("media.championhealth.co.uk", "res.cloudinary.com/champion-health/video/upload/v1630600746");
  let loop = false;
  let startVolume = 1;
  if (props.type === "sounds") {
    loop = true;
    startVolume = 0;
  }
  const [volume, setVolume] = useState(startVolume);
  const [playing, setPlaying] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState();

  function fadeIn() {
    if (props.type === "sounds") {
      let newVolume = volume;
      const interval = setInterval(function () {
        newVolume = newVolume + 0.084;
        if (newVolume > 1) {
          newVolume = 1;
        }
        if (newVolume <= 1) {
          setVolume(newVolume);
        }
        if (newVolume === 1) {
          clearInterval(interval);
        }
      }, 250);
    }
  }

  useEffect(() => {
    if (props.snoozeMinutes > 0) {
      //clear open intervals if snooze time changed
      var max = setTimeout(function () {
        /* Empty function */
      }, 1);
      for (var i = 1; i <= max; i++) {
        window.clearInterval(i);
      }

      let snoozeMSeconds = Number(props.snoozeMinutes) * 60 * 1000;
      let fadeMSeconds1 = (Number(props.snoozeMinutes) * 60 - 10) * 1000;

      if (document.getElementById("play_vid")) {
        document.getElementById("play_vid").click();
      }
      //fadeToVolume
      setTimeout(function () {
        let newVolume = 1; //volume;
        const interval2 = setInterval(function () {
          newVolume = newVolume - 0.02;
          if (newVolume > 1) {
            newVolume = 1;
          }
          if (newVolume >= 0) {
            setVolume(newVolume);
          }
        }, 200);
        if (newVolume === 0) {
          clearInterval(interval2);
        }
      }, fadeMSeconds1);

      //stop playing
      setTimeout(function () {
        setPlaying(false);
      }, snoozeMSeconds);

      //countdown
      let ntimeRemaining = (props.snoozeMinutes * 60).toFixed(2);
      const interval3 = setInterval(function () {
        ntimeRemaining = ntimeRemaining - 1;
        //convert to mins
        let minutes = Math.floor(ntimeRemaining / 60);
        let seconds = ntimeRemaining - minutes * 60;
        if (seconds < 10) {
          seconds = String("0" + seconds);
        }
        setTimeRemaining(minutes + ":" + seconds);
        if (ntimeRemaining === 0) {
          clearInterval(interval3);
        }
      }, 1000);
    }
  }, [props.snoozeMinutes]);

  function specialLoop() {
    videoPlayer.current.seekTo(0);
  }

  //onPause
  //onEnded
  const [progress, setProgress] = useState(0);
  const [progressUpdated, setProgressUpdated] = useState(false);

  const onProgress = (progress) => {
    //alert('now');
    if (props.type !== "author") {
      let fixPlayed = progress.played;
      if (fixPlayed < 0.01 && props.progress > 0.001 && !progressUpdated) {
        fixPlayed = props.progress;
        videoPlayer.current.seekTo(fixPlayed);
        setProgressUpdated(true);
      }
      setProgress(fixPlayed);
      props.currentProgress(fixPlayed);
    }
  };
  //onProgress .played
  function updateProgress() {
    //alert('update');
    //alert(progress);
    if (props.type !== "author") {
      props.onProgressChange(progress);
    }
  }

  //if progress skip to start
  useEffect(() => {
    if (props.progress && props.type !== "author") {
      //setLight(false);
      setProgress(props.progress);
      videoPlayer.current.seekTo(props.progress, "fraction");
    }
  }, [props.progress]);

  let preview = props.video_cover_image;
  let pid = "play_vid";
  let pbtn = (
    <button id={pid}>
      <FontAwesomeIcon icon={faPlay} className="icon" />
    </button>
  );
  if (props.type === "video" && !props.recipe) {
    preview =
      "https://res.cloudinary.com/champion-health/image/upload/v1630603701/img/warning.png";
    pid = "play_vid_2";
    pbtn = <button id={pid}></button>;
  } else if (props.recipe) {
    pid = "play_vid_2";
  }

  const [light, setLight] = useState(preview);
  const [previewOpen, setPreviewOpen] = useState(true);

  return (
    <React.Fragment>
      <div className="player-wrapper">
        {props.type === "video" && previewOpen && !props.recipe && (
          <div
            className="preview_image"
            style={{
              backgroundImage:
                'url("' +
                props.video_cover_image.replace(
                  "media.championhealth.co.uk",
                  "res.cloudinary.com/champion-health/image/upload/v1630603701"
                ) +
                '")',
            }}
          >
            <button id="play_vid" onClick={() => setPreviewOpen(false)}>
              <FontAwesomeIcon icon={faPlay} className="icon" />
            </button>
          </div>
        )}
        <ReactPlayer
          className="react-player fixed-bottom"
          ref={videoPlayer}
          url={videoSrc}
          width="100%"
          height="100%"
          loop={loop}
          playing={playing}
          playIcon={pbtn}
          controls={true}
          light={light}
          volume={volume}
          onStart={fadeIn}
          onPause={updateProgress}
          onEnded={updateProgress}
          onProgress={onProgress}
          //onEnded={specialLoop}
        />
      </div>
      {props.snoozeMinutes > 0 && (
        <h4 className="audio_played">{timeRemaining}</h4>
      )}
    </React.Fragment>
  );
};

export default VideoPlayer;
