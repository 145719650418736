import React, { useContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../shared/context/auth-context";

import Photo from "../header/images/profile_1.png";
import Backdrop from "./Backdrop";

import GooglePlay from "../../pages/images/googlePlayButton.png";
import AppStore from "../../pages/images/appStoreButton.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCommentDots,
  faCog,
  faQuestionCircle,
  faSignOutAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { faSignInAlt } from "@fortawesome/pro-regular-svg-icons";

function SideDrawer(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAssessPages, setShowAssessPages] = useState(false);

  const today = new Date();
  const currentYear = today.getFullYear();

  let profileImage = Photo;
  if (auth.image) {
    profileImage = auth.image;
  }

  function menuClickHandler() {
    props.onMenuClose(false);
  }

  function quickNavHandler(page) {
    props.onNextPage(page);
  }

  function togglePages() {
    setShowAssessPages(!showAssessPages);
    if (window.location.pathname !== "/assessment") {
      navigate("/assessment");
    }
  }

  // /* <a href="https://championhealth.co.uk/eula" target="_blank">
  // Terms
  // </a> */

  return (
    <Backdrop
      open={`${props.drawerIsOpen ? "open" : "closed"}`}
      onBackdropClose={menuClickHandler}
    >
      <div
        id="side-drawer"
        className={`${props.drawerIsOpen ? "open" : "closed"}`}
      >
        <FontAwesomeIcon
          icon={faTimes}
          className="icon menu_close"
          onClick={menuClickHandler}
        />
        {auth.isLoggedIn && (
          <div className="profile_section">
            <div
              id="photo_outer"
              className="menu_photo"
              style={{ backgroundImage: `url(${profileImage})` }}
            ></div>
            <Link to="/profile/settings">
              <div className="floated_content">
                <h3 className="name">{auth.fname}</h3>
                <p>{t("SD001")}</p>
              </div>
            </Link>
          </div>
        )}
        <div className="side-drawer scrollable">
          <hr />
          {auth.isLoggedIn && (
            <div>
              {auth.tags &&
                auth.tags.includes("new-assessment-available") &&
                !auth.tags.includes("new-assessment-started") && (
                  <Link to="/assessment" className="link_row">
                    <h3 style={{ marginBottom: "0px" }}>{t("SD002")}</h3>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="icon right"
                    />
                  </Link>
                )}
              {auth.tags && auth.tags.includes("new-assessment-started") && (
                <Link to="/assessment" className="link_row">
                  <h3 style={{ marginBottom: "0px" }}>{t("SD003")}</h3>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="icon right"
                  />
                </Link>
              )}
              {auth.tags && auth.tags.length < 1 && (
                <Link to="/assessment" className="link_row">
                  <h3 style={{ marginBottom: "0px" }}>{t("SD003")}</h3>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="icon right"
                  />
                </Link>
              )}
              {auth.access > 9 && (
                <React.Fragment>
                  {/*
                    <Link to="/admin/dashboard" className="link_row">
                      <h3 style={{ marginBottom: "0px" }}>ADMIN</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </Link>
                  */}
                  <a className="link_row" onClick={togglePages}>
                    <h3 style={{ marginBottom: "0px" }}>{t("SD004")}</h3>
                    {showAssessPages ? (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="icon down"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    )}
                  </a>
                </React.Fragment>
              )}
              {window.location.pathname === "/assessment" &&
                auth.access > 9 &&
                showAssessPages && (
                  <React.Fragment>
                    <a
                      onClick={() => quickNavHandler(-1)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD005")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(0)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD006")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(1)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD007")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(2)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD008")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(3)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD009")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(4)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD010")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(8)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD011")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(9)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD012")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(10)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD013")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(11)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD014")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(12)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD015")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                    <a
                      onClick={() => quickNavHandler(15)}
                      className="link_row page"
                    >
                      <h3 style={{ marginBottom: "0px" }}>{t("SD016")}</h3>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="icon right"
                      />
                    </a>
                  </React.Fragment>
                )}
              {auth.tags && auth.tags.length > 0 && (
                <React.Fragment>
                  <Link to="/for-you" className="link_row">
                    <h3 style={{ marginBottom: "0px" }}>{t("SD017")}</h3>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="icon right"
                    />
                  </Link>
                </React.Fragment>
              )}
              {auth.access > 2 && (
                <Link to="/company-report/live-analytics" className="link_row">
                  <h3 style={{ marginBottom: "0px" }}>{t("SD018")}</h3>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="icon right"
                  />
                </Link>
              )}
              <hr />
              <div className="app_banner_side_drawer">
                <h3>{t("SD019")}</h3>
                <p>{t("SD020")}</p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.championhealth.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={GooglePlay}
                    className="app_link google_play"
                    alt="Link to Google Play"
                  ></img>
                </a>
                <a
                  href="https://apps.apple.com/us/app/champion-health/id1580359828"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppStore}
                    className="app_link app_store"
                    alt="Link to App Store"
                  ></img>
                </a>
              </div>
              <hr />
              <a
                href={
                  "https://physitrack.typeform.com/first-feedback#client=" +
                  auth.clientName.replace(/ /, "_")
                }
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faCommentDots}
                  className="icon large_icon"
                />
                <div className="floated_content">
                  <h3>{t("SD021")}</h3>
                  <p>{t("SD022")}</p>
                </div>
              </a>
            </div>
          )}
          <hr />
          {auth.access > 9 && (
            <Link to="/settings" className="link_row">
              <FontAwesomeIcon icon={faCog} className="icon" />
              <h3>{t("SD023")}</h3>
              <FontAwesomeIcon icon={faChevronRight} className="icon right" />
            </Link>
          )}
          <a
            href="https://support.championhealth.co.uk/"
            className="link_row"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
            <h3>{t("SD024")}</h3>
            <FontAwesomeIcon icon={faChevronRight} className="icon right" />
          </a>
          {auth.isLoggedIn && (
            <a onClick={auth.logout} className="link_row">
              <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
              <h3>{t("SD025")}</h3>
              <FontAwesomeIcon icon={faChevronRight} className="icon right" />
            </a>
          )}
          {!auth.isLoggedIn && (
            <Link to="/sign-in" className="link_row">
              <FontAwesomeIcon icon={faSignInAlt} className="icon" />
              <h3>{t("SD026")}</h3>
              <FontAwesomeIcon icon={faChevronRight} className="icon right" />
            </Link>
          )}
        </div>
        <div id="sidebar_footer">
          <a
            href="https://championhealth.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t("SD027")}
          </a>

          <a
            href="https://championhealth.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t("SD028")}
          </a>
          <span>Champion Health &copy; {currentYear}</span>
        </div>
      </div>
    </Backdrop>
  );
}

export default SideDrawer;
