import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles/InfoBox.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowPointer,
  faCircleX,
  faShieldCheck,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";

const InfoBox = (props) => {
  const { t } = useTranslation();
  const [dataAvailable, setDataAvailable] = useState();

  useEffect(() => {
    if (props.blob?.length > 0) {
      setDataAvailable(true);
    } else {
      setDataAvailable(false);
    }
  }, [props]);

  return (
    <div className={"chart-container " + props.width + " full-height"}>
      <div className="title-container">
        <div
          className="title"
          style={{ color: `${dataAvailable ? "#fff" : "#64596b"}` }}
        >
          {props.title}
        </div>
      </div>
      <div className="info-box">
        <div className="info-sub-heading">
          <div
            className="info-left fs-mask"
            style={{ color: `${dataAvailable ? "#fff" : "#64596b"}` }}
          >
            {t("D053")}
            <span
              style={{ color: `${dataAvailable ? props.colour : "#64596b"}` }}
            >
              {props.value}
            </span>
          </div>
          {props.date && (
            <p
              className="fs-mask"
              style={{ color: `${dataAvailable ? props.colour : "#64596b"}` }}
            >
              {t("D043")} <b>{props.date}</b>
            </p>
          )}
        </div>
      </div>
      <div className="info-text">
        {dataAvailable ? (
          props.blob
        ) : (
          <div className="chart-no-data">{t("D075")}</div>
        )}
      </div>
    </div>
  );
};

export default InfoBox;
