import { useEffect, useState, useContext, useCallback } from "react";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import { validateUserInput } from "../../../shared/util/helperfunctions";
import { AuthContext } from "../../../shared/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import WhitePopUpNoTitle from "../../../shared/modal/whitePopUp/WhitePopUpNoTitle";
import { useTranslation } from "react-i18next";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBattery,
  faBoltLightning,
  faCrosshairs,
  faDroplet,
  faLightbulb,
  faMartiniGlass,
  faMoonStars,
  faSalad,
  faSkiing,
  faSmile,
  faWind,
} from "@fortawesome/pro-light-svg-icons";
import { colours } from "../../../assets/colours";

import ToggleBtn from "../../../shared/components/styledComponents/toggleBtn/ToggleBtn";
import ReminderPopUp from "../../../contentTypes/components/ReminderPopUp";
import { faBinBottles } from "@fortawesome/sharp-regular-svg-icons";

const TrackersRightMenu = (props) => {
  const { t } = useTranslation();
  const [remindersOn, setRemindersOn] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentReminder, setCurrentReminder] = useState(null);

  const [remindersArr, setRemindersArr] = useState([
    { id: 1, title: t("T339"), icon: faCrosshairs, position: true },
    {
      id: 2, // sleep
      title: t("T345"),
      icon: faMoonStars,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 3, //activity
      title: t("T346"),
      icon: faSkiing,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 4, //stress
      title: t("T347"),
      icon: faBoltLightning,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 5, //mood
      title: t("T348"),
      icon: faSmile,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 6, //nutrition
      title: t("T349"),
      icon: faSalad,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 7, //hydration
      title: t("T354"),
      icon: faDroplet,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 8, //alcohol
      title: t("T356"),
      icon: faMartiniGlass,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 9, //productivity
      title: t("T357"),
      icon: faLightbulb,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 10, //energy
      title: t("T358"),
      icon: faBattery,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 11, //smoking
      title: t("T355"),
      icon: faWind,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
  ]);

  const handleReminderToggle = (id) => {
    if (id === 1) {
      setRemindersOn(!remindersOn);
    }
    const updatedReminders = remindersArr.map((reminder) => {
      if (reminder.id === id) {
        if (reminder.id === id) {
          if (reminder.position) {
            reminder.position = false;
          } else {
            setModalIsOpen(true);
            setCurrentReminder(reminder);
            reminder.position = true;
          }
        }
      }
      return reminder;
    });
    setRemindersArr(updatedReminders);
  };

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  return (
    <>
      {modalIsOpen && (
        <ReminderPopUp
          currentItem={currentReminder}
          notifications
          setRemindOpen={modalClickHandler}
        />
      )}

      <div className="edit-field reminders fs-exclude">
        {props.hide && (
          <div className="btn-cont ">
            <ChevronBackBtn onClick={() => props.changeMenu(0, "back")} />
          </div>
        )}
        <div className="edit-title">{t("T339")}</div>
        <div className="scrollable">
          {remindersArr.map((reminder, index) => {
            return (
              <div
                className="profile-slider-btn reminders"
                key={index}
                style={
                  index === 0
                    ? {
                        background: "rgba(100, 89, 107, 0.5)",
                        border: "solid 1px rgba(100, 89, 107, 0.2)",
                        marginBottom: "20px",
                      }
                    : {}
                }
              >
                <div className="menu-item">
                  <FontAwesomeIcon
                    icon={reminder.icon}
                    style={{ color: colours.orange }}
                    className="slider-icon"
                  />
                  <div className="slider-text">{reminder.title}</div>
                </div>
                <ToggleBtn
                  isOn={remindersOn ? reminder.position : false}
                  onToggle={() => handleReminderToggle(reminder.id)}
                  containerColor={
                    reminder.position && remindersOn
                      ? colours.orange
                      : "#64596b"
                  }
                  toggleColor={"#fff"}
                  size={{ width: "62px", height: "35px" }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TrackersRightMenu;
