import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { colours } from "../../../assets/colours";
import smileyFace from "../../images/smileyFace.png";
import { useTranslation } from "react-i18next";

const InviteLeftMenu = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="profile-name left">{t("I001")}</div>
      {Array.from({ length: 3 }).map((_, index) =>
        index < props.userFnF?.length ? (
          <div
            key={index}
            className={`profile-slider-btn large ${
              index === props.activeProfile.id ? "active" : ""
            } fs-exclude`}
            onClick={() => props.handleActiveProfile(index)}
          >
            <div className="menu-item">
              <div
                className="ff-cont"
                style={{
                  backgroundColor: colours[props.userFnF[index].colour],
                }}
              >
                <img src={smileyFace} alt="smiley face" />
              </div>
              <div className="text-cont">
                <div className="slider-text">{props.userFnF[index].name}</div>
                {props.userFnF[index].status === "pending" && (
                  <div className=" pending">{t("S015")}</div>
                )}
              </div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
          </div>
        ) : (
          <div
            key={index}
            className="profile-slider-btn large empty"
            onClick={() => props.handleActiveProfile(index)}
          >
            <div className="menu-item">
              <div className="ff-cont ">
                <img src={smileyFace} alt="smiley face" />
              </div>
              <div className="text-cont">
                <div className="slider-text">{t("S013")}</div>
              </div>
            </div>
            <FontAwesomeIcon icon={faPlusCircle} className="slider-chevron" />
          </div>
        )
      )}
    </>
  );
};

export default InviteLeftMenu;
