import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";

import BarChart from "../../components/graphs/barChart/BarChart";
import TopThree from "../../components/graphs/topThree/TopThree";
import {
  DISABLED_GRAPHS,
  getDisabledData,
  graphLimit,
  listLimit,
} from "../../../../shared/util/companyReport";

function FinancialWellbeing({ data, coData, onOpenModal }) {
  const { t } = useTranslation();
  const {
    showFinComfort,
    financialPositionCounter,
    showNewMoneyWorries,
    newMoneyWorriesCounter,
    showConfidentFinance,
    confidentFinanceCounter,
    showMoneyImpact,
    moneyImpactCounter,
    discussMoneyCounter,
    showDiscussMoney,
    showFinancialHelp,
    soughtFinanacialHelpCounter,
    showOldMoneyWorries,
  } = coData;
  const {
    money_worries_yes,
    money_worries_sometimes,
    money_worries_no,
    money_worries_yes_new,
    money_worries_sometimes_new,
    money_worries_no_new,
    financial_position_score_5,
    financial_position_score_4,
    financial_position_score_3,
    financial_position_score_2,
    financial_position_score_1,
    confident_finance_score_5,
    confident_finance_score_4,
    confident_finance_score_3,
    confident_finance_score_2,
    confident_finance_score_1,
    discuss_money_yes,
    discuss_money_sometimes,
    discuss_money_no,
    soughtFinanacialHelp_no,
    soughtFinanacialHelp,
    financial_stress_no,
    financial_stress,
    financial_impact_array,
  } = data;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const awake_money_worries = [
    { date: t("F001"), value: money_worries_yes },
    {
      date: t("F002"),
      value: money_worries_sometimes,
    },
    { date: t("F003"), value: money_worries_no },
  ];

  const money_worries = [
    { date: t("F001"), value: money_worries_yes_new },
    {
      date: t("F002"),
      value: money_worries_sometimes_new,
    },
    { date: t("F003"), value: money_worries_no_new },
  ];

  const money_comfort = [
    {
      date: t("F010"),
      value: financial_position_score_5,
    },
    {
      date: t("F011"),
      value: financial_position_score_4,
    },
    {
      date: t("F012"),
      value: financial_position_score_3,
    },
    {
      date: t("F013"),
      value: financial_position_score_2,
    },
    {
      date: t("F014"),
      value: financial_position_score_1,
    },
  ];

  const managing_confidence = [
    {
      date: t("F019"),
      value: confident_finance_score_5,
    },
    {
      date: t("F018"),
      value: confident_finance_score_4,
    },
    {
      date: t("F017"),
      value: confident_finance_score_3,
    },
    {
      date: t("F016"),
      value: confident_finance_score_2,
    },
    {
      date: t("F015"),
      value: confident_finance_score_1,
    },
  ];

  const discuss_money_worries = [
    {
      date: t("F001"),
      value: discuss_money_yes,
    },
    {
      date: t("F002"),
      value: discuss_money_sometimes,
    },
    {
      date: t("F003"),
      value: discuss_money_no,
    },
  ];

  return (
    <React.Fragment>
      <BarChart
        title={t("F008")}
        subTitle={
          financialPositionCounter >= graphLimit
            ? `${t("BC012")} ${financialPositionCounter} ${t("BC013")}`
            : ""
        }
        data={
          showFinComfort
            ? money_comfort
            : getDisabledData(DISABLED_GRAPHS.MONEY_COMFORT)
        }
        unit="%"
        width="wide"
        modalText={`${t(
          "F009"
        )} <a href='https://yougov.co.uk/topics/economy/trackers/financial-comfort' target='_blank' rel='noopener noreferrer'>${t(
          "F035"
        )}</a> .`}
        onOpenModal={onOpenModal}
        disabled={!showFinComfort}
      />
      <CircleGraph
        title={t("F004")}
        square
        colour="colour2"
        value={financial_stress_no}
        large
        percent={financial_stress}
        modalText={t("F005")}
        onOpenModal={onOpenModal}
      />

      <BarChart
        title={t("F006")}
        data={
          showNewMoneyWorries
            ? money_worries
            : getDisabledData(DISABLED_GRAPHS.MONEY_WORRIES)
        }
        subTitle={
          newMoneyWorriesCounter >= graphLimit
            ? `${t("BC012")} ${newMoneyWorriesCounter} ${t("BC013")}`
            : ""
        }
        unit="%"
        modalText={t("F007")}
        onOpenModal={onOpenModal}
        disabled={!showNewMoneyWorries}
      />
      <BarChart
        title={t("F020")}
        data={
          showConfidentFinance
            ? managing_confidence
            : getDisabledData(DISABLED_GRAPHS.MANAGING_CONFIDENCE)
        }
        subTitle={
          confidentFinanceCounter >= graphLimit
            ? `${t("BC012")} ${confidentFinanceCounter} ${t("BC013")}`
            : ""
        }
        unit="%"
        width="wide"
        modalText={t("F021")}
        onOpenModal={onOpenModal}
        disabled={!showConfidentFinance}
      />
      <TopThree
        title={t("F022")}
        square
        preText={
          moneyImpactCounter >= graphLimit
            ? `${t("BC012")} ${moneyImpactCounter} ${t("BC013")}`
            : ""
        }
        modalText={t("F024")}
        onOpenModal={onOpenModal}
        value={
          !showMoneyImpact || financial_impact_array.length < listLimit
            ? getDisabledData(DISABLED_GRAPHS.FINANCIAL_IMPACT)
            : financial_impact_array
        }
        disabled={!showMoneyImpact || financial_impact_array.length < listLimit}
      />
      <BarChart
        title={t("F025")}
        data={
          showDiscussMoney
            ? discuss_money_worries
            : getDisabledData(DISABLED_GRAPHS.DISCUSS_MONEY_WORRIES)
        }
        subTitle={
          discussMoneyCounter >= graphLimit
            ? `${t("BC012")} ${discussMoneyCounter} ${t("BC013")}`
            : ""
        }
        unit="%"
        modalText={t("F026")}
        onOpenModal={onOpenModal}
        disabled={!showDiscussMoney}
      />
      <CircleGraph
        title={t("F027")}
        square
        colour="colour2"
        subTitle={
          soughtFinanacialHelpCounter >= graphLimit
            ? `${t("BC012")} ${soughtFinanacialHelpCounter} ${t("BC013")}`
            : ""
        }
        value={showFinancialHelp ? soughtFinanacialHelp_no : 0}
        large
        percent={showFinancialHelp ? soughtFinanacialHelp : 0}
        modalText={t("F028")}
        onOpenModal={onOpenModal}
        disabled={!showFinancialHelp}
      />
      {showOldMoneyWorries && (
        <BarChart
          title={t("F029")}
          data={awake_money_worries}
          width="wide"
          subTitle={t("F030")}
          unit="%"
          modalText={t("F031")}
          onOpenModal={onOpenModal}
        />
      )}
    </React.Fragment>
  );
}
export default FinancialWellbeing;
