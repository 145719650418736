import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Chart,
  CategoryScale,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { formatAssessmentDate } from "../../shared/util/helperfunctions";

const monthsArr = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];

const createCustomTooltip = (
  units,
  average_type,
  data_length,
  assessDates,
  yMaxLimit,
  outOfYMax
) => ({
  id: "customTooltip",

  beforeDatasetsDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
      scales: { x, y },
    } = chart;
    if (chart.tooltip?._active[0]) {
      const activePoint = chart.tooltip._active[0];
      const datapoint = activePoint.index;
      const datasetIndex = activePoint.datasetIndex;
      const value =
        chart.getDatasetMeta(datasetIndex).data[datapoint].$context.raw;
      const xCoor = chart
        .getDatasetMeta(datasetIndex)
        .data[datapoint].tooltipPosition().x;
      const yCoor = chart
        .getDatasetMeta(datasetIndex)
        .data[datapoint].tooltipPosition().y;
      // if (value > 0) {
      //draw annotation line
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgba(255, 255, 255, 0.5)";
      ctx.moveTo(xCoor, top);
      ctx.lineTo(xCoor, bottom);
      ctx.stroke();
      ctx.closePath();

      //draw data point
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#e92364";
      ctx.fillStyle = "#e92364";
      ctx.arc(xCoor, yCoor, 3, 0, 2 * Math.PI, false);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();
      // }
    }
  },
  afterDraw(chart, args, options) {
    if (chart.tooltip?._active[0]) {
      const {
        ctx,
        chartArea: { top, bottom },
      } = chart;
      ctx.save();
      const activePoint = chart.tooltip._active[0];
      const datapoint = activePoint.index;
      const datasetIndex = activePoint.datasetIndex;

      //get text for tooltip
      const value =
        chart.getDatasetMeta(datasetIndex).data[datapoint].$context.raw;
      let stat;
      if (value === 0) {
        stat = "No data";
        // } else if (value === 0.1) {
        //   stat = `0 hours`;
      } else if (yMaxLimit && outOfYMax && value !== 0.1) {
        stat = `${Number(value).toString()}/${yMaxLimit}`;
      } else if (yMaxLimit && outOfYMax && value === 0.1) {
        stat = `0/${yMaxLimit}`;
      } else if (value === 0.1) {
        stat = `0 ${units}`;
      } else {
        stat = `${Number(value).toString()} ${units}`;
      }
      const date =
        chart.getDatasetMeta(datasetIndex).iScale._labelItems[datapoint].label;
      const textWidth = ctx.measureText(date).width;

      //coordinates
      const xPoint = chart
        .getDatasetMeta(datasetIndex)
        .data[datapoint].tooltipPosition().x;
      const yPoint = chart
        .getDatasetMeta(datasetIndex)
        .data[datapoint].tooltipPosition().y;

      const labelsLength =
        chart.getDatasetMeta(datasetIndex).iScale._labelItems.length;

      const activeLabel =
        chart.getDatasetMeta(datasetIndex).data[datapoint].$context.index;

      //for the last two values the tooltip needs to be on the left of the active point
      if (activeLabel >= labelsLength - 2) {
        if (data_length !== 7 && !assessDates) {
          // draw rounded rectangle
          ctx.beginPath();
          ctx.roundRect(xPoint - 105, yPoint - 25, 110, 65, 12);
          ctx.strokeStyle = "#64596b";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.fillStyle = "#64596b";
          ctx.fill();
          ctx.closePath();

          //draw date
          ctx.font = "10px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${date[0]} ${date[1]}`, xPoint - 90, yPoint - 5);

          // draw average
          ctx.font = "15px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${average_type} `, xPoint - 90, yPoint + 10);

          // draw value
          ctx.font = "15px futura-pt-demi";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${stat} `, xPoint - 90, yPoint + 25);
        } else {
          // draw rounded rectangle
          ctx.beginPath();
          ctx.roundRect(xPoint - 105, yPoint - 25, 90, 50, 12);
          ctx.strokeStyle = "#64596b";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.fillStyle = "#64596b";
          ctx.fill();
          ctx.closePath();

          //draw date
          ctx.font = "10px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${date[0]} ${date[1]}`, xPoint - 90, yPoint - 5);

          // draw value
          ctx.font = "15px futura-pt-demi";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${stat} `, xPoint - 90, yPoint + 10);
        }
      } else {
        if (data_length !== 7 && !assessDates) {
          // draw rounded rectangle
          ctx.beginPath();
          ctx.roundRect(xPoint + 15, yPoint + 25, 110, -65, 12);
          ctx.strokeStyle = "#64596b";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.fillStyle = "#64596b";
          ctx.fill();
          ctx.closePath();

          //draw date
          ctx.font = "10px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${date[0]} ${date[1]}`, xPoint + 25, yPoint - 18);

          // draw average type
          ctx.font = "15px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${average_type}`, xPoint + 25, yPoint - 3);

          // draw value
          ctx.font = "15px futura-pt-demi";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${stat}`, xPoint + 25, yPoint + 12);
        } else {
          // draw rounded rectangle
          ctx.beginPath();
          ctx.roundRect(xPoint + 15, yPoint + 25, 90, -50, 12);
          ctx.strokeStyle = "#64596b";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.fillStyle = "#64596b";
          ctx.fill();
          ctx.closePath();

          //draw date
          ctx.font = "10px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${date[0]} ${date[1]}`, xPoint + 25, yPoint - 5);

          // draw value
          ctx.font = "15px futura-pt-demi";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${stat}`, xPoint + 25, yPoint + 10);
        }
      }
      ctx.restore();
    }
  },
});

// const customMouseOut = {
//   id: "myEventCatcher",
//   afterEvent(chart, args, pluginOptions) {
//     const event = args.event;
//     if (event.type === "mouseout") {
//       if (chart) {
//         //reset tick colors
//         let labelColors = chart.config.options.scales["x"].ticks.color;
//         let foundIndex = labelColors.findIndex(
//           (x) => x === "rgba(221,206,219, 1)"
//         );
//         labelColors[foundIndex] = "rgba(221,206,219, 0.5)";
//         chart.config.options.scales["x"].ticks.color = labelColors;

//         chart.update();
//       }
//     }
//   },
// };

const LineChart = (props) => {
  const { t } = useTranslation();
  const [dataAvailable, setDataAvailable] = useState(true);
  // const customTooltip = createCustomTooltip(
  //   props.units,
  //   props.average_type,
  //   props.data.length,
  //   props.assessDates,
  //   props.yMaxLimit,
  //   props.outOfYMax
  // );

  const chartRef1 = useRef(null);

  useEffect(() => {
    setDataAvailable(true);
    Chart.register(
      CategoryScale,
      LineController,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );

    let labels = [];
    let dataArr = [];
    let labelColor = [];
    let highest = 0;
    let assessmentDate;
    let dateIndex;
    let stepSize = 2;
    if (props.assessDates) {
      assessmentDate = formatAssessmentDate(
        props.assessDates[props.assessmentNumber]
      ).slice(0, -5);
    } else {
      let currentDate = new Date().toJSON().slice(0, 10);
      assessmentDate = formatAssessmentDate(currentDate).slice(0, 6);
    }
    if (props.data) {
      props.data.forEach((item, index) => {
        let date = item.date;
        if (!props.assessDates && props.data.length !== 12) {
          date = formatAssessmentDate(date.slice(0, 10)).slice(0, 6);
        } else if (!props.assessDates && props.data.length === 12) {
          let newDate = new Date(date);
          date = newDate.getFullYear().toString();
        }
        if (date.startsWith("0")) date = date.substring(1);
        if (assessmentDate === date) {
          labelColor.push("#fff");
          dateIndex = index;
        } else {
          labelColor.push("rgba(221,206,219, 0.5)");
        }
        date = date.toUpperCase();
        if (!props.assessDates && props.data.length === 5) {
          labels.push([date.split(" ")[1], `WEEK ${index + 1}`]);
        } else if (!props.assessDates && props.data.length === 12) {
          labels.push([monthsArr[index], date.substring(2)]);
        } else {
          labels.push([date.split(" ")[0], date.split(" ")[1]]);
        }
        if (item.value === null) {
          dataArr.push(0);
        } else if (item.value === 0) {
          dataArr.push(0.1);
        } else {
          dataArr.push(item.value);
        }
      });

      //if there is no tracker data, create mock data
      if (props.average === null) {
        const arr = [];

        for (let i = 0; i < dataArr.length; i++) {
          const randomNumber = Math.floor(Math.random() * (4 - 0 + 1)); // Generate a random number between 0 and 4
          arr.push(randomNumber);
        }
        setDataAvailable(false);
        dataArr = [...arr];
      }

      // if only 1 assessment value add 4 blank values
      if (dataArr.length === 1) {
        for (let x = 0; x < 4; x++) {
          labelColor.push("rgba(221,206,219, 0.5)");
          dataArr.push(0);
          labels.push(["DD", "MM"]);
        }
      }

      highest = Math.max(...dataArr);
      if (props.yMaxLimit) {
        highest = props.yMaxLimit;
      } else if (highest <= 20) {
        highest = Math.ceil(highest / 5) * 5;
      } else {
        highest = Math.ceil(highest / 10) * 10;
      }

      if (highest <= 5) {
        stepSize = 1;
      } else if (highest <= 15) {
        highest % 3 === 0 ? (stepSize = 3) : (stepSize = 2);
      } else {
        stepSize = 4;
      }
    }
    const data = {
      labels,
      datasets: [
        {
          data: dataArr,
          fill: false,
          borderColor: `${
            props.average === null ? "#615668" : "rgb(233, 35, 100)"
          }`,
          backgroundColor: `${
            props.average === null ? "#615668" : "rgb(233, 35, 100)"
          }`,
          tension: 0,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      spanGaps: true,
      layout: {
        padding: {
          top: 50,
          right: 20,
          bottom: 0,
          left: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },

      scales: {
        y: {
          max: highest,
          min: 0,
          beginAtZero: true,
          grid: {
            color: "rgba(221, 206, 219, 0.2)",
          },
          border: {
            display: false,
          },
          position: "right",
          ticks: {
            display: props.average !== null ? true : false,
            stepSize: stepSize,
          },
        },
        x: {
          ticks: {
            font: "futura-pt-medium",
            size: 12.5,
            color: labelColor,
          },
          grid: {
            display: false,
          },
        },
      },
      elements: {
        point: {
          radius: 3,
          hitRadius: 15, // Increase as needed
          pointStyle: (context) => {
            const value = context.dataset.data[context.dataIndex];
            return value === 0 ? false : true;
          },
        },
      },
      onHover: (event, activeElements) => {
        const chart = event.chart;

        if (chart && props.average !== null) {
          labelColor.forEach((item, index) => {
            if (index === dateIndex) {
              labelColor[index] = "#fff";
            } else {
              labelColor[index] = "rgba(221,206,219, 0.5)";
            }
          });

          if (activeElements.length > 0) {
            activeElements.forEach((activeElement) => {
              const { index } = activeElement;

              if (index === dateIndex) {
                labelColor[index] = "#fff";
              } else {
                labelColor[index] = "rgba(221,206,219, 1)";
              }
            });
          }

          chart.config.options.scales["x"].ticks.color = labelColor;

          chart.update();
        }
      },
    };

    if (chartRef1 && chartRef1.current && props.average !== null) {
      const chartInstance = chartRef1.current.chartInstance;
      if (chartInstance) {
        chartInstance.destroy();
      }
      chartRef1.current.chartInstance = new Chart(
        chartRef1.current.getContext("2d"),
        {
          type: "line",
          data,
          options,
          plugins: [
            createCustomTooltip(
              props.units,
              props.average_type,
              props.data.length,
              props.assessDates,
              props.yMaxLimit,
              props.outOfYMax
            ),
          ],
        }
      );
    } else if (chartRef1 && chartRef1.current && props.average === null) {
      const chartInstance = chartRef1.current.chartInstance;
      if (chartInstance) {
        chartInstance.destroy();
      }
      chartRef1.current.chartInstance = new Chart(
        chartRef1.current.getContext("2d"),
        {
          type: "line",
          data,
          options,
        }
      );
    }

    return () => {
      if (chartRef1 && chartRef1.current && chartRef1.current.chartInstance) {
        chartRef1.current.chartInstance.destroy();
      }
    };
  }, [props.data, props.assessmentNumber]);

  return (
    <div className={"chart-container " + props.width}>
      <div className="title-container">
        <div className="title">{props.title}</div>
        <div className="wtf">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="wtf icon"
            onClick={() => props.openModal(props.title, props.modalText)}
          />
        </div>
      </div>
      <div className="charts-box fs-mask">
        {!dataAvailable && <div className="chart-no-data">{t("D075")}</div>}
        <div className="chart-wrap">
          <canvas ref={chartRef1} />{" "}
        </div>

        <div className="average">
          <div
            className="title"
            style={{ color: `${!dataAvailable ? "#615668" : ""}` }}
          >
            {props.average_type}
          </div>
          <div
            className="stats"
            style={{ color: `${dataAvailable ? props.color : "#615668"}` }}
          >
            <FontAwesomeIcon icon={props.icon} className="icon alarm" />
            <div className="stat">
              {props.average && dataAvailable
                ? Number(props.average).toString()
                : " "}
              {/* {props.units && <span>{props.units}</span>} */}
            </div>
            {props.units && <div className="unit">{props.units}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LineChart;
