import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TextArea from "../../../individualReport/pages/components/TextArea";
import DropDown from "../../../individualReport/pages/components/DropDown";
import NavSlider from "../../../shared/components/slider/NavSlider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/pro-light-svg-icons";

function Page(props) {
  const { t } = useTranslation();
  let premium = props.coData && props.coData.premium === "yes" ? true : false;
  let strategy_document =
    props.coData && props.coData.strategy_document
      ? props.coData.strategy_document
      : "";

  return (
    <React.Fragment>
      <div className="report-section active leadership_page">
        <h2 className="main_title">{t("HW001")}</h2>
        {/* <NavSlider ids={['your_strategy', 'action']} titles={{your_strategy: 'Your Strategy',action: 'FAQs'}} />        */}

        <div id="your_strategy" className="results-content">
          <TextArea containerClass="clear wide hw_strategy"></TextArea>
          <TextArea containerClass="clear">
            <span className="centred">
              <h2>{t("HW002")}</h2>
              {!premium && (
                <React.Fragment>
                  <p>{t("HW003")}</p>
                </React.Fragment>
              )}
              {premium && (
                <React.Fragment>
                  <p>{t("HW004")}</p>
                  <p>{t("HW005")}</p>
                  {strategy_document && (
                    <a href={strategy_document} target="_blank">
                      <button className="">
                        <FontAwesomeIcon icon={faCloudDownload} className="" />
                        {t("HW006")}
                      </button>
                    </a>
                  )}
                  {!strategy_document && (
                    <button className="">
                      <FontAwesomeIcon icon={faCloudDownload} className="" />
                      {t("HW007")}
                    </button>
                  )}
                </React.Fragment>
              )}
            </span>
          </TextArea>
          {!premium && (
            <div id="action">
              <DropDown title={t("HW008")} key="what" show="show">
                <div>
                  <p>{t("HW009")}</p>
                </div>
              </DropDown>

              <DropDown title={t("HW010")} key="why" show="show">
                <div>
                  <p>{t("HW011")}</p>
                </div>
              </DropDown>

              <DropDown title={t("HW012")} key="how" show="show">
                <div>
                  <p>{t("HW013")}</p>
                </div>
              </DropDown>
            </div>
          )}
          {premium && (
            <div id="action">
              <DropDown title={t("HW014")} key="what" show="show">
                <div>
                  <p>{t("HW015")}</p>
                </div>
              </DropDown>

              <DropDown title={t("HW016")} key="why" show="show">
                <div>
                  <p>{t("HW017")}</p>
                </div>
              </DropDown>

              <DropDown title={t("HW018")} key="how" show="show">
                <div>
                  <p>{t("HW019")}</p>
                </div>
              </DropDown>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
export default Page;
