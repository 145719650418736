import React from "react";
import { useTranslation } from "react-i18next";

import { useHttpClient } from "../../shared/hooks/http-hook";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Carousel from "../components/Carousel";

function Register({ children }) {
  const { t } = useTranslation();

  const { isLoading } = useHttpClient();

  return (
    <div id="main_container" className="register">
      {isLoading && <LoadingSpinner asOverlay />}
      <div id="left_container">
        <h1 className="main_text">{t("R001")}</h1>
        <div className="carousel_container">
          <Carousel />
        </div>
        <div className="fade"></div>
      </div>
      {children}
    </div>
  );
}
export default Register;
