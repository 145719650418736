export const colours = {
  blue: "#41c0d3",
  green: "#62bf92",
  yellow: "#fcc954",
  red: "#ef545a",
  pink: "#e92364",
  white: "#ffffff",
  orange: "#f69358",
  amber: "#f69358",
  "faded-rose": "#ddcedb", //text
  "purple-grey": "#64596b", //background
  "border-colour": "rgba(98, 90, 106, 0.95)",
  "half-opacity": "rgba(221,206,219, 0.5)",
  fade: "rgb(40, 29, 45)", // #281d2d
};
