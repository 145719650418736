import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Clock from "../questions/clock/Clock";
import OneToTenSlider from "../questions/painSlider/OneToTenSlider";
import Checkbox from "../questions/checkbox/Checkbox";
import Radio from "../questions/radio/Radio";
import CheckboxRadio from "../questions/checkboxRadio/CheckboxRadio";

function Sleep1(props) {
  const { t } = useTranslation();
  const sleepQualityTextValue = [t("SL001"), t("SL002")];

  const sleepExperienceOptions = [
    { id: "none", title: t("SL003") },
    { id: "sleep_apnoea", title: t("SL004") },
    { id: "insomnia", title: t("SL005") },
    { id: "restless_leg_syndrome", title: t("SL006") },
    { id: "difficulty_getting_to_sleep", title: t("SL007") },
    {
      id: "waking_up_regularly_at_night",
      title: t("SL008"),
    },
  ];

  const nightShiftOptions = [
    { id: "yes", title: t("SL009") },
    { id: "no", title: t("SL010") },
  ];

  const sleepPatternOptions = [
    { id: "regular", title: t("SL011") },
    { id: "somewhat_irregular", title: t("SL012") },
    { id: "irregular", title: t("SL013") },
  ];

  useEffect(() => {
    if (props.assessmentValues.night_shift === "yes") {
      props.onSetValue({
        id: "sleeping_pattern",
        value: null,
      });
    }
  }, [props.assessmentValues.night_shift]);

  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>
      <Clock
        questionid="clock_bed"
        width=""
        questionTitle={t("SL014")}
        startHour="22"
        startMinute="00"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <Clock
        questionid="clock_wake"
        width=""
        questionTitle={t("SL015")}
        startHour="07"
        startMinute="00"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <OneToTenSlider
        questionid="quality_of_sleep"
        questionTitle={t("SL016")}
        width="_wide"
        textValues={sleepQualityTextValue}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("SL017")}
        onOpenModal={props.onOpenModal}
      />
      <Checkbox
        questionid="sleep_experience"
        width="_wide"
        questionTitle={t("SL018")}
        questionTitleSuffix={t("SL019")}
        options={sleepExperienceOptions}
        placeholder={t("SL020")}
        itemLimit="3"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("SL021")}
        onOpenModal={props.onOpenModal}
      />

      <Radio
        questionid="night_shift"
        width=""
        questionTitle={t("SL022")}
        options={nightShiftOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("SL023")}
        onOpenModal={props.onOpenModal}
      />
      {props.assessmentValues.night_shift === "no" && (
        <CheckboxRadio
          questionid="sleeping_pattern"
          width=""
          options={sleepPatternOptions}
          questionTitle={t("SL024")}
          placeholder={t("SL025")}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          fadeIn="yes"
          modalText={t("SL026")}
          onOpenModal={props.onOpenModal}
        />
      )}
    </div>
  );
}

export default Sleep1;
