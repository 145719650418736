import React, { useState, useEffect } from "react";
import {
  formatName,
  getChallengeTypeUnit,
  getFormattedValue,
  getInitial,
} from "../../shared/util/challenges";
import { checkImage } from "../../shared/util/helperfunctions";

const BoardListRow = ({ user, userPlace, challengeType, leaderBoard }) => {
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    if (user?.image) {
      checkImage(user?.image).then(setImageExists);
    }
  }, [user?.image]);

  return (
    <div
      className={`boardList-row ${leaderBoard ? "boardList-row-leader" : ""} ${
        leaderBoard && userPlace < 14 ? "boardList-row-leader" : ""
      } ${
        !leaderBoard && userPlace === user.place ? "boardList-row-user" : ""
      }`}
    >
      <div className="boardList-number">{user.place}</div>
      <div
        className={`boardList-user ${
          userPlace === user.place ? "boardList-user-active" : ""
        }`}
      >
        <div className="boardList-user-1">
          <div
            className="boardList-avatar fs-exclude"
            style={
              imageExists ? { backgroundImage: `url(${user?.image})` } : {}
            }
          >
            <div
              className="boardList-initial"
              style={imageExists ? { display: "none" } : {}}
            >
              {getInitial(user?.name || "")}
            </div>
          </div>
          <div className="boardList-name">{formatName(user.name)}</div>
        </div>
        <div className="boardList-user-2">
          <div className="boardList-progress">
            {getFormattedValue(user.score, challengeType)}
          </div>
          <div className="boardList-progress-unit">
            {getChallengeTypeUnit(challengeType, user.score)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardListRow;
