import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TextArea from "../../../../individualReport/pages/components/TextArea";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-light-svg-icons";

function Overview(props) {
  const { t } = useTranslation();
  const limit = 15;
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  let engagement;
  if (
    props.coData.report_period_array.length > 0 &&
    props.coData.licences_array.length > 0
  ) {
    engagement = Math.round(
      (100 /
        Number(
          props.coData.licences_array[
            props.coData.report_period_array.indexOf(props.reportPeriod)
          ]
        )) *
        props.data.completed_assessments
    );
  } else {
    engagement = Math.round(
      (100 / props.coData.licences) * props.data.completed_assessments
    );
  }

  return (
    <div id="health-report" className="results-content analytics">
      <TextArea containerClass="clear half_square noreport"></TextArea>
      <TextArea containerClass="clear half_square">
        {props.data.completed_assessments &&
          props.coData.licences_array &&
          props.coData.report_period_array &&
          props.reportPeriod && (
            <CircleGraph
              title={t("OV001")}
              colour="red"
              value={props.data.completed_assessments}
              subText={t("OV002")}
              large
              percent={engagement}
              numberFirst="true"
              modalText={t("OV003")}
              onOpenModal={props.onOpenModal}
            />
          )}
        <span className="health_assessment_closed">
          {(props.data.completed_assessments < limit ||
            !props.coData.analytics_open ||
            props.coData.analytics_open !== "yes") && (
            <React.Fragment>
              <h2>{t("OV004")}</h2>
              <p>
                {t("OV005")}{" "}
                <Link to="/company-report/engagement-resources">
                  {t("OV011")}
                </Link>
                .
              </p>
              <Link to="/company-report/engagement-resources">
                <button className="green">
                  <FontAwesomeIcon icon={faArrowUp} className="" />
                  {t("OV006")}
                </button>
              </Link>
              <button
                className="black right"
                onClick={() => {
                  props.onOpenModal();
                }}
              >
                {t("OV007")}
              </button>
            </React.Fragment>
          )}
          {props.data.completed_assessments >= limit &&
            props.coData.analytics_open &&
            props.coData.analytics_open === "yes" && (
              <React.Fragment>
                <h2>{t("OV008")}</h2>
                <p>{t("OV009")}</p>
                <p>{t("OV010")}</p>
              </React.Fragment>
            )}
        </span>
      </TextArea>
    </div>
  );
}
export default Overview;
