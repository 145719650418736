// goalsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    nps: true,
  },
  reducers: {
    updateNpsState: (state, action) => {
      if (action.payload) {
        state.nps = action.payload.nps;
      }
    },
  },
});

// your exported actions and reducer...
export const { updateNpsState } = userSlice.actions;
export default userSlice.reducer;
