import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import StressDialSlider from "../questions/dialSlider/StressDialSlider";
import Radio from "../questions/radio/Radio";
import Checkbox from "../questions/checkbox/Checkbox";

function Stress1(props) {
  const { t } = useTranslation();
  const textValue = [
    t("ST001"),
    t("ST002"),
    t("ST003"),
    t("ST004"),
    t("ST005"),
  ];

  const workStressOptions = [
    { id: "none", title: t("ST006") },
    {
      id: "perfect",
      title: t("ST007"),
    },
    {
      id: "too_much",
      title: t("ST008"),
    },
    {
      id: "overwhelmed",
      title: t("ST009"),
    },
  ];

  const workStressReasons = [
    // { id: "none_sq", title: "None" },
    { id: "peers", title: t("ST010") },
    { id: "workload", title: t("ST011") },
    { id: "senior_staff_members", title: t("ST012") },
    { id: "commuting", title: t("ST013") },
    { id: "lack_of_support", title: t("ST014") },
    { id: "working_from_home", title: t("ST015") },
    { id: "lack_of_control", title: t("ST016") },
    { id: "bullying", title: t("ST017") },
    { id: "insufficient_training", title: t("ST018") },
    { id: "job_security", title: t("ST019") },
    { id: "other", title: t("ST020") },
  ];

  const outsidePressureOptions = [
    { id: "none", title: t("ST021") },
    { id: "relationships", title: t("ST022") },
    { id: "financial_pressure", title: t("ST023") },
    { id: "parenting", title: t("ST024") },
    { id: "caring_responsibilities", title: t("ST025") },
    { id: "ill_health", title: t("ST026") },
    { id: "bereavement", title: t("ST027") },
    { id: "other", title: t("ST028") },
  ];

  useEffect(() => {
    if (
      props.assessmentValues.stress_at_work &&
      (props.assessmentValues.stress_at_work === "none" ||
        props.assessmentValues.stress_at_work === "perfect")
    ) {
      props.onSetValue({
        id: "work_aspects_stressful",
        value: null,
      });
    }
  }, [props.assessmentValues.stress_at_work]);

  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <StressDialSlider
        questionid="stress_at_moment"
        width="_wide center"
        questionTitle={t("ST029")}
        textValue={textValue}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("ST030")}
        onOpenModal={props.onOpenModal}
      />
      <Radio
        questionid="stress_at_work"
        width="_wide"
        questionTitle={t("ST031")}
        options={workStressOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("ST032")}
        onOpenModal={props.onOpenModal}
      />
      {props.assessmentValues.stress_at_work &&
        (props.assessmentValues.stress_at_work === "overwhelmed" ||
          props.assessmentValues.stress_at_work === "too_much") && (
          <Checkbox
            questionid="work_aspects_stressful"
            width=""
            questionTitle={t("ST033")}
            questionTitleSuffix={t("ST034")}
            options={workStressReasons}
            placeholder={t("ST035")}
            itemLimit="2"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
            modalText={t("ST036")}
            onOpenModal={props.onOpenModal}
          />
        )}
      <Checkbox
        questionid="outside_work_stressful"
        width="_wide"
        questionTitle={t("ST037")}
        questionTitleSuffix={t("ST038")}
        options={outsidePressureOptions}
        placeholder={t("ST039")}
        itemLimit="2"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("ST040")}
        onOpenModal={props.onOpenModal}
      />
    </div>
  );
}

export default Stress1;
