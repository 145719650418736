import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";

export default function CsvReader(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [existingErvIds, setExistingErvIds] = useState(props.ervIds || []);
  const [added, setAdded] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [message, setMessage] = useState();

  const processCSV = (str, delim = ",") => {
    const rows = str.split("\n");
    let newArray = rows
      .map((row) => {
        const values = row.split(delim);
        return values[0].trim();
      })
      .filter((id) => id);
    const newArray2 = newArray.filter((id) => id);
    setCsvArray(newArray2);
    setAdded(newArray2.filter((id) => !existingErvIds.includes(id)));
    setRemoved(existingErvIds.filter((id) => !newArray2.includes(id)));
  };

  const submit = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      //console.log(text);
      processCSV(text);
    };

    reader.readAsText(file);
  };

  const confirmImport = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/clients/ervUpdate`,
        "POST",
        JSON.stringify({
          ervIds: csvArray,
        })
      );
      if (responseData) {
        setMessage(responseData.response);
        setExistingErvIds(csvArray);
      }
    } catch (err) {}
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <form id="csv-form">
          <input
            type="file"
            accept=".csv"
            id="csvFile"
            onChange={(e) => {
              setCsvFile(e.target.files[0]);
            }}
          ></input>
          <br />
          <br />
          <button
            onClick={(e) => {
              e.preventDefault();
              if (csvFile) submit();
            }}
          >
            {t("ERV004")}
          </button>
          {csvArray.length > 0 && !message && (
            <div>
              <p>
                {t("ERV005")} {csvArray.length} {t("ERV006")}
                <br />({t("ERV007")} {csvArray[0]}, {csvArray[1]}, {csvArray[2]}
                )
              </p>
              <p>
                {t("ERV008")} {added.length} {t("ERV009")}
                {added.length > 0 && (
                  <span>
                    <br />({t("ERV011")}
                    {added.slice(0, 3).map((item, index) => {
                      return index !== 2 ? item + ", " : item;
                    })}
                    )
                  </span>
                )}
              </p>
              <p>
                {t("ERV012")} {removed.length} {t("ERV006")}
                {removed.length > 0 && (
                  <span>
                    <br />({t("ERV011")}
                    {removed.slice(0, 3).map((item, index) => {
                      return index !== 2 ? item + ", " : item;
                    })}
                    )
                  </span>
                )}
              </p>
              <br />
              {!message && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    confirmImport(e);
                  }}
                >
                  {t("ERV013")}
                </button>
              )}
            </div>
          )}
          {message && (
            <p>
              <b>{message}</b>
              <br />
              <br />
              <a style={{ color: "#000000" }} href="/company-report/erv-import">
                {t("ERV014")}
              </a>
            </p>
          )}
        </form>
      )}
    </div>
  );
}
