import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./AssessmentStyle.css";
import Modal from "../../shared/modal/Modal";
import Header from "../../shared/header/Header";
import Footer from "../footer/Footer";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import AssessmentProfile from "./AssessmentProfile";
import PhysicalHealth1 from "./PhysicalHealth1";
import Alcohol1 from "./Alcohol1";
import Behaviour1 from "./Behaviour1";
import Energy1 from "./Energy1";
import Energy2 from "./Energy2";
import Energy3 from "./Energy3";
import Hydration1 from "./Hydration1";
import MentalHealth2 from "./MentalHealth2";
import MentalHealth3 from "./MentalHealth3";
import MentalHealth4 from "./MentalHealth4";
import MentalHealth5 from "./MentalHealth5";
import FinancialWellbeing1 from "./FinancialWellbeing";
import Nutrition1 from "./Nutrition1";
import PhysicalActivity1 from "./PhysicalActivity1";
import Sleep1 from "./Sleep1";
import Stress1 from "./Stress1";
import Completed from "./Completed";
import { useFetchGoalsQuery } from "../../goals/goalsApiSlice";
const pageReducer = (state, action) => {
  switch (action.type) {
    case "MEDIA":
      return {
        ...state,
        currentMedia: action.media,
      };
    case "PAGE":
      return {
        ...state,
        currentPage: action.title,
      };
    case "PROGRESS":
      return {
        ...state,
        assessmentProgress: action.progress,
      };
    case "SECTION":
      return {
        ...state,
        sectionNo: action.no,
        sectionMax: action.max,
      };
    default:
      return state;
  }
};
const assessmentReducer = (state, action) => {
  switch (action.type) {
    case "date":
      //alert(action.value);
      return {
        ...state,
        [action.id]: action.value,
      };
    case "DELETE_KEY":
      const newState = { ...state };
      delete newState[action.id];
      return newState;

    default:
      return {
        ...state,
        [action.id]: action.value,
      };
  }
};
function Assessment(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [regionContent, setRegionContent] = useState();
  const navigate = useNavigate();

  const pageList = [
    {
      title: "AssessmentProfile",
      media: "Profile_Setup",
      sectionNo: 0,
      sectionMax: 0,
    },
    {
      title: "PhysicalHealth1",
      media: "PhysicalHealth",
      sectionNo: 0,
      sectionMax: 0,
    },
    {
      title: "PhysicalActivity1",
      media: "PhysicalActivity",
      sectionNo: 0,
      sectionMax: 0,
    },
    { title: "Sleep1", media: "Sleep", sectionNo: 0, sectionMax: 0 },
    { title: "Stress1", media: "MentalHealth", sectionNo: 0, sectionMax: 0 },

    {
      title: "MentalHealth2",
      media: "MentalHealth",
      sectionNo: 1,
      sectionMax: 4,
    },
    {
      title: "MentalHealth3",
      media: "MentalHealth",
      sectionNo: 2,
      sectionMax: 4,
    },
    {
      title: "MentalHealth4",
      media: "MentalHealth",
      sectionNo: 3,
      sectionMax: 4,
    },
    {
      title: "MentalHealth5",
      media: "MentalHealth",
      sectionNo: 4,
      sectionMax: 4,
    },
    {
      title: "FinancialWellbeing1",
      media: "FinancialWellbeing",
      sectionNo: 0,
      sectionMax: 0,
    },
    { title: "Nutrition1", media: "Nutrition", sectionNo: 0, sectionMax: 0 },
    { title: "Hydration1", media: "Hydration", sectionNo: 0, sectionMax: 0 },
    { title: "Alcohol1", media: "Alcohol", sectionNo: 0, sectionMax: 0 },
    {
      title: "Energy1",
      media: "Energy",
      sectionNo: auth.access === "2" ? 0 : 1,
      sectionMax: auth.access === "2" ? 0 : 3,
    },
    {
      title: "Energy2",
      media: "Energy",
      sectionNo: 2,
      sectionMax: 3,
      conditional: true,
    },
    {
      title: "Energy3",
      media: "Energy",
      sectionNo: 3,
      sectionMax: 3,
      conditional: true,
    },
    { title: "Behaviour1", media: "Behaviour", sectionNo: 0, sectionMax: 0 },
  ];
  const { data: goals, isLoading: isLoadingGoals } = useFetchGoalsQuery(
    auth.token,
    {
      skip: !auth.token,
    }
  );

  const [assessmentValues, dispatchValues] = useReducer(assessmentReducer, {});

  const saveAssessmentValue = useCallback(
    (props) => {
      //check for empty values first and remove them from the object
      if (
        props.value === null ||
        props.value === undefined ||
        props.value.length === 0
      ) {
        if (props.id in assessmentValues) {
          dispatchValues({ type: "DELETE_KEY", id: props.id });
        }
        return;
      }

      if (props.id && (props.value || props.value === 0)) {
        dispatchValues({
          type: props.id,
          id: props.id,
          value: isNaN(+props.value) ? props.value : +props.value,
        });
      }
    },
    [assessmentValues]
  );
  const [pageOptions, dispatch] = useReducer(pageReducer, {
    assessmentProgress: 12,
    sectionNo: 0,
    sectionMax: 0,
    currentMedia: "Profile_Setup",
    currentPage: "AssessmentProfile",
  });
  const [assessmentId, setAssessmentId] = useState();
  const [repeatAssessment, setRepeatAssessment] = useState(false);

  const saveAssessmentDataHandler = async (dir) => {
    let source = "web";
    if (assessmentValues && auth.userId && assessmentId && auth.token) {
      let pageNo = assessmentValues.iProgress;
      if (
        auth.access === "2" &&
        pageNo === 13 &&
        !isLoadingGoals &&
        goals?.length > 0
      ) {
        assessmentValues.iProgress = 16;
      } else if (
        !isLoadingGoals &&
        goals?.length > 0 &&
        pageNo === 15 &&
        auth.access !== "10"
      ) {
        assessmentValues.iProgress = 16;
      } else if (pageNo === 16) {
      }
      //assessmentValues.pageOptions = pageOptions;
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/assessments/update`,
          "POST",
          JSON.stringify({
            source: source,
            userid: auth.userId,
            assessmentid: assessmentId,
            data: assessmentValues,
          })
        );
      } catch (err) {}
      if (
        responseData &&
        responseData.status === "complete" &&
        dir !== "prev" &&
        assessmentValues.iProgress === 16
      ) {
        auth.tagHandler(responseData.tags);
        //window.location = "/individual-report/overview/?referer=assessment";
        let url = "/for-you/?referer=assessment";
        if (repeatAssessment) {
          url = "/for-you/?referer=repeat_assessment";
        }
        navigate(url);
      }
    }
  };

  const getAssessmentDataHandler = async () => {
    if (auth.userId && auth.token) {
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/assessments/uid/${auth.userId}`
        );
      } catch (err) {}
      let data;
      if (!responseData) {
        return false;
      } else if (!responseData.editable) {
        openModal(t("A071"));
        return;
      } else {
        setAssessmentId(responseData.id);
        //set regional content
        setRegionContent(responseData.regional_content);
        //let assessment = responseData.assessment;
        if (responseData.data !== "empty") {
          data = responseData.data;
        }
        //check repeat assessment
        if (goals?.length > 0) {
          setRepeatAssessment(true);
        }
      }
      let iProgress = -1;
      if (data) {
        if (data.iProgress) {
          iProgress = data.iProgress;
        }
        const dkeys = Object.keys(data).reduce(function (result, key) {
          dispatchValues({
            id: key,
            value: data[key],
          });
          if (key === "iProgress") {
            iProgress = data[key];
          }
        });
      }
      navigateNextPageHandler("load", iProgress);
    }
  };

  const navigateNextPageHandler = (dir, pi = 1) => {
    //find current page position
    let currentPage = pageOptions.currentPage;
    let currentMedia = pageOptions.currentMedia;
    if (dir !== "prev" && dir !== "load") {
      //is current page valid
      //check for registration
      if (currentPage === "AssessmentProfile" && !auth.isLoggedIn) {
        //cant pass register until logged in
        return;
      }
      // else if (currentPage === "AssessmentProfile" && auth.isLoggedIn) {
      //registration and two factor completed
      // }
      else {
        //validate
        let questions = document.querySelectorAll("#" + currentPage + " .req");
        let valid = true;
        for (let q = 0; q < questions.length; q++) {
          let questionid = questions[q].id;
          if (
            assessmentValues[questionid] === null ||
            assessmentValues[questionid] === undefined ||
            assessmentValues[questionid].length === 0
          ) {
            valid = false;
          }
        }
        if (!valid) {
          openModal(t("A072"));
          return;
        }
      }
    }
    // alert(dir, "dir should be load");
    if (dir !== "load") {
      //save
      saveAssessmentDataHandler(dir);
    }
    //progress
    let i = pageList.findIndex((p) => p.title === currentPage);
    // alert(currentPage, "current page");
    if (dir === "load") {
      if (Number(pi) === 16) {
        i = 15;
      } else {
        i = Number(pi);
      }
    }
    if (dir === "prev") {
      i--;
    } else {
      i++;
    }
    //prevent cliff edge and block back to register
    if (i < 0) {
      i = 0;
    }
    if (i > pageList.length) {
      i = pageList.length - 1;
    }

    if (i > 16) {
      return;
    } else if (
      i > 15 &&
      !isLoadingGoals &&
      goals?.length > 0 &&
      auth.access !== "10"
    ) {
      return;
    } else if (
      i > 13 &&
      auth.access === "2" &&
      !isLoadingGoals &&
      goals?.length > 0
    ) {
      return;
    } else {
      //continue
      //prevent prev on register
      if (dir === "prev" && currentPage === "AssessmentProfile") {
        i = 0;
      }
      //check to skip pages
      if (pageList[i] && pageList[i].conditional) {
        //page is conditional
        if (auth.access === "2") {
          //condition not met - skip
          if (dir === "prev") {
            i--;
          } else {
            i++;
          }
        }
      }

      //save page we're on
      dispatchValues({
        id: "iProgress",
        value: i,
      });
      //expand container to keep scroll bar
      var newheight = window.innerHeight + 10;
      document.getElementById("right_container").style.minHeight =
        newheight + "px";
      var width =
        document.getElementsByClassName("question_container")[0].offsetWidth -
        50;
      document.getElementById("right_container").style.width = width + "px";
      //fadeout current page
      if (dir !== "load") {
        document.getElementById(currentPage).classList.remove("active");
        if (currentMedia !== pageList[i].media) {
          document.getElementById(currentMedia).classList.remove("active");
        }
      }
      setTimeout(() => {
        document.getElementById("right_container").style.width = "auto";
        //switch content
        dispatch({
          type: "PAGE",
          title: pageList[i].title,
        });
        if (pageOptions.currentMedia !== pageList[i].media) {
          dispatch({
            type: "MEDIA",
            media: pageList[i].media,
          });
        }
      }, 500);
      //fadein new page
      setTimeout(() => {
        if (document.getElementById(pageList[i].title)) {
          document.getElementById(pageList[i].title).classList.add("active");
        }
        if (
          document.getElementById(pageList[i].media) &&
          currentMedia !== pageList[i].media
        ) {
          document.getElementById(pageList[i].media).classList.add("active");
        }
        //scroll up
        window.scrollTo({ top: 0, behavior: "smooth" });
        //animate top progress bar
        var progressStep = Math.round(
          (100 / pageList.length) * (i + 1) * 0.88 + 12
        );
        dispatch({
          type: "PROGRESS",
          progress: progressStep,
        });
      }, 700);
      // change bottom progress bar
      dispatch({
        type: "SECTION",
        no: pageList[i].sectionNo,
        max: pageList[i].sectionMax,
      });
      // //health wellbeing image
      // if (pageList[i].media === "Health") {
      //   setTimeout(function () {
      //     document.getElementsByClassName(
      //       "health_wellbeing_image"
      //     )[0].style.opacity = 1;
      //     document.getElementById("Health").style.opacity = 1;
      //   }, 1000);
      // }
    }
  };
  const navigatePrevPageHandler = () => {
    navigateNextPageHandler("prev");
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState("");
  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
    if (!assessmentId) {
      //navigate("/for-you");
    }
  }, [modalIsOpen]);
  const openModal = useCallback(
    (props) => {
      setModalIsOpen(true);
      setModalTextContent(props);
    },
    [modalIsOpen, modalTextContent]
  );
  useEffect(() => {
    if (auth.isLoggedIn) {
      getAssessmentDataHandler();
    }
  }, [auth.isLoggedIn]);
  const quickNavHandler = useCallback((props) => {
    navigateNextPageHandler("load", props);
  }, []);

  //show video toggle for mobile safari browsers
  const calculateWidth = () => {
    let width = document.documentElement.clientWidth;
    if (width <= 1050) {
      let video = document.getElementById(pageOptions.currentMedia);
      if (video) video.autoplay = false;
    } else if (width > 1050) {
      let video = document.getElementById(pageOptions.currentMedia);
      video.autoplay = true;
      video.play();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      calculateWidth();
    };

    if (document.documentElement.clientWidth > 1050) {
      let video = document.getElementById(pageOptions.currentMedia);
      video.autoplay = true;
      video.play();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pageOptions.currentMedia]);

  return (
    <div id="main_container" className="assessment">
      <Modal
        modalIsOpen={modalIsOpen}
        onModalClose={modalClickHandler}
        modalText={modalTextContent}
      />
      <Header
        page="assessment"
        progress={pageOptions.assessmentProgress}
        onNextPage={quickNavHandler}
      />
      <div id="left_container">
        {/* {pageOptions.currentMedia === "Health" && (
          <div id="Health" className="health_wellbeing"></div>
        )} */}
        {pageOptions.currentMedia === "Profile_Setup" && (
          <video
            className="video active"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Profile_Setup.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "Behaviour" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Behaviour_Change.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "Alcohol" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Alcohol.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "Energy" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Energy_Levels.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "Nutrition" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Nutrition.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "Hydration" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Hydration.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "Sleep" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Sleep.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "PhysicalHealth" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Physical_Health.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "MentalHealth" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Mental_Health.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia === "FinancialWellbeing" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1676468179/assessment/videos/fin-well.mp4"
            }
            type="/video/mp4"
            muted
            autoPlay
          />
        )}
        {pageOptions.currentMedia === "PhysicalActivity" && (
          <video
            className="video"
            id={pageOptions.currentMedia}
            src={
              "https://res.cloudinary.com/champion-health/video/upload/v1630600746/assessment/videos/Physical_Activity.mp4"
            }
            type="/video/mp4"
            muted
          />
        )}
        {pageOptions.currentMedia !== "Health" && (
          <div id={pageOptions.currentMedia}></div>
        )}
      </div>
      <div id="right_container">
        {auth.isLoggedIn && (
          <React.Fragment>
            {pageOptions.currentPage === "AssessmentProfile" && (
              <AssessmentProfile
                questionid="AssessmentProfile"
                active=""
                questionHeading={
                  repeatAssessment
                    ? t("A073") + auth.fname
                    : t("A074") + auth.fname + t("A075")
                }
                questionText={repeatAssessment ? t("A076") : t("A077")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {pageOptions.currentPage === "PhysicalHealth1" && (
              <PhysicalHealth1
                questionid="PhysicalHealth1"
                active=""
                questionHeading={t("A078")}
                questionText={t("A079")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}

            {pageOptions.currentPage === "PhysicalActivity1" && (
              <PhysicalActivity1
                questionid="PhysicalActivity1"
                active=""
                questionHeading={t("A080")}
                questionText={t("A081")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {pageOptions.currentPage === "Sleep1" && (
              <Sleep1
                questionid="Sleep1"
                active=""
                questionHeading={t("A082")}
                questionText={t("A083")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}

            {pageOptions.currentPage === "Stress1" && (
              <Stress1
                questionid="Stress1"
                active=""
                questionHeading={t("A084")}
                questionText={t("A085")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}

            {pageOptions.currentPage === "MentalHealth2" && (
              <MentalHealth2
                questionid="MentalHealth2"
                active=""
                questionHeading={t("A086")}
                questionText={t("A087")}
                // questionTitle="In the last two weeks, I've been..."
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {pageOptions.currentPage === "MentalHealth3" && (
              <MentalHealth3
                questionid="MentalHealth3"
                active=""
                questionHeading={t("A088")}
                questionText={t("A089")}
                questionTitle={t("A090")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {pageOptions.currentPage === "MentalHealth4" && (
              <MentalHealth4
                questionid="MentalHealth4"
                active=""
                questionHeading={t("A091")}
                questionText={t("A092")}
                questionTitle={t("A093")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
                regionContent={regionContent}
              />
            )}
            {pageOptions.currentPage === "MentalHealth5" && (
              <MentalHealth5
                questionid="MentalHealth5"
                active=""
                questionHeading={t("A094")}
                questionText={t("A095")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}

            {pageOptions.currentPage === "FinancialWellbeing1" && (
              <FinancialWellbeing1
                questionid="FinancialWellbeing1"
                active=""
                questionHeading={t("A096")}
                questionText={t("A097")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {pageOptions.currentPage === "Nutrition1" && (
              <Nutrition1
                questionid="Nutrition1"
                active=""
                questionHeading={t("A098")}
                questionText={t("A099")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
                regionContent={regionContent}
              />
            )}
            {pageOptions.currentPage === "Hydration1" && (
              <Hydration1
                questionid="Hydration1"
                active=""
                questionHeading={t("A100")}
                questionText={t("A101")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {pageOptions.currentPage === "Alcohol1" && (
              <Alcohol1
                questionid="Alcohol1"
                active=""
                questionHeading={t("A102")}
                questionText={t("A103")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {pageOptions.currentPage === "Energy1" && (
              <Energy1
                questionid="Energy1"
                active=""
                questionHeading={t("A104")}
                questionText={t("A105")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {auth.access !== "2" && pageOptions.currentPage === "Energy2" && (
              <Energy2
                questionid="Energy2"
                active=""
                questionHeading={t("A106")}
                questionTitle={t("A107")}
                questionText={t("A108")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {auth.access !== "2" && pageOptions.currentPage === "Energy3" && (
              <Energy3
                questionid="Energy3"
                active=""
                questionHeading={t("A109")}
                questionText={t("A110")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}
            {pageOptions.currentPage === "Behaviour1" && (
              <Behaviour1
                questionid="Behaviour1"
                active=""
                questionHeading={t("A111")}
                questionText={t("A112")}
                assessmentValues={assessmentValues}
                onSetValue={saveAssessmentValue}
                onOpenModal={openModal}
              />
            )}

            {pageOptions.currentPage === "Completed" && (
              <Completed
                questionid="Completed"
                questionHeading={t("A113")}
                questionText={t("A114")}
                assessmentValues={assessmentValues}
              />
            )}
          </React.Fragment>
        )}
      </div>

      <Footer
        sectionNo={pageOptions.sectionNo}
        sectionMax={pageOptions.sectionMax}
        onNextPage={navigateNextPageHandler}
        onPrevPage={navigatePrevPageHandler}
        goals={goals}
        iProgress={assessmentValues.iProgress}
      />
    </div>
  );
}
export default Assessment;
