import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ChevronBackBtn.css";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";

const ChevronBackBtn = ({ className, onClick }) => (
  <button className={`chevronBackBtn ${className}`} onClick={onClick}>
    <FontAwesomeIcon icon={faChevronLeft} className="icon" />
  </button>
);

export default ChevronBackBtn;
