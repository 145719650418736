import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faClosedCaptioning as CC,
  faHighDefinition,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowDownToBracket,
  faArrowUpRightFromSquare,
} from "@fortawesome/pro-light-svg-icons";
import { convertTime } from "../../shared/util/helperfunctions";
import GreyButton from "../../shared/components/styledComponents/greyButton/GreyButton";
import { useTranslation } from "react-i18next";

const McMediaDescription = ({ masterclass, nowActive, chapterNo, day }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="description">
        <div className="title">{nowActive.title.replace(/&amp;/gi, "&")}</div>
        <div className="tag-line">
          <div>
            {day && `Day ${day}, `}Chapter {chapterNo}
          </div>
          <div>{convertTime(nowActive.length)}</div>
          <FontAwesomeIcon icon={faHighDefinition} />
          <FontAwesomeIcon icon={CC} />
        </div>
        <div className="text">{nowActive.description}</div>
      </div>
      <div className="button-container">
        {masterclass.resources && (
          <a
            href={masterclass.resources}
            rel="noopener noreferrer"
            target="_blank"
          >
            <GreyButton className="download">
              <FontAwesomeIcon icon={faArrowDownToBracket} className="icon" />{" "}
              {t("M002")}
            </GreyButton>
          </a>
        )}
        {masterclass.signposting && (
          <a
            href={masterclass.signposting}
            rel="noopener noreferrer"
            target="_blank"
          >
            <GreyButton className="support">
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="icon"
              />
              {t("M001")}
            </GreyButton>
          </a>
        )}
      </div>
    </React.Fragment>
  );
};
export default McMediaDescription;
