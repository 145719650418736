import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faBatteryFull,
} from "@fortawesome/pro-light-svg-icons";

import EnergyTracker from "./EnergyTracker";

const EnergyCover = (props) => {
  const { t } = useTranslation();

  const [areaPicker, setAreaPicker] = useState(true);
  const [openEnergy, setOpenEnergy] = useState(false);

  const handleEnergy = () => {
    const main = document.getElementById("snm-main");
    main?.classList.remove("slide-in");
    setTimeout(() => {
      setAreaPicker(false);
      setOpenEnergy(true);
    }, 500);
  };

  useEffect(() => {
    if (openEnergy) {
      const energy = document.getElementById("snm-energy");
      setTimeout(() => {
        energy?.classList.add("slide-in");
      }, 300);
    }
  }, [openEnergy]);

  useEffect(() => {
    if (areaPicker) {
      const main = document.getElementById("snm-main");
      setTimeout(() => {
        main?.classList.add("slide-in");
      }, 300);
    }
  }, [areaPicker]);

  const handleBackToMain = () => {
    if (openEnergy) {
      const energy = document.getElementById("snm-energy");
      energy?.classList.remove("slide-in");
      setTimeout(() => {
        setOpenEnergy(false);
        setAreaPicker(true);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      {areaPicker && (
        <div id="snm-main" className="tracker-container">
          <div className="ind-tracker mood">
            <div className="title">{t("T134")}</div>
            <div className="mood-area" onClick={handleEnergy}>
              <div>
                <FontAwesomeIcon icon={faBatteryFull} className="mood-icon" />{" "}
                {t("T358")}{" "}
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="buttons mood">
            <div className="close-btn" onClick={props.closeTrackers}>
              {t("T015")}
            </div>
          </div>
        </div>
      )}{" "}
      {openEnergy && (
        <EnergyTracker
          handleBackToMain={handleBackToMain}
          closeTrackers={props.closeTrackers}
        />
      )}
    </React.Fragment>
  );
};
export default EnergyCover;
