import React, { useContext } from "react";
import "./Footer.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBullseyeArrow,
  faMagnifyingGlass,
  faUser,
  faLifeRing,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth-context";

function Footer(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const pageid = window.location.pathname.split("/")[1];

  return (
    <div className="main_footer">
      <div id="scroll_fade_bottom"></div>
      <div id="progress_footer_container">
        <div id="progress_footer">
          <ul className="icon_footer">
            {auth.tags?.length > 0 && (
              <>
                <Link to="/for-you">
                  <li>
                    <div
                      className={
                        pageid === "for-you" ? "icon-cont active" : "icon-cont"
                      }
                    >
                      <FontAwesomeIcon icon={faHouse} className="icon" />
                    </div>
                    {t("FT001")}
                  </li>
                </Link>
                <Link to="/goals">
                  <li>
                    <div
                      className={
                        pageid === "goals" ? "icon-cont active" : "icon-cont"
                      }
                    >
                      <FontAwesomeIcon
                        icon={faBullseyeArrow}
                        className="icon"
                      />
                    </div>
                    {t("FT002")}
                  </li>
                </Link>
                <Link to="/explore">
                  <li>
                    <div
                      className={
                        pageid === "explore" ? "icon-cont active" : "icon-cont"
                      }
                    >
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="icon"
                      />
                    </div>
                    {t("FT003")}
                  </li>
                </Link>
              </>
            )}
            <Link to="/profile/settings">
              <li>
                <div
                  className={
                    pageid === "profile" ? "icon-cont active" : "icon-cont"
                  }
                >
                  <FontAwesomeIcon icon={faUser} className="icon" />
                </div>
                {t("FT004")}
              </li>
            </Link>
            <Link to="/support">
              <li>
                <div
                  className={
                    pageid === "support" ? "icon-cont active" : "icon-cont"
                  }
                >
                  <FontAwesomeIcon icon={faLifeRing} className="icon" />{" "}
                </div>
                {t("H001")}
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
