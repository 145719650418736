import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../shared/context/auth-context";

import Passcode from "../components/Passcode";

function SignupScreen5(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const handleContinue = (code) => {
    let dataObj = {
      progress: "screen5",
      code,
    };
    props.handleContinue(dataObj);
  };

  const handleReset = () => {
    let dataObj = {
      progress: "screen5",
      code: "reset",
    };
    props.handleContinue(dataObj);
  };

  return (
    <React.Fragment>
      <h1>{t("R039")}</h1>
      <p>{t("R040")}</p>
      {!auth.isLoggedIn && (
        <Passcode
          passcode={props.passcode}
          confirm
          handleContinue={handleContinue}
          handleReset={handleReset}
        />
      )}
    </React.Fragment>
  );
}
export default SignupScreen5;
