import { createSlice } from "@reduxjs/toolkit";

export const emptyChallenge = {
  background_image: null,
  daysLeft: null,
  description: null,
  end_date: null,
  isActive: null,
  leaders: [],
  participantsNumber: null,
  start_date: null,
  target: null,
  teamProgressCompleted: null,
  teamProgressRemaining: null,
  title: null,
  type: null,
  userGaveConsent: false,
  userProgressOverall: null,
  userProgressToday: null,
  users: [],
  _id: null,
};

const challengesSlice = createSlice({
  name: "challenge",
  initialState: {
    challenge: emptyChallenge,
  },
  reducers: {
    setChallenge: (state, action) => {
      if (action.payload) {
        state.challenge = action.payload.challenge;
      }
    },
    setChallengeAfterToogleConsent: (state, action) => {
      if (action.payload) {
        state.challenge = {
          ...state.challenge,
          users: action.payload.challenge.users,
          leaders: action.payload.challenge.leaders,
          userGaveConsent: action.payload.challenge.userGaveConsent,
          userPlace: action.payload.challenge.userPlace,
        };
      }
    },
  },
});

export const { setChallenge, setChallengeAfterToogleConsent } =
  challengesSlice.actions;
export default challengesSlice.reducer;
