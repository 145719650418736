import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../shared/context/auth-context";
import ContentCover from "../components/ContentCover";
import { useFetchProgrammeQuery } from "../mediaApiSlice";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { setProgramme } from "../mediaSlice";

const ProgrammeCoverWrap = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const chid = window.location.pathname.split("/")[2];

  const programme = useSelector((state) => state.media.programme);

  const {
    data: media,
    isFetching,
    refetch,
  } = useFetchProgrammeQuery(
    {
      token: auth.token,
      chid: chid,
    },
    {
      skip: !chid,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!isFetching && media) {
      if (
        media.favourite !== programme.favourite ||
        programme.chid !== chid ||
        media.progress !== programme.progress ||
        media.like !== programme.like ||
        media.dislike !== programme.dislike
      ) {
        dispatch(setProgramme(media));
      }
    }
  }, [dispatch, media, isFetching]);

  // const {
  //   data: programme,
  //   isFetching,
  //   refetch,
  // } = useFetchProgrammeQuery({
  //   token: auth.token,
  //   chid: chid,
  // });

  // useEffect(() => {
  //   refetch(); // Manually trigger a refetch when the component is mounted
  // }, [refetch]); // Add refetch to the dependency array

  // useEffect(() => {
  //   if (!isFetching && programme) {
  //     dispatch(
  //       setProgramme({
  //         _id: programme._id,
  //         completed: programme.completed,
  //         minutes_watched: programme.minutes_watched,
  //         progress: programme.progress,
  //         related_media: programme.related_media,
  //         chid: programme.chid,
  //         days: programme.days,
  //         title: programme.title,
  //         description: programme.description,
  //         label: programme.label,
  //         tags: programme.tags,
  //         signposting: programme.signposting,
  //         length: programme.length,
  //         author: programme.author,
  //         background_image: programme.background_image,
  //         thumbnail_image: programme.thumbnail_image,
  //         favourite: programme.favourite,
  //         like: programme.like,
  //         dislike: programme.dislike,
  //         chapters: programme.chapters,
  //         resources: programme.resources,
  //         active_day: programme.active_day,
  //       })
  //     );
  //   }
  // }, [dispatch, programme, isFetching]);

  return (
    <div id="cover-page">
      {isFetching ? (
        <LoadingSpinner asOverlay />
      ) : (
        <ContentCover mediaObject={programme} />
      )}
    </div>
  );
};
export default ProgrammeCoverWrap;
