import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import { convertTime } from "../../shared/util/helperfunctions";

const AuthorContainer = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="author">
      <div className="author-about">
        <div
          className="author-img"
          style={{
            backgroundImage: `url(${props.author.author_image})`,
          }}
        ></div>
        <div className="author-bio">
          <h3>
            {t("M020")} {props.author.author}
          </h3>
          <p>{props.author.author_title}</p>
          <p>{props.author.description}</p>
          <p>
            <Link to={`/authors/${props.author.author_shortcode}`}>
              {t("M020")}{" "}
              {props.author.author.split(" ").slice(0, -1).join(" ")}{" "}
              <FontAwesomeIcon icon={faArrowRight} className="icon " />
            </Link>
          </p>
        </div>
      </div>
      {props.author.content?.length > 0 && (
        <div className="library">
          <h2>{t("M073W")}</h2>
          <div className="scrollable">
            {props.author.content &&
              props.author.content.map((item, index) => (
                <div
                  className="content"
                  key={index}
                  onClick={() =>
                    navigate(
                      `/${
                        props.author.content[index].category.toLowerCase() ===
                        "masterclass"
                          ? "masterclass"
                          : props.author.content[
                              index
                            ].category.toLowerCase() === "programme"
                          ? "programme"
                          : "media"
                      }/${
                        props.author.content[index].chid
                      }/${props.author.content[index].title.replace(/ /g, "_")}`
                    )
                  }
                >
                  <div
                    className="content-img"
                    style={{
                      backgroundImage: `url(${props.author.content[index].thumbnail_image})`,
                    }}
                  ></div>
                  <div className="text">
                    <p
                      className={`text-title ${
                        props.author.content[index].length
                          ? ""
                          : "text-title-notime"
                      }`}
                    >
                      {props.author.content[index].title}
                    </p>
                    {props.author.content[index]?.length && (
                      <p>{convertTime(props.author.content[index].length)}</p>
                    )}
                    <p>{props.author.content[index].description}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default AuthorContainer;
