import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/Chapters.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCircleCheck,
  faLock,
  faTimer,
} from "@fortawesome/pro-solid-svg-icons";

import { convertTime } from "../../shared/util/helperfunctions";
import { handleSeeAll, handleSeeMore } from "../functions";

const ProgrammeChapters = (props) => {
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = useState(false);

  const handleSeeAllWrapper = () => handleSeeAll(seeMore, setSeeMore);

  const handleSeeMoreWrapper = (id, item) => handleSeeMore(id, item);

  const switchIcon = (index, progress, item) => {
    if (item.completed || progress >= 100) {
      return faCircleCheck;
    } else if (
      (progress > 0 && progress < 100) ||
      props.nowActiveIndex === index
    ) {
      return faTimer;
    } else {
      return faLock;
    }
  };

  const checkIfUnlocked = (index, progress, item) => {
    if (item.completed || progress > 0 || props.nowActiveIndex === index) {
      return (
        <button onClick={() => props.handleSetActive(item)}>{t("M030")}</button>
      );
    } else {
      return;
    }
  };

  return (
    <div className={"chapters-popup player"}>
      <div className="chapters-heading">
        <div className="title">{t("M027")}</div>

        <div className="expand">
          <div>{seeMore ? t("M028") : t("M029")}</div>
          <FontAwesomeIcon
            icon={seeMore ? faChevronCircleUp : faChevronCircleDown}
            className="icon chevron"
            onClick={handleSeeAllWrapper}
          />
        </div>
      </div>
      <div className="scrollable">
        {props.masterclass?.chapters?.length > 0 &&
          props.masterclass?.chapters?.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className="chapter"
                onClick={() => handleSeeMoreWrapper(`${index}chapter`, item)}
                id={`${index}chapter`}
              >
                <div className="top-row">
                  <div className="constant-text">
                    <div className="number prog">
                      <FontAwesomeIcon
                        icon={switchIcon(
                          index,
                          props.masterclass.progress_array[index],
                          item
                        )}
                        className="prog-icon"
                      />
                    </div>
                    <div className="title">
                      {item.title.replace(/&amp;/gi, "&")}
                    </div>
                  </div>

                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="icon chevron"
                  />
                </div>

                <div className="see-more">
                  <p className="time">{convertTime(item.length)}</p>
                  <p className="text">{item.description}</p>
                  {checkIfUnlocked(
                    index,
                    props.masterclass.progress_array[index],
                    item
                  )}
                </div>
              </div>
              {props.masterclass.progress_array && (
                <React.Fragment>
                  {item.completed === true ||
                  props.masterclass.progress_array[index] >= 99 ? (
                    <div className="chapter-progress-container">
                      <div
                        className="progress-bar"
                        style={{
                          width: "100%",
                        }}
                      ></div>
                    </div>
                  ) : props.masterclass.progress_array[index] > 0 ? (
                    <div className="chapter-progress-container">
                      <div
                        className="progress-bar"
                        style={{
                          width: props.masterclass.progress_array[index] + "%",
                        }}
                      ></div>
                    </div>
                  ) : (
                    ""
                  )}{" "}
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};
export default ProgrammeChapters;
