import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import {
  faDroplet,
  faMartiniGlass,
} from "@fortawesome/sharp-regular-svg-icons";

// import TeamChallenge from "../../components/TeamChallenge";
import WaterTracker from "./WaterTracker";
import AlcoholTracker from "./AlcoholTracker";

const HydrationTracker = (props) => {
  const { t } = useTranslation();

  const [areaPicker, setAreaPicker] = useState(true);
  const [openHydration, setOpenHydration] = useState(false);
  const [openAlcohol, setOpenAlcohol] = useState(false);

  const handleHydration = () => {
    const main = document.getElementById("snm-main");
    main?.classList.remove("slide-in");
    setTimeout(() => {
      setAreaPicker(false);
      setOpenHydration(true);
    }, 500);
  };

  const handleAlcohol = () => {
    const main = document.getElementById("snm-main");
    main?.classList.remove("slide-in");
    setTimeout(() => {
      setAreaPicker(false);
      setOpenAlcohol(true);
    }, 500);
  };

  useEffect(() => {
    if (openHydration) {
      const hydration = document.getElementById("snm-hydration");
      setTimeout(() => {
        hydration?.classList.add("slide-in");
      }, 300);
    }
  }, [openHydration]);

  useEffect(() => {
    if (areaPicker) {
      const main = document.getElementById("snm-main");
      setTimeout(() => {
        main?.classList.add("slide-in");
      }, 300);
    }
  }, [areaPicker]);

  useEffect(() => {
    if (openAlcohol) {
      const alcohol = document.getElementById("snm-alcohol");
      setTimeout(() => {
        alcohol?.classList.add("slide-in");
      }, 300);
    }
  }, [openAlcohol]);

  const handleBackToMain = () => {
    if (openHydration) {
      const hydration = document.getElementById("snm-hydration");
      hydration?.classList.remove("slide-in");
      setTimeout(() => {
        setOpenHydration(false);
        setAreaPicker(true);
      }, 500);
    }

    if (openAlcohol) {
      const alcohol = document.getElementById("snm-alcohol");
      alcohol?.classList.remove("slide-in");
      setTimeout(() => {
        setOpenAlcohol(false);
        setAreaPicker(true);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      {areaPicker && (
        <div id="snm-main" className="tracker-container">
          <div className="ind-tracker mood">
            <div className="title">{t("T134")}</div>
            <div className="mood-area" onClick={handleHydration}>
              <div>
                <FontAwesomeIcon icon={faDroplet} className="mood-icon" />{" "}
                {t("T135")}
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="mood-area" onClick={handleAlcohol}>
              <div>
                <FontAwesomeIcon icon={faMartiniGlass} className="mood-icon" />{" "}
                {t("T136")}
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="buttons mood">
            <div className="close-btn" onClick={props.closeTrackers}>
              {t("T015")}
            </div>
          </div>
        </div>
      )}{" "}
      {openHydration && (
        <WaterTracker
          handleBackToMain={handleBackToMain}
          closeTrackers={props.closeTrackers}
        />
      )}
      {openAlcohol && (
        <AlcoholTracker
          handleBackToMain={handleBackToMain}
          closeTrackers={props.closeTrackers}
        />
      )}
    </React.Fragment>
  );
};
export default HydrationTracker;
