import { useRef, useEffect } from "react";

function useDocumentTitle(title, description, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const defaultDescription = useRef(
    document
      .querySelector('meta[property="og:description"]')
      .getAttribute("content")
  );

  useEffect(() => {
    document.title = title;
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", description);
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute("content", defaultDescription.current);
      }
    },
    []
  );
}

export default useDocumentTitle;
