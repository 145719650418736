import React, { useState, useEffect } from "react";
import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function Radio(props) {
  const [skipActive, setSkipActive] = useState("");

  function toggleSkipActive() {
    if (skipActive === "active") {
      setSkipActive("");
      props.onSetValue({ id: props.questionid, value: "" });
    } else {
      setSkipActive("active");
      props.onSetValue({ id: props.questionid, value: "skipped" });
    }
  }

  function saveValue(event) {
    props.onSetValue({ id: props.questionid, value: event.target.value });
    setIsValid(true);
    if (event.target.value !== "skipped") {
      setSkipActive("");
    }
  }

  const [blockOpacity, setBlockOpacity] = useState(0);

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      var value = props.assessmentValues[props.questionid];
      if (value === "skipped") {
        setSkipActive("active");
      }
    }

    setTimeout(function () {
      setBlockOpacity(1);
    }, 100);
  });

  const [isValid, setIsValid] = useState(false);

  return (
    <div
      id={props.questionid}
      className={"req question_block" + props.width}
      style={{ opacity: props.fadeIn ? blockOpacity : "1" }}
    >
      <h3>{props.questionTitle}</h3>
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      {props.questionTitleSuffix ? (
        <span className="titleSuffix">{props.questionTitleSuffix}</span>
      ) : null}
      {props.skipText ? (
        <span
          className={"suffixLabel " + skipActive}
          onClick={toggleSkipActive}
        >
          {props.skipText}
        </span>
      ) : null}
      <div className="radio answer_block fs-exclude">
        {props.options.map((option) => {
          let checked;
          {
            props.assessmentValues[props.questionid] === option.id
              ? (checked = "checked")
              : (checked = "");
          }
          return (
            <div className="radioItem" key={option.id}>
              <input
                id={props.questionid + "_" + option.id}
                type="radio"
                name={props.questionid}
                value={option.id}
                onChange={saveValue}
                readOnly
                checked={checked}
              />
              <label htmlFor={props.questionid + "_" + option.id}>
                {option.title}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Radio;
