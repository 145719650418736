import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles/ColumnGrid.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowPointer,
  faBolt,
  faCircleX,
  faShieldCheck,
} from "@fortawesome/pro-solid-svg-icons";

function ColumnGridEmpty(props) {
  const { t } = useTranslation();

  return (
    <div className={"chart-container " + props.width}>
      <div className="title-container">
        <div className="title">{props.title}</div>
      </div>
      <div className="grid-container">
        <div className="column fs-exclude">
          <div className="row">
            <div className="row-heading">{t("D015")}</div>
            <div>{t("D031")}</div>
          </div>

          <div className="row">
            <div className="row-heading empty ">{t("D016")}</div>
            <div className="empty">
              <span className="icon_circle">
                <FontAwesomeIcon icon={faArrowPointer} className="icon" />
              </span>
              {t("DW025")}
            </div>
          </div>

          <div className="row">
            <div className="row-heading empty">{t("D017")}</div>

            <div className="empty">
              <span className="icon_circle">
                <FontAwesomeIcon icon={faBolt} className="icon" />
              </span>
              {t("D032")}
            </div>
          </div>
          <div className="row">
            <div className="row-heading empty">{t("D018")}</div>

            <div className="empty">
              <span className="icon_circle ">
                <FontAwesomeIcon icon={faCircleX} className="icon" />
              </span>
              {t("D032")}
            </div>
          </div>

          <div className="row">
            <div className="row-heading empty">{t("D019")}</div>

            <div className="empty">
              <span className="icon_circle">
                <FontAwesomeIcon icon={faShieldCheck} className="icon" />
              </span>
              {t("D032")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColumnGridEmpty;
