import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-regular-svg-icons";

const Warning = () => {
  const { t } = useTranslation();
  return (
    <div className="warning">
      <FontAwesomeIcon icon={faWarning} className="warning-icon" />
      {t("T150")}
    </div>
  );
};

export default Warning;
