import { useCallback, useContext, useEffect, useState } from "react";
import { faBolt, faCrown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import ScrollingNumber from "../../../shared/components/styledComponents/scrollingNumber/ScrollingNumber";
import { useTranslation } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import WhitePopUpNoTitle from "../../../shared/modal/whitePopUp/WhitePopUpNoTitle";

const StatsRightMenu = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [userStats, setUserStats] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState("");

  useEffect(() => {
    const getStats = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile`
        );
        if (responseData) {
          setUserStats({
            currentStreak: responseData.current_days_streak,
            bestStreak: responseData.best_days_streak,
            platformMinutes: responseData.platformMinutes,
            mindfulMinutes: responseData.mindfulnessMinutes,
            activeMinutes: responseData.activeMinutes,
          });
        }
      } catch (err) {}
    };
    if (!userStats && auth.userId) {
      getStats();
    }
  }, [auth.userId, userStats]);

  const openModal = () => {
    setModalIsOpen(true);
    setModalTextContent({ title: t("S047"), text: [t("S130")] });
  };

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const renderScrollingNumber = useCallback((number) => {
    // Format the number with commas
    if (number < 0 || number === null) number = 0;

    const formattedNumber = number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Split the formatted number into individual characters (digits and commas)
    const characters = formattedNumber.split("");

    return (
      <div className="profile-name left nm">
        {characters.map((char, index) =>
          char === "," ? (
            <span key={index}>,</span>
          ) : (
            <ScrollingNumber
              key={index}
              number={parseInt(char, 10)}
              duration={10000}
              direction={index % 2 === 0 ? "up" : "down"}
            />
          )
        )}
      </div>
    );
  });

  return (
    <>
      {modalIsOpen && (
        <WhitePopUpNoTitle
          popupContents={modalTextContent}
          ctaBtn={t("T015")}
          handleSubmit={modalClickHandler}
        />
      )}
      {userStats && (
        <>
          {(props.activeSubMenu.id === 61 || props.hide) && (
            <div className="rm-box fs-exclude">
              <div className="profile-name left">{t("S043")}</div>
              <div className="streaks-cont">
                <div className="streak-clmn">
                  <div className="streak-name">{t("S044")}</div>
                  <div className="streak-circle blue">
                    <FontAwesomeIcon icon={faBolt} className="icon" />
                    <div className="profile-name nm">
                      {userStats.currentStreak}
                    </div>
                    <div className="bold-sm-txt">{t("M070")}</div>
                  </div>
                </div>
                <div className="streak-clmn">
                  <div className="streak-name">{t("S045")}</div>
                  <div className="streak-circle green">
                    <FontAwesomeIcon icon={faCrown} className="icon" />
                    <div className="profile-name nm">
                      {userStats.bestStreak}
                    </div>
                    <div className="bold-sm-txt">{t("M070")}</div>
                  </div>
                </div>
              </div>
              <p className="cm">{t("S046")}</p>
            </div>
          )}
          {(props.activeSubMenu.id === 62 || props.hide) && (
            <div className={`rm-box ${props.hide ? "mt" : ""} fs-exclude`}>
              <div className="profile-name wtf">
                {t("S047")}
                <div className="wtf">
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="wtf icon"
                    onClick={openModal}
                  />
                </div>
              </div>
              <div className="streaks-cont">
                <div className="streak-clmn-full">
                  <div className="streak-line">
                    <div className="streak-name">{t("S048")}</div>
                    {renderScrollingNumber(userStats.platformMinutes)}
                  </div>
                  <div className="streak-line">
                    <div className="streak-name">{t("S049")}</div>
                    {renderScrollingNumber(userStats.mindfulMinutes)}
                  </div>
                  <div className="streak-line">
                    <div className="streak-name">{t("S050")}</div>
                    {renderScrollingNumber(userStats.activeMinutes)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default StatsRightMenu;
