import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles/WaterIntake.css";

import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import waterDrop0 from "../../assets/icons/water-drop-blue-0.png";
import waterDrop1 from "../../assets/icons/water-drop-blue-1.png";
import waterDrop2 from "../../assets/icons/water-drop-blue-2.png";
import waterDrop3 from "../../assets/icons/water-drop-blue-3.png";
import waterDrop4 from "../../assets/icons/water-drop-blue-4.png";
import waterDrop5 from "../../assets/icons/water-drop-blue-5.png";
import waterDrop6 from "../../assets/icons/water-drop-blue-6.png";
import waterDrop7 from "../../assets/icons/water-drop-blue-7.png";
import waterDrop8 from "../../assets/icons/water-drop-blue-8.png";
import waterDrop9 from "../../assets/icons/water-drop-blue-9.png";
import waterDrop10 from "../../assets/icons/water-drop-blue-10.png";
import waterDrop11 from "../../assets/icons/water-drop-blue-11.png";

import glassBlue from "../../assets/icons/glass-blue.png";
import glassMuted from "../../assets/icons/glass-muted.png";

const waterDropImages = [
  waterDrop0,
  waterDrop1,
  waterDrop2,
  waterDrop3,
  waterDrop4,
  waterDrop5,
  waterDrop6,
  waterDrop7,
  waterDrop8,
  waterDrop9,
  waterDrop10,
  waterDrop11,
];

const glassImages = [glassBlue, glassMuted];

const WaterIntake = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (props.data?.[props.questionid]) {
      setValue(props.data[props.questionid]);
    }
  }, [props.data]);

  const plus = () => {
    let newValue = value;
    if (newValue < 11) {
      newValue++;
      setValue(newValue);
      props.saveResponse("data", {
        id: props.questionid,
        value: newValue,
      });
    }
  };

  const minus = () => {
    let newValue = value;
    if (newValue > 0) {
      newValue--;
      setValue(newValue);
      props.saveResponse("data", {
        id: props.questionid,
        value: newValue,
      });
    }
  };

  return (
    <div>
      <div className="tracker-cont">
        <div className="water minus">
          {/* change to grey button */}
          <div onClick={minus} className="button">
            <FontAwesomeIcon icon={faMinus} />
          </div>

          <div className="value fs-mask">{value}</div>
          <div className="measurment">{t("T164WA")}</div>
        </div>

        <img
          className="water-drop fs-exclude"
          src={waterDropImages[value]}
          alt="large drop filling with water"
        />

        <div className="water plus">
          {/* change to grey button */}
          <div className="button" onClick={plus}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <div className="value fs-mask">
            {value * 0.25} {t("T164WC")}
          </div>
          <div className="measurment">{t("T164WB")}</div>
        </div>
      </div>
      <div className="glass-cont fs-exclude">
        {Array.apply(null, Array(11)).map((y, i) => {
          if (i < value) {
            return (
              <img
                key={i}
                className="glass"
                src={glassImages[0]}
                alt="full glass"
              />
            );
          } else {
            return (
              <img
                key={i}
                className="glass"
                src={glassImages[1]}
                alt="empty glass"
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default WaterIntake;
