import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../shared/context/auth-context";
import ContentCover from "../components/ContentCover";
import { useFetchMasterclassQuery } from "../mediaApiSlice";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { setMasterclass } from "../mediaSlice";

const MasterclassCoverWrap = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const chid = window.location.pathname.split("/")[2];

  const masterclass = useSelector((state) => state.media.masterclass);

  const {
    data: media,
    isFetching,
    refetch,
  } = useFetchMasterclassQuery(
    {
      token: auth.token,
      chid: chid,
    },
    {
      skip: !chid,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!isFetching && media) {
      if (
        media.favourite !== masterclass.favourite ||
        masterclass.chid !== chid ||
        media.progress !== masterclass.progress ||
        media.like !== masterclass.like ||
        media.dislike !== masterclass.dislike
      ) {
        dispatch(setMasterclass(media));
      }
    }
  }, [dispatch, media, isFetching]);
  // const {
  //   data: masterclass,
  //   isFetching,
  //   refetch,
  // } = useFetchMasterclassQuery(
  //   {
  //     token: auth.token,
  //     chid: chid,
  //   },
  //   {
  //     skip: !chid,
  //   }
  // );

  // useEffect(() => {
  //   refetch(); // Manually trigger a refetch when the component is mounted
  // }, [refetch]); // Add refetch to the dependency array

  // useEffect(() => {
  //   if (isFetching || !masterclass) {
  //     return;
  //   }
  //   dispatch(
  //     setMasterclass({
  //       _id: masterclass._id,
  //       completed: masterclass.completed,
  //       minutes_watched: masterclass.minutes_watched,
  //       progress: masterclass.progress,
  //       related_media: masterclass.related_media,
  //       chid: masterclass.chid,
  //       title: masterclass.title,
  //       description: masterclass.description,
  //       label: masterclass.label,
  //       tags: masterclass.tags,
  //       signposting: masterclass.signposting,
  //       length: masterclass.length,
  //       author: masterclass.author,
  //       background_image: masterclass.background_image,
  //       thumbnail_image: masterclass.thumbnail_image,
  //       favourite: masterclass.favourite,
  //       like: masterclass.like,
  //       dislike: masterclass.dislike,
  //       chapters: masterclass.chapters,
  //       resources: masterclass.resources,
  //       progress_array: masterclass.progress_array,
  //     })
  //   );
  // }, [dispatch, isFetching, masterclass]);

  return (
    <div id="cover-page">
      {isFetching ? (
        <LoadingSpinner asOverlay />
      ) : (
        <ContentCover mediaObject={masterclass} />
      )}
    </div>
  );
};
export default MasterclassCoverWrap;
