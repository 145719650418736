import React from "react";
import "../styles/componentsStyles/TopLeaders.css";
import SingleLeader from "./SingleLeader";

const TopLeaders = ({
  dataToMap,
  challengeType,
  leadersNumber,
  buttonText,
  isActive,
  handleButtonClick,
}) => {
  return (
    <div className="leaders">
      <div className="leaders-heads">
        {dataToMap.map((leader, index) => (
          <SingleLeader
            key={index}
            leader={leader}
            index={index}
            leadersNumber={leadersNumber}
            challengeType={challengeType}
          />
        ))}
      </div>
      {isActive && (
        <button className="feature-btn topLeaders" onClick={handleButtonClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default TopLeaders;
