import { useTranslation } from "react-i18next";
import "../styles/RecipeStyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

const Recipe = (props) => {
  const { t } = useTranslation();

  return (
    <div className="recipe">
      <div className="container ingredients">
        <div className="title">
          <h3>{t("M036")}</h3>
          <h4>
            {t("M043")} {props.recipe.serves}
          </h4>
        </div>
        <div className="body">
          <div className="fade"></div>
          <div className="list">
            {props.recipe.ingredients?.map((item, index) => {
              return (
                <div className="item" key={index + "ingr"}>
                  <FontAwesomeIcon icon={faCheckCircle} className="check" />
                  <div className="step">{item}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container method">
        <div className="title">
          <h3>{t("M041")}</h3>
          <h4> </h4>
        </div>
        <div className="body">
          <div className="fade"></div>
          <div className="list">
            {props.recipe.method?.map((item, index) => {
              return (
                <div className="item" key={index + "method"}>
                  <div className="number">{index + 1}</div>
                  <div className="step">{item.replace(/^\d+\.\s/, "")}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container nutrition">
        <div className="title">
          <h3>Nutrition</h3>
          <h4>{t("M042")}</h4>
        </div>
        <div className="body">
          <div className="nutri-info">
            <h4 className="kcal">{t("M044")}</h4>
            <div>{props.recipe.kcal}</div>
          </div>
          <div className="nutri-info">
            <h4 className="carbs">{t("M045")}</h4>
            <div>{props.recipe.carbs}g</div>
          </div>
          <div className="nutri-info">
            <h4 className="fat">{t("M046")}</h4>
            <div>{props.recipe.fat}g</div>
          </div>
          <div className="nutri-info">
            <h4 className="protein">{t("M047")}</h4>
            <div>{props.recipe.protein}g</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipe;
