import React from "react";



function GadHeaderRow(props) {


  return (
        <div className="gad-header-row">
            <div className="gad-header-spacer"></div>
            <div className="gad_option_container">
            {props.headerText.map((heading) => {
                        return <div className="gad-header" key={heading.key}><span>{heading.value}</span></div> 
             })
            }
            </div>
        </div>
  );}

export default GadHeaderRow;