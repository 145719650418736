import { useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/WorkoutStyles.css";

const Workout = (props) => {
  const { t } = useTranslation();
  let equipment;

  if (props.equipment?.length > 0)
    equipment = [...props.equipment, ...Array(8 - props.equipment.length)];

  return (
    <div
      className={
        props.equipment?.length > 0 ? "workout" : "workout no-equipment"
      }
    >
      {props.equipment?.length > 0 && (
        <div className="container equipment">
          <div className="title">
            <h3>{t("M054")}</h3>
          </div>
          <div className="body">
            <div className="list">
              <div className="first column">
                {equipment.slice(0, 4).map((item, index) =>
                  item ? (
                    <div className="item" key={index}>
                      <div className="number">{index + 1}</div>
                      {item}
                    </div>
                  ) : (
                    <div className="item"></div>
                  )
                )}
              </div>
              <div className="second column">
                {equipment.slice(4, 8).map((item, index) =>
                  item ? (
                    <div className="item" key={index + 4}>
                      <div className="number">{index + 5}</div>
                      {item}
                    </div>
                  ) : (
                    <div className="item"></div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={
          props.equipment?.length > 0
            ? "container exercises"
            : "container exercises stretched"
        }
      >
        <div className="title">
          <h3>{t("M053")}</h3>
        </div>
        <div className="body">
          <div className="list">
            {props.exercises.map((item, index) => {
              return (
                <div className="item" key={item}>
                  <div className="number">{index + 1} </div>
                  <div className="step">{item.replaceAll("&amp;", "&")}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workout;
