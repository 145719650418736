import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/ProgrammeCompleted.css";

import completeImg from "../images/complete.png";
import Confetti from "./Confetti";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faAward } from "@fortawesome/pro-thin-svg-icons";
import WhiteButton from "../../shared/components/styledComponents/whiteButton/WhiteButton";

const ProgrammeCompleted = (props) => {
  const { t } = useTranslation();
  const [confettiActive, setConfettiActive] = useState(true);

  const textArr = t("M073").split("!");
  textArr[0] = textArr[0] + "!";

  useEffect(() => {
    setConfettiActive(true);
    setTimeout(() => {
      setConfettiActive(false);
    }, 10000);
  }, []);

  return (
    <div className="popup-overlay">
      {confettiActive && <Confetti isActive={confettiActive} />}
      <div className="dark-container">
        <FontAwesomeIcon icon={faAward} className="icon award" />
        <img src={completeImg} alt="`Complete` in large colourful font" />

        {textArr.map((item, index) => (
          <h1 key={index}>{item}</h1>
        ))}

        <WhiteButton onClick={props.closeProgrammeCompleted}>
          {t("M012")} <FontAwesomeIcon icon={faArrowRight} className="icon" />{" "}
        </WhiteButton>
      </div>
    </div>
  );
};

export default ProgrammeCompleted;
