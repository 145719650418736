import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faWind } from "@fortawesome/pro-light-svg-icons";

import SmokingTracker from "./SmokingTracker";

const PhysicalWBCover = (props) => {
  const { t } = useTranslation();

  const [areaPicker, setAreaPicker] = useState(true);
  const [openSmoking, setOpenSmoking] = useState(false);

  const handleSmoking = () => {
    const main = document.getElementById("snm-main");
    main?.classList.remove("slide-in");
    setTimeout(() => {
      setAreaPicker(false);
      setOpenSmoking(true);
    }, 500);
  };

  useEffect(() => {
    if (openSmoking) {
      const smoking = document.getElementById("snm-smoking");
      setTimeout(() => {
        smoking?.classList.add("slide-in");
      }, 300);
    }
  }, [openSmoking]);

  useEffect(() => {
    if (areaPicker) {
      const main = document.getElementById("snm-main");
      setTimeout(() => {
        main?.classList.add("slide-in");
      }, 300);
    }
  }, [areaPicker]);

  const handleBackToMain = () => {
    if (openSmoking) {
      const smoking = document.getElementById("snm-smoking");
      smoking?.classList.remove("slide-in");
      setTimeout(() => {
        setOpenSmoking(false);
        setAreaPicker(true);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      {areaPicker && (
        <div id="snm-main" className="tracker-container">
          <div className="ind-tracker mood">
            <div className="title">{t("T134")}</div>
            <div className="mood-area" onClick={handleSmoking}>
              <div>
                <FontAwesomeIcon icon={faWind} className="mood-icon" />{" "}
                {t("T355")}{" "}
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="buttons mood">
            <div className="close-btn" onClick={props.closeTrackers}>
              {t("T015")}
            </div>
          </div>
        </div>
      )}{" "}
      {openSmoking && (
        <SmokingTracker
          handleBackToMain={handleBackToMain}
          closeTrackers={props.closeTrackers}
        />
      )}
    </React.Fragment>
  );
};
export default PhysicalWBCover;
