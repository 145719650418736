import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../shared/context/auth-context";
import ContentCover from "../components/ContentCover";
import { useFetchSingleMediaQuery } from "../mediaApiSlice";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { setSingleMedia } from "../mediaSlice";

const CoverPage = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleMedia = useSelector((state) => state.media.singleMedia);

  // const mediaCHID = useSelector((state) => state.media.singleMedia.chid);
  // const progress = useSelector((state) => state.media.singleMedia.progress);
  // const favourites = useSelector((state) => state.media.singleMedia.favourite);

  const {
    data: media,
    isFetching,
    refetch,
  } = useFetchSingleMediaQuery(
    {
      token: auth?.token,
      url: `/media/${location.pathname.split("/")[2]}`,
    },
    {
      skip:
        !location.pathname.split("/")[2] ||
        location.pathname.split("/")[2]?.startsWith("TA"),
    }
  );

  useEffect(() => {
    if (!isFetching && media) {
      if (
        media.favourite !== singleMedia.favourite ||
        singleMedia.chid !== location.pathname.split("/")[2] ||
        media.progress !== singleMedia.progress ||
        media.like !== singleMedia.like ||
        media.dislike !== singleMedia.dislike
      )
        dispatch(setSingleMedia(media));
    }
  }, [dispatch, media, isFetching]);

  useEffect(() => {
    if (
      location.pathname.split("/")[2]?.startsWith("TA") &&
      (!singleMedia || singleMedia.chid !== location.pathname.split("/")[2])
    ) {
      navigate("/for-you");
    } else if (
      location.pathname.split("/")[2]?.startsWith("TA") &&
      singleMedia?.chid === location.pathname.split("/")[2]
    ) {
      return;
    } else {
      refetch();
    }
  }, []);

  // const fetchResult = useFetchSingleMediaQuery(
  //   {
  //     token: auth?.token,
  //     url: location.pathname.split("/")[2]?.startsWith("TA")
  //       ? `/take-actions/${location.pathname.split("/")[2]}`
  //       : `/media/${location.pathname.split("/")[2]}`,
  //   },
  //   {
  //     skip: !location.pathname.split("/")[2],
  //   }
  // );

  // const { data: singleMedia, isFetching } =
  //   mediaCHID !== location.pathname.split("/")[2]
  //     ? fetchResult
  //     : { data: null, isFetching: false };

  // useEffect(() => {
  //   if (!isFetching && singleMedia) {
  //     if (
  //       mediaCHID !== location.pathname.split("/")[2] ||
  //       progress !== singleMedia.progress ||
  //       favourites !== singleMedia.favourite
  //     ) {
  //       dispatch(
  //         setSingleMedia(singleMedia)
  //         //   {
  //         //   _id: singleMedia._id,
  //         //   regions: singleMedia.regions,
  //         //   completed: singleMedia.completed,
  //         //   favourite: singleMedia.favourite,
  //         //   minutes_watched: singleMedia.minutes_watched,
  //         //   progress: singleMedia.progress,
  //         //   related_media: singleMedia.related_media,
  //         //   video_cover_image: singleMedia.video_cover_image,
  //         //   chid: singleMedia.chid,
  //         //   title: singleMedia.title,
  //         //   description: singleMedia.description,

  //         //   category: singleMedia.category, // recipe || workout || session || service || take-action || soundscape || masterclass (Chapters)
  //         //   type: singleMedia.type, // audio || video || null
  //         //   label: singleMedia.label,

  //         //   tags: singleMedia.tags,
  //         //   transcript: singleMedia.transcript,
  //         //   signposting: singleMedia.signposting,
  //         //   waiver: singleMedia.waiver,

  //         //   url: singleMedia.url,
  //         //   resources: singleMedia.resources,
  //         //   length: singleMedia.length,
  //         //   author: singleMedia.author,

  //         //   //Workouts/Recipes
  //         //   difficulty: singleMedia.difficulty,

  //         //   //Workouts
  //         //   exercises: singleMedia.exercises,
  //         //   equipment: singleMedia.equipment,

  //         //   //Recipes
  //         //   serves: singleMedia.serves,
  //         //   ingredients: singleMedia.ingredients,
  //         //   method: singleMedia.method,
  //         //   kcal: singleMedia.kcal,
  //         //   carbs: singleMedia.carbs,
  //         //   fat: singleMedia.fat,
  //         //   protein: singleMedia.protein,

  //         //   //Service/Take Action
  //         //   action_url: singleMedia.action_url,
  //         //   action_string: singleMedia.action_string,

  //         //   background_image: singleMedia.background_image,
  //         //   thumbnail_image: singleMedia.thumbnail_image,

  //         //   like: singleMedia.like,
  //         //   dislike: singleMedia.dislike,
  //         // })
  //       );
  //     }
  //   }
  // }, [dispatch, singleMedia]);

  return (
    <div id="cover-page">
      {isFetching ? (
        <LoadingSpinner asOverlay />
      ) : (
        <ContentCover mediaObject={singleMedia} />
      )}
    </div>
  );
};
export default CoverPage;
