import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../shared/context/auth-context";
import { useFetchMasterclassQuery } from "../mediaApiSlice";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { setMasterclass } from "../mediaSlice";
import MasterclassPlayer from "../components/MasterclassPlayer";

const MasterclassPlayerWrap = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const chid = window.location.pathname.split("/")[2];

  const media = useSelector((state) => state.media.masterclass);

  const fetchResult = useFetchMasterclassQuery(
    {
      token: auth.token,
      chid: chid,
    },
    {
      skip: !chid,
    }
  );

  const { data: masterclass, isLoading } =
    media.chid !== chid ? fetchResult : { data: null, isLoading: false };

  useEffect(() => {
    if (!isLoading && masterclass) {
      dispatch(
        setMasterclass({
          _id: masterclass._id,
          completed: masterclass.completed,
          minutes_watched: masterclass.minutes_watched,
          progress: masterclass.progress,
          related_media: masterclass.related_media,
          chid: masterclass.chid,
          title: masterclass.title,
          description: masterclass.description,
          label: masterclass.label,
          tags: masterclass.tags,
          signposting: masterclass.signposting,
          length: masterclass.length,
          author: masterclass.author,
          background_image: masterclass.background_image,
          thumbnail_image: masterclass.thumbnail_image,
          favourite: masterclass.favourite,
          like: masterclass.like,
          dislike: masterclass.dislike,
          chapters: masterclass.chapters,
          resources: masterclass.resources,
          progress_array: masterclass.progress_array,
        })
      );
    }
  }, [dispatch, masterclass]);

  return (
    <div id="cover-page">
      {isLoading ? <LoadingSpinner asOverlay /> : <MasterclassPlayer />}
    </div>
  );
};
export default MasterclassPlayerWrap;
