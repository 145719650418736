import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "./languages/en.json";
import translationDE from "./languages/de.json";
import translationSV from "./languages/sv.json";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  sv: {
    translation: translationSV,
  },
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("i18nextLng") || "en", // Use stored language or default to English
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
