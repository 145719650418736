import { useState } from "react";
import { useTranslation } from "react-i18next";

import { faCircleXmark } from "@fortawesome/pro-thin-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";

const SharePopUp = (props) => {
  const { t } = useTranslation();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopiedToClipboard(true);
  };

  const handleShareOpen = () => {
    setCopiedToClipboard(false);
    props.setShareOpen(false);
  };
  return (
    <div className="rate-overlay share">
      <div className="share-container">
        <div className="share-heading">
          <div>{t("M006")}</div>
          <FontAwesomeIcon
            onClick={handleShareOpen}
            icon={faCircleXmark}
            className="icon "
          />
        </div>
        <div className="share-body">
          <div className="title">{t("M006W")}</div>
          <div className={copiedToClipboard ? "copied visible" : "copied"}>
            {t("M072W")}
            <FontAwesomeIcon
              onClick={handleShareOpen}
              icon={faCheck}
              className="icon "
            />
          </div>
          <div className="url">
            <div className="url-address">{window.location.href}</div>
            <FontAwesomeIcon
              onClick={handleCopyToClipboard}
              icon={faCopy}
              className="icon "
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SharePopUp;
