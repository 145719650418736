import React, { useState, useReducer, useEffect, useContext } from "react";
import Backdrop from "../menu/Backdrop";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth-context";
import Ellipse from "./images/Ellipse.png";

function Modal(props) {
  const [stage, setStage] = useState(1);
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const onboardingReducer = (state, action) => {
    switch (action.section) {
      case 2:
        return {
          ...state,
          title: t("O011"),
          text: `<p>${t("O012")}</p>`,
          section: 2,
          buttonText: t("O003"),
        };
      case 3:
        return {
          ...state,
          title: t("O013"),
          text: `<p>${t("O014")}</p>`,
          section: 3,
          buttonText: t("O003"),
        };
      case 4:
        return {
          ...state,
          title: t("O015"),
          text: `<p>${t("O016")}</p>`,
          section: 4,
          buttonText: t("O017"),
        };
      default:
        return state;
    }
  };

  const [pageOptions, dispatch] = useReducer(onboardingReducer, {
    title: `${t("O019")} ${auth.fname}!`,
    text: `<p>${t("O018")}</p>`,
    section: 1,
    buttonText: t("O003"),
  });

  function modalClickHandler() {
    let newstage = stage;
    newstage++;
    if (newstage <= 4) {
      setStage(newstage);
      dispatch({
        section: newstage,
      });
    } else {
      props.modalClickHandler();
    }
  }

  function createMarkup() {
    return { __html: pageOptions.text };
  }

  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);
  useEffect(() => {
    setProgress2(100);
    setTimeout(function () {
      setGeneratorTitle(t("O021"));
    }, 2500);
    setTimeout(function () {
      setGeneratorTitle(t("O022"));
    }, 5000);
    setTimeout(function () {
      setGeneratorTitle(t("O023"));
    }, 7500);
    setTimeout(function () {
      setGeneratorShow(false);
    }, 11000);
    setTimeout(function () {
      setGeneratorClass("close");
    }, 10500);
    for (var i = 0; i < 20; i++) {
      let time = (i + 1) * 500;
      setTimeout(function () {
        setProgress(time / 100);
      }, time);
    }
  }, []);
  const [generatorTitle, setGeneratorTitle] = useState(t("O020"));
  const [generatorShow, setGeneratorShow] = useState(true);
  const [generatorClass, setGeneratorClass] = useState("");

  return (
    <Backdrop
      open={`${props.modalIsOpen ? "open" : "closed"}`}
      onBackdropClose={modalClickHandler}
    >
      {generatorShow && (
        <div id="report_generator" className={generatorClass}>
          <div id="report_generator_inner">
            <h2>{generatorTitle}</h2>
            <div id="report_generator_container">
              <div
                id="report_generator_content"
                style={{ width: progress2 + "%" }}
              ></div>
            </div>
            <p>
              {progress}% {t("A124")}
            </p>
          </div>
        </div>
      )}
      <div
        id="modal-container-onboarding"
        className={` ${props.modalIsOpen ? "open" : "closed"}`}
      >
        <div
          id={"offboarding_image_" + pageOptions.section}
          className="left_side_onboarding"
        ></div>
        {/* {pageOptions.section === 1 && (
          <div
            className="name-circle"
            style={{ backgroundImage: `url(${Ellipse})` }}
          >
            {auth.fname.charAt(0)}
          </div>
        )} */}
        <div className="right_side_onboarding">
          <h3>{t("O019")}</h3>
          <h1>{pageOptions.title}</h1>
          <div dangerouslySetInnerHTML={createMarkup()} />
          <div id="section_progress" className="foryou">
            <div
              className={
                pageOptions.section === 1 ? "section_bar active" : "section_bar"
              }
            ></div>
            <div
              className={
                pageOptions.section === 2 ? "section_bar active" : "section_bar"
              }
            ></div>
            <div
              className={
                pageOptions.section === 3 ? "section_bar active" : "section_bar"
              }
            ></div>
            <div
              className={
                pageOptions.section === 4 ? "section_bar active" : "section_bar"
              }
            ></div>
          </div>
          <button onClick={modalClickHandler}>
            {pageOptions.buttonText}{" "}
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
          </button>
        </div>
      </div>
    </Backdrop>
  );
}
export default Modal;
