import "./WhitePopUpStyles.css";
import PinkButton1 from "../../components/styledComponents/pinkButton1/PinkButton1";
import DarkButton from "../../components/styledComponents/darkButton/DarkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifiExclamation } from "@fortawesome/pro-regular-svg-icons";

const WhitePopUpCTA = ({ popupContents, offline }) => {
  if (!popupContents) return null;

  const { title, textArr, buttons } = popupContents;

  return (
    <div className={offline ? "popup-overlay offline" : "popup-overlay"}>
      <div className="popup-container results">
        <div className="popup-title">{title}</div>
        <div className="popup-body ">
          <div className="popup-text-container center">
            {offline && (
              <FontAwesomeIcon
                icon={faWifiExclamation}
                className="icon-offline"
              />
            )}
            {textArr.map((item, index) => {
              if (typeof item === "string") {
                return (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item.replace(/\n/g, "<br />"),
                    }}
                  ></div>
                );
              } else {
                return (
                  <div key={index} className={item.className}>
                    {item.text}
                  </div>
                );
              }
            })}
          </div>
        </div>
        {buttons && (buttons[0].text || buttons[1].text) && (
          <div className="popup-cta">
            {buttons[0].text && (
              <DarkButton onClick={buttons[0].onClick}>
                {buttons[0].text}
              </DarkButton>
            )}
            {buttons[1].text && (
              <PinkButton1 onClick={buttons[1].onClick}>
                {buttons[1].text}
              </PinkButton1>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WhitePopUpCTA;
