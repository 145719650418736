import React from "react";
import { useTranslation } from "react-i18next";

import DotRadio from "../questions/dotRadio/DotRadio";
import GadHeaderRow from "../questions/dotRadio/GadHeaderRow";

function MentalHealth4(props) {
  const { t } = useTranslation();
  const headerText = [
    { key: 0, value: t("MH020") },
    { key: 1, value: t("MH021") },
    { key: 2, value: t("MH022") },
    { key: 3, value: t("MH023") },
  ];

  return (
    <div className={"question_container"} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <div className={"question_block_wide gad"}>
        <h3>{props.questionTitle}</h3>
        <div className="answer_block">
          <GadHeaderRow headerText={headerText} />
          <DotRadio
            questionid="little_interest"
            range="4"
            width=""
            questionTitle={t("MH024")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="feeling_down"
            range="4"
            width=""
            questionTitle={t("MH025")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="trouble_falling_asleep"
            range="4"
            width=""
            questionTitle={t("MH026")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="feeling_tired"
            range="4"
            width=""
            questionTitle={t("MH027")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="poor_appetite"
            range="4"
            width=""
            questionTitle={t("MH028")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="feeling_bad"
            range="4"
            width=""
            questionTitle={t("MH029")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="trouble_concentrating"
            range="4"
            width=""
            questionTitle={t("MH030")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="slow"
            range="4"
            width=""
            questionTitle={t("MH031")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
          />
          <DotRadio
            questionid="self_harm"
            range="4"
            width=""
            questionTitle={t("MH032")}
            type="gad"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
            onOpenModal={props.onOpenModal}
            regionContent={props.regionContent}
          />
        </div>
      </div>
    </div>
  );
}

export default MentalHealth4;
