import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ActivitySlider from "../questions/activitySlider/ActivitySlider";
import DotRadio from "../questions/dotRadio/DotRadio";
import CheckboxRadio from "../questions/checkboxRadio/CheckboxRadio";
import HourSelect from "../questions/hourSelect/HourSelect";

function PhysicalActivity1(props) {
  const { t } = useTranslation();
  const sittingBreaksOptions = [
    { id: "30mins", title: t("PA001") },
    { id: "1hour", title: t("PA002") },
    { id: "2hours", title: t("PA003") },
    { id: "3hours", title: t("PA004") },
  ];
  const [showBreaks, setShowBreaks] = useState(false);

  useEffect(() => {
    if (props.assessmentValues?.hours_sitting) {
      if (props.assessmentValues.hours_sitting === "wheelchair") {
        setShowBreaks(false);
        props.onSetValue({ id: "sitting_breaks", value: null });
      } else {
        let hours = props.assessmentValues.hours_sitting.split(":")[0];
        if (Number(hours) > 0) {
          setShowBreaks(true);
        } else {
          setShowBreaks(false);
          props.onSetValue({ id: "sitting_breaks", value: null });
        }
      }
    }
  }, [props.assessmentValues?.hours_sitting]);
  
  useEffect(() => {
      if (!props.assessmentValues["activity"]) {
        props.onSetValue({
          id: "activity",
          value: {
            percent: "0.00",
            textValue: "0mins",
            value: "0",
          },
        });
      }
  }, []);

  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <ActivitySlider
        questionid="activity"
        width="_wide"
        questionTitle={t("PA005")}
        leftlabel={t("PA006")}
        rightlabel={t("PA007")}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("PA008")}
        onOpenModal={props.onOpenModal}
      />

      <DotRadio
        questionid="resistence_strength"
        range="8"
        width=""
        questionTitle={t("PA009")}
        type="question"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("PA010")}
        onOpenModal={props.onOpenModal}
      />
      <DotRadio
        questionid="stretch"
        range="8"
        width=""
        questionTitle={t("PA011")}
        type="question"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("PA012")}
        onOpenModal={props.onOpenModal}
      />
      <HourSelect
        questionid="hours_sitting"
        questionTitle={t("PA013")}
        hourSuffix={t("A122")}
        minuteSuffix={t("A120")}
        width=""
        skipText={t("PA014")}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("PA015")}
        onOpenModal={props.onOpenModal}
      />
      {showBreaks && (
        <CheckboxRadio
          questionid="sitting_breaks"
          width=""
          options={sittingBreaksOptions}
          questionTitle={t("PA016")}
          placeholder={t("PA017")}
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          fadeIn="yes"
        />
      )}
    </div>
  );
}

export default PhysicalActivity1;
