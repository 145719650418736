import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import {
  faBoltLightning,
  faFaceSmileBeam,
} from "@fortawesome/sharp-regular-svg-icons";

import StressTracker from "./StressTracker";
import MoodTracker from "./MoodTracker";

const StressNMoodTracker = (props) => {
  const { t } = useTranslation();

  const [areaPicker, setAreaPicker] = useState(true);
  const [openStress, setOpenStress] = useState(false);
  const [openMood, setOpenMood] = useState(false);

  const handleStress = () => {
    const main = document.getElementById("snm-main");
    main?.classList.remove("slide-in");
    setTimeout(() => {
      setAreaPicker(false);
      setOpenStress(true);
    }, 500);
  };

  const handleMood = () => {
    const main = document.getElementById("snm-main");
    main?.classList.remove("slide-in");
    setTimeout(() => {
      setAreaPicker(false);
      setOpenMood(true);
    }, 500);
  };

  useEffect(() => {
    if (openStress) {
      const stress = document.getElementById("snm-stress");
      setTimeout(() => {
        stress?.classList.add("slide-in");
      }, 300);
    }
  }, [openStress]);

  useEffect(() => {
    if (areaPicker) {
      const main = document.getElementById("snm-main");
      setTimeout(() => {
        main?.classList.add("slide-in");
      }, 300);
    }
  }, [areaPicker]);

  useEffect(() => {
    if (openMood) {
      const mood = document.getElementById("snm-mood");
      setTimeout(() => {
        mood?.classList.add("slide-in");
      }, 300);
    }
  }, [openMood]);

  const handleBackToMain = () => {
    if (openStress) {
      const stress = document.getElementById("snm-stress");
      stress?.classList.remove("slide-in");
      setTimeout(() => {
        setOpenStress(false);
        setAreaPicker(true);
      }, 500);
    }

    if (openMood) {
      const mood = document.getElementById("snm-mood");
      mood?.classList.remove("slide-in");
      setTimeout(() => {
        setOpenMood(false);
        setAreaPicker(true);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      {areaPicker && (
        <div id="snm-main" className="tracker-container">
          <div className="ind-tracker mood">
            <div className="title">{t("T134")}</div>
            <div className="mood-area" onClick={handleStress}>
              <div>
                <FontAwesomeIcon icon={faBoltLightning} className="mood-icon" />{" "}
                {t("T183")}{" "}
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="mood-area" onClick={handleMood}>
              <div>
                <FontAwesomeIcon icon={faFaceSmileBeam} className="mood-icon" />{" "}
                {t("T184")}
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="buttons mood">
            <div className="close-btn" onClick={props.closeTrackers}>
              {t("T015")}
            </div>
          </div>
        </div>
      )}{" "}
      {openStress && (
        <StressTracker
          handleBackToMain={handleBackToMain}
          closeTrackers={props.closeTrackers}
        />
      )}
      {openMood && (
        <MoodTracker
          handleBackToMain={handleBackToMain}
          closeTrackers={props.closeTrackers}
        />
      )}
    </React.Fragment>
  );
};
export default StressNMoodTracker;
