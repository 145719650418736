import React, { useState, useRef, useEffect } from "react";

import "../styles/CountdownTimer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/sharp-solid-svg-icons";

const CountdownTimer = ({
  seconds,
  size,
  strokeBgColor,
  strokeColor,
  strokeWidth,
  setOpenModal,
  playNext,
}) => {
  const [countdown, setCountdown] = useState(seconds * 1000);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const milliseconds = seconds * 1000;
  const radius = size / 2;
  const circumference = size * Math.PI;
  const svgRef = useRef(null);

  const strokeDashoffset = () =>
    circumference + (countdown / milliseconds) * circumference;

  const startTimer = () => {
    setIsPlaying(true);
    setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 10);
    }, 10);
  };

  useEffect(() => {
    startTimer();
  }, []);

  const stopCountdown = () => {
    setCountdown(0);
    setIsPlaying(false);
    setOpenModal(false);
    playNext();
  };

  useEffect(() => {
    if (countdown <= 0) {
      stopCountdown();
    }
  }, [countdown]);

  const secondsDisplay = Math.max(0, Math.ceil(countdown / 1000));

  return (
    <div>
      <div
        className="start-btn"
        onClick={startTimer}
        disabled={isPlaying || countdown <= 0}
      >
        Next Chapter
      </div>
      <div
        className="countdown-cont"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <p className="countdown-sec" onClick={() => stopCountdown()}>
          {isHovered ? (
            <FontAwesomeIcon
              icon={faPlay}
              className={`play-icon ${isHovered ? "" : "hidden"}`}
            />
          ) : (
            secondsDisplay
          )}
        </p>
        <svg className="countdown-crcl" ref={svgRef}>
          <circle
            cx={radius}
            cy={radius}
            r={radius}
            fill="none"
            stroke={strokeBgColor}
            strokeWidth={strokeWidth}
          />
        </svg>
        <svg className="countdown-crcl">
          <circle
            strokeDasharray={circumference}
            strokeDashoffset={isPlaying ? strokeDashoffset() : 0}
            r={radius}
            cx={radius}
            cy={radius}
            fill="none"
            strokeLinecap="round"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </div>
    </div>
  );
};

const CountdownTimerContainer = (props) => (
  <div className="autoplay">
    <div className="timer">
      <CountdownTimer
        seconds={6}
        size={120}
        strokeBgColor="rgba(255,255,255,0.5)"
        strokeColor="#ea2364"
        strokeWidth={2}
        setOpenModal={props.setOpenModal}
        playNext={props.playNext}
      />
    </div>
    <div className="cancel" onClick={() => props.setOpenModal(false)}>
      Cancel
    </div>
  </div>
);

export default CountdownTimerContainer;
