import { useState, useEffect } from "react";

import "./styles/Trackers.css";

import HydrationTracker from "./hydration/HydrationTracker";
import StressNMoodTracker from "./mentalwb/StressNMoodTracker";
import MovementCover from "./movement/MovementCover";
import PhysicalWBCover from "./physicalwb/PhysicalWBCover";
import EnergyCover from "./energy/EnergyCover";
import NutritionCover from "./nutrition/NutritionCover";
import ProductivityCover from "./productivity/ProductivityCover";
import SleepCover from "./sleep/SleepCover";

const Trackers = (props) => {
  return (
    <div className="trackers-wrap">
      <div className="trackers">
        {props?.pageName.replace(/%20/g, " ") === "sleep & rest" && (
          <SleepCover closeTrackers={props.closeTrackers} />
        )}
        {props?.pageName.replace(/%20/g, " ") === "movement" && (
          <MovementCover closeTrackers={props.closeTrackers} />
        )}
        {props?.pageName.replace(/%20/g, " ") === "mental wellbeing" && (
          <StressNMoodTracker closeTrackers={props.closeTrackers} />
        )}
        {props?.pageName.replace(/%20/g, " ") === "nutrition" && (
          <NutritionCover closeTrackers={props.closeTrackers} />
        )}
        {props?.pageName.replace(/%20/g, " ") === "hydration & alcohol" && (
          <HydrationTracker closeTrackers={props.closeTrackers} />
        )}
        {props?.pageName.replace(/%20/g, " ") === "work & productivity" && (
          <ProductivityCover closeTrackers={props.closeTrackers} />
        )}
        {props?.pageName.replace(/%20/g, " ") === "energy" && (
          <EnergyCover closeTrackers={props.closeTrackers} />
        )}
        {props?.pageName.replace(/%20/g, " ") === "physical wellbeing" && (
          <PhysicalWBCover closeTrackers={props.closeTrackers} />
        )}
      </div>
    </div>
  );
};
export default Trackers;
