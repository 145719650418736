import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Checkbox from "../questions/checkbox/Checkbox";
import OneToTenSlider from "../questions/painSlider/OneToTenSlider";
import CheckboxRadio from "../questions/checkboxRadio/CheckboxRadio";

function Behaviour1(props) {
  const { t } = useTranslation();
  const [goalOptions, setGoalOptions] = useState([
    { id: "sleep", title: t("B001") },
    { id: "activity", title: t("B002") },
    { id: "stress", title: t("B003") },
    { id: "mh", title: t("B004") },
    { id: "nutrition", title: t("B005") },
    { id: "hydration", title: t("B006") },
    { id: "productivity", title: t("B008") },
    { id: "energy", title: t("B009") },
  ]);

  const motivatedTextValues = [t("B011"), t("B012")];

  const confidenceTextValues = [t("B011"), t("B013")];

  const stoppingOptions = [
    { id: "nothing", title: t("B014") },
    { id: "work", title: t("B015") },
    { id: "family", title: t("B016") },
    { id: "time", title: t("B017") },
    { id: "will_power", title: t("B018") },
    { id: "finances", title: t("B019") },
    { id: "mental", title: t("B020") },
    { id: "physical_health", title: t("B021") },
    { id: "low_confidence", title: t("B022") },
    { id: "lack_knowledge", title: t("B023") },
    { id: "lack_support", title: t("B024") },
  ];

  useEffect(() => {
    const { smoker, drink_alcohol } = props.assessmentValues || {};

    const addOption = (id, title) => {
      setGoalOptions((prev) => {
        if (!prev.some((item) => item.id === id)) {
          return [...prev, { id: id, title: title }];
        } else {
          return prev;
        }
      });
    };

    if (smoker === "yes") {
      addOption("smoking", "Stop smoking");
    }

    if (drink_alcohol !== "no") {
      addOption("alcohol", "Drink less alcohol");
    }
  }, [props.assessmentValues]);

  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <CheckboxRadio
        questionid="goal_selected"
        width="_wide"
        questionTitle={t("B025")}
        questionTitleSuffix={t("B026")}
        options={goalOptions}
        placeholder={t("B027")}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <OneToTenSlider
        questionid="how_motivated"
        questionTitle={t("B028")}
        width=""
        textValues={motivatedTextValues}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <OneToTenSlider
        questionid="how_confident"
        questionTitle={t("B029")}
        width=""
        textValues={confidenceTextValues}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      <Checkbox
        questionid="stopping_from_improving"
        width="_wide"
        questionTitle={t("B030")}
        questionTitleSuffix={t("B031")}
        options={stoppingOptions}
        placeholder={t("B032")}
        itemLimit="3"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("B033")}
        onOpenModal={props.onOpenModal}
      />
    </div>
  );
}

export default Behaviour1;
