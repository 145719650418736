export const handleEmailChange = (e, setEmail) => {
  let valid = false;
  let el = document.getElementById(e.target.id);

  if (e.target.id === "email") {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    valid = re.test(String(e.target.value).toLowerCase());
  }

  if (valid) {
    setEmail(e.target.value ? e.target.value.trim() : e.target.value);
    el.classList.remove("invalid");
  } else {
    el.classList.add("invalid");
  }
};

export const handleEidChange = (e, setEid) => {
  let valid = false;
  let el = document.getElementById(e.target.id);

  if (e.target.id === "erv") {
    if (e.target.value.length > 0) {
      valid = true;
    }
  }

  if (valid) {
    setEid(e.target.value ? e.target.value.trim() : e.target.value);
    el.classList.remove("invalid");
  } else {
    el.classList.add("invalid");
  }
};

export const handleNameChange = (e, setName) => {
  let valid = false;
  let el = document.getElementById(e.target.id);

  if (e.target.id === "name") {
    if (e.target.value.length > 0) {
      valid = true;
    }
  }

  if (valid) {
    setName(e.target.value ? e.target.value.trim() : e.target.value);
    el.classList.remove("invalid");
  } else {
    el.classList.add("invalid");
  }
};
