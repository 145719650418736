import { useEffect, useState, useContext, useCallback } from "react";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import { validateUserInput } from "../../../shared/util/helperfunctions";
import { AuthContext } from "../../../shared/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import WhitePopUpNoTitle from "../../../shared/modal/whitePopUp/WhitePopUpNoTitle";
import { useTranslation } from "react-i18next";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBullseyeArrow,
  faCog,
  faDroplet,
  faMoonStars,
  faSpa,
  faTrashCan,
  faWavePulse,
} from "@fortawesome/pro-light-svg-icons";
import { colours } from "../../../assets/colours";

import ToggleBtn from "../../../shared/components/styledComponents/toggleBtn/ToggleBtn";
import ReminderPopUp from "../../../contentTypes/components/ReminderPopUp";
import { faBinBottles } from "@fortawesome/sharp-regular-svg-icons";

const RemindersRightMenu = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [remindersOn, setRemindersOn] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showGreenCheck, setShowGreenCheck] = useState(false);
  const [currentReminder, setCurrentReminder] = useState(null);

  const [remindersArr, setRemindersArr] = useState([
    { id: 1, title: t("T338"), icon: faBell, position: true },
    {
      id: 2,
      title: "Goals",
      icon: faBullseyeArrow,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 3,
      title: "Meditate",
      icon: faSpa,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 4,
      title: "Get Moving",
      icon: faWavePulse,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 5,
      title: "Drink Water",
      icon: faDroplet,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 6,
      title: "Sleep Routine",
      icon: faMoonStars,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
  ]);

  const [contentReminders, setContentReminders] = useState([
    {
      id: 1,
      title: "Exercise & Asthma",
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 2,
      title: "Supporting your Partner through the Menopause",
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 3,
      title: "The Stream",
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
  ]);
  const handleReminderToggle = (id) => {
    if (id === 1) {
      setRemindersOn(!remindersOn);
    }
    const updatedReminders = remindersArr.map((reminder) => {
      if (reminder.id === id) {
        if (reminder.id === id) {
          if (reminder.position) {
            reminder.position = false;
          } else {
            setModalIsOpen(true);
            setCurrentReminder(reminder);
            reminder.position = true;
          }
        }
      }
      return reminder;
    });
    setRemindersArr(updatedReminders);
  };

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const editContentReminder = (id) => {
    setCurrentReminder(() =>
      contentReminders.find((reminder) => reminder.id === id)
    );
    setModalIsOpen(true);
  };
  const deleteContentReminder = (id) => {
    setContentReminders((prevReminders) =>
      prevReminders.filter((reminder) => reminder.id !== id)
    );
    // setShowGreenCheck(true);
    // setTimeout(() => {
    //   setShowGreenCheck(false);
    // }, 2000);
  };

  return (
    <>
      {modalIsOpen && (
        <ReminderPopUp
          currentItem={currentReminder}
          notifications
          setRemindOpen={modalClickHandler}
        />
      )}
      {showGreenCheck && <GreenCheckOverlay />}

      <div className="edit-field reminders fs-exclude">
        {props.hide && (
          <div className="btn-cont ">
            <ChevronBackBtn onClick={() => props.changeMenu(0, "back")} />
          </div>
        )}
        <div className="edit-title">{t("T338")}</div>
        <div className="scrollable">
          {remindersArr.map((reminder, index) => {
            return (
              <div
                className="profile-slider-btn reminders"
                key={index}
                style={
                  index === 0
                    ? {
                        background: "rgba(100, 89, 107, 0.5)",
                        border: "solid 1px rgba(100, 89, 107, 0.2)",
                        marginBottom: "20px",
                      }
                    : {}
                }
              >
                <div className="menu-item">
                  <FontAwesomeIcon
                    icon={reminder.icon}
                    style={{ color: colours.green }}
                    className="slider-icon"
                  />
                  <div className="slider-text">{reminder.title}</div>
                </div>
                <ToggleBtn
                  isOn={remindersOn ? reminder.position : false}
                  onToggle={() => handleReminderToggle(reminder.id)}
                  containerColor={
                    reminder.position && remindersOn ? colours.green : "#64596b"
                  }
                  toggleColor={"#fff"}
                  size={{ width: "62px", height: "35px" }}
                />
              </div>
            );
          })}
          {contentReminders.map((reminder, index) => {
            return (
              <div
                className="profile-slider-btn reminders content"
                key={index * reminder.id}
              >
                <div className="circle"></div>
                <div className="content-remind">
                  <span>Reminder for</span>
                  <div className="slider-text">{reminder.title}</div>
                </div>

                <FontAwesomeIcon
                  icon={faCog}
                  className="icon"
                  onClick={() => editContentReminder(reminder.id)}
                />

                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="icon"
                  style={{ color: `${colours.red}` }}
                  onClick={() => deleteContentReminder(reminder.id)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RemindersRightMenu;
