import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Hydration({ data, onOpenModal }) {
  const { t } = useTranslation();
  const {
    caffeine_0,
    caffeine_3,
    caffeine_5,
    caffeine_6,
    alcohol_units_14,
    alcohol_units_27,
    alcohol_units_28,
    binge_drinking_never,
    binge_drinking_less_than_monthly,
    binge_drinking_monthly,
    binge_drinking_weekly,
    binge_drinking_daily,
    stress_alcohol_1,
    stress_alcohol_2,
    stress_alcohol_3,
    stress_alcohol_4,
    stress_alcohol_5,
    water_no,
    water,
    productivity_dehydration_no,
    productivity_dehydration,
    dont_drink_no,
    dont_drink,
    drink_over_14_no,
    drink_over_14,
    hangover_productivity_no,
    hangover_productivity,
  } = data;
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const caffeine_daily = [
    { date: "0", value: caffeine_0 },
    { date: "1-3", value: caffeine_3 },
    { date: "4-5", value: caffeine_5 },
    { date: "6+", value: caffeine_6 },
  ];
  const alcohol_consumption = [
    { date: t("AS001"), value: alcohol_units_14 },
    { date: t("AS002"), value: alcohol_units_27 },
    { date: t("AS003"), value: alcohol_units_28 },
  ];

  const binge_drink = [
    { date: t("AS006"), value: binge_drinking_never },
    {
      date: t("AS007"),
      value: binge_drinking_less_than_monthly,
    },
    {
      date: t("AS008"),
      value: binge_drinking_monthly,
    },
    { date: t("AS009"), value: binge_drinking_weekly },
    { date: t("AS010"), value: binge_drinking_daily },
  ];

  const stressAlcohol = [
    { date: t("AS029"), value: stress_alcohol_1 },
    { date: t("AS030"), value: stress_alcohol_2 },
    { date: t("AS031"), value: stress_alcohol_3 },
    { date: t("AS032"), value: stress_alcohol_4 },
    { date: t("AS033"), value: stress_alcohol_5 },
  ];

  return (
    <React.Fragment>
      <CircleGraph
        title={t("HR004")}
        square
        colour="black"
        value={water_no}
        large
        percent={water}
        modalText={t("HR005")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("HR008")}
        square
        colour="colour1"
        value={productivity_dehydration_no}
        large
        percent={productivity_dehydration}
        modalText={t("HR009")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("HR006")}
        data={caffeine_daily}
        unit="%"
        modalText={t("HR007")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("AS011")}
        data={alcohol_consumption}
        unit="%"
        width="wide"
        startZero
        modalText={t("AS012")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AS013")}
        square
        colour="colour1"
        value={dont_drink_no}
        large
        percent={dont_drink}
        modalText={t("AS014")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AS015")}
        square
        colour="colour2"
        value={drink_over_14_no}
        large
        percent={drink_over_14}
        modalText={t("AS016")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("AS017")}
        data={binge_drink}
        unit="%"
        width="wide"
        startZero
        modalText={t("AS018")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("AS026")}
        data={stressAlcohol}
        width="wide"
        unit=""
        yaxis={t("AS028")}
        modalText={t("AS027")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("AS019")}
        square
        colour="colour3"
        value={hangover_productivity_no}
        large
        percent={hangover_productivity}
        modalText={t("AS020")}
        onOpenModal={onOpenModal}
      />
    </React.Fragment>
  );
}
export default Hydration;
