import "./ToggleBtn.css";

import React from "react";

// Styles for the toggle container and the toggle itself
const getStyles = (isOn, containerColor, toggleColor, width, height) => ({
  container: {
    width,
    height,
    borderRadius: `${parseInt(height, 10) / 2}px`,
    backgroundColor: containerColor,
    justifyContent: isOn ? "flex-end" : "flex-start",
    cursor: "pointer",
  },
  toggle: {
    width: `${parseInt(height, 10) - 8}px`,
    height: `${parseInt(height, 10) - 8}px`,
    backgroundColor: toggleColor,
    transform: isOn
      ? `translateX(${parseInt(width, 10) - parseInt(height, 10)}px)`
      : "none",
  },
});

// The Toggle component
const ToggleBtn = ({ isOn, onToggle, containerColor, toggleColor, size }) => {
  const styles = getStyles(
    isOn,
    containerColor,
    toggleColor,
    size.width,
    size.height
  );

  return (
    <div style={styles.container} className="container" onClick={onToggle}>
      <div style={styles.toggle} className="toggle" />
    </div>
  );
};

// Default props for the component
ToggleBtn.defaultProps = {
  containerColor: "#4CD964",
  toggleColor: "#FFFFFF",
  size: { width: "60px", height: "30px" },
};

export default ToggleBtn;
