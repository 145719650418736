import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import TextArea from "../../../individualReport/pages/components/TextArea";
import NavSlider from "../../../shared/components/slider/NavSlider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/pro-light-svg-icons";

function Page(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="report-section active leadership_page">
        <h2 className="main_title">{t("ER001")}</h2>
        {/* <NavSlider ids={['materials']} titles={{materials: 'Materials'}} />        */}

        <div id="materials" className="results-content">
          <TextArea containerClass="clear wide engagement"></TextArea>
          <TextArea containerClass="clear">
            <span className="centred">
              <h2>{t("ER002")}</h2>
              <p>{t("ER003")}</p>
              <h3>{t("ER004")}</h3>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/The_Ingredients_for_an_Engagement_Revolution.pdf"
                target="_blank"
              >
                <button className="">
                  <FontAwesomeIcon icon={faCloudDownload} className="" />
                  {t("ER005")}
                </button>
              </a>
              <a
                href="https://www.dropbox.com/sh/cqgbwf56rw90kmt/AABLIZT18EYw3j9wuQoqAI7ba?dl=0"
                target="_blank"
              >
                <button className="">
                  <FontAwesomeIcon icon={faCloudDownload} className="" />
                  {t("ER006")}
                </button>
              </a>
            </span>
          </TextArea>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Page;
