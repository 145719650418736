import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCalendar,
  faMinus,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { faThumbsUp } from "@fortawesome/sharp-regular-svg-icons";
import {
  faArrowLeft,
  faCircleChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";

import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton";
import Warning from "../components/Warning";
// import TeamChallenge from "../../../components/TeamChallenge";
import Journal from "../components/Journal";
import Influencers from "../components/Influencers";
import HorizontalSlider from "../components/HorizontalSlider";
import TrackerHeading from "../components/TrackerHeading";
import { useHttpClient } from "../../shared/hooks/http-hook";

const SmokingTracker = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();
  const [smokeFree, setSmokeFree] = useState(false);
  const [hadSmoke, setHadSmoke] = useState(false);
  const [cigarettes, setCigarettes] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [date, setDate] = useState(false);
  const [data, setData] = useState(null);
  const [journal, setJournal] = useState(null);
  const [responseRequired, setResponseRequired] = useState([]);
  const [currentTracker, setCurrentTracker] = useState(null);
  const [daysNotSmoked, setDaysNotSmoked] = useState(0);

  const warningRef = useRef(null);
  const daysSmokeFree = useRef(null);

  const handleDecrease = () => {
    if (cigarettes > 0) {
      setCigarettes((prevState) => prevState - 1);
      saveResponse("data", {
        id: "smoke_per_day",
        value: cigarettes - 1,
      });
    }
  };

  const handleIncrease = () => {
    if (cigarettes < 30) {
      setCigarettes((prevState) => prevState + 1);
      saveResponse("data", {
        id: "smoke_per_day",
        value: cigarettes + 1,
      });
    }
  };

  const handleYesNo = (e) => {
    if (e.currentTarget.id === "smoke-yes") {
      setSmokeFree(false);
      setHadSmoke(true);
      setCigarettes(0);
      saveResponse("data", {
        id: "smoke_free",
        value: false,
      });
      saveResponse("journal", {
        id: "enhancers",
        value: [],
      });
    } else if (e.currentTarget.id === "smoke-no") {
      if (daysSmokeFree.current === daysNotSmoked && !smokeFree) {
        setDaysNotSmoked(() => daysNotSmoked + 1);
      }
      setHadSmoke(false);
      setSmokeFree(true);
      setCigarettes(0);
      saveResponse("data", {
        id: "smoke_per_day",
        value: 0,
      });
      saveResponse("data", {
        id: "smoke_free",
        value: true,
      });
      saveResponse("journal", {
        id: "inhibitors",
        value: [],
      });
    }
  };

  useEffect(() => {
    if (warningRef.current && responseRequired) {
      const modalElement = warningRef.current.closest(".trackers");

      if (modalElement) {
        modalElement.scrollTop = warningRef.current.offsetTop;
      } else {
        window.scrollTo({
          top: warningRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [warningRef, responseRequired]);

  const getData = async (date) => {
    if (date.length > 0) {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/trackers/smoking/${date}`
        );
        if (response) {
          daysSmokeFree.current = response.daysNotSmoked;
          setDaysNotSmoked(response.daysNotSmoked);
        }
        if (response.tracker?._id) {
          let tracker = response.tracker;
          setCurrentTracker({
            id: tracker._id,
            trackerDate: tracker.tracker_date.toString().slice(0, 10),
          });
          let data = JSON.parse(tracker.data);
          setData(data);
          setJournal({
            enhancers: tracker.enhancers,
            inhibitors: tracker.inhibitors,
            journal: tracker.journal,
          });
          setIsSaved(true);

          if (data.smoke_free) {
            setCigarettes(0);
            setHadSmoke(false);
            setSmokeFree(true);
          } else {
            setCigarettes(Number(data.smoke_per_day));
            setHadSmoke(true);
            setSmokeFree(false);
          }
        } else {
          setCurrentTracker({
            id: "",
            trackerDate: date,
          });
          setData({
            smoke_free: "",
            craving_intensity: 5,
            bar_index: 16,
            smoke_per_day: 0,
          });
          setJournal({
            enhancers: [],
            inhibitors: [],
            journal: "",
          });
          setCigarettes(0);
          setHadSmoke(false);
          setSmokeFree(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getData(date);
  }, [date]);

  const saveResponse = (location, stat) => {
    if (location === "journal") {
      setJournal((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
    } else {
      setData((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
    }
  };

  const sendData = async () => {
    let method = "POST";
    let url = `${process.env.REACT_APP_BACKEND_URL}/trackers/`;
    if (currentTracker.id !== "") {
      method = "PUT";
      url = `${process.env.REACT_APP_BACKEND_URL}/trackers/${currentTracker.id}`;
    }
    try {
      const response = await sendRequest(
        url,
        method,
        JSON.stringify({
          trackerDate: date,
          type: "smoking",
          data: data,
          hidden: false,
          enhancers: journal.enhancers,
          inhibitors: journal.inhibitors,
          journal: journal.journal,
          platform: "web",
        })
      );
      if (response) {
        setIsSaved(true);
        props.closeTrackers();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    const emptyKeys = [];

    for (const [key, value] of Object.entries(data)) {
      if (
        (key === "smoke_per_day" && hadSmoke && value < 1) ||
        (key === "smoke_free" && value === "")
      ) {
        emptyKeys.push(key);
      }
    }

    if (emptyKeys.length > 0) {
      setResponseRequired(emptyKeys);
    } else {
      setResponseRequired([]);
      sendData();
    }
  };
  const [narrowButtons, setNarrowButtons] = useState(true);

  useEffect(() => {
    //if .trackers-inner is scrolled to bottom - set narrow buttons to false)
    const trackersInner = document.querySelector(".trackers-inner");
    const handleScroll = () => {
      if (
        trackersInner.scrollTop + trackersInner.clientHeight >=
        trackersInner.scrollHeight
      ) {
        setNarrowButtons(false);
      } else {
        setNarrowButtons(true);
      }
    };
    trackersInner.addEventListener("scroll", handleScroll);
    return () => {
      trackersInner.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="snm-smoking" className="tracker-container">
      {" "}
      <div className="trackers-inner">
        <div className="back-to-main">
          <div className="back-btn" onClick={props.handleBackToMain}>
            <FontAwesomeIcon icon={faCircleChevronLeft} />
          </div>
        </div>
        <TrackerHeading title="T100" setDate={setDate} isSaved={isSaved} />
        <div className="trackers-body">
          <div className="ind-tracker">
            {responseRequired.includes("craving_intensity") && (
              <React.Fragment>
                <div className="buffer" ref={warningRef}></div>
                <Warning />
              </React.Fragment>
            )}
            <div className="title">
              {t("T101")}
              {responseRequired.includes("craving_intesity") && (
                <FontAwesomeIcon className="red-arrow" icon={faArrowLeft} />
              )}
            </div>
            <HorizontalSlider
              scale1to10
              questionid="craving_intensity"
              data={data}
              saveResponse={saveResponse}
            />
          </div>
          <div className="ind-tracker">
            {responseRequired.includes("smoke_free") && (
              <React.Fragment>
                <div className="buffer" ref={warningRef}></div>
                <Warning />
              </React.Fragment>
            )}
            <div className="title">
              {t("T102")}
              {responseRequired.includes("smoke_free") && (
                <FontAwesomeIcon className="red-arrow" icon={faArrowLeft} />
              )}
            </div>
            <div className="buffer"></div>
            <div className="yes-no-btn fs-exclude">
              <p
                id="smoke-yes"
                className={hadSmoke ? "alc-btn sm selected" : "alc-btn sm"}
                onClick={handleYesNo}
              >
                {t("T066")}
              </p>
              <p
                id="smoke-no"
                className={smokeFree ? "alc-btn sm selected" : "alc-btn sm"}
                onClick={handleYesNo}
              >
                {t("T067")}
              </p>
            </div>
            <div className="buffer"></div>
          </div>
          {smokeFree && (
            <>
              {daysNotSmoked && (
                <div className="whc-cont alc">
                  <div className="whc-body">
                    <div className="whc-heading">
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className="whc-thumbs-up"
                      />
                      {daysNotSmoked === 1 ? (
                        t("T553")
                      ) : (
                        <>
                          {t("T108")}!{daysNotSmoked} {t("T105")}
                        </>
                      )}
                    </div>
                    <div className="whc-text">
                      {daysNotSmoked === 1 ? (
                        <>{t("T554")}</>
                      ) : (
                        <>
                          {t("T104")} {daysNotSmoked} {t("T105")} {t("T109")}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="icon-cont">
                    <FontAwesomeIcon icon={faCalendar} />
                    <div className="days">{daysNotSmoked}</div>
                  </div>
                </div>
              )}
              <div className="ind-tracker">
                <div className="title">
                  {t("T106")}
                  <p>{t("T019")}</p>
                </div>
                <Influencers
                  notsmoking
                  questionid={["enhancers", "inhibitors"]}
                  data={journal}
                  saveResponse={saveResponse}
                />
                <div className="buffer"></div>
              </div>
            </>
          )}
          {hadSmoke && (
            <>
              <div className="ind-tracker">
                {responseRequired.includes("smoke_per_day") && (
                  <React.Fragment>
                    <div className="buffer" ref={warningRef}></div>
                    <Warning />
                  </React.Fragment>
                )}
                <div className="title ">
                  {t("T103")}
                  <div className="plus-minus-cont fs-exclude">
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="icon"
                      onClick={handleDecrease}
                    />
                    <div className="value sm">{cigarettes}</div>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="icon"
                      onClick={handleIncrease}
                    />
                  </div>
                </div>
                <div className="buffer"></div>
              </div>
              <div className="ind-tracker">
                <div className="title">
                  {t("T107")}
                  <p>{t("T019")}</p>
                </div>
                <Influencers
                  smoking
                  questionid={["enhancers", "inhibitors"]}
                  data={journal}
                  saveResponse={saveResponse}
                />
                <div className="buffer"></div>
              </div>
            </>
          )}

          <div className="ind-tracker">
            <Journal
              placeholder={t("T547")}
              questionid="journal"
              data={journal}
              saveResponse={saveResponse}
            />
          </div>
        </div>{" "}
      </div>
      <div className={"buttons " + (narrowButtons ? "narrow" : "")}>
        <div className="close-btn" onClick={props.closeTrackers}>
          {t("T015")}
        </div>
        <DarkButton className="submit" onClick={handleSubmit}>
          {t("T016")} <FontAwesomeIcon className="icon" icon={faArrowRight} />
        </DarkButton>
      </div>
    </div>
  );
};
export default SmokingTracker;
