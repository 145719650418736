import React, { useState, useEffect, useReducer, useContext } from "react";
import Backdrop from "../menu/Backdrop";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth-context";

function Modal(props) {
  const [stage, setStage] = useState();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const onboardingReducer = (state, action) => {
    switch (action.section) {
      case 1:
        return {
          ...state,
          title: t("O001"),
          text: t("O002"),
          section: 1,
          buttonText: t("O003"),
        };
      case 2:
        return {
          ...state,
          title: t("O004"),
          text: t("O005"),
          section: 2,
          buttonText: t("O003"),
        };
      case 3:
        return {
          ...state,
          title: t("O006"),
          text: t("O007"),
          section: 3,
          buttonText: t("O010"),
        };
      case 4:
        return {
          ...state,
          title: t("O008"),
          text: t("O009"),
          section: 4,
          buttonText: t("O010"),
        };
      default:
        return state;
    }
  };
  const [pageOptions, dispatch] = useReducer(onboardingReducer, {
    title: "",
    text: "",
    section: "",
    buttonText: "",
  });

  function modalClickHandler() {
    let newstage = stage;
    newstage++;
    if (newstage <= 3) {
      setStage(newstage);
      dispatch({
        section: newstage,
      });
    } else {
      props.modalClickHandler();
    }
  }

  useEffect(() => {
    if (auth.tags.length > 2) {
      setStage(4);
      dispatch({
        section: 4,
      });
    } else {
      setStage(1);
      dispatch({
        section: 1,
      });
    }
  }, [auth.tags]);

  return (
    <Backdrop
      open={`${props.modalIsOpen ? "open" : "closed"}`}
      onBackdropClose={modalClickHandler}
    >
      <div
        id="modal-container-onboarding"
        className={` ${props.modalIsOpen ? "open" : "closed"}`}
      >
        <div
          id={"onboarding_image_" + pageOptions.section}
          className="left_side_onboarding"
        ></div>
        <div className="right_side_onboarding">
          <h3>{t("O019")}</h3>
          <h1>{pageOptions.title}</h1>
          <p>{pageOptions.text}</p>
          {pageOptions.section !== 4 && (
            <div id="section_progress">
              <div
                className={
                  pageOptions.section === 1
                    ? "section_bar active"
                    : "section_bar"
                }
              ></div>
              <div
                className={
                  pageOptions.section === 2
                    ? "section_bar active"
                    : "section_bar"
                }
              ></div>
              <div
                className={
                  pageOptions.section === 3
                    ? "section_bar active"
                    : "section_bar"
                }
              ></div>
            </div>
          )}
          {pageOptions.section === 4 && <div className="buffer"></div>}
          <button onClick={modalClickHandler} id="onboarding">
            {pageOptions.buttonText}{" "}
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
          </button>
        </div>
      </div>
    </Backdrop>
  );
}
export default Modal;
