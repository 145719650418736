import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./InfoTileStyle.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faBoltLightning,
  faChartLineDown,
  faChartLineUp,
  faCrown,
  faDash,
  faFaceSmileBeam,
  faMartiniGlass,
  faMinus,
  faMoon,
  faMoonStars,
  faPersonSkiing,
  faSalad,
  faWater,
  faWind,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBatteryFull,
  faDroplet,
  faLightbulb,
} from "@fortawesome/sharp-regular-svg-icons";

const InfoTile = ({ tileContents }) => {
  const { t } = useTranslation();
  const [icon, setIcon] = useState(null);
  const [colour, setColour] = useState(null);
  const [comment, setComment] = useState(null);
  const [trackerIcon, setTrackerIcon] = useState(null);
  const goals = useSelector((state) => state.goals.goals);
  useEffect(() => {
    if (!tileContents) return;
    if (tileContents.id === "alcohol" || tileContents.id === "smoking") {
      if (tileContents.previousValue < tileContents.currentValue) {
        setIcon(faChartLineDown);
        setColour("red");
        setComment(
          () => goals.find((goal) => goal.title === tileContents.title).worsens
        );
      } else if (tileContents.previousValue > tileContents.currentValue) {
        setIcon(faChartLineUp);
        setColour("green");
        //find goal in goals with title of tileContents.title; if found, set comment to goal.imporoves
        setComment(
          () => goals.find((goal) => goal.title === tileContents.title).improves
        );
      }
    } else if (tileContents.previousValue > tileContents.currentValue) {
      setIcon(faChartLineDown);
      setColour("red");
      setComment(
        () => goals.find((goal) => goal.title === tileContents.title).worsens
      );
    } else if (tileContents.previousValue < tileContents.currentValue) {
      setIcon(faChartLineUp);
      setColour("green");
      //find goal in goals with title of tileContents.title; if found, set comment to goal.imporoves
      setComment(
        () => goals.find((goal) => goal.title === tileContents.title).improves
      );
    } else {
      setIcon(faDash);
      setColour("blue");
      setComment(
        () => goals.find((goal) => goal.title === tileContents.title).maintains
      );
    }
    switch (tileContents.id) {
      case "energy":
        setTrackerIcon(faBatteryFull);
        break;
      case "hydration":
        setTrackerIcon(faDroplet);
        break;
      case "productivity":
        setTrackerIcon(faLightbulb);
        break;
      case "stress":
        setTrackerIcon(faBoltLightning);
        break;
      case "sleep":
        setTrackerIcon(faMoonStars);
        break;
      case "mood":
        setTrackerIcon(faFaceSmileBeam);
        break;
      case "activity":
        setTrackerIcon(faPersonSkiing);
        break;
      case "smoking":
        setTrackerIcon(faWind);
        break;
      case "alcohol":
        setTrackerIcon(faMartiniGlass);
        break;
      case "nutrition":
        setTrackerIcon(faSalad);
        break;
      default:
        break;
    }
  }, [tileContents, goals]);

  return (
    <div className="rm-box">
      <div className="profile-name left">
        {t(tileContents.title)}
        <FontAwesomeIcon icon={icon} className={"icon " + colour} />
      </div>
      <div className="streaks-cont">
        <div className="streak-clmn">
          <div className="streak-name">{t("G025")}</div>
          <div className="streak-circle grey">
            <FontAwesomeIcon icon={trackerIcon} className="icon " />
            <div className="profile-name nm">
              {tileContents.previousValue || "-"}
            </div>
            <div className="bold-sm-txt">{t(tileContents.descriptor)}</div>
          </div>
        </div>
        <div className="streak-clmn">
          <div className="streak-name">{t("S044")}</div>
          <div className={"streak-circle " + colour}>
            <FontAwesomeIcon icon={trackerIcon} className="icon" />
            <div className="profile-name nm">{tileContents.currentValue}</div>
            <div className="bold-sm-txt">{t(tileContents.descriptor)}</div>
          </div>
        </div>
      </div>
      <p className="cm">{t(comment)}</p>
    </div>
  );
};

export default InfoTile;
