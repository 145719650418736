import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faShieldAlt,
} from "@fortawesome/pro-regular-svg-icons";

function DropDown(props) {
  const [closed, setClosed] = useState("closed");

  const dropDownClickHandler = () => {
    if (closed === "closed") {
      setClosed("");
      setupDownArrow(faChevronUp);
    } else {
      setClosed("closed");
      setupDownArrow(faChevronDown);
    }
  };

  const [upDownArrow, setupDownArrow] = useState(faChevronDown);

  return (
    <div
      className={"drop_down " + props.show + " " + closed}
      onClick={dropDownClickHandler}
    >
      {props.show !== "profile_dd" && (
        <FontAwesomeIcon icon={faShieldAlt} className="icon drop_down_shield" />
      )}
      <FontAwesomeIcon icon={upDownArrow} className="icon drop_down_operator" />
      <span>{props.title}</span>
      <div className="drop_down_content">{props.children}</div>
    </div>
  );
}

export default DropDown;
