import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TopThreeGraph from "../../components/graphs/topThreeGraph/TopThreeGraph";
import BarChart from "../../components/graphs/barChart/BarChart";
import {
  DISABLED_GRAPHS,
  getDisabledData,
  listLimit,
} from "../../../../shared/util/companyReport";

function Nutrition({ data, onOpenModal }) {
  const { t } = useTranslation();
  const {
    nutrition_diet_type,
    nutrition_quality_very_poor,
    nutrition_quality_poor,
    nutrition_quality_average,
    nutrition_quality_good,
    nutrition_quality_excellent,
    nutrition_veg_3,
    nutrition_veg_6,
    nutrition_veg_7,
    nutrition_productivity_no,
    nutrition_productivity,
  } = data;
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const nutrition_quality = [
    {
      date: t("NTR001"),
      value: nutrition_quality_very_poor,
    },
    {
      date: t("NTR002"),
      value: nutrition_quality_poor,
    },
    {
      date: t("NTR003"),
      value: nutrition_quality_average,
    },
    {
      date: t("NTR004"),
      value: nutrition_quality_good,
    },
    {
      date: t("NTR005"),
      value: nutrition_quality_excellent,
    },
  ];
  const nutrition_veg = [
    { date: "≤ 3", value: nutrition_veg_3 },
    { date: "4-6", value: nutrition_veg_6 },
    { date: "≥7", value: nutrition_veg_7 },
  ];

  return (
    <React.Fragment>
      <TopThreeGraph
        title={t("NTR006")}
        square
        data={
          nutrition_diet_type.length < listLimit
            ? getDisabledData(DISABLED_GRAPHS.NUTRITION)
            : nutrition_diet_type
        }
        onOpenModal={onOpenModal}
        disabled={nutrition_diet_type.length < listLimit}
      />
      <BarChart
        title={t("NTR007")}
        data={nutrition_quality}
        unit="%"
        width="wide"
        modalText={t("NTR008")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("NTR009")}
        data={nutrition_veg}
        unit="%"
        width="wide"
        modalText={t("NTR010")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("NTR011")}
        square
        colour="colour1"
        value={nutrition_productivity_no}
        large
        percent={nutrition_productivity}
        modalText={t("NTR012")}
        onOpenModal={onOpenModal}
      />
    </React.Fragment>
  );
}
export default Nutrition;
