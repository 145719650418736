import { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { mediaSlidersApiSlice } from "../../../forYou/mediaSlidersApiSlice";
import { mediaApiSlice } from "../../../contentTypes/mediaApiSlice";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import { AuthContext } from "../../../shared/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { useTranslation } from "react-i18next";
import { resetMediaSliders } from "../../../forYou/mediaSlidersSlice";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import En from "../../images/En.svg";
import De from "../../images/De.svg";
import Sv from "../../images/Sv.svg";

const LanguagesRightMenu = (props) => {
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [profile, setProfile] = useState(props.profile);
  const [showGreenCheck, setShowGreenCheck] = useState(false);
  const [activeLang, setActiveLang] = useState(props.profile?.language || "en");

  const showGreenCheckOverlay = () => {
    setShowGreenCheck(true);
    setTimeout(() => {
      setShowGreenCheck(false);
    }, 2000);
  };

  useEffect(() => {
    setProfile((prevState) => ({
      ...prevState,
      language: activeLang,
    }));
  }, [activeLang]);

  const saveProfile = async () => {
    if (profile && auth.token) {
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile`,
          "POST",
          JSON.stringify({
            profile: profile,
          })
        );
      } catch (err) {
        console.error(err);
      }
      if (responseData) {
        showGreenCheckOverlay();
        auth.languageHandler(activeLang);
        dispatch(resetMediaSliders());
        dispatch(
          mediaSlidersApiSlice.util.invalidateTags([
            { type: "MediaSliders", id: "LIST" },
            { type: "Feature", id: "LIST" },
            { type: "TakeAction", id: "LIST" },
          ])
        );
        dispatch(
          mediaApiSlice.util.invalidateTags([
            { type: "SingleMedia", id: "LIST" },
            { type: "Masterclass", id: "LIST" },
            { type: "Programme", id: "LIST" },
          ])
        );
      }
    }
  };

  return (
    <>
      {showGreenCheck && <GreenCheckOverlay />}
      {profile && (
        <>
          <div className="edit-field lang">
            {props.hide && (
              <div className="btn-cont ">
                <ChevronBackBtn onClick={() => props.changeMenu(0, "back")} />
              </div>
            )}
            <div className="edit-title">{t("PL001")}</div>
            <div
              className={`field-cont  
               ${activeLang === "en" ? "active" : ""}
              `}
              onClick={() => setActiveLang("en")}
            >
              {" "}
              <div className="menu-item">
                <img src={En} alt="British flag" />
                <div className="lang-option">{t("PL002")}</div>
              </div>
            </div>
            <div
              className={`field-cont  
               ${activeLang === "de" ? "active" : ""}
              `}
              onClick={() => setActiveLang("de")}
            >
              {" "}
              <div className="menu-item">
                <img src={De} alt="German flag" />
                <div className="lang-option">{t("PL003")}</div>
              </div>
            </div>
            <div
              className={`field-cont 
               ${activeLang === "sv" ? "active" : ""}
              `}
              onClick={() => setActiveLang("sv")}
            >
              {" "}
              <div className="menu-item">
                <img src={Sv} alt="Swedish flag" />
                <div className="lang-option">{t("PL004")}</div>
              </div>
            </div>
            <PinkButton1 className="wide marginTop30" onClick={saveProfile}>
              {t("S017")}
            </PinkButton1>
          </div>
        </>
      )}
    </>
  );
};

export default LanguagesRightMenu;
