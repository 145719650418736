import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CheckboxRadio from "../questions/checkboxRadio/CheckboxRadio";
import NumberSelect from "../questions/numberSelect/NumberSelect";
import Radio from "../questions/radio/Radio";
import NumberSelectQuestion from "../questions/numberSelectQuestion/NumberSelectQuestion";

import ModalTrigger from "../../shared/modal/ModalTrigger";

function Alcohol1(props) {
  const { t } = useTranslation();
  const yesNoOptions = [
    { id: "yes", title: t("AL001") },
    { id: "no", title: t("AL002") },
  ];
  const yesNoAlcoholOptions = [
    { id: "no", title: t("AL003") },
    { id: "monthly", title: t("AL004") },
    { id: "weekly", title: t("AL005") },
    { id: "daily", title: t("AL006") },
  ];

  const beerOptions = [
    { id: "pint", title: t("AL007") },
    { id: "half-pint", title: t("AL008") },
    //{id: 'bottle', title: 'Bottle'}
  ];

  const beerStrengthOptions = [
    { id: "3", title: "3%" },
    { id: "4", title: "4%" },
    { id: "5", title: "5%" },
    { id: "6", title: "6%" },
    { id: "7", title: "7%" },
  ];

  const wineOptions = [
    { id: "175", title: t("AL009") },
    { id: "250", title: t("AL010") },
  ];

  const wineStrengthOptions = [
    { id: "12", title: "12%" },
    { id: "13", title: "13%" },
    { id: "14", title: "14%" },
    { id: "15", title: "15%" },
  ];

  const spiritOptions = [
    { id: "single", title: t("AL011") },
    { id: "double", title: t("AL012") },
  ];

  const spiritStrengthOptions = [
    { id: "20", title: "20%" },
    { id: "37.5", title: "37.5%" },
    { id: "40", title: "40%" },
  ];

  const bingeDrinkOptions = [
    { id: "never", title: t("AL013") },
    { id: "less_than_monthly", title: t("AL014") },
    { id: "monthly", title: t("AL015") },
    { id: "weekly", title: t("AL016") },
    { id: "daily", title: t("AL017") },
  ];

  const [blockOpacity, setBlockOpacity] = useState(0);

  useEffect(() => {
    if (
      ["monthly", "weekly", "daily"].includes(
        props.assessmentValues.drink_alcohol
      )
    ) {
      //alert(JSON.stringify(props.assessmentValues.drink_alcohol));
      setTimeout(function () {
        setBlockOpacity(1);
      }, 100);
    }
  }, [props.assessmentValues.drink_alcohol]);

  useEffect(() => {
    if (
      ["monthly", "weekly", "daily"].includes(
        props.assessmentValues.drink_alcohol
      )
    ) {
      //we check if any alcohol combo selected
      let valid = false;
      let incomplete = false;
      if (
        props.assessmentValues.beers &&
        props.assessmentValues.beers > 0 &&
        props.assessmentValues.beer_type &&
        props.assessmentValues.beer_strength
      ) {
        valid = true;
      } else if (props.assessmentValues.beers > 0) {
        incomplete = true;
      }
      if (
        props.assessmentValues.wines &&
        props.assessmentValues.wines > 0 &&
        props.assessmentValues.wine_type &&
        props.assessmentValues.wine_strength
      ) {
        valid = true;
      } else if (props.assessmentValues.wines > 0) {
        incomplete = true;
      }
      if (
        props.assessmentValues.spirits &&
        props.assessmentValues.spirits > 0 &&
        props.assessmentValues.spirit_type &&
        props.assessmentValues.spirit_strength
      ) {
        valid = true;
      } else if (props.assessmentValues.spirits > 0) {
        incomplete = true;
      }
      //if okay we can progress
      if (valid && !incomplete) {
        document.getElementById("alcohol_fade").classList.remove("req");
      } else {
        document.getElementById("alcohol_fade").classList.add("req");
      }
    }
  }, [props.assessmentValues]);

  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <Radio
        questionid="smoker"
        width="_wide"
        questionTitle={t("AL018")}
        options={yesNoOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
      {props.assessmentValues.smoker &&
        props.assessmentValues.smoker === "yes" && (
          <React.Fragment>
            <NumberSelectQuestion
              questionid="smoke_per_day"
              questionTitle={t("AL019")}
              minlimit="0"
              maxlimit="100"
              trailingText={t("AL020")}
              width=""
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              fadeIn
            />
            <Radio
              questionid="stop_smoking"
              width=""
              questionTitle={t("AL021")}
              options={yesNoOptions}
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              fadeIn
            />
          </React.Fragment>
        )}
      <Radio
        questionid="drink_alcohol"
        width="_wide"
        questionTitle={t("AL022")}
        options={yesNoAlcoholOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />

      {props.assessmentValues.drink_alcohol &&
        ["monthly", "weekly", "daily"].includes(
          props.assessmentValues.drink_alcohol
        ) && (
          <>
            <div
              id="alcohol_fade"
              style={{ opacity: blockOpacity }}
              className="req"
            >
              <h3>
                {t("AL023")} {props.assessmentValues.drink_alcohol} {t("AL024")}
                <ModalTrigger
                  modalText={t("AL025")}
                  onOpenModal={props.onOpenModal}
                />
              </h3>
              <div className="question_block third_width">
                <h4>{t("AL026")}</h4>
                <NumberSelect
                  questionid="beers"
                  minlimit="0"
                  maxlimit="100"
                  trailingText=""
                  width=""
                  type=""
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                />
                <CheckboxRadio
                  questionid="beer_type"
                  width=""
                  options={beerOptions}
                  questionTitle=""
                  placeholder={t("AL027")}
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                  skippable
                />
                <CheckboxRadio
                  questionid="beer_strength"
                  width=""
                  options={beerStrengthOptions}
                  questionTitle=""
                  placeholder={t("AL028")}
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                  skippable
                />
              </div>

              <div className="question_block third_width">
                <h4>{t("AL029")}</h4>
                <NumberSelect
                  questionid="wines"
                  minlimit="0"
                  maxlimit="100"
                  trailingText=""
                  width=""
                  type=""
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                />
                <CheckboxRadio
                  questionid="wine_type"
                  width=""
                  options={wineOptions}
                  questionTitle=""
                  placeholder={t("AL027")}
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                  skippable
                />
                <CheckboxRadio
                  questionid="wine_strength"
                  width=""
                  options={wineStrengthOptions}
                  questionTitle=""
                  placeholder={t("AL028")}
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                  skippable
                />
              </div>

              <div className="question_block third_width">
                <h4>{t("AL030")}</h4>
                <NumberSelect
                  questionid="spirits"
                  minlimit="0"
                  maxlimit="100"
                  trailingText=""
                  width=""
                  type=""
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                />
                <CheckboxRadio
                  questionid="spirit_type"
                  width=""
                  options={spiritOptions}
                  questionTitle=""
                  placeholder={t("AL027")}
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                  skippable
                />
                <CheckboxRadio
                  questionid="spirit_strength"
                  width=""
                  options={spiritStrengthOptions}
                  questionTitle=""
                  placeholder={t("AL028")}
                  onSetValue={props.onSetValue}
                  assessmentValues={props.assessmentValues}
                  skippable
                />
              </div>
            </div>
            <Radio
              questionid="binge_drink"
              width="_wide"
              questionTitle={t("AL031")}
              options={bingeDrinkOptions}
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
            />
          </>
        )}
    </div>
  );
}

export default Alcohol1;
