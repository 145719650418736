import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const goalsApiSlice = createApi({
  reducerPath: "goalsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
  }),
  tagTypes: ["Goals"],
  endpoints: (builder) => ({
    fetchGoals: builder.query({
      query: (token) => ({
        url: "/goals",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: [{ type: "Goals", id: "LIST" }],
    }),
    addGoal: builder.mutation({
      query: ({ token, goalId }) => ({
        url: `/goals/${goalId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Goals", id: "LIST" }],
    }),
    deleteGoal: builder.mutation({
      query: ({ token, goalId }) => ({
        url: `/goals/${goalId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Goals", id: "LIST" }],
    }),
  }),
});

export const { useFetchGoalsQuery, useAddGoalMutation, useDeleteGoalMutation } =
  goalsApiSlice;
