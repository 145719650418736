import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSalad } from "@fortawesome/pro-light-svg-icons";
import NutritionTracker from "./NutritionTracker";

const NutritionCover = (props) => {
  const { t } = useTranslation();

  const [areaPicker, setAreaPicker] = useState(true);
  const [openNutrition, setOpenNutrition] = useState(false);

  const handleNutrition = () => {
    const main = document.getElementById("snm-main");
    main?.classList.remove("slide-in");
    setTimeout(() => {
      setAreaPicker(false);
      setOpenNutrition(true);
    }, 500);
  };

  useEffect(() => {
    if (openNutrition) {
      const nutrition = document.getElementById("snm-nutrition");
      setTimeout(() => {
        nutrition?.classList.add("slide-in");
      }, 300);
    }
  }, [openNutrition]);

  useEffect(() => {
    if (areaPicker) {
      const main = document.getElementById("snm-main");
      setTimeout(() => {
        main?.classList.add("slide-in");
      }, 300);
    }
  }, [areaPicker]);

  const handleBackToMain = () => {
    if (openNutrition) {
      const nutrition = document.getElementById("snm-nutrition");
      nutrition?.classList.remove("slide-in");
      setTimeout(() => {
        setOpenNutrition(false);
        setAreaPicker(true);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      {areaPicker && (
        <div id="snm-main" className="tracker-container">
          <div className="ind-tracker mood">
            <div className="title">{t("T134")}</div>
            <div className="mood-area" onClick={handleNutrition}>
              <div>
                <FontAwesomeIcon icon={faSalad} className="mood-icon" />{" "}
                {t("T349")}{" "}
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="buttons mood">
            <div className="close-btn" onClick={props.closeTrackers}>
              {t("T015")}
            </div>
          </div>
        </div>
      )}{" "}
      {openNutrition && (
        <NutritionTracker
          handleBackToMain={handleBackToMain}
          closeTrackers={props.closeTrackers}
        />
      )}
    </React.Fragment>
  );
};
export default NutritionCover;
