import React, { useEffect, useState } from "react";
import "./Style.css";
import NumberSelect from "../numberSelect/NumberSelect";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function MeasurementsInput(props) {
  const [unitType, setUnitType] = useState("type1");
  const [skipActive, setSkipActive] = useState("");
  const [skipActive2, setSkipActive2] = useState("");

  function setTypeToggle(type) {
    setUnitType(type);
    saveValue(type);
  }

  function saveValue(item) {
    if (item !== "skipped") {
      setSkipActive("");
    }
    if (item !== "unsure") {
      setSkipActive2("");
    }
    props.onSetValue({ id: props.questionid, value: item });
  }

  function toggleSkipActive() {
    if (skipActive === "active") {
      setSkipActive("");
      props.onSetValue({ id: props.questionid, value: "" });
      setUnitType("type1");
    } else {
      setSkipActive("active");
      setSkipActive2("");
      props.onSetValue({ id: props.questionid, value: "skipped" });
    }
  }

  function toggleSkipActive2() {
    if (skipActive2 === "active") {
      setSkipActive2("");
      props.onSetValue({ id: props.questionid, value: "" });
      setUnitType("type1");
    } else {
      setSkipActive2("active");
      setSkipActive("");
      props.onSetValue({ id: props.questionid, value: "unsure" });
    }
  }

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      var value = props.assessmentValues[props.questionid];
      if (value === "skipped") {
        setSkipActive("active");
      }
      if (value === "unsure") {
        setSkipActive2("active");
      }

      if (value !== "skipped" && value !== "unsure") {
        setUnitType(value);
      }
    }
  }, [props.assessmentValues[props.questionid]]);

  const [blockOpacity, setBlockOpacity] = useState(0);

  useEffect(() => {
    setTimeout(function () {
      setBlockOpacity(1);
    }, 100);
  });

  return (
    <div
      id={props.questionid}
      className={"measures question_block" + props.width}
      style={{ opacity: props.fadeIn ? blockOpacity : "1" }}
    >
      <h3>{props.questionTitle}</h3>
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      {props.skipText ? (
        <span
          className={"suffixLabel " + skipActive + " fs-exclude"}
          onClick={toggleSkipActive}
        >
          {props.skipText}
        </span>
      ) : null}
      {props.skipText2 ? (
        <span
          className={"suffixLabel " + skipActive2 + " fs-exclude"}
          onClick={toggleSkipActive2}
        >
          {props.skipText2}
        </span>
      ) : null}
      <div className="rightLabel">
        <div
          className={
            unitType === "type1"
              ? "measureSwitch measureType active"
              : "measureSwitch measureType"
          }
          id={props.questionid + "settype1"}
          onClick={() => setTypeToggle("type1")}
        >
          {props.type1Text}
        </div>
        <div
          className={
            unitType === "type2"
              ? "measureSwitch measureType active"
              : "measureSwitch measureType"
          }
          id={props.questionid + "settype2"}
          onClick={() => setTypeToggle("type2")}
        >
          {props.type2Text}
        </div>
      </div>
      <div className="answer_block multiple_number_select">
        {props.type === "height" && unitType === "type1" && (
          <React.Fragment>
            <NumberSelect
              questionid="height_ft"
              minlimit="0"
              maxlimit="8"
              trailingText="ft"
              width=""
              type="multiple"
              startVal="5"
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              disabled={skipActive === "active" || skipActive2 === "active"}
            />
            <NumberSelect
              questionid="height_in"
              minlimit="0"
              maxlimit="11"
              trailingText="in"
              width=""
              type="multiple"
              startVal="6"
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              disabled={skipActive === "active" || skipActive2 === "active"}
            />
          </React.Fragment>
        )}
        {props.type === "height" && unitType === "type2" && (
          <NumberSelect
            questionid="height_cm"
            minlimit="0"
            maxlimit="300"
            trailingText="cm"
            width=""
            type="multiple"
            startVal="160"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
            disabled={skipActive === "active" || skipActive2 === "active"}
          />
        )}
        {props.type === "weight" && unitType === "type1" && (
          <React.Fragment>
            <NumberSelect
              questionid="weight_st"
              minlimit="0"
              maxlimit="100"
              trailingText="st"
              width=""
              type="multiple"
              startVal="12"
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              disabled={skipActive === "active" || skipActive2 === "active"}
            />
            <NumberSelect
              questionid="weight_lb"
              minlimit="0"
              maxlimit="13"
              trailingText="lb"
              width=""
              type="multiple"
              startVal="0"
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
              disabled={skipActive === "active" || skipActive2 === "active"}
            />
          </React.Fragment>
        )}
        {props.type === "weight" && unitType === "type2" && (
          <NumberSelect
            questionid="weight_kg"
            minlimit="0"
            maxlimit="500"
            trailingText="kg"
            width=""
            type="multiple"
            startVal="80"
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
            disabled={skipActive === "active" || skipActive2 === "active"}
          />
        )}
      </div>
    </div>
  );
}

export default MeasurementsInput;
