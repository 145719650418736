// goalsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const mediaSlice = createSlice({
  name: "mediaSliders",
  initialState: {
    programme: {
      _id: "",
      chid: "",
      title: "",
      description: "",
      label: "",
      tags: [],
      signposting: false,
      days: [],
      progress: "",
      minutes_watched: "",
      author: {},
      resources: "",
      background_image: "",
      thumbnail_image: "",
      favourite: false,
      like: false,
      dislike: false,
      related_media: [],
      active_day: 0,
      chapters: [],
      completed: false,
    },
    masterclass: {
      _id: "",
      chid: "",
      title: "",
      description: "",
      label: "",
      tags: [],
      signposting: false,
      chapters: [],
      length: "",
      author: {},
      resources: "",
      background_image: "",
      thumbnail_image: "",
      like: false,
      dislike: false,
      progress: "",
      completed: false,
      minutes_watched: "",
      favourite: false,
      related_media: [],
      progress_array: [],
    },
    singleMedia: {
      _id: "",
      regions: [],
      completed: false,
      favourite: false,
      minutes_watched: "",
      progress: "",
      related_media: [],
      video_cover_image: "",
      chid: "",
      title: "",
      description: "",

      category: "", // recipe || workout || session || service || take-action || soundscape || masterclass (Chapters)
      type: "", // audio || video || null
      label: "",
      snooze: false,
      mindfulness: false,

      tags: [],
      transcript: "",
      signposting: false,
      waiver: false,

      url: "",
      resources: "",
      length: "",
      author: {},

      //Workouts/Recipes
      difficulty: "",

      //Workouts
      exercises: [],
      equipment: [],

      //Recipes
      serves: "",
      ingredients: [],
      method: [],
      kcal: "",
      carbs: "",
      fat: "",
      protein: "",

      //Service/Take Action
      action_url: "",
      action_string: "",

      background_image: "",
      thumbnail_image: "",

      like: "",
      dislike: "",
      media: "", //for take-action
    },
  },
  reducers: {
    setSingleMedia: (state, action) => {
      if (action.payload) {
        state.singleMedia._id = action.payload._id;
        state.singleMedia.regions = action.payload.regions;
        state.singleMedia.completed = action.payload.completed;
        state.singleMedia.favourite = action.payload.favourite;
        state.singleMedia.minutes_watched = action.payload.minutes_watched;
        state.singleMedia.progress = action.payload.progress;
        state.singleMedia.related_media = action.payload.related_media;
        state.singleMedia.video_cover_image = action.payload.video_cover_image;
        state.singleMedia.chid = action.payload.chid;
        state.singleMedia.title = action.payload.title;
        state.singleMedia.description = action.payload.description;
        state.singleMedia.category = action.payload.category;
        state.singleMedia.type = action.payload.type;
        state.singleMedia.label = action.payload.label;
        state.singleMedia.snooze = action.payload.snooze;
        state.singleMedia.mindfulness = action.payload.mindfulness;
        state.singleMedia.tags = action.payload.tags;
        state.singleMedia.transcript = action.payload.transcript;
        state.singleMedia.signposting = action.payload.signposting;
        state.singleMedia.waiver = action.payload.waiver;
        state.singleMedia.url = action.payload.url;
        state.singleMedia.resources = action.payload.resources;
        state.singleMedia.length = action.payload.length;
        state.singleMedia.author = action.payload.author;
        state.singleMedia.difficulty = action.payload.difficulty;
        state.singleMedia.exercises = action.payload.exercises;
        state.singleMedia.equipment = action.payload.equipment;
        state.singleMedia.serves = action.payload.serves;
        state.singleMedia.ingredients = action.payload.ingredients;
        state.singleMedia.method = action.payload.method;
        state.singleMedia.kcal = action.payload.kcal;
        state.singleMedia.carbs = action.payload.carbs;
        state.singleMedia.fat = action.payload.fat;
        state.singleMedia.protein = action.payload.protein;
        state.singleMedia.action_url = action.payload.action_url;
        state.singleMedia.action_string = action.payload.action_string;
        state.singleMedia.video_cover_image = action.payload.video_cover_image;
        state.singleMedia.background_image = action.payload.background_image;
        state.singleMedia.thumbnail_image = action.payload.thumbnail_image;
        state.singleMedia.like = action.payload.like;
        state.singleMedia.dislike = action.payload.dislike;
        state.singleMedia.media = action.payload.media;
      }
    },
    setMasterclass: (state, action) => {
      if (action.payload) {
        state.masterclass._id = action.payload._id;
        state.masterclass.completed = action.payload.completed;
        state.masterclass.minutes_watched = action.payload.minutes_watched;
        state.masterclass.progress = action.payload.progress;
        state.masterclass.related_media = action.payload.related_media;
        state.masterclass.chid = action.payload.chid;
        state.masterclass.title = action.payload.title;
        state.masterclass.description = action.payload.description;
        state.masterclass.label = action.payload.label;
        state.masterclass.tags = action.payload.tags;
        state.masterclass.signposting = action.payload.signposting;
        state.masterclass.length = action.payload.length;
        state.masterclass.author = action.payload.author;
        state.masterclass.background_image = action.payload.background_image;
        state.masterclass.thumbnail_image = action.payload.thumbnail_image;
        state.masterclass.favourite = action.payload.favourite;
        state.masterclass.like = action.payload.like;
        state.masterclass.dislike = action.payload.dislike;
        state.masterclass.chapters = action.payload.chapters;
        state.masterclass.resources = action.payload.resources;
        let progressArr = [];
        action.payload.chapters.forEach((chapter) => {
          progressArr.push(chapter.progress || 0);
        });
        state.masterclass.progress_array = progressArr;
      }
    },
    setProgramme: (state, action) => {
      if (action.payload) {
        state.programme._id = action.payload._id;
        state.programme.completed = action.payload.completed;
        state.programme.minutes_watched = action.payload.minutes_watched;
        state.programme.progress = action.payload.progress;
        state.programme.related_media = action.payload.related_media;
        state.programme.chid = action.payload.chid;
        state.programme.days = action.payload.days;
        state.programme.title = action.payload.title;
        state.programme.description = action.payload.description;
        state.programme.label = action.payload.label;
        state.programme.tags = action.payload.tags;
        state.programme.signposting = action.payload.signposting;
        state.programme.length = action.payload.length;
        state.programme.author = action.payload.author;
        state.programme.background_image = action.payload.background_image;
        state.programme.thumbnail_image = action.payload.thumbnail_image;
        state.programme.favourite = action.payload.favourite;
        state.programme.like = action.payload.like;
        state.programme.dislike = action.payload.dislike;
        state.programme.chapters = action.payload.chapters;
        state.programme.resources = action.payload.resources;
      }
    },
    updateStateProgress: (state, action) => {
      if (action.payload) {
        state.singleMedia.progress = action.payload.progress;
        state.singleMedia.completed = action.payload.completed;
      }
    },
    updateMCProgressArray: (state, action) => {
      let index = state.masterclass.chapters.findIndex(
        (chapter) => chapter._id === action.payload.id
      );
      if (action.payload) {
        state.masterclass.progress_array[index] = action.payload.progress;
      }
    },
  },
});

// your exported actions and reducer...
export const {
  setSingleMedia,
  setMasterclass,
  setProgramme,
  updateStateProgress,
  updateMCProgressArray,
} = mediaSlice.actions;
export default mediaSlice.reducer;
