import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import "./PromoContent.css";

const PromoContent = ({
  label,
  title,
  fade,
  labelColourFirst,
  labelColourSecond,
  subTitle,
  url,
  company_logo,
}) => {
  return (
    <div className="promoContent">
      <div
        className="promoContent_fade"
        style={{
          background: `linear-gradient(to bottom, transparent 0%, transparent 60%, ${fade} 100%)`,
        }}
      />
      {company_logo && (
        <img
          src={company_logo}
          className="promoContent_companyLogo"
          alt="company logo"
        />
      )}
      <div className="promoContent_labelContainer">
        {label}
        <div
          className="promoContent_outerLabelContainer"
          style={{
            background: `linear-gradient(to right, ${labelColourFirst} 0%,  ${labelColourSecond} 100%)`,
          }}
        />
      </div>
      <div className="promoContent_title">{title}</div>
      {subTitle && (
        <div className="promoContent_subtitleContainer">
          <div className="promoContent_subtitle">{subTitle}</div>{" "}
          {url && (
            <FontAwesomeIcon icon={faArrowRight} className="small-icon " />
          )}
        </div>
      )}
    </div>
  );
};

export default PromoContent;
