import React, { useContext, useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import "./styles/Profile.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faChevronRight } from "@fortawesome/pro-light-svg-icons";

import Header from "../shared/header/MainHeader";
import Footer from "../shared/navfooter/Footer";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";

import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import Settings from "./profileSettings/Settings";
import Stats from "./profileStats/Stats";
import Invite from "./profileFnF/Invite";
import { useTranslation } from "react-i18next";

const Profile = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageid = window.location.pathname.split("/")[2];
  const subpageid = window.location.pathname.split("/")[3] || "";
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigation = [
    {
      target: 31,
      title: "settings",
      code: "S001",
      fullstory: "settings_nav",
    },
    { target: 32, title: "stats", code: "S003", fullstory: "stats_nav" },
    { target: 33, title: "invite", code: "S004", fullstory: "invite_nav" },
    {
      target: 34,
      title: "assessment",
      code: "S132",
      fullstory: "assessment_nav",
    },
    {
      target: 35,
      title: "leadership hub",
      code: "S133",
      fullstory: "leadership-hub_nav",
    },
  ];
  const [activePage, setActivePage] = useState();
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setHide(window.innerWidth < 1240);
    };

    // Check screen size on mount
    checkScreenSize();

    // Add event listener for resize events
    window.addEventListener("resize", checkScreenSize);

    // Cleanup function to remove event listener on unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (!activePage) {
      let page = navigation.find((item) => item.title === pageid);
      setActivePage(page);
      // navigate(`/profile/${navigation[0].title}`);
    }
  }, []);

  const toggleActivePage = (tabid) => {
    if (tabid === 3) {
      navigate("/assessment");
    } else if (tabid === 4) {
      navigate("/company-report/live-analytics");
    } else {
      setActivePage(navigation[tabid]);
      navigate(`/profile/${navigation[tabid].title}`);
    }
  };

  // useEffect(() => {
  //   if (scrolled) {
  //     window.scrollTo(0, scrolled);
  //   }
  // }, [scrolled]);

  //scroll top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageid, subpageid]);

  // useEffect(() => {
  //   document.body.classList.remove("popup_open");
  // }, [pageid, subpageid]);
  return (
    <div id="main_container" className="foryou health-area platform glowrm">
      {isLoading && <LoadingSpinner asOverlay />}

      {auth.isLoggedIn && (
        <React.Fragment>
          <div id="nav_container">
            <Header />
          </div>
          <div className="heading">
            {/* <Link to="/for-you">
              <FontAwesomeIcon icon={faHouse} className="icon home" />
            </Link> */}
            {/* <FontAwesomeIcon icon={faChevronRight} className="icon" /> */}
            <h2>{t("S002")}</h2>
          </div>
          {activePage && (
            <ul className="nav_slider na">
              {navigation.map((item, index) => {
                // Skip this item if index is 3 and auth.access is not 10 or tags length is less than 1

                // Remove invite tab if user is F&F
                if (index === 2 && (auth.access === "2" || auth.isHub)) {
                  return null;
                }

                //remove assessment tab if user is not admin or has tags
                if (
                  index === 3 &&
                  !(auth.access === "10" || auth.tags.length < 1)
                ) {
                  return null;
                }

                //remove leadership hub tab for employees and f&f
                if (
                  index === 4 &&
                  !(auth.access === "3" || auth.access === "10")
                ) {
                  return null;
                }

                if (activePage && item.target === activePage.target) {
                  return (
                    <li
                      key={item.target}
                      id={item.fullstory}
                      className="active"
                    >
                      {t(item.code)}
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={item.target}
                      id={item.fullstory}
                      onClick={() => {
                        toggleActivePage(index);
                      }}
                    >
                      {t(item.code)}
                    </li>
                  );
                }
              })}
            </ul>
          )}
          {activePage && activePage.target === navigation[0].target && (
            <div className="page-container">
              <Settings hide={hide} />
            </div>
          )}
          {activePage && activePage.target === navigation[1].target && (
            <div className="page-container">
              <Stats hide={hide} />
            </div>
          )}
          {activePage && activePage.target === navigation[2].target && (
            <div className="page-container">
              <Invite hide={hide} />
            </div>
          )}
          <Footer />
        </React.Fragment>
      )}
    </div>
  );
};

export default Profile;
