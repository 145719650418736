import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Cursor from "./Cursor";

import "../styles/componentStyles/CircularSlider.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoonStars,
  faSunBright,
  faBed,
  faAlarmClock,
  faBowlSpoon,
  faUtensils,
} from "@fortawesome/pro-regular-svg-icons";

const outerWidth = 475;
const outerStroke = 75;
const stroke = 2.5;
const radius = 200;
const PI = Math.PI;
const centre = { x: outerWidth / 2, y: outerWidth / 2 };
const TAU = 2 * PI;
const sweepFlag = 1;

const CircularSlider = (props) => {
  const { t } = useTranslation();

  const arc = (x, y, largeArcFlag) => {
    return `A ${radius} ${radius} 0 ${
      largeArcFlag ? 1 : 0
    } ${sweepFlag} ${x} ${y}`;
  };

  const convertToPI = (value) => {
    const xPos = value.x - centre.x;
    const yPos = value.y - centre.y;
    const angle = Math.atan2(yPos, xPos);
    const PIValue = normalize(angle);
    return PIValue;
  };

  const normalize = (angle) => {
    const rest = angle % TAU;
    return rest > 0 ? rest : TAU + rest;
  };

  const angleToTime = (angle) => {
    const duration = Math.round((24 * 60 * angle) / TAU);
    let minutes = duration % 60;
    let hours = (duration - minutes) / 60;

    const roundedMinutes = Math.round(minutes / 5) * 5;
    if (roundedMinutes === 60) {
      hours += 1;
      minutes = 0;
    } else {
      minutes = roundedMinutes;
    }

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return { hours: formattedHours, minutes: formattedMinutes };
  };

  const timeToAngle = (time) => {
    const [hours, minutes] = time.split(":").map(Number);

    const totalMinutes = hours * 60 + minutes;
    const angle = (totalMinutes * 2) / (24 * 60);

    return angle * PI;
  };

  const absoluteDuration = (start, end) =>
    start > end ? end + (TAU - start) : end - start;

  const [isEndPressed, setIsEndPressed] = useState(false);
  const [isStartPressed, setIsStartPressed] = useState(false);
  const [isSliderPressed, setIsSliderPressed] = useState(false);
  const [startAngle, setStartAngle] = useState(false);
  const [sliderCursor, setSliderCursor] = useState("grab");
  const [startSliderCursor, setStartSliderCursor] = useState("grab");
  const [endSliderCursor, setEndSliderCursor] = useState("grab");
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [startTime, setStartTime] = useState(false);
  const [endPos, setEndPos] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const [startPos, setStartPos] = useState(false);

  useEffect(() => {
    if (props.startTime) {
      setStart(() => timeToAngle(props.startTime));
    }
  }, [props.startTime]);

  useEffect(() => {
    if (start) {
      setStartTime(() => angleToTime(start));
      setStartPos(() => ({
        x: centre.x + radius * Math.cos(start),
        y: centre.y + radius * Math.sin(start),
      }));
    }
  }, [start]);

  useEffect(() => {
    if (props.endTime) {
      setEnd(() => timeToAngle(props.endTime));
    }
  }, [props.endTime]);

  useEffect(() => {
    if (end) {
      setEndTime(() => angleToTime(end));
      setEndPos(() => ({
        x: centre.x + radius * Math.cos(end),
        y: centre.y + radius * Math.sin(end),
      }));
    }
  }, [end]);

  // let start = timeToAngle(props.startTime) || 1.834 * PI;
  // let end = timeToAngle(props.endTime) || 0.5 * PI;

  const animatedProps = React.useMemo(() => {
    const p1 = startPos;
    const p2 = endPos;
    const startPI = convertToPI(startPos);
    const endPI = convertToPI(endPos);
    const duration = absoluteDuration(startPI, endPI);
    return {
      d: `M ${p1.x} ${p1.y} ${arc(p2.x, p2.y, duration > PI)}`,
    };
  }, [startPos, endPos]);

  const handleMouseMove = (event) => {
    if (isEndPressed || isStartPressed || (isSliderPressed && startAngle)) {
      //check if mobile browser is used
      if (event.type === "touchmove") {
        event.preventDefault(); // Prevent the default behavior
        event = event.touches[0];
      }
      const { clientX, clientY } = event;
      const slider = document.getElementById("slider");
      const rect = slider.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      let angle =
        Math.atan2(clientY - centerY, clientX - centerX) * (180 / Math.PI);

      if (isEndPressed) {
        const newEnd = (((angle + 90) * 2) / 360) * PI;
        const newEndTime = angleToTime(normalize(newEnd));
        setEndTime(newEndTime);
        props.saveResponse("data", {
          id: props.questionid[1],
          value: `${newEndTime.hours}:${newEndTime.minutes}`,
        });
        setEndPos({
          x: centre.x + radius * Math.cos(newEnd),
          y: centre.y + radius * Math.sin(newEnd),
        });
      } else if (isStartPressed) {
        const newStart = (((angle + 90) * 2) / 360) * PI;
        const newStartTime = angleToTime(normalize(newStart));
        setStartTime(newStartTime);
        props.saveResponse("data", {
          id: props.questionid[0],
          value: `${newStartTime.hours}:${newStartTime.minutes}`,
        });
        setStartPos({
          x: centre.x + radius * Math.cos(newStart),
          y: centre.y + radius * Math.sin(newStart),
        });
      } else if (isSliderPressed && startAngle) {
        const travelledAngle = angle - startAngle;
        const oldEnd = convertToPI(endPos);
        const newEnd = oldEnd + ((travelledAngle * 2) / 360) * PI;
        const newEndTime = angleToTime(normalize(newEnd));
        setEndTime(newEndTime);
        props.saveResponse("data", {
          id: props.questionid[1],
          value: `${newEndTime.hours}:${newEndTime.minutes}`,
        });
        setEndPos({
          x: centre.x + radius * Math.cos(newEnd),
          y: centre.y + radius * Math.sin(newEnd),
        });
        const oldStart = convertToPI(startPos);
        const newStart = oldStart + ((travelledAngle * 2) / 360) * Math.PI;
        const newStartTime = angleToTime(normalize(newStart));
        setStartTime(newStartTime);
        props.saveResponse("data", {
          id: props.questionid[1],
          value: `${newStartTime.hours}:${newStartTime.minutes}`,
        });
        setStartPos({
          x: centre.x + radius * Math.cos(newStart),
          y: centre.y + radius * Math.sin(newStart),
        });
      }
    }
  };

  const handleMouseUp = () => {
    setIsEndPressed(false);
    setIsStartPressed(false);
    setIsSliderPressed(false);
    setStartAngle(false);
    setSliderCursor("grab");
    setStartSliderCursor("grab");
    setEndSliderCursor("grab");
    let slider = document.getElementById("slider");
    slider.removeEventListener("mousemove", handleMouseMove);
    slider.removeEventListener("touchmove", handleMouseMove);

    let endEl = document.getElementById("end");
    endEl.removeEventListener("mousemove", handleMouseMove);
    endEl.removeEventListener("touchmove", handleMouseMove);

    let startEl = document.getElementById("start");
    startEl.removeEventListener("mouseup", handleMouseMove);
    startEl.removeEventListener("touchmove", handleMouseMove);
  };

  useEffect(() => {
    let endEl = document.getElementById("end");

    if (isEndPressed && endEl) {
      endEl.addEventListener("mousemove", handleMouseMove);
      endEl.addEventListener("touchmove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchend", handleMouseUp);

      return () => {
        endEl.removeEventListener("mousemove", handleMouseMove);
        endEl.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchend", handleMouseUp);
      };
    }
  }, [isEndPressed]);

  useEffect(() => {
    let startEl = document.getElementById("start");

    if (isStartPressed && startEl) {
      startEl.addEventListener("mousemove", handleMouseMove);
      startEl.addEventListener("touchmove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchend", handleMouseUp);

      return () => {
        startEl.removeEventListener("mousemove", handleMouseMove);
        startEl.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchend", handleMouseUp);
      };
    }
  }, [isStartPressed]);

  useEffect(() => {
    let slider = document.getElementById("slider");

    if (isSliderPressed && slider && startAngle) {
      slider.addEventListener("mousemove", handleMouseMove);
      slider.addEventListener("touchmove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchend", handleMouseUp);

      return () => {
        slider.removeEventListener("mousemove", handleMouseMove);
        slider.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchend", handleMouseUp);
      };
    }
  }, [isSliderPressed, startAngle]);

  const handleEndMouseDown = () => {
    setIsEndPressed(true);
    setEndSliderCursor("grabbing");
  };

  const handleStartMouseDown = () => {
    setIsStartPressed(true);
    setStartSliderCursor("grabbing");
  };

  const handleSliderMouseDown = (event) => {
    if (event.type === "touchstart") {
      event.preventDefault(); // Prevent the default behavior
      event = event.touches[0];
    }
    const { clientX, clientY } = event;

    const rect = event.target.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    let angle =
      Math.atan2(clientY - centerY, clientX - centerX) * (180 / Math.PI);
    setStartAngle(angle);
    setIsSliderPressed(true);
    setSliderCursor("grabbing");
  };

  return (
    <div className="circular-slider">
      {startTime && endTime && (
        <div className="slider-labels">
          <div>
            <FontAwesomeIcon
              icon={props.meals ? faBowlSpoon : faBed}
              className="icon "
            />
            {props.meals ? <p>{t("T058")}</p> : <p>{t("T060")}</p>}
            <div className="sub title fs-mask">
              {startTime.hours}:{startTime.minutes}
            </div>
          </div>
          <div>
            <FontAwesomeIcon
              icon={props.meals ? faUtensils : faAlarmClock}
              className="icon "
            />
            {props.meals ? <p>{t("T059")}</p> : <p>{t("T061")}</p>}
            <div className="sub title fs-mask">
              {endTime.hours}:{endTime.minutes}
            </div>
          </div>
        </div>
      )}
      {startPos && endPos && (
        <div className="fs-exclude">
          <svg
            className="circular-slider-container"
            width={outerWidth}
            height={outerWidth}
          >
            <g>
              <g>
                <circle
                  className="outer-circle"
                  cx="50%"
                  cy="50%"
                  r={(outerWidth - outerStroke) / 2}
                  stroke="#f1ebf4"
                  strokeWidth={outerStroke}
                  fill="#fff"
                  style={{
                    stroke: "#f1ebf4",
                    strokeWidth: `${outerStroke}`,
                    fill: "#fff",
                    userSelect: "none",
                  }}
                />
                {[...Array(24).keys()]
                  .filter((num) => num % 2 === 0)
                  .map((item, i) => {
                    const delta = TAU / 12;
                    const theta = delta * i - PI / 2;
                    const p1 = {
                      x: centre.x + (radius - 60) * Math.cos(theta),
                      y: centre.y + (radius - 60) * Math.sin(theta),
                    };

                    return (
                      <React.Fragment key={i}>
                        <g transform={`translate(${p1.x - 5}, ${p1.y + 5})`}>
                          <text
                            x={0}
                            y={0}
                            fontWeight="bold"
                            fontSize={item % 6 === 0 ? 14 : 12}
                            fill={item % 6 === 0 ? "#281D2D" : "#AAA8AB"}
                            fontFamily="FuturaPT-Medium"
                            style={{
                              WebkitUserSelect: "none",
                              MozUserSelect: "none",
                              MsUserSelect: "none",
                              userSelect: "none",
                            }}
                          >
                            {item}
                          </text>
                          {item === 0 && (
                            <foreignObject
                              x={-10}
                              y={5}
                              width={35}
                              height={30}
                              style={{
                                textAlign: "center",
                                color: "#ddcedb",
                                userSelect: "none",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faMoonStars}
                                  style={{
                                    fontSize: "30px",
                                    color: "#41c0d3",
                                  }}
                                />
                              </div>
                            </foreignObject>
                          )}
                          {item === 12 && (
                            <foreignObject
                              x={-10}
                              y={-45}
                              width={35}
                              height={30}
                              style={{
                                textAlign: "center",
                                color: "#ddcedb",
                                userSelect: "none",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faSunBright}
                                  style={{
                                    fontSize: "30px",
                                    color: "#fcc954",
                                  }}
                                />
                              </div>
                            </foreignObject>
                          )}
                        </g>
                      </React.Fragment>
                    );
                  })}
              </g>
              <circle
                className="track"
                cx="50%"
                cy="50%"
                r={radius}
                stroke="#fff"
                strokeWidth={stroke}
                fill="transparent"
              />
              <defs>
                <mask id="mask">
                  <path
                    stroke="#fff"
                    strokeWidth={stroke}
                    {...animatedProps}
                    transform={`translate(${centre.x}, ${
                      centre.y
                    }) rotate(-90) translate(${-centre.x}, ${-centre.y})`}
                  />
                </mask>
              </defs>
              <svg id="slider" mask="url(#mask)">
                <circle
                  cx={centre.x}
                  cy={centre.y}
                  r={radius + stroke / 2}
                  fill="#e92364"
                  style={{ cursor: `${sliderCursor}` }}
                  onMouseDown={handleSliderMouseDown}
                  onTouchStart={handleSliderMouseDown}
                />
              </svg>
              <Cursor
                pos={startPos}
                cx={centre.x}
                cy={centre.y}
                sliderCursor={startSliderCursor}
                region="start"
                handleStartMouseDown={handleStartMouseDown}
                meals={props.meals}
              />
              <Cursor
                pos={endPos}
                region="end"
                cx={centre.x}
                cy={centre.y}
                sliderCursor={endSliderCursor}
                handleEndMouseDown={handleEndMouseDown}
                meals={props.meals}
              />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export default CircularSlider;
