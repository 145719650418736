import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBattery,
  faBoltLightning,
  faDroplet,
  faLightbulb,
  faMartiniGlass,
  faMoonStars,
  faSalad,
  faSkiing,
  faSmile,
  faWind,
  faBullseyeArrow,
  faSpa,
  faTrashCan,
  faWavePulse,
  faEllipsis,
  faTick,
  faCheck,
  faBell,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { colours } from "../../assets/colours";
import { Link } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PinkButton1 from "../components/styledComponents/pinkButton1/PinkButton1";

const NotificationsList = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [recentNotifications, setRecentNotifications] = useState([
    {
      id: 1,
      type: "content",
      title: "Exercise & Asthma",
      subtitle: t("N005"),
      time: "2min",
      days: "Mon, Wed, Fri",
      repeat: false,
      read: true,
    },
    {
      id: 2, // sleep
      type: "tracker",
      title: "How did you sleep?",
      subtitle: "It's time to track your sleep results.",
      icon: faMoonStars,
      position: true,
      time: "2h",
      days: "Mon, Wed, Fri",
      repeat: false,
      read: false,
    },
    {
      id: 3,
      type: "content",
      title: "Supporting your Partner through the Menopause",
      subtitle: t("N005"),
      time: "3h",
      days: "Mon, Wed, Fri",
      repeat: false,
      read: false,
    },
    // {
    //   id: 1, //goals
    //   type: "health",
    //   title: t("N017"),
    //   subtitle: t("N018"),
    //   icon: faBullseyeArrow,
    //   position: true,
    //   time: "10:00",
    //   days: "Mon, Wed, Fri",
    //   repeat: false,
    // },
    // {
    //   id: 2, //meditate
    //   type: "health",
    //   title: t("N011"),
    //   subtitle: t("N012"),
    //   icon: faSpa,
    //   position: true,
    //   time: "10:00",
    //   days: "Mon, Wed, Fri",
    //   repeat: false,
    // },
    // {
    //   id: 3, //get moving
    //   type: "health",
    //   title: t("N013"),
    //   subtitle: t("N014"),
    //   icon: faWavePulse,
    //   position: true,
    //   time: "10:00",
    //   days: "Mon, Wed, Fri",
    //   repeat: false,
    // },
    // {
    //   id: 4, //drink water
    //   type: "health",
    //   title: t("N019"),
    //   subtitle: t("N020"),
    //   icon: faDroplet,
    //   position: true,
    //   time: "10:00",
    //   days: "Mon, Wed, Fri",
    //   repeat: false,
    // },
    // {
    //   id: 5, //sleep routine
    //   type: "health",
    //   title: t("N015"),
    //   subtitle: t("N016"),
    //   icon: faMoonStars,
    //   position: true,
    //   time: "10:00",
    //   days: "Mon, Wed, Fri",
    //   repeat: false,
    // },
  ]);

  const [earlierNotifications, setEarlierNotifications] = useState([
    {
      id: "1e", //meditate
      type: "health",
      title: t("N011"),
      subtitle: t("N012"),
      icon: faSpa,
      position: true,
      time: "1d",
      days: "Mon, Wed, Fri",
      repeat: false,
      read: true,
    },
    {
      id: "2e",
      type: "content",
      title: "Exercise & Asthma",
      subtitle: t("N005"),
      time: "5d",
      days: "Mon, Wed, Fri",
      repeat: false,
      read: true,
    },
    {
      id: "3e",
      type: "content",
      title: "Supporting your Partner through the Menopause",
      subtitle: t("N005"),
      time: "13d",
      days: "Mon, Wed, Fri",
      repeat: false,
      read: true,
    },
    // {
    //   id: 3,
    //   type: "content",
    //   title: "The Stream",
    //   subtitle: t("N005"),
    //   time: "10:00",
    //   days: "Mon, Wed, Fri",
    //   repeat: false,
    // },
  ]);

  const [trackerNotifications, setTrackerNotifications] = useState([
    {
      id: 1, // sleep
      type: "tracker",
      title: t("T345"),
      icon: faMoonStars,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 2, //activity
      type: "tracker",

      title: t("T346"),
      icon: faSkiing,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 3, //stress
      type: "tracker",

      title: t("T347"),
      icon: faBoltLightning,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 4, //mood
      type: "tracker",

      title: t("T348"),
      icon: faSmile,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 5, //nutrition
      type: "tracker",

      title: t("T349"),
      icon: faSalad,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 6, //hydration
      type: "tracker",

      title: t("T354"),
      icon: faDroplet,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 7, //alcohol
      type: "tracker",

      title: t("T356"),
      icon: faMartiniGlass,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 8, //productivity
      type: "tracker",

      title: t("T357"),
      icon: faLightbulb,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 9, //energy
      type: "tracker",

      title: t("T358"),
      icon: faBattery,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
    {
      id: 10, //smoking
      type: "tracker",

      title: t("T355"),
      icon: faWind,
      position: true,
      time: "10:00",
      days: "Mon, Wed, Fri",
      repeat: false,
    },
  ]);
  const [activeTooltip, setActiveTooltip] = useState(0);

  const toggleTooltip = (id) => {
    if (activeTooltip === id) {
      setActiveTooltip(0);
    } else {
      setActiveTooltip(id);
    }
  };

  const handleTooltipClick = (id, action) => {
    if (action === "read") {
      let newNotifications;
      //if id ends with e, it's from earlier notifications
      if (id.toString().endsWith("e")) {
        newNotifications = earlierNotifications.map((item) => {
          if (item.id === id) {
            item.read = !item.read;
          }
          return item;
        });
        setEarlierNotifications(newNotifications);
      } else {
        newNotifications = recentNotifications.map((item) => {
          if (item.id === id) {
            item.read = !item.read;
          }
          return item;
        });
        setRecentNotifications(newNotifications);
      }
    } else if (action === "delete") {
      let newNotifications;
      //if id ends with e, it's from earlier notifications
      if (id.toString().endsWith("e")) {
        newNotifications = earlierNotifications.filter(
          (item) => item.id !== id
        );
        setEarlierNotifications(newNotifications);
      } else {
        newNotifications = recentNotifications.filter((item) => item.id !== id);
        setRecentNotifications(newNotifications);
      }
    } else if (action === "settings") {
      setActiveTooltip(0);
      navigate("/profile/settings/notifications");
    }
  };

  const getNotifications = (notifications) => {
    return notifications.map((item, index) => {
      return (
        <div
          className={"notification-item " + (item.read ? "" : "unread")}
          key={index}
        >
          {item.type === "content" ? (
            <div className="notification-cont">
              <div className="circle"></div>
              <div className="content-remind">
                <span>{item.subtitle}</span>
                <div className="slider-text">{item.title}</div>
              </div>
            </div>
          ) : (
            <div className="notification-cont">
              <FontAwesomeIcon
                icon={item.icon}
                style={{
                  color: `${
                    item.type === "health" ? colours.green : colours.orange
                  }`,
                }}
                className="notification-icon"
              />
              <div className="content-remind">
                <div
                  className="slider-text"
                  style={{
                    color: `${
                      item.type === "health" ? colours.green : colours.orange
                    }`,
                  }}
                >
                  {item.title}
                </div>
                <span>{item.subtitle}</span>
              </div>
            </div>
          )}
          <div className="notification-actions">
            <div className="time">{item.time}</div>
            <div
              className="tooltip-toggle"
              onClick={() => toggleTooltip(item.id)}
            >
              <FontAwesomeIcon
                icon={faEllipsis}
                style={{ cursor: "pointer" }}
              />
              <div
                className="notifications-tooltip"
                style={{
                  display: activeTooltip === item.id ? "block" : "none",
                }}
              >
                <div
                  className="tooltip-item"
                  onClick={() => handleTooltipClick(item.id, "read")}
                >
                  <div className="item-btn">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="item-text">
                    {item.read ? t("N021") : t("N006")}
                  </div>
                </div>
                <div
                  className="tooltip-item"
                  onClick={() => handleTooltipClick(item.id, "delete")}
                >
                  <div className="item-btn">
                    <FontAwesomeIcon icon={faTrashCan} />
                  </div>
                  <div className="item-text">{t("N007")}</div>
                </div>
                <div
                  className="tooltip-item"
                  onClick={() => handleTooltipClick(item.id, "settings")}
                >
                  <div className="item-btn">
                    <FontAwesomeIcon icon={faCog} />
                  </div>
                  <div className="item-text">{t("N008")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="notifications-list">
      <div className="empty-list">
        <div className="icons-cont">
          <FontAwesomeIcon icon={faBell} className="ringing-bell" />
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: colours.green }}
            className="delayed-check"
          />
        </div>
        <div className="empty-title">{t("N001")}</div>
        <div className="empty-text">{t("N002")}</div>
        <PinkButton1
          onClick={() => navigate("/profile/settings/notifications")}
        >
          {t("N003")}
        </PinkButton1>
      </div>
      {/* <div className="notification-header">
        <div className="notification-title">Notifications</div>
        <Link to="/profile/settings/notifications">
          <FontAwesomeIcon icon={faCog} className="cog-icon" />
        </Link>
      </div>
      <div className="scrollable">
        <div className="notification-title wi">Recent</div>
        <div className="notifications-block">
          {getNotifications(recentNotifications)}
        </div>

        <div className="notification-title wi">Earlier</div>
        <div className="notifications-block">
          {getNotifications(earlierNotifications)}
        </div>
      </div> */}
    </div>
  );
};

export default NotificationsList;
