import { useEffect, useState } from "react";

import "../styles/componentStyles/MindfulMinutes.css";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-thin-svg-icons";
const MindfulMinutes = (props) => {
  const { t } = useTranslation();

  const removeForbiddenChars = (inputString) => {
    const forbiddenCharsRegex = /[+\-.]/g;
    let cleanedString = inputString.replace(forbiddenCharsRegex, "");
    if (cleanedString.startsWith("0") && cleanedString.length > 1) {
      cleanedString = cleanedString.slice(1);
    }
    return cleanedString;
  };

  const handleChange = (event) => {
    let inputValue = "";
    if (event.target.value === "") {
      inputValue = "";
    } else {
      inputValue = removeForbiddenChars(event.target.value);
    }
    props.handleMinutes(inputValue);
  };

  return (
    <>
      <div className="mm-line">
        <div className="first-col">{t("T207")}</div>
        <div className="second-col fs-mask">
          <FontAwesomeIcon icon={faClock} className="icon clock" />
          <input
            id="inPlat"
            type="number"
            min="0"
            value={props.inPlatform}
            readOnly
          />
          <p>mins</p>
        </div>
      </div>
      <div className="mm-line">
        <div className="first-col">{t("T208")}</div>
        <div className="second-col fs-mask">
          <FontAwesomeIcon icon={faClock} className="icon clock" />
          <input
            id="offPlat"
            type="number"
            min="0"
            value={props.offPlatform}
            onChange={handleChange}
            onBlur={() => {
              if (props.offPlatform === "") props.handleMinutes(0);
            }}
          />
          <p>mins</p>
        </div>
      </div>
    </>
  );
};
export default MindfulMinutes;
