import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles//GreyToggle.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faWheelchair,
  faCalculator,
} from "@fortawesome/sharp-regular-svg-icons";

const GreyToggle = (props) => {
  const { t } = useTranslation();
  const [on, setOn] = useState(true);
  const [icon, setIcon] = useState(null);
  const [heading, setHeading] = useState(null);
  const [text, setText] = useState(null);
  const [toggle, setToggle] = useState([]);

  useEffect(() => {
    if (props.questionid === "wheelchair") {
      setIcon(faWheelchair);
      setHeading(t("T065"));
      setToggle([t("T066"), t("T067")]);
      props.wheelchair ? setOn(true) : setOn(false);
    } else if (props.steps) {
      setIcon(faCalculator);
      setHeading(() => (props.wheelchair ? t("T575") : t("T095")));
      setText(() => (props.wheelchair ? t("T576") : t("T096")));
      setToggle([t("T097"), t("T098")]);
    }
  }, [props]);

  const handleToggle = () => {
    setOn(!on);
    if (props.steps) props.toggleSteps();
    if (props.questionid === "wheelchair") props.toggleWheelchair(!on);
  };

  const closeToggle = () => {
    if (props.questionid === "wheelchair") {
      props.closeWheelchairToggle();
    } else if (props.steps) {
      props.closeStepsToggle();
    }
  };

  return (
    <div className="whc-cont fs-exclude">
      {icon && heading && toggle?.length > 0 && (
        <>
          <FontAwesomeIcon
            icon={faTimes}
            className="quit"
            onClick={closeToggle}
          />
          <div className="whc-body">
            <div className="whc-heading">
              <FontAwesomeIcon icon={icon} /> {heading}
            </div>
            {text && <div className="whc-text">{text}</div>}
          </div>
          <div className="whc-slider fs-mask">
            <div
              className={`whc-switch ${on ? "on" : "off"}`}
              onClick={handleToggle}
            >
              <div className="whc-slider-button"></div>
              <div className="whc-label">{on ? toggle[0] : toggle[1]}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default GreyToggle;
