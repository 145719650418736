// mediaSlidersApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const mediaApiSlice = createApi({
  reducerPath: "mediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
  }),
  tagTypes: ["SingleMedia", "Masterclass", "Programme"],
  endpoints: (builder) => ({
    fetchSingleMedia: builder.query({
      query: ({ token, url }) => ({
        url: `${url}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: [{ type: "SingleMedia", id: "LIST" }],
    }),
    fetchMasterclass: builder.query({
      query: ({ token, chid }) => ({
        url: `/masterclass/${chid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: [{ type: "Masterclass", id: "LIST" }],
    }),

    fetchProgramme: builder.query({
      query: ({ token, chid }) => ({
        url: `/programme/${chid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: [{ type: "Programme", id: "LIST" }],
    }),
    updateProgress: builder.mutation({
      query: ({ token, mediaId, progress, completed }) => ({
        url: "/media/progress",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: {
          mediaId: mediaId,
          progress: progress,
          completed: completed,
        },
      }),
    }),

    reEnrollProgramme: builder.query({
      query: ({ token, chid }) => ({
        url: `/programme/${chid}/reEnroll`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useFetchSingleMediaQuery,
  useFetchMasterclassQuery,
  useFetchProgrammeQuery,
  useUpdateProgressMutation,
  useReEnrollProgrammeQuery,
} = mediaApiSlice;
