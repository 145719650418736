import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  formatAssessmentDate,
  timeConversion,
} from "../../shared/util/helperfunctions";
import { smoking, notSmoking } from "../../shared/components/data";

import {
  faBolt,
  faHeart,
  faWeightScale,
  faWind,
} from "@fortawesome/pro-regular-svg-icons";

import CircleGraph from "../components/CircleGraph";
import ColumnGrid from "../components/ColumnGrid";
import BarChart from "../components/BarChart";
import Slider from "../../shared/components/slider/Slider";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import ValueTile from "../components/ValueTile";

import { colours } from "../../assets/colours";
import InfoBox from "../components/InfoBox";
import MotivationTile from "../components/MotivationTile";
import ColumnGridEmpty from "../components/ColumnGridEmpty";
import JournalBlock from "../components/JournalBlock";
import { useHttpClient } from "../../shared/hooks/http-hook";

const PhysicalWellbeingResults = (props) => {
  const { assessScores, trackerScores, trackerJournals } = props.dataObj;
  const { assessDates, assessmentNumber } = props.pageObjRef?.current;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();
  const [lifeRegained, setLifeRegained] = useState(null);

  const getColour = (value, reverse) => {
    if (value === "high" && reverse) {
      return colours.red;
    } else if (value === "low" && reverse) {
      return colours.green;
    } else if (value === "high" || value === "healthy") {
      return colours.green;
    } else if (
      value === "moderate" ||
      value === "overweight" ||
      value === "underweight"
    ) {
      return colours.orange;
    } else {
      return colours.red;
    }
  };

  const getLabel = (value) => {
    switch (value) {
      case "low":
        return t("PW001");
      case "moderate":
        return t("PW002");
      case "high":
        return t("PW003");
      case "healthy":
        return t("PW004");
      case "moderate":
        return t("PW005");
      case "overweight":
        return t("PW006");
      case "underweight":
        return t("PW007");
      case "clinically obese":
        return t("PW008");
      case "obese":
        return t("PW009");
      default:
        return "";
    }
  };

  //smoking data
  const [smokingOverview, setSmokingOverview] = useState(null);

  useEffect(() => {
    if (smokingOverview?.minutes_of_life_regained) {
      setLifeRegained(timeConversion(smokingOverview.minutes_of_life_regained));
    } else {
      setLifeRegained({ id: "Minutes", value: 0 });
    }
  }, [smokingOverview]);

  useEffect(() => {
    if (!smokingOverview) getSmokingData();
  }, []);

  useEffect(() => {
    if (props.updateSmoking) {
      getSmokingData();
      props.closeUpdateSmoking();
    }
  }, [props.updateSmoking]);

  const getSmokingData = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/trackers/smokingOverview`;
    try {
      const responseData = await sendRequest(url);
      if (responseData) {
        setSmokingOverview(responseData);
      }
    } catch (err) {}
  };

  return (
    <div className="results-container ">
      {props.showAssessment && assessScores?.smoke && (
        <>
          <div className="results-row">
            <CircleGraph
              title={t("D006")}
              value={getLabel(assessScores.physical_wellbeing_level)}
              discriptor=""
              colour={getColour(assessScores.physical_wellbeing_level)}
              icon={faHeart}
              width="half"
              modalText={[t("D021")]}
              openModal={props.openModal}
            />
            {!assessScores.bmi_no_show &&
              (assessScores.bmi_score > 0 ? (
                <CircleGraph
                  title={t("D007")}
                  value={getLabel(assessScores.bmi_value)}
                  discriptor="BMI"
                  colour={getColour(assessScores.bmi_value)}
                  icon={faWeightScale}
                  width="half"
                  modalText={[t("D022")]}
                  openModal={props.openModal}
                />
              ) : (
                <CircleGraph
                  title={t("D007")}
                  value={t("D075")}
                  discriptor=""
                  colour="#ddcedb"
                  icon=""
                  width="half"
                  modalText={[t("D022")]}
                  openModal={props.openModal}
                />
              ))}
            {assessScores.bmi_no_show && (
              <CircleGraph
                title={t("D007")}
                value={t("DW023")}
                discriptor=""
                colour="#ddcedb"
                icon=""
                width="half"
                modalText={[t("D022")]}
                openModal={props.openModal}
              />
            )}
            <CircleGraph
              title={t("D008")}
              value={getLabel(assessScores.back_pain_value)}
              discriptor={t("D011")}
              colour={getColour(assessScores.back_pain_value, "back")}
              icon={faBolt}
              width="half"
              modalText={[t("D023")]}
              openModal={props.openModal}
            />
            {assessScores.pain && (
              <ColumnGrid
                title={t("D014")}
                data={assessScores.msk_pain_data}
                width="half"
              />
            )}
            {!assessScores.pain && (
              <ColumnGridEmpty title={t("D014")} width="half" />
            )}
          </div>
          {assessScores.smoke === "yes" && (
            <BarChart
              title={t("D010")}
              color={colours.blue}
              data={assessScores.smoking_data}
              average={assessScores.smoking_data_average}
              icon={faWind}
              units={t("D026")}
              average_type={t("D079")}
              width="half bars"
              modalText={[t("D027"), t("D028"), t("D029")]}
              openModal={props.openModal}
              assessmentNumber={assessmentNumber}
              assessDates={assessDates}
            />
          )}
        </>
      )}
      {!props.showAssessment &&
        trackerScores?.smoking?.smoke_per_day.length > 0 && (
          <>
            {" "}
            <div className="results-row">
              <BarChart
                title={t("D010")}
                color={colours.blue}
                data={trackerScores.smoking.smoke_per_day}
                average={trackerScores.smoking.smoked_per_day_average}
                icon={faWind}
                units={t("D026")}
                average_type={t("DW032")}
                width="half bars"
                modalText={[t("D044")]}
                openModal={props.openModal}
                assessDates={null}
              />
              <BarChart
                title={t("D033")}
                data={trackerScores.smoking.craving_intensity}
                average={trackerScores.smoking.craving_intensity_average}
                icon={faExclamationTriangle}
                color={colours.red}
                units={t("D035")}
                yMaxLimit={10}
                average_type={t("D079")}
                width="half bars"
                modalText={[t("D045")]}
                openModal={props.openModal}
                outOfYMax
                assessDates={null}
              />

              <ValueTile
                title={t("D036")}
                value={smokingOverview?.current_days_smoke_free || 0}
                date={
                  smokingOverview?.last_updated
                    ? formatAssessmentDate(smokingOverview?.last_updated)
                    : false
                }
                modalText={[t("D046"), t("D028"), t("D047")]}
                openModal={props.openModal}
                colour={colours.blue}
                width="quarter"
              />
              {lifeRegained && (
                <ValueTile
                  title={`${lifeRegained.id} ` + t("DW026")}
                  value={lifeRegained.value}
                  date={
                    smokingOverview?.last_updated
                      ? formatAssessmentDate(smokingOverview?.last_updated)
                      : false
                  }
                  modalText={[t("D048")]}
                  openModal={props.openModal}
                  colour={colours.yellow}
                  width="quarter"
                />
              )}
              <ValueTile
                title={t("D038")}
                value={smokingOverview?.total_smoke_free || 0}
                date={
                  smokingOverview?.last_updated
                    ? formatAssessmentDate(smokingOverview?.last_updated)
                    : false
                }
                modalText={[t("D049")]}
                openModal={props.openModal}
                colour={colours.pink}
                width="quarter"
              />
              <ValueTile
                title={t("D039")}
                value={smokingOverview?.total_money_saved || 0}
                date={
                  smokingOverview?.last_updated
                    ? formatAssessmentDate(smokingOverview?.last_updated)
                    : false
                }
                modalText={[t("D050")]}
                openModal={props.openModal}
                colour={colours.green}
                width="quarter"
              />
              <InfoBox
                title={t("D040")}
                value={` ${smokingOverview?.current_days_smoke_free || 0} ${t(
                  "D054"
                )}`}
                date={
                  smokingOverview?.last_updated
                    ? formatAssessmentDate(smokingOverview?.last_updated)
                    : false
                }
                colour={colours.blue}
                blob={smokingOverview?.health_benefits}
                width="half"
              />
              <MotivationTile
                title={t("D041")} //helping you stop smoking
                modalText={[t("D051")]}
                openModal={props.openModal}
                colour={colours.green}
                data={trackerScores.smoking.topEnhancers}
                valueArr={notSmoking}
                width="quarter"
              />
              <MotivationTile
                title={t("D042")} //reasons for smoking
                modalText={[t("D052")]}
                openModal={props.openModal}
                colour={colours.red}
                data={trackerScores.smoking.topInhibitors}
                valueArr={smoking}
                width="quarter"
              />

              <JournalBlock
                width="quarter"
                startWith={8}
                journalArr={trackerJournals}
                openModal={props.openModal}
              />
            </div>
          </>
        )}

      {/* {props.slidersList?.[1] && (
            <div className="results-row">
              <Slider
                title={props.slidersList[1].title}
                key={props.slidersList[1].id}
                id={props.slidersList[1].id}
                media={props.slidersList[1].media}
                favourites={favourites}
                dislikes={dislikes}
                progressList={progressList}
                onOpenMediaModal={openMediaModal}
                addLikeDislike={addLikeDislike}
                target={props.slidersList[1].target || false}
              />
            </div>
          )} */}
      {/* </div>
      </div> */}
    </div>
  );
};
export default PhysicalWellbeingResults;
