import React from "react";
import { useTranslation } from "react-i18next";

import NumberSelectQuestion from "../questions/numberSelectQuestion/NumberSelectQuestion";

import Radio from "../questions/radio/Radio";

function Energy2(props) {
  const { t } = useTranslation();
  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>
      <NumberSelectQuestion
        questionid="hours_worked"
        questionTitle={t("EN039")}
        minlimit="0"
        maxlimit="168"
        trailingText={t("EN040")}
        width=""
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("EN041")}
        onOpenModal={props.onOpenModal}
      />

      <NumberSelectQuestion
        questionid="days_absence"
        questionTitle={t("EN042")}
        minlimit="0"
        maxlimit="100"
        trailingText={t("EN043")}
        width=""
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("EN044")}
        onOpenModal={props.onOpenModal}
        skippable
      />
    </div>
  );
}

export default Energy2;
