import { useEffect, useState } from "react";

export const useUrl = () => {
  const [url, setUrl] = useState(window.location.href);

  useEffect(() => {
    // Create a new MutationObserver instance
    const observer = new MutationObserver(() => {
      setUrl(window.location.href);
    });

    // Start observing the document with the configured parameters
    observer.observe(document, { childList: true, subtree: true });

    return () => {
      // Disconnect the observer on cleanup
      observer.disconnect();
    };
  }, []);

  return url;
};
