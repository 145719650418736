import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../styles/Chapters.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-thin-svg-icons";
import {
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons";

import { convertTime } from "../../shared/util/helperfunctions";
import { handleSeeAll, handleSeeMore } from "../functions";

const Chapters = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(false);

  const handleSeeAllWrapper = () => handleSeeAll(seeMore, setSeeMore);

  const handleSeeMoreWrapper = (id, item) => {
    const clickedId = id;
    if (props.masterclassPage) {
      handleSeeMore(clickedId, item);
    } else {
      let index = props.masterclass.chapters.findIndex(
        (x) => x.chid === item.chid
      );
      navigate(
        `/masterclass/${
          props.masterclass.chid
        }/${props.masterclass.title.replace(/ /g, "_")}/${
          index + 1
        }/${item.title.replace(/ /g, "_").toLowerCase()}`
      );
    }
  };
  return (
    <div
      className={
        props.masterclassPage ? "chapters-popup player" : "chapters-popup"
      }
    >
      <div className="chapters-heading">
        <div className="title">{t("M027")}</div>
        {props.masterclassPage ? (
          <div className="expand">
            <div>{seeMore ? t("M028") : t("M029")}</div>
            <FontAwesomeIcon
              icon={seeMore ? faChevronCircleUp : faChevronCircleDown}
              className="icon chevron"
              onClick={handleSeeAllWrapper}
            />
          </div>
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} className="times" />
        )}
      </div>
      <div className="scrollable">
        {props.masterclass?.chapters?.length > 0 &&
          props.masterclass.chapters.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className="chapter"
                onClick={() => handleSeeMoreWrapper(`${index}chapter`, item)}
                id={`${index}chapter`}
              >
                <div className="top-row">
                  <div className="constant-text">
                    <div className="number">{index + 1}</div>
                    <div className="title">
                      {item.title.replace(/&amp;/gi, "&")}
                    </div>
                  </div>
                  {props.masterclassPage && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="icon chevron"
                    />
                  )}
                </div>

                <div className="see-more">
                  <p className="time">{convertTime(item.length)}</p>
                  <p className="text">{item.description}</p>
                  <button
                    onClick={() =>
                      navigate(
                        `/masterclass/${
                          props.masterclass.chid
                        }/${props.masterclass.title.replace(/ /g, "_")}/${
                          index + 1
                        }/${item.title.replace(/ /g, "_").toLowerCase()}`
                      )
                    }
                  >
                    {t("M030")}
                  </button>
                </div>
              </div>
              {props.masterclass.progress_array &&
                props.masterclass.progress_array[index] > 0 && (
                  <div className="chapter-progress-container">
                    <div
                      className="progress-bar"
                      style={{
                        width: props.masterclass.progress_array[index] + "%",
                      }}
                    ></div>
                  </div>
                )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};
export default Chapters;
