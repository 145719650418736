import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics({ data, onOpenModal }) {
  const { t } = useTranslation();
  const { ages, total, have_children, home_office, night_shift } = data;
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const age = [
    {
      date: "16-24",
      value: Math.round((100 / total) * ages[0]),
    },
    {
      date: "25-34",
      value: Math.round((100 / total) * ages[1]),
    },
    {
      date: "35-44",
      value: Math.round((100 / total) * ages[2]),
    },
    {
      date: "45-54",
      value: Math.round((100 / total) * ages[3]),
    },
    {
      date: "55-64",
      value: Math.round((100 / total) * ages[4]),
    },
    {
      date: "65+",
      value: Math.round((100 / total) * ages[5]),
    },
  ];

  return (
    <React.Fragment>
      <BarChart title={t("DM001")} data={age} unit="%" width="wide" />
      <CircleGraph
        title={t("DM002")}
        square
        colour="colour1"
        value={have_children}
        subText=""
        large
        percent={Math.round((100 / total) * have_children)}
      />
      <CircleGraph
        title={t("DM005")}
        square
        colour="colour1"
        value={home_office}
        subTitle={t("DM006")}
        large
        modalText={t("DM007")}
        percent={total > 0 ? Math.round((100 / total) * home_office) : 0}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("DM003")}
        square
        colour="colour2"
        value={night_shift}
        large
        percent={Math.round((100 / total) * night_shift)}
        modalText={t("DM004")}
        onOpenModal={onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
