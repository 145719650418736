import React, { useState, useEffect } from "react";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

function NumberSelect(props) {
  const [inputValue, setInputValue] = useState("0");

  function setIncreaseNumber() {
    if (props.disabled) return;
    var newValue = Number(inputValue);
    if (newValue < props.maxlimit) {
      newValue++;
      setInputValue(newValue);
      saveValue(newValue);
    }
  }

  function setDecreaseNumber() {
    if (props.disabled) return;
    var newValue = Number(inputValue);
    if (newValue > 0) {
      newValue--;
      setInputValue(newValue);
      saveValue(newValue);
    }
  }

  function numberChangeHandler(event) {
    setInputValue(event.target.value);
    saveValue(event.target.value);
  }

  const handleFocusOut = (event) => {
    if (props.maxlimit && Number(event.target.value) > props.maxlimit) {
      setInputValue(props.maxlimit);
      saveValue(props.maxlimit);
    } else if (
      props.minlimit &&
      (Number(event.target.value) < props.minlimit || !event.target.value)
    ) {
      setInputValue(props.minlimit);
      saveValue(props.minlimit);
    }
  };

  function saveValue(newValue) {
    props.onSetValue({ id: props.questionid, value: newValue });
  }

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      setInputValue(props.assessmentValues[props.questionid]);
    } else if (
      props.startVal &&
      props.assessmentValues[props.questionid] !== 0
    ) {
      setInputValue(props.startVal);
      saveValue(props.startVal);
    }
  });

  return (
    <div className={`number_field ${props.disabled ? "disabled" : "active"}`}>
      <div className="number_container fs-exclude">
        <input
          type="number"
          className="number"
          id={props.questionid}
          onChange={numberChangeHandler}
          onBlur={handleFocusOut}
          value={inputValue}
          max={props.maxlimit}
          min="0"
          disabled={props.disabled}
        />
        {props.trailingText}
      </div>
      <div className="number_controls">
        <div className="up_btn" onClick={setIncreaseNumber}>
          <FontAwesomeIcon icon={faChevronUp} className="icon" />
        </div>
        <div className="down_btn" onClick={setDecreaseNumber}>
          <FontAwesomeIcon icon={faChevronDown} className="icon" />
        </div>
      </div>
    </div>
  );
}

export default NumberSelect;
