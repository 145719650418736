import React, { useEffect, useState } from "react";
import Backdrop from "../menu/Backdrop";
import { useTranslation } from "react-i18next";
import "./Modal.css";
import DropDownCalendar from "../../individualReport/pages/components/DropDownCalendar";
// import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faInfoCircle,
  faChevronRight,
  faLockAlt,
} from "@fortawesome/pro-solid-svg-icons";

function Modal(props) {
  const [activeReason, setActiveReason] = useState("Reason for leaving");
  const [removeAcct, setRemoveAcct] = useState("Remove account");
  const { t } = useTranslation();

  let modalText1 = "";
  let modalText2 = "";
  //check if modal text is a string (standard modal) or an html (individual report copy), otherwise it's an object with the main and secondary copy (pop-up with an array of boxes from assessement)
  if (props.removeAccount) {
    modalText1 =
      "Once you remove your account, you will no longer have access to Champion Health and your account and personal health data will be permanently deleted. <br /> <br />Please note, this will also remove any friend or family profiles associated with your account, and they will no longer have access to the platform.";
  } else if (
    typeof props.modalText === "string" ||
    props.modalText.type === "div"
  ) {
    modalText1 = props.modalText;
  } else {
    modalText1 = props.modalText.main;
    modalText2 = props.modalText.secondary;
  }

  function modalClickHandler() {
    props.onModalClose(false);
    if (activeReason) {
      setActiveReason("Reason for leaving");
      setFollowUp("");
    }
    if (removeAcct === "Confirm remove") {
      setRemoveAcct("Remove account");
    }
  }

  function ModalHtml() {
    if (typeof modalText1 === "string") {
      return <div dangerouslySetInnerHTML={{ __html: modalText1 }}></div>;
    } else {
      //it's already an html - do nothing
      return modalText1;
    }
  }

  const toggleActiveReason = (region) => {
    setActiveReason(region);
  };
  const [style, setStyle] = useState("disabled");
  const [followUp, setFollowUp] = useState();
  const [userText, setUserText] = useState();
  const [placeholderText, setPlaceholderText] = useState("");

  let deleteClass = "";
  if (props.deleteAccount) {
    deleteClass = "user_delete";
  }

  useEffect(() => {
    if (document.getElementById("remove_btn")) {
      let button = document.getElementById("remove_btn");
      if (activeReason === "Reason for leaving") {
        button.disabled = true;
        setStyle("disabled");
      } else {
        button.disabled = false;
        setStyle("");
      }
    }
  }, [activeReason]);

  const deleteBtnHandler = () => {
    if (activeReason === "Other" && !userText) {
      setPlaceholderText("Please enter your reason for leaving...");
    } else if (activeReason === "Other" && userText.length < 4) {
      setUserText("");
      setPlaceholderText("Please enter your reason for leaving...");
    } else if (activeReason === "Other" && userText) {
      if (removeAcct === "Remove account") {
        setRemoveAcct("Confirm remove");
      } else {
        props.deleteUser({ reason: "Other: " + userText, platform: "web" });
        modalClickHandler();
      }
    } else {
      if (removeAcct === "Remove account") {
        setRemoveAcct("Confirm remove");
      } else {
        props.deleteUser({ reason: activeReason, platform: "web" });
        modalClickHandler();
      }
    }
  };

  const addFollowUp = (props) => {
    setFollowUp(props);
  };

  const handleTextAreaChange = (event) => {
    setUserText(event.target.value);
  };

  return (
    <Backdrop
      open={`${props.modalIsOpen ? "open" : "closed"}`}
      onBackdropClose={modalClickHandler}
    >
      <div
        id="modal-container"
        className={`${props.modalIsOpen ? "open " + deleteClass : "closed "}`}
      >
        {props.removeAccount ? (
          <FontAwesomeIcon icon={faExclamationCircle} className="icon modal" />
        ) : (
          <FontAwesomeIcon icon={faInfoCircle} className="icon modal" />
        )}
        <ModalHtml />
        {props.modalText && props.error && (
          <div dangerouslySetInnerHTML={{ __html: props.modalText }}></div>
        )}
        {modalText2 &&
          modalText2.map((item) =>
            typeof item === "string" ? (
              <div className="modal_mappedBoxes" key={item.key}>
                {item.value}
              </div>
            ) : (
              <div
                className="modal_mappedBoxes"
                dangerouslySetInnerHTML={{ __html: item.value }}
              ></div>
            )
          )}
        {props.removeAccount && removeAcct === "Remove account" && (
          <div className="delete_account">
            <DropDownCalendar
              assessmentDate={activeReason}
              dates={props.modalText}
              show={"deleteAccount"}
              setAssessmentDate={toggleActiveReason}
              addFollowUp={addFollowUp}
              profile
              deleteAccount
            />
          </div>
        )}
        {followUp &&
          removeAcct === "Remove account" &&
          followUp.map((item, index) => {
            if (item.startsWith("ICON")) {
              //delete ICON and add lock icon
              let div = item.slice(4);
              return (
                <div className="list_item" key={index}>
                  <FontAwesomeIcon
                    icon={faLockAlt}
                    className="icon modal_list"
                  />
                  <div dangerouslySetInnerHTML={{ __html: div }}></div>
                </div>
              );
            } else if (item.startsWith(">")) {
              //delete chevron and add icon
              let div = item.slice(1);
              return (
                <div className="list_item" key={index}>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="icon modal_list"
                  />
                  <div dangerouslySetInnerHTML={{ __html: div }}></div>
                </div>
              );
            } else if (item.startsWith("input")) {
              return (
                <textarea
                  key={index}
                  className="modal_input_field"
                  type="text"
                  required
                  minlength="4"
                  value={userText}
                  onChange={handleTextAreaChange}
                  placeholder={placeholderText}
                />
              );
            } else {
              return (
                <p
                  className="main_point"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></p>
              );
            }
          })}

        {!props.removeAccount && (
          <button className="modal_close" onClick={modalClickHandler}>
            {t("AT007")}
          </button>
        )}
        {props.removeAccount && (
          <React.Fragment>
            <button
              className="modal_close cancel_account"
              onClick={modalClickHandler}
            >
              Cancel
            </button>
            <button
              id="remove_btn"
              className={"modal_close remove_account " + style}
              onClick={deleteBtnHandler}
            >
              {removeAcct}
            </button>
          </React.Fragment>
        )}
      </div>
    </Backdrop>
  );
}
export default Modal;
