import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-light-svg-icons";

import "./MainHeader.css";
import LogoImg from "./images/white_mountains.png";
import LogoImgColour from "./images/colour_mountains.png";
import UserBgImg from "./images/profile_1.png";

import { AuthContext } from "../context/auth-context";
import NotificationsList from "./NotificationsList";

function MainHeader(props) {
  const [profileImage, setProfileImage] = useState(UserBgImg);
  const [openNotificationsList, setOpenNotificationsList] = useState(false);
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.image) {
      setProfileImage(auth.image);
    }
  }, [auth.image]);

  return (
    <React.Fragment>
      {openNotificationsList && <NotificationsList />}
      {props.page !== "register" && (
        <a href="/">
          {props.page !== "company" && (
            <img src={LogoImg} alt="Champion Health" />
          )}
          {props.page === "company" && (
            <img src={LogoImgColour} alt="Champion Health" />
          )}
        </a>
      )}
      {auth.isLoggedIn && (
        <div className="header-right">
          {/* <div
            className="notifications_container"
            onClick={() => setOpenNotificationsList(!openNotificationsList)}
          >
            <FontAwesomeIcon icon={faBell} className="icon notifications" />
          </div> */}
          <Link to="/profile/settings">
            <div
              id="photo_outer"
              className="fs-exclude"
              style={{ backgroundImage: `url(${profileImage})` }}
            ></div>
          </Link>
        </div>
      )}
    </React.Fragment>
  );
}

export default MainHeader;
