import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh as faVolumeSharp } from "@fortawesome/pro-light-svg-icons";
import WhitePopUp from "../../shared/modal/whitePopUp/WhitePopUp";
import Chapters from "./Chapters";
import rectangle from "../images/rectangle.png";
import {
  useFetchProgrammeQuery,
  useReEnrollProgrammeQuery,
} from "../mediaApiSlice";
import { AuthContext } from "../../shared/context/auth-context";
import { setProgramme } from "../mediaSlice";

const CTA = ({ mediaType, mediaObject, activeDay, setActiveDay }) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cta, setCta] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [consented, setConsented] = useState(false);
  const [indexToBePlayed, setIndexToBePlayed] = useState();
  const [showChapters, setShowChapters] = useState(false);

  // const btnTakeAction = t("M025");
  const [popUpContents, setPopUpContents] = useState();

  const handlePlay = () => {
    if (mediaObject.category === "workout" && !consented) {
      setPopUpContents({
        title: t("M008"),
        heading: `${t("M009")}...`,
        paragraphs: [t("M010")],
        secondaryBtn: t("M011"),
        primaryBtn: t("M012"),
      });
      setOpenPopUp((prevState) => !prevState);
    } else {
      playMedia();
    }
  };

  const shouldFetch = cta === `${t("M074W")}` && consented;
  const { data: reEnrollData, error: reEnrollError } =
    useReEnrollProgrammeQuery(
      { token: auth?.token, chid: mediaObject.chid },
      { skip: !shouldFetch }
    );

  const fetchProgrammeAPI = useFetchProgrammeQuery(
    {
      token: auth?.token,
      chid: mediaObject.chid,
    },
    { skip: !shouldFetch }
  );

  useEffect(() => {
    if (reEnrollError) {
      console.log(reEnrollError);
    }

    if (reEnrollData) {
      // handle the response data
      fetchProgrammeAPI
        .refetch()
        .then((result) => {
          setProgramme(result);
        })
        .then(() => {
          navigate(
            `/programme/${mediaObject.chid}/${mediaObject.title.replace(
              / /g,
              "_"
            )}/day_1`
          );
        });
    }
  }, [reEnrollData, reEnrollError, consented]);

  const handleConsent = (value) => {
    if (value) {
      setConsented(() => true);
      if (cta !== `${t("M074W")}`) {
        playMedia();
      }
    } else {
      setConsented(false);
    }
    setOpenPopUp(false);
  };

  //set cta text
  useEffect(() => {
    switch (mediaType) {
      case "programme":
        if (mediaObject.progress === 0 || !mediaObject.progress) {
          setActiveDay(mediaObject.days[0]);
          setCta(`${t("M070W")}`);
        } else if (Math.ceil(mediaObject.progress) === 100) {
          setActiveDay(mediaObject.days[0]);
          setCta(`${t("M074W")}`);
        } else {
          let lastCompletedIndex;
          for (let i = 0; i < mediaObject.days.length; i++) {
            if (mediaObject.days[i].completed === true) {
              lastCompletedIndex = i;
            }
          }
          if (lastCompletedIndex === undefined) {
            setActiveDay(mediaObject.days[0]);
            mediaObject.days[0]?.progress === 0
              ? setCta(`${t("M069W")} 1`)
              : setCta(`${t("M068W")} 1`);
          } else if (mediaObject.days[lastCompletedIndex + 1]?.progress === 0) {
            setActiveDay(mediaObject.days[lastCompletedIndex + 1]);
            setCta(`${t("M069W")} ${lastCompletedIndex + 2}`);
          } else {
            setActiveDay(mediaObject.days[lastCompletedIndex + 1]);
            setCta(`${t("M068W")} ${lastCompletedIndex + 2}`);
          }
        }
        break;
      case "masterclass":
        let indexToPlay = mediaObject.progress_array.findIndex(
          (item) => Math.ceil(item) < 100
        );

        if (indexToPlay === -1) {
          // If all chapters are completed or not started, start at the beginning
          indexToPlay = 0;
        }

        if (
          mediaObject.progress_array[indexToPlay] > 0 &&
          Math.ceil(mediaObject.progress_array[indexToPlay]) < 100
        ) {
          setCta(`${t("M064W")} ${indexToPlay + 1}`);
          setIndexToBePlayed(indexToPlay);
        } else {
          setCta(`${t("M065W")} ${indexToPlay + 1}`);
          setIndexToBePlayed(indexToPlay);
        }
        break;

      default:
        if (mediaObject.category === "workout") {
          if (mediaObject.progress && Math.ceil(mediaObject.progress) < 100) {
            setCta(t("M056"));
          } else if (
            mediaObject.progress &&
            Math.ceil(mediaObject.progress) === 100
          ) {
            setCta(t("M056W"));
          } else {
            setCta(t("M055"));
          }
        } else if (mediaObject.type === "audio") {
          if (
            mediaObject.progress &&
            mediaObject.progress > 0 &&
            Math.ceil(mediaObject.progress) < 100
          ) {
            setCta(t("M024"));
          } else if (
            mediaObject.progress &&
            Math.ceil(mediaObject.progress) === 100
          ) {
            setCta(t("M075"));
          } else {
            setCta(t("M023"));
          }
        } else if (mediaObject.type === "video") {
          if (mediaObject.progress && Math.ceil(mediaObject.progress) < 100) {
            setCta(t("M048"));
          } else if (
            mediaObject.progress &&
            Math.ceil(mediaObject.progress) === 100
          ) {
            setCta(t("M071W"));
          } else {
            setCta(t("M035"));
          }
        }
        break;
    }
  }, [mediaObject]);

  const checkRestartProg = () => {
    setPopUpContents({
      title: t("M074W"),
      heading: `${t("M076W")}`,
      paragraphs: [t("M077W")],
      secondaryBtn: t("M011"),
      primaryBtn: t("M012"),
    });
    setOpenPopUp(true);
  };

  const playMedia = () => {
    switch (mediaType) {
      case "programme":
        cta === `${t("M074W")}` && !consented
          ? // pop up to check to erase progress
            checkRestartProg()
          : navigate(
              `/programme/${mediaObject.chid}/${mediaObject.title.replace(
                / /g,
                "_"
              )}/day_${
                mediaObject.days.findIndex(
                  (day) => activeDay.title === day.title
                ) + 1
              }`
            );
        break;
      case "masterclass":
        navigate(
          `/masterclass/${mediaObject.chid}/${mediaObject.title.replace(
            / /g,
            "_"
          )}/${indexToBePlayed + 1}/${mediaObject.chapters[
            indexToBePlayed
          ].title
            .replace(/ /g, "_")
            .toLowerCase()}`
        );
        break;
      case "take-actions":
        if (
          mediaObject.media.category.toLowerCase() === "programme" ||
          mediaObject.media.category.toLowerCase() === "masterclass"
        ) {
          navigate(
            `/${mediaObject.media.category.toLowerCase().replace(/ /g, "_")}/${
              mediaObject.media.chid
            }/${mediaObject.title.replace(/ /g, "_")}`
          );
        } else {
          navigate(
            `/media/player/${
              mediaObject.media.chid
            }/${mediaObject.title.replace(/ /g, "_")}`
          );
        }
        break;
      default:
        navigate(
          `/media/player/${mediaObject.chid}/${mediaObject.title.replace(
            / /g,
            "_"
          )}`
        );
        break;
    }
  };

  const handleMasterclassChapters = (e) => {
    e.preventDefault();
    setShowChapters(!showChapters);
  };

  return (
    <React.Fragment>
      {openPopUp && (
        <WhitePopUp
          popUpContents={popUpContents}
          handleSubmit={handleConsent}
        />
      )}
      {mediaType === "programme" && (
        <div className="cta-block">
          <div className="cta-container">
            <button id="hero-button" onClick={handlePlay}>
              {cta}
            </button>
            {activeDay.progress > 0 && (
              <div className="progress">
                <div className="progress-container">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${activeDay.progress + "%"}`,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {mediaType === "masterclass" && (
        <div className="cta-block">
          <div className="cta-container">
            <button id="hero-button" onClick={handlePlay}>
              {cta}
            </button>
            {mediaObject.progress_array?.[indexToBePlayed] > 0 && (
              <div className="progress">
                <div className="progress-container">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${
                        mediaObject.progress_array?.[indexToBePlayed] + "%"
                      }`,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>

          <div
            className={showChapters ? "chapters-icon open" : "chapters-icon"}
            style={{
              backgroundImage: `url(${rectangle}`,
            }}
            onClick={handleMasterclassChapters}
          >
            {showChapters && (
              <Chapters
                masterclass={mediaObject}
                progressArr={mediaObject.progress_array}
                // chid={mediaObject.chid}
                // title={mediaObject.title}
              />
            )}
          </div>
        </div>
      )}
      {!mediaObject.chid.startsWith("TA") &&
        mediaObject.category !== "service" &&
        mediaObject.type && (
          <div className="cta-block">
            <div className="cta-container">
              <button id="hero-button" onClick={handlePlay}>
                {cta}
              </button>
              {mediaObject.progress > 0 && (
                <div className="progress">
                  <div className="progress-container">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${mediaObject.progress + "%"}`,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      {(mediaObject.chid.startsWith("TA") ||
        mediaObject.category === "service") && (
        <div className="cta-block service">
          <div className="cta-container">
            {mediaObject.action_url && (
              <a
                href={mediaObject.action_url}
                target={
                  mediaObject.action_url.includes("app.championhealth.co.uk")
                    ? "_self"
                    : "_blank"
                }
                rel="noreferrer"
              >
                <button id="hero-button" className="take-action">
                  {mediaObject.action_string}
                </button>
              </a>
            )}{" "}
            {(mediaObject.type ||
              (mediaType === "take-actions" && mediaObject.media)) && (
              <button
                id="hero-button"
                className="take-action learn"
                onClick={handlePlay}
              >
                {t("M026")}
                <FontAwesomeIcon icon={faVolumeSharp} className="icon volume" />
              </button>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CTA;
