import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faAlarmClock,
  faUtensils,
  faBowlSpoon,
} from "@fortawesome/pro-regular-svg-icons";

const r = 25;

const Cursor = (props) => {
  const [circleProps, setCircleProps] = useState({
    cx: props.pos.x,
    cy: props.pos.y,
    r,
  });
  useEffect(() => {
    setCircleProps({
      cx: props.pos.x,
      cy: props.pos.y,
      r,
    });
  }, [props.pos]);

  const handleEvent = (event) => {
    if (props.region === "end") {
      props.handleEndMouseDown();
    } else {
      props.handleStartMouseDown();
    }
  };

  return (
    <g
      transform={`translate(${props.cx}, ${
        props.cy
      }) rotate(-90) translate(${-props.cx}, ${-props.cy})`}
    >
      <circle
        {...circleProps}
        fill="#fff"
        style={{ cursor: `${props.sliderCursor}` }}
      />
      <circle
        fill="transparent"
        cx={props.pos.x}
        cy={props.pos.y}
        r={r * 2}
        id={props.region}
        onMouseDown={handleEvent}
        onTouchStart={handleEvent}
        style={{ cursor: `${props.sliderCursor}` }}
      />

      <foreignObject
        x={props.pos.x - r}
        y={props.pos.y - r}
        width={r * 2}
        height={r * 2}
        style={{ textAlign: "center", color: "#ddcedb", pointerEvents: "none" }}
      >
        <FontAwesomeIcon
          icon={
            props.region === "end"
              ? props.meals
                ? faUtensils
                : faAlarmClock
              : props.meals
              ? faBowlSpoon
              : faBed
          }
          style={{
            fontSize: "25px",
            padding: "13px",
            transform: "rotate(90deg)",
            verticalAlign: 0,
          }}
        />
      </foreignObject>
    </g>
  );
};

export default Cursor;
