import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import DialSlider from "../questions/dialSlider/DialSlider";
import MentalWellbeingModal from "../../shared/modal/MentalWellbeingModal";

function MentalHealth2(props) {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const textValue = [t("MH001"), t("MH002")];

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  return (
    <>
      {modalIsOpen && (
        <MentalWellbeingModal
          modalIsOpen={modalIsOpen}
          modalClickHandler={modalClickHandler}
        />
      )}
      <div className={"question_container"} id={props.questionid}>
        <h2>{props.questionHeading}</h2>
        <p>{props.questionText}</p>

        <div className={"question_block_wide gad center"}>
          <h3>{props.questionTitle}</h3>
          <div className="answer_block">
            <DialSlider
              questionid="mood_rating"
              width="_wide center"
              questionTitle={t("MH008")}
              textValue={textValue}
              scale={5}
              onSetValue={props.onSetValue}
              assessmentValues={props.assessmentValues}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MentalHealth2;
