import { useState, useCallback, useRef, useEffect, useContext } from "react";
import fetch from "cross-fetch";

import { AuthContext } from "../context/auth-context";

export const useHttpClient = () => {
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = null) => {
      setError(null);
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      // if (!headers) {
      //   headers = {
      //     "Content-Type": "application/json",
      //   };
      // }
      if (url.includes(process.env.REACT_APP_BACKEND_URL)) {
        let token = JSON.parse(localStorage.getItem("userData"))?.token;
        if (
          token &&
          method !== "GET" &&
          !url.includes("/assessments/datarequest")
        ) {
          headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            ...headers,
          };
        } else if (
          (token && method === "GET") ||
          (token && url.includes("/assessments/datarequest"))
        ) {
          headers = {
            Authorization: "Bearer " + token,
            ...headers,
          };
        } else {
          headers = {
            "Content-Type": "application/json",
          };
        }
      }

      try {
        const response = await fetch(url, {
          method,
          body,
          headers: headers,
          signal: httpAbortCtrl.signal,
        });

        const responseData = await response.text().then((text) => {
          try {
            return text ? JSON.parse(text) : {};
          } catch (err) {
            return text;
          }
        });

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );

        if (!response.ok) {
          throw responseData.error.message;
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        setError(err);
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
