import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/pro-light-svg-icons";
import {
  faPlusCircle as SolidPlus,
  faThumbsUp as SolidThumbsUp,
  faThumbsDown as SolidThumsDown,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/auth-context";
import {
  useUpdateFavouritesMutation,
  useUpdateLikesMutation,
} from "../../../forYou/mediaSlidersApiSlice";
import {
  updateFavouritesSlider,
  updateLikesDislikes,
} from "../../../forYou/mediaSlidersSlice";

const SliderTooltip = (props) => {
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const [updateFavourites] = useUpdateFavouritesMutation();
  const [updateLikes] = useUpdateLikesMutation();
  const [favourite, setFavourite] = useState();
  const [like, setLike] = useState();
  const [dislike, setDislike] = useState();
  const favourites = useSelector((state) => state.mediaSliders.favourites);
  const likes = useSelector((state) => state.mediaSliders.likes);
  const dislikes = useSelector((state) => state.mediaSliders.dislikes);

  useEffect(() => {
    if (
      !props.slideContent ||
      !favourites ||
      !favourites?.media ||
      !likes ||
      !dislikes
    )
      return;
    setFavourite(() =>
      favourites.media.some((element) => element._id === props.slideContent._id)
    );
    setLike(() => likes.includes(props.slideContent._id));
    setDislike(() => dislikes.includes(props.slideContent._id));
  }, [favourites?.media.length, likes, dislikes, props.slideContent]);

  const handleTooltipClick = (e, type) => {
    props.handleTileTooltip(e);
    let url = "/media";
    if (props.slideContent?.category?.toLowerCase() === "programme") {
      url = "/programme";
    } else if (props.slideContent?.category.toLowerCase() === "masterclass") {
      url = "/masterclass";
    } else if (props.slideContent?.chid.startsWith("TA")) {
      url = "/take-actions";
    }
    if (type === "fave" && props.slideContent?._id) {
      updateFavourites({
        token: auth.token,
        url: `${url}/favourites`,
        mediaId: props.slideContent._id,
      }).catch((err) => {
        console.log(err);
      });
    } else if ((type === "like" || type === "dislike") && props.slideContent) {
      updateLikes({
        token: auth.token,
        url: `${url}/preference`,
        action: type,
        mediaId: props.slideContent._id,
      }).catch((err) => {
        console.log(err);
      });
    }
    props.closeTooltip();
  };

  return (
    <div className="slider-tooltip arrow">
      {favourite ? (
        <div
          className="tooltip-row"
          onClick={(e) => handleTooltipClick(e, "fave")}
        >
          {" "}
          <FontAwesomeIcon icon={SolidPlus} /> {t("FY002")}
        </div>
      ) : (
        <div
          className="tooltip-row"
          onClick={(e) => handleTooltipClick(e, "fave")}
        >
          {" "}
          <FontAwesomeIcon icon={faPlusCircle} /> {t("FY001")}
        </div>
      )}
      {!(
        props.slideContent.category === "service" ||
        props.slideContent.chid.startsWith("TA")
      ) && (
        <React.Fragment>
          <div
            className="tooltip-row"
            onClick={(e) => handleTooltipClick(e, "like")}
          >
            {" "}
            <FontAwesomeIcon icon={like ? SolidThumbsUp : faThumbsUp} />{" "}
            {t("FY003")}
          </div>
          <div
            className="tooltip-row"
            onClick={(e) => handleTooltipClick(e, "dislike")}
          >
            {" "}
            <FontAwesomeIcon
              icon={dislike ? SolidThumsDown : faThumbsDown}
            />{" "}
            {t("FY004")}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default SliderTooltip;
