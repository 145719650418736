import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "../styles/MediaPlayerStyles.css";
import "../styles/MasterclassPlayer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

import { setMasterclass, setSingleMedia } from "../mediaSlice";
import ProgrammeChapters from "./ProgrammeChapters";
import SingleMediaPlayer from "./SingleMediaPlayer";
import McMediaDescription from "./McMediaDescription";

const ProgrammePlayer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const day = window.location.pathname.split("/")[4]?.slice(4);
  const chapterNoRef = useRef(0);
  const [openCountdown, setOpenCountdown] = useState(false);
  const [keepPlaying, setKeepPlaying] = useState(false);
  const [progCompletedOpen, setProgCompletedOpen] = useState(false);
  const [showGreenCheck, setShowGreenCheck] = useState(false);

  const programme = useSelector((state) => state.media.programme);
  const activeDay = useSelector((state) => state.media.masterclass);
  const nowActive = useSelector((state) => state.media.singleMedia);

  //scroll top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //set active day(masterclass) from url
  useEffect(() => {
    if (programme) {
      dispatch(setMasterclass(programme.days[Number(day) - 1]));
    }
  }, [programme, day]);

  //set active chapter to play
  useEffect(() => {
    if (
      activeDay &&
      (!nowActive._id ||
        chapterNoRef.current === 0 ||
        !activeDay.chapters.some((chapter) => chapter._id === nowActive._id))
    ) {
      // if all values in progress array are 0 or 100 play first chapter
      if (
        activeDay.progress_array.every((item) => item === 0) ||
        activeDay.progress_array.every((item) => Math.ceil(item) === 100)
      ) {
        dispatch(setSingleMedia(activeDay.chapters[0]));
        chapterNoRef.current = 1;
      } else {
        let started = activeDay.progress_array.findIndex(
          (item) => item > 0 && Math.ceil(item) < 100
        );
        if (started !== -1) {
          dispatch(setSingleMedia(activeDay.chapters[started]));
          chapterNoRef.current = started + 1;
        } else {
          //find last completed chapter
          let lastCompletedIndex;
          for (let i = 0; i < activeDay.progress_array.length; i++) {
            if (Math.ceil(activeDay.progress_array[i]) === 100) {
              lastCompletedIndex = i;
            }
          }
          if (
            lastCompletedIndex >= 0 &&
            lastCompletedIndex < activeDay.progress_array.length - 1
          ) {
            dispatch(
              setSingleMedia(activeDay.chapters[lastCompletedIndex + 1])
            );
            chapterNoRef.current = lastCompletedIndex + 2;
          }
        }
      }
    }
  }, [activeDay, keepPlaying]);

  //play chapter chosen from the chapters list
  const handleSetActive = (item) => {
    let index = activeDay.chapters.findIndex(
      (chapter) => chapter._id === item._id
    );
    dispatch(setSingleMedia(item));
    chapterNoRef.current = index + 1;
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.setItem("pageReload", true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleNavigation = () => {
    const defaultPath = "/for-you";
    const reloaded = sessionStorage.getItem("pageReload");

    if (sessionStorage.length > 0) {
      const previousPages =
        JSON.parse(sessionStorage.getItem("contentTypePreviousLocations")) ||
        [];
      const previousLocation = JSON.parse(
        sessionStorage.getItem("previousLocation")
      );

      let targetPath;
      if (previousPages.length > 0) {
        targetPath = previousPages.shift();
      }

      // If the target path is the same as the current path, shift again
      if (targetPath === location.pathname) {
        targetPath = previousPages.shift();
      }
      // Update the previous pages in sessionStorage
      sessionStorage.setItem(
        "contentTypePreviousLocations",
        JSON.stringify(previousPages)
      );

      if (!targetPath) {
        targetPath = previousLocation?.pathname;
      }

      // Navigate to the target path if it exists, otherwise navigate to the default path
      targetPath
        ? reloaded
          ? navigate(targetPath)
          : navigate(-1)
        : navigate(defaultPath);
    } else {
      navigate(defaultPath);
    }
  };

  //show green check mark when the day is completed
  const showGreenCheckOverlay = () => {
    setShowGreenCheck(true);
    setTimeout(() => {
      setShowGreenCheck(false);
      handleNavigation();
    }, 2000);
  };

  //functions related to autoplay
  const handleProgAutoPlay = () => {
    // setKeepPlaying(false);
    if (chapterNoRef.current < activeDay.chapters.length) {
      setOpenCountdown(true);
    } else if (day === programme.days.length.toString()) {
      setProgCompletedOpen(true);
    } else {
      showGreenCheckOverlay();
    }
  };

  useEffect(() => {
    if (nowActive?.chid) {
      let mcMedia = document.getElementsByClassName("mcplayer");
      let text = document.getElementsByClassName("below-video");
      if (mcMedia[0]) {
        mcMedia[0].classList.add("fade-in");
        text[0].classList.add("fade-in");
      }
    }
  }, [nowActive?.chid]);

  const playNext = () => {
    let mcMedia = document.getElementsByClassName("mcplayer");
    let text = document.getElementsByClassName("below-video");
    if (mcMedia[0] && text[0]) {
      mcMedia[0].classList.remove("fade-in");
      text[0].classList.remove("fade-in");
    }
    setKeepPlaying(true);
    setTimeout(() => {
      dispatch(setSingleMedia(activeDay.chapters[chapterNoRef.current]));
      chapterNoRef.current = chapterNoRef.current + 1;
    }, 700);
  };

  //end of the programme
  const closeProgrammeCompleted = () => {
    setProgCompletedOpen(false);
    handleNavigation();
  };

  //setting player height, so other elements don't move around when chapters change
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mediaHeight, setMediaHeight] = useState(0);

  const calculateHeight = () => {
    let newValue;
    if (screenWidth < 850) {
      newValue = Math.floor(screenWidth * 0.6);
    } else {
      newValue = Math.floor(screenWidth * 0.38);
    }
    setMediaHeight(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  useEffect(() => {
    calculateHeight();
  }, [screenWidth]);

  return (
    <React.Fragment>
      <div id="masterclass-container">
        <div className="column-1">
          <div
            className="back-button"
            onClick={(e) => {
              handleNavigation();
            }}
          >
            {" "}
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="icon return-arrow back"
            />
          </div>
          <div className="media-cont" style={{ height: `${mediaHeight}px` }}>
            <SingleMediaPlayer
              mediaHeight={mediaHeight}
              programme
              masterclass
              handleProgAutoPlay={handleProgAutoPlay}
              keepPlaying={keepPlaying}
              progressArray={activeDay.progress_array}
              nowActiveIndex={chapterNoRef.current - 1}
              openCountdown={openCountdown}
              setOpenCountdown={setOpenCountdown}
              playNext={playNext}
              progress={activeDay.progress_array[chapterNoRef.current - 1]}
              showGreenCheck={showGreenCheck}
              progCompletedOpen={progCompletedOpen}
              closeProgrammeCompleted={closeProgrammeCompleted}
            />
          </div>
          <div className="below-video">
            {nowActive && (
              <McMediaDescription
                masterclass={activeDay}
                nowActive={nowActive}
                chapterNo={chapterNoRef.current}
                day={day}
              />
            )}
          </div>
        </div>

        {nowActive && (
          <ProgrammeChapters
            masterclass={activeDay}
            handleSetActive={handleSetActive}
            nowActiveIndex={chapterNoRef.current - 1}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ProgrammePlayer;
