import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import IconSelect from "../questions/iconSelect/IconSelect";
import Dehydration from "../questions/dehydration/Dehydration";
import NumberSelectQuestion from "../questions/numberSelectQuestion/NumberSelectQuestion";

function Hydration1(props) {
  const { t } = useTranslation();
  const [showSQ, setShowSQ] = useState(false);

  const { caffeine } = props.assessmentValues;

  useEffect(() => {
    if (caffeine && caffeine > 0) {
      setShowSQ(true);
    } else {
      setShowSQ(false);
      props.onSetValue({ id: "caffeine_hours_before_bed", value: null });
    }
  }, [caffeine]);

  return (
    <div className={"question_container " + props.active} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <IconSelect
        questionid="hydration"
        range="11"
        questionTitle={t("HD001")}
        width=""
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("HD002")}
        onOpenModal={props.onOpenModal}
      />
      <Dehydration
        questionid="dehydration"
        questionTitle={t("HD003")}
        width=""
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("HD004")}
        onOpenModal={props.onOpenModal}
      />
      <IconSelect
        questionid="caffeine"
        range="6"
        questionTitle={t("HD005")}
        width=" spaced"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("HD006")}
        onOpenModal={props.onOpenModal}
      />
      {showSQ && (
        <NumberSelectQuestion
          questionid="caffeine_hours_before_bed"
          questionTitle={t("HD007")}
          minlimit="0"
          maxlimit="20"
          trailingText={t("HD008")}
          width=""
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          skippable
        />
      )}
    </div>
  );
}

export default Hydration1;
