import React from "react";


function TextArea(props) {


return (


    <div className={`${props.containerClass ? props.containerClass : "square"}`}>
        <div className="square_content">
            {props.children}
        </div>
    </div>


);}

export default TextArea;