import React, { useEffect, useState } from "react";

import "./Carousel.css";

const CarouselItem = (props) => {
  const [fadeOut, setFadeOut] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut("fadeOut");
    }, 3500);
    return () => {
      if (interval) {
        clearInterval(interval);
        setFadeOut("");
      }
    };
  }, []);

  return (
    <React.Fragment>
      {props && (
        <h1 className={"secondary_text " + fadeOut}>{props.activeItem.name}</h1>
      )}
      <div className="tiles_container ">
        {props &&
          props.activeItem.tiles.map((tile, index) => (
            <img
              key={index}
              className={`tile${index} ` + fadeOut}
              src={tile}
              alt={`${props.activeItem.name}`}
            ></img>
          ))}
      </div>
    </React.Fragment>
  );
};

export default CarouselItem;
