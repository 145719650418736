import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Radio from "../questions/radio/Radio";
import Checkbox from "../questions/checkbox/Checkbox";

function MentalHealth5(props) {
  const { t } = useTranslation();
  const mentalDiagnosisOptions = [
    { id: "yes", title: t("MH033") },
    { id: "no", title: t("MH034") },
  ];

  const mentalHealthOptions = [
    { id: "none", title: t("MH035") },
    { id: "anxiety", title: t("MH036") },
    { id: "depression", title: t("MH037") },
    { id: "panic", title: t("MH038") },
    { id: "bipolar", title: t("MH039") },
    { id: "ocd", title: t("MH040") },
    { id: "social_anxiety", title: t("MH041") },
    { id: "ptsd", title: t("MH042") },
    { id: "phobia", title: t("MH043") },
    { id: "eating", title: t("MH044") },
    { id: "schizophrenia", title: t("MH045") },
    { id: "other", title: t("MH046") },
  ];

  const mentalSupportOptions = [
    { id: "currently", title: t("MH047") },
    { id: "previously", title: t("MH048") },
    { id: "never", title: t("MH049") },
  ];

  const neurodivergentOptions = [
    { id: "none_nd", title: t("MH050") },
    { id: "asd", title: t("MH051") },
    { id: "adhd", title: t("MH052") },
    { id: "dyslexia", title: t("MH053") },
    { id: "dyscalculia", title: t("MH054") },
    { id: "dyspraxia", title: t("MH055") },
    { id: "other_nd", title: t("MH056") },
  ];

  useEffect(() => {
    if (props.assessmentValues.mental_health_diagnosis === "no") {
      props.onSetValue({
        id: "current_mental_health_conditions",
        value: null,
      });
      props.onSetValue({
        id: "past_mental_health_conditions",
        value: null,
      });
    }
  }, [props.assessmentValues.mental_health_diagnosis]);

  return (
    <div className={"question_container"} id={props.questionid}>
      <h2>{props.questionHeading}</h2>
      <p>{props.questionText}</p>

      <Radio
        questionid="mental_health_diagnosis"
        width=""
        questionTitle={t("MH057")}
        options={mentalDiagnosisOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        onOpenModal={props.onOpenModal}
      />
      {props.assessmentValues.mental_health_diagnosis === "yes" && (
        <Checkbox
          questionid="current_mental_health_conditions"
          width=""
          questionTitle={t("MH058")}
          questionTitleSuffix={t("MH059")}
          options={mentalHealthOptions}
          placeholder={t("MH060")}
          itemLimit="2"
          onSetValue={props.onSetValue}
          assessmentValues={props.assessmentValues}
          fadeIn="yes"
        />
      )}
      {props.assessmentValues.mental_health_diagnosis === "yes" &&
        props.assessmentValues.current_mental_health_conditions != null && (
          <Checkbox
            questionid="past_mental_health_conditions"
            width=""
            questionTitle={t("MH061")}
            questionTitleSuffix={t("MH062")}
            options={mentalHealthOptions}
            placeholder={t("MH063")}
            itemLimit="2"
            exclude={props.assessmentValues.current_mental_health_conditions}
            onSetValue={props.onSetValue}
            assessmentValues={props.assessmentValues}
            secondaryQ
            fadeIn="yes"
          />
        )}
      <Radio
        questionid="received_mental_support"
        width=""
        questionTitle={t("MH064")}
        options={mentalSupportOptions}
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
        modalText={t("MH065")}
        onOpenModal={props.onOpenModal}
      />
      <Checkbox
        questionid="neurodivergent"
        width="_wide"
        questionTitle={t("MH066")}
        questionTitleSuffix={t("MH067")}
        options={neurodivergentOptions}
        placeholder={t("MH068")}
        itemLimit="2"
        onSetValue={props.onSetValue}
        assessmentValues={props.assessmentValues}
      />
    </div>
  );
}

export default MentalHealth5;
