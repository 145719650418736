import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import TextArea from "../../../individualReport/pages/components/TextArea";
import NavSlider from "../../../shared/components/slider/NavSlider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/pro-light-svg-icons";

function Page(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="report-section active leadership_page">
        <h2 className="main_title">{t("CC001")}</h2>
        {/* <NavSlider ids={['materials']} titles={{materials: 'Materials'}} />        */}

        <div id="materials" className="results-content">
          <TextArea containerClass="clear wide campaigns"></TextArea>
          <TextArea containerClass="clear">
            <span className="centred clear_downloads">
              <h2>{t("CC002")}</h2>
              <p>{t("CC003")}</p>
            </span>
          </TextArea>
          <TextArea containerClass="clear wide">
            <span className="centred clear_downloads">
              <h3>{t("CC004")}</h3>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/an_olympians_guide_to_workplace_health.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC005")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/boost_wellbeing_nature.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC006")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/childrens_mental_health_week.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC007")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/cost_of_living_crisis_wellbeing_report.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC008")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/eating_disorders_workplace_support.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC009")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/drive_engagement_workplace_wellbeing.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC010")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/stop_sexual_harassment_in_the_workplace.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC011")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/managing_menopause_at_work.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC012")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/night_shift_survival_guide.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC013")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/psychological_safety_at_work.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC014")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/reducing_the-risk_of_suicide.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC015")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/global_productivity_report.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC016")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/workplace_health_report_2022.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC017")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/workplace_health_report_2023.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC018")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/wellbeing_hospitality_sector.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC019")}
              </a>
              <a
                href="https://res.cloudinary.com/champion-health/image/upload/v1630596930/leaders/resources/wellbeing_retail_sector.pdf"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCloudDownload} className="" />
                {t("CC020")}
              </a>{" "}
            </span>
          </TextArea>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Page;
