import React from "react";
import { useTranslation } from "react-i18next";

import TextArea from "../../../individualReport/pages/components/TextArea";

import CsvImport from "../components/CsvReader";

function Page(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="report-section active leadership_page">
        <h2 className="main_title">{t("ERV003")}</h2>

        <div id="materials" className="results-content">
          <TextArea containerClass="clear wide partners"></TextArea>
          <TextArea containerClass="clear">
            <span className="centred">
              <h2>
                {t("ERV001")} {props.coData.name}
              </h2>
              <p>{t("ERV002")}</p>
              <CsvImport ervIds={props.coData.validIds} />
            </span>
          </TextArea>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Page;
