// mediaSlidersApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// mediaSlidersApiSlice.js
export const mediaSlidersApiSlice = createApi({
  reducerPath: "mediaSlidersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
  }),
  tagTypes: ["MediaSliders"],
  endpoints: (builder) => ({
    fetchPageSliders: builder.query({
      query: ({ token, pageid }) => ({
        url: `/media/sliders/${pageid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: [{ type: "MediaSliders", id: "LIST" }],
    }),
    fetchFeature: builder.query({
      query: ({ token, pageid }) => ({
        url: `/features/${pageid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: [{ type: "Feature", id: "LIST" }],
    }),

    fetchTakeAction: builder.query({
      query: ({ token, pageid }) => ({
        url: `/take-actions/slider/${pageid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: [{ type: "TakeAction", id: "LIST" }],
    }),

    updateFavourites: builder.mutation({
      query: ({ token, url, mediaId }) => ({
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: {
          id: mediaId,
        },
      }),
      invalidatesTags: [
        { type: "MediaSliders", id: "LIST" },
        { type: "SingleMedia", id: "LIST" },
        { type: "Masterclass", id: "LIST" },
        { type: "Programme", id: "LIST" },
      ],
    }),

    updateLikes: builder.mutation({
      query: ({ token, url, action, mediaId }) => ({
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: {
          action: action,
          id: mediaId,
        },
      }),
      invalidatesTags: [
        { type: "MediaSliders", id: "LIST" },
        { type: "SingleMedia", id: "LIST" },
        { type: "Masterclass", id: "LIST" },
        { type: "Programme", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useFetchPageSlidersQuery,
  useFetchFeatureQuery,
  useFetchTakeActionQuery,
  useUpdateFavouritesMutation,
  useUpdateLikesMutation,
} = mediaSlidersApiSlice;
