import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Carousel.css";

import CarouselItem from "./CarouselItem";

import wo1 from "../images/workouts/H_WO_07.jpg";
import wo2 from "../images/workouts/H_WO_13.jpg";
import wo3 from "../images/workouts/H_WO_24.jpg";
import wo4 from "../images/workouts/H_WO_25.jpg";
import wo5 from "../images/workouts/H_WO_26.jpg";
import wo6 from "../images/workouts/H_WO_27.jpg";
import wo7 from "../images/workouts/H_WO_29.jpg";
import wo8 from "../images/workouts/H_WO_32.jpg";
import wo9 from "../images/workouts/H_WO_40.jpg";
import so1 from "../images/soundscapes/H_SO_06.jpg";
import so2 from "../images/soundscapes/H_SO_07.jpg";
import so3 from "../images/soundscapes/H_SO_08.jpg";
import so4 from "../images/soundscapes/H_SO_14.jpg";
import so5 from "../images/soundscapes/H_SO_19.jpg";
import so6 from "../images/soundscapes/H_SO_21.jpg";
import so7 from "../images/soundscapes/H_SO_30.jpg";
import so8 from "../images/soundscapes/H_SO_34.jpg";
import so9 from "../images/soundscapes/H_SO_36.jpg";
import ss1 from "../images/sleepstories/H_SS_01_F.jpg";
import ss2 from "../images/sleepstories/H_SS_02.jpg";
import ss3 from "../images/sleepstories/H_SS_03.jpg";
import ss4 from "../images/sleepstories/H_SS_10.jpg";
import ss5 from "../images/sleepstories/H_SS_11.jpg";
import ss6 from "../images/sleepstories/H_SS_12.jpg";
import ss7 from "../images/sleepstories/H_SS_13.jpg";
import ss8 from "../images/sleepstories/H_SS_14.jpg";
import ss9 from "../images/sleepstories/H_SS_15.jpg";
import r1 from "../images/recipes/H_R_07.jpg";
import r2 from "../images/recipes/H_R_25.jpg";
import r3 from "../images/recipes/H_R_26.jpg";
import r4 from "../images/recipes/H_R_27.jpg";
import r5 from "../images/recipes/H_R_40.jpg";
import r6 from "../images/recipes/H_R_46.jpg";
import r7 from "../images/recipes/H_R_50.jpg";
import r8 from "../images/recipes/H_R_56.jpg";
import r9 from "../images/recipes/H_R_74.jpg";
import m1 from "../images/meditations/H_M_05.jpg";
import m2 from "../images/meditations/H_M_08.jpg";
import m3 from "../images/meditations/H_M_11.jpg";
import m4 from "../images/meditations/H_M_20.jpg";
import m5 from "../images/meditations/H_VI_02.jpg";
import m6 from "../images/meditations/H_VI_03.jpg";
import m7 from "../images/meditations/H_VI_05.jpg";
import m8 from "../images/meditations/H_VI_06.jpg";
import m9 from "../images/meditations/H_VI_07.jpg";
import mc1 from "../images/masterclasses/A_MH_EX_02.jpg";
import mc2 from "../images/masterclasses/A_MH_SE_04.jpg";
import mc3 from "../images/masterclasses/A_NU_SE_01.jpg";
import mc4 from "../images/masterclasses/A_PH_SE_02.jpg";
import mc5 from "../images/masterclasses/A_PR_EX_05.jpg";
import mc6 from "../images/masterclasses/A_PR_EX_07.jpg";
import mc7 from "../images/masterclasses/A_PR_SE_01.jpg";
import mc8 from "../images/masterclasses/A_SL_EX_02.jpg";
import mc9 from "../images/masterclasses/A_SL_SE_01.jpg";

const Carousel = (props) => {
  const { t } = useTranslation();
  let array = [
    {
      name: `${t("R003")}`,
      tiles: [wo1, wo2, wo3, wo4, wo5, wo6, wo7, wo8, wo9],
    },
    {
      name: `${t("R007")}`,
      tiles: [so1, so2, so3, so4, so5, so6, so7, so8, so9],
    },
    {
      name: `${t("R006")}`,
      tiles: [ss1, ss2, ss3, ss4, ss5, ss6, ss7, ss8, ss9],
    },
    {
      name: `${t("R004")}`,
      tiles: [r1, r2, r3, r4, r5, r6, r7, r8, r9],
    },
    {
      name: `${t("R005")}`,
      tiles: [m1, m2, m3, m4, m5, m6, m7, m8, m9],
    },
    {
      name: `${t("R002")}`,
      tiles: [mc1, mc2, mc3, mc4, mc5, mc6, mc7, mc8, mc9],
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [items, setItems] = useState(array);
  const [activeItem, setActiveItem] = useState(items[0]);
  const [margin, setMargin] = useState();
  const [left, setLeft] = useState();

  const recalculateWidth = () => {
    if (document.getElementById("left_container")){
      let width = document.getElementById("left_container").clientWidth;
      //to center the animited tiles
      let indent = (860 - width) / 2;
      // to calculate indent for the navigation
      let indent2 = width / 2 - 75;
      setMargin(-indent);
      setLeft(indent2);
    }
  };

  
  useEffect(() => {
    if (document.getElementById("left_container")) {
      recalculateWidth();
    }
    window.addEventListener("resize", (event) => {
      recalculateWidth();
    });

    return () => {
      window.removeEventListener("resize", (event) => {
        recalculateWidth();
      });

    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => updateIndex(activeIndex + 1), 6000);
    return () => {
      clearTimeout(timer);
    };
  }, [activeItem]);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = items.length - 1;
    } else if (newIndex >= items.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
    setActiveItem(items[newIndex]);
  };

  return (
    <div className="carousel_wrapper">
      {" "}
      <div className="indicators" style={{ left: `${left}px` }}>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={`${index === activeIndex ? "active" : ""}`}
              onClick={() => {
                updateIndex(index);
              }}
            ></div>
          );
        })}
      </div>
      <div className="carousel">
        <div className="inner" style={{ marginLeft: margin }}>
          <CarouselItem
            key={activeIndex}
            name={items[activeIndex].name}
            activeItem={activeItem}
          />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
