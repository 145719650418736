import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/pro-thin-svg-icons";

import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

const RateBlock = ({
  like,
  dislike,
  setLike,
  setDislike,
  handleLikeDislike,
}) => {
  const { t } = useTranslation();
  const [likeDislikeOpen, setLikeDislikeOpen] = useState(false);

  const handleThumbsClick = (e) => {
    if (like && e.currentTarget.id === "like") {
      setLike(false);
    } else if (dislike && e.currentTarget.id === "dislike") {
      setDislike(false);
    } else if (like && e.currentTarget.id === "dislike") {
      setDislike(true);
      setLike(false);
    } else if (dislike && e.currentTarget.id === "like") {
      setDislike(false);
      setLike(true);
    } else if (!like && !dislike) {
      e.currentTarget.id === "dislike" ? setDislike(true) : setLike(true);
    }
    handleLikeDislike(e.currentTarget.id);
  };

  return (
    <React.Fragment>
      {likeDislikeOpen && <div className="rate-overlay"></div>}
      <div
        className="rate-block"
        onClick={() => {
          setLikeDislikeOpen(!likeDislikeOpen);
        }}
      >
        {likeDislikeOpen && (
          <div className="rate-btns">
            {" "}
            <FontAwesomeIcon
              id="dislike"
              onClick={handleThumbsClick}
              icon={dislike ? faThumbsDownSolid : faThumbsDown}
              className="icon action"
            />
            <FontAwesomeIcon
              id="like"
              onClick={handleThumbsClick}
              icon={like ? faThumbsUpSolid : faThumbsUp}
              className="icon action"
            />
          </div>
        )}{" "}
        {likeDislikeOpen ? (
          <FontAwesomeIcon icon={faCircleXmark} className="icon action" />
        ) : !like && !dislike ? (
          <FontAwesomeIcon icon={faThumbsUp} className="icon action" />
        ) : like ? (
          <FontAwesomeIcon icon={faThumbsUpSolid} className="icon action" />
        ) : (
          <FontAwesomeIcon icon={faThumbsDownSolid} className="icon action" />
        )}
        {!likeDislikeOpen && <div className="rate">{t("M022")}</div>}
      </div>
    </React.Fragment>
  );
};

export default RateBlock;
