import React from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalWrapper from "../../../../../shared/modal/ModalWrapper";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";

function TopThree(props) {
  const { t } = useTranslation();
  const list = props.value ? props.value : [t("TS001"), t("TS002"), t("TS003")];

  return (
    <div
      className={`${props.square ? "square" : "narrow"} ${
        props.disabled ? "disabled" : ""
      }`}
    >
      <div className={`square_content ${props.disabled ? "disabled" : ""}`}>
        {props.modalText && !props.disabled && (
          <ModalWrapper
            modalText={props.modalText}
            onOpenModal={props.onOpenModal}
          >
            <FontAwesomeIcon icon={faInfo} className="icon info_icon" />
          </ModalWrapper>
        )}
        {props.disabled && (
          <FontAwesomeIcon icon={faInfo} className="icon info_icon" />
        )}
        {props.disabled && (
          <div className="graph_disabled_text">{t("BC014")}</div>
        )}
        {props.title ? <h3>{props.title}</h3> : ""}
        <div className={"top_three"}>
          {props.preText && <span className="sub_head">{props.preText}</span>}
          {list.map((item, index) => {
            return (
              <div key={item + index} className="top_three_row">
                <span>{parseFloat(index + 1)}</span>
                {item
                  .replaceAll("_", " ")
                  .replace("ocd", t("TS004"))
                  .replace("eating", t("TS005"))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TopThree;
