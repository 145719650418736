import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import "../styles/componentsStyles/ChallengeErrorModal.css";
import WhiteButton from "../../shared/components/styledComponents/whiteButton/WhiteButton";

const ChallengeErrorModal = ({ subtitle, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="popup-overlay">
      <div className="dark-container challenge-error">
        <div className="challenge-error-subtitile">
          {subtitle}
          <a
            href="https://support.championhealth.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="challenge-error-link"
          >
            {t("CH042")}
          </a>
        </div>
        <WhiteButton onClick={onClose}>
          {t("M012")} <FontAwesomeIcon icon={faArrowRight} className="icon" />
        </WhiteButton>
      </div>
    </div>
  );
};

export default ChallengeErrorModal;
