import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../shared/context/auth-context";

import Passcode from "../components/Passcode";

function SignupScreen4(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const handleContinue = (code) => {
    let dataObj = {
      progress: "screen4",
      code: code,
    };
    props.handleContinue(dataObj);
  };

  return (
    <React.Fragment>
      <h1>{t("R037")}</h1>
      <p> {t("R038")}</p>
      {!auth.isLoggedIn && <Passcode handleContinue={handleContinue} />}
    </React.Fragment>
  );
}
export default SignupScreen4;
