import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "./ForYou.css";

import Header from "../shared/header/MainHeader";
import Footer from "../shared/navfooter/Footer";
import NavSlider from "../shared/components/slider/NavSlider";
import Slider from "../shared/components/slider/Slider";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import OnboardingModal from "../shared/modal/OffboardingModal";
import { AuthContext } from "../shared/context/auth-context";
import {
  useFetchPageSlidersQuery,
  useFetchFeatureQuery,
  useFetchTakeActionQuery,
} from "./mediaSlidersApiSlice";
import { useFetchSingleMediaQuery } from "../contentTypes/mediaApiSlice";
import { useFetchGoalsQuery } from "../goals/goalsApiSlice";
import { setGoals } from "../goals/goalsSlice";

import { useUpdateNpsMutation } from "../profile/userApiSlice";
import {
  setMediaSliders,
  setFeature,
  setTakeAction,
} from "./mediaSlidersSlice";
import Nps from "../platform/components/Nps";
import { updateNpsState } from "../profile/userSlice";
import { haNavigation, pages } from "../assets/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/sharp-solid-svg-icons";
import { faHouse } from "@fortawesome/pro-light-svg-icons";
import GoalSelector from "../goals/children/GoalSelector";
import ReviewGoals from "../goals/children/ReviewGoals";
import {
  checkChallengeSecionId,
  shouldHideNavChallenge,
} from "../shared/util/challenges";

const ForYou = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let pageid = window.location.pathname.split("/")[1];
  let section = pages.find((page) => page.url === pageid);
  let sectionid = section.id;
  const tag = window.location.pathname.split("/")[2] || "";
  const { data: goals, isLoading: isFetchingGoals } = useFetchGoalsQuery(
    auth.token
  );

  const { data: featureData, isLoading: isFetchingFeature } =
    useFetchFeatureQuery(
      {
        token: auth.token,
        pageid: sectionid,
      },
      { skip: !sectionid }
    );

  const calculateLength = (lengthInMin) => {
    let length;

    if (lengthInMin < 60) {
      length = `${lengthInMin} ${t("FRY002")}`;
    } else {
      if (lengthInMin % 60 === 0) {
        length = `${lengthInMin / 60} ${t("FRY003")}`;
      } else {
        length = `${lengthInMin / 60} ${t("FRY003")} ${lengthInMin % 60} ${t(
          "FRY002"
        )} `;
      }
    }
    return length;
  };

  const { data: takeActionData, isLoading: isFetchingTakeAction } =
    useFetchTakeActionQuery(
      {
        token: auth.token,
        pageid: sectionid,
      },
      { skip: !sectionid }
    );

  const { data: mediaSliders, isLoading: isFetchingSliders } =
    useFetchPageSlidersQuery(
      {
        token: auth.token,
        pageid: sectionid,
      },
      { skip: !sectionid }
    );

  const navigation = useSelector((state) => state.mediaSliders.navigation);
  const pageTitle = useSelector((state) => state.mediaSliders.title);
  const sliders = useSelector((state) => state.mediaSliders.sliders);
  const feature = useSelector((state) => state.mediaSliders.feature);
  const takeAction = useSelector((state) => state.mediaSliders.takeAction);
  const promotions = useSelector((state) => state.mediaSliders.promotions);
  const favourites = useSelector((state) => state.mediaSliders.favourites);

  //get translation code for page name
  let displayPageName;
  navigation.map((nav) => {
    if (
      nav?.title?.toLowerCase() ===
      pageid.replaceAll(/%20/g, " ").replaceAll("-", " ")
    ) {
      displayPageName = nav.code;
    }
  });

  useEffect(() => {
    if (!isFetchingFeature && featureData) {
      dispatch(
        setFeature({
          feature: featureData.feature,
          promotions: featureData.promotions,
          sectionid: sectionid,
        })
      );
    }
    if (!isFetchingTakeAction && takeActionData) {
      dispatch(setTakeAction(takeActionData));
    }
  }, [
    dispatch,
    featureData,
    isFetchingFeature,
    isFetchingTakeAction,
    takeActionData,
  ]);

  useEffect(() => {
    if (!isFetchingSliders && mediaSliders) {
      dispatch(
        setMediaSliders({ mediaSliders: mediaSliders, sectionid: sectionid })
      );
    }
  }, [dispatch, mediaSliders, isFetchingSliders]);

  useEffect(() => {
    let pageCont = document.getElementsByClassName("page-cont");
    if (
      pageCont.length > 0 &&
      !isFetchingFeature &&
      !isFetchingTakeAction &&
      sectionid === feature.type
    ) {
      pageCont[0].classList.add("visible");
    }
  }, [sectionid, isFetchingFeature, isFetchingTakeAction, feature?.type]);

  const [activePage, setActivePage] = useState();

  useEffect(() => {
    if (!pageid) return;
    if (pageid !== "for-you") {
      let page = haNavigation.find((item) => item.title === tag);
      setActivePage(page);
    } else {
      setActivePage();
    }
    if (sessionStorage.length > 0) {
      const previousLocation = JSON.parse(
        sessionStorage.getItem("previousLocation")
      );
      setTimeout(() => {
        if (previousLocation) {
          window.scrollTo(0, previousLocation.scrollY);
          sessionStorage.clear();
        } else {
          window.scrollTo(0, 0);
        }
      }, 50);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pageid]);

  const toggleActivePage = (tabid) => {
    setActivePage(haNavigation[tabid]);
    navigate(`/${pageid}/${haNavigation[tabid].title}`);
  };

  //nps
  const [npsOpen, setNpsOpen] = useState(false);
  const [updateNps, { isLoading }] = useUpdateNpsMutation();

  const triggerNps = (action) => {
    if (action) {
      saveNps(action);
      setTimeout(function () {
        setNpsOpen(false);
      }, 495);
    } else {
      setNpsOpen(true);
    }
  };

  const saveNps = async (nps) => {
    try {
      const result = await updateNps({ token: auth.token, nps });
      if (result.isSuccess) {
        dispatch(updateNpsState(nps));
      }
    } catch (error) {
      console.error("Failed to update NPS:", error);
    }
  };

  //onboarding on to for you
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openReviewGoals, setOpenReviewGoals] = useState(false);
  const [openGoalSelector, setOpenGoalSelector] = useState(false);

  const referer = useLocation().search;

  useEffect(() => {
    if (referer && referer === "?referer=assessment") {
      setShowOnboarding(true);
      setTimeout(function () {
        setModalIsOpen(true);
      }, 10500);
    } else if (referer && referer === "?referer=repeat_assessment") {
      setOpenReviewGoals(true);
    }
  }, [referer]);

  useEffect(() => {
    if (!isFetchingGoals) {
      dispatch(setGoals(goals));
    }
  }, [dispatch, goals, isFetchingGoals]);

  const handleCloseReviewGoals = () => {
    setOpenReviewGoals(false);
  };

  const handleCloseGoalSelector = () => {
    setOpenGoalSelector(false);
  };

  const editGoals = () => {
    setOpenReviewGoals(false);
    setOpenGoalSelector(true);
  };

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const [isClicked, setIsClicked] = useState(false);
  const {
    data: media,
    error,
    isLoading: isLoadingMedia,
  } = useFetchSingleMediaQuery(
    {
      token: auth?.token,
      url: `/${
        feature.media?.category?.toLowerCase() === "programme"
          ? "programme"
          : feature.media?.category?.toLowerCase() === "masterclass"
          ? "masterclass"
          : feature.media?.chid.startsWith("TA")
          ? "take-actions"
          : "media"
      }/${feature.media?.chid}`,
    },
    {
      skip: !isClicked,
    }
  );

  useEffect(() => {
    if (!isLoadingMedia && media && isClicked) {
      setIsClicked(false);
      let url = "media";
      if (media.chid.startsWith("TA")) {
        url = "take-actions";
      } else if (media.category?.toLowerCase() === "programme") {
        url = "programme";
      } else if (media.category?.toLowerCase() === "masterclass") {
        url = "masterclass";
      }
      navigate(`/${url}/${media.chid}/${media.title.replace(/ /g, "_")}`);
    }
  }, [media, isLoadingMedia, isClicked]);

  const handleContentClick = () => {
    if (feature.media?.chid) {
      //get single media item and trigger useEffect
      setIsClicked(true);
    } else if (
      feature.action_url &&
      feature.action_url.endsWith("/challenge")
    ) {
      const partsArray = feature.action_url.split("/");
      if (
        partsArray.length < 3 ||
        partsArray[partsArray.length - 1] !== "challenge" ||
        checkChallengeSecionId(partsArray[partsArray.length - 2])
      ) {
        return;
      }
      const toNavigate = `/${partsArray[partsArray.length - 2]}/${
        partsArray[partsArray.length - 1]
      }`;
      navigate(toNavigate);
    } else if (feature.action_url) {
      window.open(feature.action_url, "_blank");
    }
  };

  return (
    <div id="main_container" className="foryou glowrm platform">
      {(isFetchingFeature || isFetchingSliders) && <LoadingSpinner asOverlay />}
      {showOnboarding && (
        <OnboardingModal
          modalIsOpen={modalIsOpen}
          modalClickHandler={modalClickHandler}
        />
      )}
      {npsOpen && <Nps triggerNps={triggerNps} saveNps={saveNps} />}
      {openReviewGoals && (
        <ReviewGoals
          handleClose={handleCloseReviewGoals}
          editGoals={editGoals}
        />
      )}
      {openGoalSelector && (
        <GoalSelector handleClose={handleCloseGoalSelector} />
      )}
      <div id="nav_container">
        <Header />
        <div className="heading">
          {pageid === "for-you" ? (
            <h2>{t("FRY001")}</h2>
          ) : (
            <React.Fragment>
              <Link to="/for-you">
                <FontAwesomeIcon icon={faHouse} className="icon home" />
              </Link>
              <FontAwesomeIcon icon={faChevronRight} className="icon" />
              <h2>{t(displayPageName)}</h2>
            </React.Fragment>
          )}
        </div>
        {pageid === "for-you" && navigation && (
          <NavSlider navigation={navigation} />
        )}{" "}
        {pageid !== "for-you" &&
          activePage &&
          activePage?.target &&
          haNavigation[0]?.target && (
            <ul className="nav_slider na">
              {haNavigation.map((item, index) => {
                if (pageid === "financial-wellbeing" && item.target === 23) {
                  return null;
                } else if (
                  item &&
                  (shouldHideNavChallenge(pageid) ||
                    auth.access === null ||
                    auth.access === "2") &&
                  item.target === 24
                ) {
                  return null;
                } else {
                  return (
                    <li
                      key={item.target}
                      id={item.fullstory}
                      className={
                        item.target === activePage.target ? "active" : ""
                      }
                      onClick={() => {
                        toggleActivePage(index);
                      }}
                    >
                      {t(item.code)}
                    </li>
                  );
                }
              })}
            </ul>
          )}
      </div>
      <div className="page-cont">
        <div
          className="results-img"
          style={{
            background: `url(${feature.background_image}) center/cover no-repeat`,
          }}
        >
          <div className="gradient-overlay">
            <div className="hero-contents">
              <h1 className="hero-title">{feature.title}</h1>
              <h3>{feature.description}</h3>
              {feature.length && <p>{calculateLength(feature.length)}</p>}
              <button className="feature-btn" onClick={handleContentClick}>
                {feature?.action_string?.toLowerCase()}
              </button>
            </div>
          </div>
        </div>
        <div className="results-container">
          <div className="other-content">
            {promotions?.length > 0 && sectionid === pageTitle && (
              <Slider
                title={false}
                id={"promo_dsakghsdalf"}
                media={promotions}
                target={false}
                promo
              />
            )}
            {takeAction?.length > 0 && (
              <Slider
                title={t("FY005")}
                id={"takeAction_kdasjf"}
                media={takeAction}
                target={false}
              />
            )}
            {sliders?.length > 0 &&
              sliders.map((slider) => {
                if (slider.media.length > 0) {
                  return (
                    <Slider
                      title={slider.title}
                      key={`${slider.id}+${favourites.media.length}`}
                      id={slider.id}
                      media={slider.media}
                      target={false}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ForYou;
