import { useTranslation } from "react-i18next";

import "./WhitePopUpStyles.css";

import VideoPlayer from "../../../platform/components/Video";

const WhiteVideoPopUp = ({ title, video, onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-title">{title}</div>
        <div className="popup-body">
          <div className="popup-text-container">
            <div className="video_container video">
              <VideoPlayer
                image={
                  "https://res.cloudinary.com/champion-health/image/upload/v1630603701/authors/images/video_bg.png"
                }
                url={video}
                type="author"
              />
            </div>
          </div>
        </div>
        <div className="popup-cta-container" onClick={onClose}>
          <div className="popup-cta"> {t("AT007")}</div>
        </div>
      </div>
    </div>
  );
};

export default WhiteVideoPopUp;
